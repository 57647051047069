import { Icon } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { setOpen } from "../../actions/ventas_estimadas";

const useStyles = makeStyles((theme) => ({
  fab: {
    bottom: theme.spacing(0),
    
    marginLeft: "10px",
  },
  extendedIcon: {
    
  }
}));
const AddVentaEstimadaBtn = ({ dispatch }) => {
  const classes = useStyles();

  return (
    
      <a onClick={() => dispatch(setOpen(true))} color="primary" aria-label="Add" className={classes.fab}>
        <Icon>edit</Icon>
      </a>
    
  );
};
const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    current: state.ventasEstimadas.current
  };
};

export default connect(mapStateToProps)(AddVentaEstimadaBtn);
