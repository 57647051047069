import {
  Button, CardActions, Grid,
  Typography
} from '@material-ui/core/';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  clearCurrent, deleteProducto,

  setCurrent
} from '../../actions/productos';

const useStyles = makeStyles({
  card: {
    maxWidth: 280
  },
  cardActions: {
    backgroundColor: '#f7f7f7'
  },
  addressLink: {
    color: 'black',
    textDecorationLine: 'none',

    overflow: 'hidden',

    '&:hover': {
      textDecorationLine: 'underline'
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: '120px',
    //width: '120px',
    overflowY: 'auto',
    fontSize: '0.9rem'
  },
  cardTitle: {
    fontSize: '1.15rem'
  }
});

const ProductoItem = ({ producto, productos, current, dispatch }) => {
  const { id, nombre, stock, cod } = producto;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteProducto(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, producto) => {
    confirm( {
              description: `Desea borrar la producto "${producto.nombre}"?`,
              title: 'ATENCION',
              cancellationText: 'Cancelar',
              confirmationText: 'Confirmar'
            })

    .then(() => onDelete());
}

  return (
    <Grid item xs={6} sm={6} md={2} xl={2} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
        <Typography
        gutterBottom
        variant='h6'
        component='h6'
        className={classes.cardTitle}
      >
      {cod && cod + ' - '} {nombre}
      </Typography>
      <Typography
      gutterBottom
      
      
      className={classes.cardTitle}
    >
     Stock: {stock}
    </Typography>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            onClick={e=>onClickBorrar(confirm, dispatch, producto)}
            size='small'
            color='secondary'
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={() => dispatch(setCurrent(producto))}
            size='small'
            color='primary'
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

ProductoItem.propTypes = {
  producto: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    productos: state.productos.productos,
    current: state.productos.current
  };
};

export default connect(mapStateToProps)((ProductoItem));
