import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getZonas } from '../../actions/zonas';
import subscribeTo from '../../utils/subscribeTo';
import AddZonaBtn from './AddZonaBtn';
import ZonaForm from './ZonaForm';
import ZonaItem from './ZonaItem';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const Zonas = ({ zonas, filtered, loading, dispatch }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getZonas());
    if (!loading) {
      subscribeTo('zonasChanged', (err, data) => {
        dispatch(getZonas());
      });
      return () => {
        subscribeTo('zonasChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (zonas !== null && !zonas.length && !loading) {
    return (
      <Fragment>
        <ZonaForm />
        <h4>No hay ninguna zona todavía!</h4> <AddZonaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ZonaForm />
      </div>
      <div>
        <div className={classes.root}>
          {zonas !== null && !loading ? (
            <Fragment>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
              >
                {(filtered ? filtered : zonas).map((zona, key) => (
                  <ZonaItem key={key} zona={zona} />
                ))}
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddZonaBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    zonas: state.zonas.zonas,
    filtered: state.zonas.filtered,
    loading: state.zonas.loading
  };
};

export default connect(mapStateToProps)(Zonas);
