import React, { useState, useEffect, Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { getChequesPropios, setCurrent } from '../../actions/cheques_propios';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Grid, Button } from '@material-ui/core/';
import subscribeTo from '../../utils/subscribeTo';
import ChequePropioForm from '../cheques_propios/ChequePropioForm';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import AddChequePropioBtn from '../cheques_propios/AddChequePropioBtn';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const ChequesPropios = ({ cheques_propios, filtered, loading, dispatch }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getChequesPropios());
    if (!loading) {
      subscribeTo('cheques_propiosChanged', (err, data) => {
        dispatch(getChequesPropios());
      });
      return () => {
        subscribeTo('cheques_propiosChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (cheques_propios !== null && !cheques_propios.length && !loading) {
    return (
      <Fragment>
        <ChequePropioForm />
        <h4>No hay ningun cheque_propio todavía!</h4> <AddChequePropioBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ChequePropioForm />
      </div>
      <div>
        <div className={classes.root}>
          {cheques_propios !== null && !loading ? (
            <Fragment>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <ReactTable
                    data={cheques_propios}
                    getTdProps={(state, rowInfo, column, instance) => {
                      return {
                        onClick: (e, handleOriginal) => {
                          if (column.id !== 'acciones') {
                            if (rowInfo) dispatch(setCurrent(rowInfo.original));
                          }
                          if (handleOriginal) {
                            handleOriginal();
                          }
                        }
                      };
                    }}
                    previousText='Anterior'
                    nextText='Próxima'
                    loadingText='Cargando...'
                    noDataText='Nada cargado'
                    pageText='Pagina'
                    ofText='de'
                    rowsText='filas'
                    pageJumpText='ir a la pág.'
                    rowsSelectorText='filas por pág.'
                    defaultPageSize={14}
                    columns={[
                      {
                        columns: [
                          {
                            Header: 'Fecha Cobro',
                            accessor: 'fecha_formatted'
                          },
                          {
                            Header: 'Valor',
                            style: { textAlign: 'right' },
                            id: 'valor',
                            accessor: row =>
                              row.valor !== undefined
                                ? parseFloat(row.valor.toFixed(2))
                                : '',
                            Cell: row =>
                              '$' +
                              (row.original.valor !== undefined
                                ? parseFloat(row.original.valor.toFixed(2))
                                : '')
                          },
                          {
                            Header: 'Estado',
                            style: { textAlign: 'right' },
                            id: 'estado',
                            accessor: row =>
                              row.lanzamientoCajaObj !== undefined
                                ? row.lanzamientoCajaObj.gasto
                                  ? 'Forma de pago'
                                  : 'Depositado'
                                : 'En caja'
                          },
                          {
                            Header: 'Notas',
                            accessor: 'nota'
                          }
                        ]
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddChequePropioBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    cheques_propios: state.chequesPropios.cheques_propios,
    filtered: state.chequesPropios.filtered,
    loading: state.chequesPropios.loading
  };
};

export default connect(mapStateToProps)(ChequesPropios);
