import { Button, Menu, MenuItem, TableCell } from "@material-ui/core/";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import axios from "axios";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  addContacto,
  removeContactosSemana,
  setCurrent,
} from "../../actions/clientes";
import { setCurrentVenta, setOpenVenta } from "../../actions/ventas";
import { clienteMotivosComunicacion } from "../../config/constantes";

const useStyles = makeStyles({
  button: {
    minWidth: "32px",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
});

const PanelVentasBotones = ({ cliente, clientes, current, dispatch }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const editUltimoPedido = async (cliente) => {
    const res = await axios.get(
      "/api/ventas/ultima_venta_cliente/" + cliente.id
    );

    const ventaAnterior = res.data;
    dispatch(setCurrentVenta(res.data));
  };

  return (
    <Fragment>
      <TableCell align="center">
        <Button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          color="secondary"
          style={{ minWidth: "32px" }}
          title={"No compra"}
        >
          <Icon>thumb_down</Icon>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(event) => {
            setAnchorEl(null);
          }}
        >
          {clienteMotivosComunicacion.map((m) => (
            <MenuItem
              key={m.id}
              onClick={(event) => {
                dispatch(
                  addContacto({
                    ...cliente,
                    nuevoContacto: {
                      cliente_tipo_comunicacion_id: 1,
                      cliente_motivo_comunicacion_id: m.id,
                    },
                  })
                );
                setAnchorEl(null);
              }}
            >
              {m.nombre}
            </MenuItem>
          ))}
        </Menu>
        <Button
          title={"No responde"}
          onClick={(e) =>
            dispatch(
              addContacto({
                ...cliente,
                nuevoContacto: { cliente_tipo_comunicacion_id: 2 },
              })
            )
          }
          style={{ color: "blue", minWidth: "32px" }}
        >
          <Icon>phone_missed</Icon>
          <span style={{ marginTop: "-10px", fontSize: "10px" }}>
            {cliente.comunicaciones
              ? cliente.comunicaciones.length -
                cliente.comunicaciones.filter(
                  (cont) =>
                    cont.cliente_tipo_comunicacion_id === 1 ||
                    cont.cliente_tipo_comunicacion_id === 3
                ).length
              : "0"}
          </span>
        </Button>
        <Button
          title={
            cliente.comunicacionesSemana &&
            cliente.comunicacionesSemana.length > 0
              ? "Editar última venta"
              : "Editar cliente"
          }
          onClick={() =>
            cliente.comunicacionesSemana &&
            cliente.comunicacionesSemana.length > 0
              ? editUltimoPedido(cliente)
              : dispatch(setCurrent(cliente))
          }
          style={{ color: "grey", minWidth: "32px" }}
        >
          <Icon>edit</Icon>
        </Button>
        <Button
          title={"Nuevo pedido"}
          onClick={() => dispatch(setOpenVenta(cliente))}
          style={{ color: "green", minWidth: "32px" }}
        >
          <Icon>add_shopping_cart</Icon>
        </Button>
        {cliente.comunicacionesSemana &&
          cliente.comunicacionesSemana.length > 0 && (
            <Button
              onClick={(e) => dispatch(removeContactosSemana({ ...cliente }))}
              style={{ color: "red", minWidth: "32px" }}
            >
              <Icon>undo</Icon>
            </Button>
          )}
      </TableCell>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    current: state.clientes.current,
  };
};

export default connect(mapStateToProps)(PanelVentasBotones);
