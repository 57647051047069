import axios from "axios";

import {
  ADD_VENTAS_ESTIMADAS,
  DELETE_VENTAS_ESTIMADAS,
  SET_CURRENT_VENTAS_ESTIMADAS,
  CLEAR_CURRENT_VENTAS_ESTIMADAS,
  UPDATE_VENTAS_ESTIMADAS,
  FILTER_VENTAS_ESTIMADAS,
  CLEAR_FILTER_VENTAS_ESTIMADAS,
  ERROR_VENTAS_ESTIMADAS,
  GET_VENTAS_ESTIMADAS,
  CLEAR_VENTAS_ESTIMADAS,
  SET_OPEN_VENTAS_ESTIMADAS,
  GET_MIX_VENTAS,
  AJUSTAR_STOCK,
  GET_AJUSTES_STOCK,
} from "./types";

//Add VentaEstimada
export const addVentaEstimada = (venta_estimada) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "/api/ventas_estimadas",
        venta_estimada,
        config
      );
      dispatch({ type: ADD_VENTAS_ESTIMADAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.msg });
    }
  };
};

export const getVentasEstimadas = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/ventas_estimadas");
      dispatch({ type: GET_VENTAS_ESTIMADAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.msg });
    }
  };
};

export const getMixVentas = (start = null, end = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        "/api/ventas_estimadas/mix_ventas" +
          (start ? "/" + start + "/" + end : "")
      );
      dispatch({ type: GET_MIX_VENTAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.msg });
    }
  };
};

export const getAjustesStock = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/ventas_estimadas/ajustes_stock");
      dispatch({ type: GET_AJUSTES_STOCK, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.msg });
    }
  };
};

export const ajustarStock = (ajuste, multiple = false) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "/api/ventas_estimadas/ajustar_stock",
        ajuste,
        config
      );
      dispatch({ type: AJUSTAR_STOCK, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.msg });
    }
  };
};

//Delete VentaEstimada

export const deleteVentaEstimada = (venta_estimadaId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(
        `/api/ventas_estimadas/${venta_estimadaId}`
      );

      dispatch({ type: DELETE_VENTAS_ESTIMADAS, payload: venta_estimadaId });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.response });
    }
  };
};

//Set Current VentaEstimada

export function setCurrent(venta_estimada) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_VENTAS_ESTIMADAS, payload: venta_estimada });
  };
}

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_VENTAS_ESTIMADAS, payload: open });
  };
}

//Clear Current VentaEstimada
export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_VENTAS_ESTIMADAS });
  };
};
//Update VentaEstimada

export const updateVentaEstimada = (venta_estimada) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/ventas_estimadas/${venta_estimada.id}`,
        venta_estimada,
        config
      );
      dispatch({ type: UPDATE_VENTAS_ESTIMADAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTAS_ESTIMADAS, payload: error.response.msg });
    }
  };
};

//Filter VentaEstimada

export const filterVentasEstimadas = (text) => {
  return (dispatch) => {
    dispatch({ type: FILTER_VENTAS_ESTIMADAS, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_VENTAS_ESTIMADAS });
  };
};

export const clearVentasEstimadas = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VENTAS_ESTIMADAS });
  };
};
