import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { filterCheques } from "../../actions/cheques";
import { cEstadosCheques } from "../../config/constantes";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  listIcon: {
    paddingRight: "25px",
  },
}));

const ChequesFilter = ({ cheques, filter, filtered, dispatch }) => {
  useEffect(() => {}, [filtered]);
  const classes = useStyles();

  const onChange = (e, name) => {
    let prop = name ? name : e.target.name;
    dispatch(filterCheques({ ...filter, [prop]: e.target.value }));
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={4} align="right">
        <TextField
          type="text"
          label="Numero / Valor"
          name="numero"
          margin="dense"
          variant="outlined"
          onChange={onChange}
          fullWidth
          value={filter.numero}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          type="text"
          name="nombre"
          label="Razón Social/Cliente"
          margin="dense"
          variant="outlined"
          onChange={onChange}
          fullWidth
          value={filter.nombre}
        />
      </Grid>
      <Grid item md={4}>
        <FormControl
          variant="outlined"
          style={{
            height: "40px",
            width: "100%",
            paddingTop: "8px",
          }}
        >
          <InputLabel htmlFor="select-tipo-comprobante">E-Check</InputLabel>
          <Select
            input={
              <OutlinedInput
                style={{
                  height: "40px",
                  width: "80%",
                }}
                id="select-tipo-comprobante"
              />
            }
            value={filter.echeck || []}
            name="echeck"
            multiple={false}
            onChange={(e) => onChange(e, "echeck")}
          >
            {["-", "Si", "No"].map((echeck) => (
              <MenuItem value={echeck} key={echeck}>
                {echeck}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4}>
        <FormControl
          variant="outlined"
          style={{
            height: "40px",
            width: "100%",
            paddingTop: "8px",
          }}
        >
          <InputLabel htmlFor="select-tipo-comprobante">Estado</InputLabel>
          <Select
            input={
              <OutlinedInput
                style={{
                  height: "40px",
                  width: "80%",
                }}
                id="select-tipo-comprobante"
              />
            }
            value={filter.estados || []}
            name="estado"
            multiple={true}
            onChange={(e) => onChange(e, "estados")}
          >
            {cEstadosCheques.map((estado) => (
              <MenuItem value={estado.id} key={estado.id}>
                {estado.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <div
          style={{
            height: "40px",
            width: "100%",
            paddingTop: "12px",
          }}
        >
          Fecha de Emision:{" "}
          <DateRangePicker
            value={filter.fechaEmision}
            onChange={(ranges) => {
              dispatch(
                filterCheques({
                  ...filter,
                  fechaEmision: ranges,
                })
              );
            }}
            locale="es-AR"
          />{" "}
          Fecha de Cobro:{" "}
          <DateRangePicker
            value={filter.fechaCobro}
            onChange={(ranges) =>
              dispatch(
                filterCheques({
                  ...filter,
                  fechaCobro: ranges,
                })
              )
            }
            locale="es-AR"
          />
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.cheques.filter,
    filtered: state.cheques.filtered,
    cheques: state.cheques,
  };
};

export default connect(mapStateToProps)(ChequesFilter);
