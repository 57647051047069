import {
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  fab2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(12)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ImprimirMultipleBtn = ({ dispatch, ids, modo }) => {
  const [documento, setDocumento] = useState("Original y Duplicado");
  const classes = useStyles();
  const confirm = useConfirm();

  const onClickAprobar = (confirm, dispatch) => {
    let opcion;
    /*
              "Original",
              "Duplicado",
              "Original y Duplicado",
              "Original, Duplicado y Triplicado",
              "Remitos (Triplicado)"
    */
    if (documento === "Original") opcion = "original";
    else if (documento === "Original y Duplicado") opcion = "duplicado";
    else if (documento === "Original, Duplicado y Triplicado")
      opcion = "triplicado";
    else if (documento === "Duplicado") opcion = "solo_duplicado";

    if (documento === "Remitos (Triplicado)") {
      let w = window.open("/api/remito/pdf/" + ids.join(","));
    } else {
      let w = window.open("/api/invoice/pdf/" + opcion + "/" + ids.join(","));
    }
  };

  return (
    <div className="fixed-action-btn">
      <div
        style={{
          color: "black",
          backgroundColor: "rgb(237 247 237)",
          padding: "10px",
          border: "1px black solid",
          borderRadius: "10px"
        }}
        className={classes.fab2}
      >
        <Fragment>
          {" "}
          Imprimir:{" "}
          <Select
            input={
              <OutlinedInput
                style={{
                  height: "40px",
                  width: "200px"
                }}
                id="select-forma-pago"
              />
            }
            onChange={(e) => setDocumento(e.target.value)}
            value={documento}
            name="documento"
          >
            {[
              "Original",
              "Duplicado",
              "Original y Duplicado",
              "Original, Duplicado y Triplicado",
              "Remitos (Triplicado)"
            ].map((tipo, index) => (
              <MenuItem value={tipo} key={index}>
                {tipo}
              </MenuItem>
            ))}
          </Select>
        </Fragment>
      </div>

      <Fab
        style={{ color: "white", backgroundColor: "navy" }}
        aria-label="Add"
        onClick={(e) => onClickAprobar(confirm, dispatch)}
        className={classes.fab}
      >
        <Icon>print</Icon>
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    current: state.ventas.current
  };
};

export default connect(mapStateToProps)(ImprimirMultipleBtn);
