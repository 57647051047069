import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import {
  GET_RENDICIONES,
  RENDICION_ERROR,
  DELETE_RENDICION,
  SET_CURRENT,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_RENDICIONES,
  CLEAR_FILTER,
  ADD_RENDICION,
  UPDATE_RENDICION,
  SET_SAVING_RENDICION,
  CHEQUEAR_RENDICION
} from "../actions/types";

const initialState = {
  rendiciones: null,
  current: null,
  filters: { descripcion: "", tipo: "" },
  filtered: null,
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  switch (action.type) {
    case GET_RENDICIONES:
      return {
        ...state,
        rendiciones: action.payload,
        loading: false
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };
    case SET_SAVING_RENDICION:
      return {
        ...state,
        saving: action.payload
      };

    case FILTER_RENDICIONES:
      const shouldFilter = (rendicion, property, value) => {
        switch (property) {
          case "descripcion":
            return rendicion.descripcion.match(new RegExp(`${escapeRegExp(value)}`, "gi"));
            break;
          case "tipo":
            return rendicion.tipo.match(value);
            break;
          case "fecha_cobranza":
          case "fecha_pago":
            return (
              formatDate(rendicion[property]) >= formatDate(value[0]) &&
              formatDate(rendicion[property]) <= formatDate(value[1])
            );
            break;
          default:
            break;
        }
      };
      return {
        ...state,
        filters: action.filter,
        filtered: state.rendiciones.filter((rendicion) => {
          let ok = true;
          for (var property in action.filter) {
            //if (action.filterType === property) continue;
            if (action.filter.hasOwnProperty(property) && action.filter[property]) {
              ok = ok && shouldFilter(rendicion, property, action.filter[property]);
            }
          }
          //ok = ok && shouldFilter(rendicion, action.filterType, action.payload);
          return ok;
        })
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null
      };
    case ADD_RENDICION:
      return {
        ...state,
        rendiciones: [action.payload, ...state.rendiciones],
        loading: false
      };
    case UPDATE_RENDICION:
      return {
        ...state,
        rendiciones: state.rendiciones.map((rendicion) =>
          rendicion.id === action.payload.id ? action.payload : rendicion
        )
      };
    case CHEQUEAR_RENDICION:
      return {
        ...state,
        rendiciones: state.rendiciones.map((rendicion) =>
          rendicion.id === action.payload.id ? { ...rendicion, chequeada: action.payload.chequeada } : rendicion
        )
      };
    case DELETE_RENDICION:
      return {
        ...state,
        rendiciones: state.rendiciones.filter((rendicion) => rendicion.id !== action.payload),
        loading: false
      };
    /*

    case CLEAR_CONTACTS:
      return {
        ...state,
        transportistas: null,
        error: null,
        current: null,
        loading: null
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };

    case FILTER_CONTACTS:
      return {
        ...state,
        filtered: state.transportistas.filter(transportista => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return (
            transportista.nombre.match(regex) ||
            transportista.email.match(regex)
          );
        })
      };

    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload
      };
      */
    default:
      return state;
  }
};
