import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { addProducto, clearCurrent, setOpen, updateProducto } from "../../actions/productos";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const emptyProducto = {
  nombre: "",
  stock: ""
};

const ProductoForm = ({ children, current, open, dispatch }) => {
  const [producto, setProducto] = useState(emptyProducto);

  const { nombre, stock, peso, contabilium_id, cod } = producto;

  useEffect(() => {
    if (current !== null) {
      setProducto(current);
    } else {
      setProducto(emptyProducto);
    }
  }, [current, open]);

  const onChange = (e) => {
    setProducto({ ...producto, [e.target.name]: e.target.value });
  };

  const onValueChange = (e, name) => {
    setProducto({ ...producto, [name]: e.floatValue });
  };

  const onChangeCheck = (name) => (event) => {
    setProducto({ ...producto, [name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!nombre) {
    } else {
      try {
        if (!current) {
          await dispatch(addProducto(producto));
        } else {
          await dispatch(updateProducto(producto));
        }
      } catch (error) {
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setProducto(emptyProducto);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current ? "Nuevo producto" : "Editar producto"}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                mask=""
                decimalScale={0}
                allowNegative={false}
                margin="dense"
                name="cod"
                label="Cod"
                value={cod}
                onValueChange={(e) => onValueChange(e, "cod")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                name="nombre"
                label="Nombre"
                value={nombre}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                mask=""
                decimalScale={0}
                allowNegative={false}
                margin="dense"
                name="peso"
                label="Peso (grs)"
                value={peso}
                onValueChange={(e) => onValueChange(e, "peso")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                mask=""
                decimalScale={0}
                allowNegative={false}
                margin="dense"
                name="stock"
                label="Stock"
                value={stock}
                disabled={true}
                onValueChange={(e) => onValueChange(e, "stock")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
              <input type="hidden" name="contabilium_id" value={contabilium_id} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} variant="contained" color="primary" type="submit">
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.productos.current,
    open: state.productos.open
  };
};

export default connect(mapStateToProps)(ProductoForm);
