import {
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-date-picker";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { getCajas } from "../../actions/cajas";
import { getCheques } from "../../actions/cheques";
import { getChequesPropios } from "../../actions/cheques_propios";
import { getClientes } from "../../actions/clientes";
import { getGrupos } from "../../actions/grupos";
import {
  addCobranza,
  clearCurrent,
  setOpen,
  updateCobranza,
} from "../../actions/cobranzas";
import { getUsuarios } from "../../actions/usuarios";
import { getVentasClienteNoPagas } from "../../actions/ventas";
import {
  setOpen as setOpenCheques,
  setCurrent as setCurrentCheques,
} from "../../actions/cheques";
import AlertContext from "../../context/alert/alertContext";
import { getCurrentUser } from "../usuarios/Usuario";
import {
  provincias,
  tiposCobranzasCobro,
  tiposComprobante,
  tiposRetenciones,
  cTiposCajas,
} from "../../config/constantes";
import { getRendiciones } from "../../actions/rendiciones";
import {
  esNotaDeCreditoOPagoACuenta,
  esX,
  formaCobroCajaTipo,
  toFixed,
} from "../../utils/functions";
import ReactSelectNonCreate from "react-select";
import ChequeForm from "../cheques/ChequeForm";
import BigNumber from "bignumber.js";
import axios from "axios";
/** SQL
 
UPDATE ventas SET saldada=1 WHERE id NOT IN (SELECT id FROM `ventas` WHERE cliente_id IN (SELECT id FROM clientes WHERE grupo_id=21)) AND cliente_id<>301 AND cliente_id<>210 AND clasificacion <> 4;
UPDATE ventas SET saldada=1 WHERE cliente_id<>301 AND cliente_id<>210;
  
 */
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formHeader: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",

    padding: "10px",
  },
}));

export const emptyCobranza = (ultimaRendicion) => ({
  tipo: "",
  fecha_cobranza: new Date(),
  fecha_rendicion: new Date(),
  cliente_id: null,
  provedor_id: null,
  nro_cobranza: "",
  rendidor_id: getCurrentUser().id,
  n_ruta: "",
  observaciones: "",
  ventas: [],
  rendicion_id: ultimaRendicion ? ultimaRendicion : -1,
  formas_cobro: [],
  retenciones_sufridas: [],
  cliente: null,
  rendidor: null,
  balance: null,
});

const CobranzaForm = ({
  children,
  current,
  open,
  saving,
  dispatch,
  clientes,
  loading_clientes,
  loading_grupos,
  grupos,
  cajas,
  loading_cajas,
  cheques,
  loading_cheques,
  cheques_propios,
  loading_cheques_propios,
  usuarios,
  loading_usuarios,
  loading_ventas,
  ventas_clientes_no_pagas,
  error,
  rendiciones,
  loading_rendiciones,
  last_cheque_id,
  ultimaRendicion,
  setUltimaRendicion,
}) => {
  const [cobranza, setCobranza] = useState(emptyCobranza(ultimaRendicion));
  const [balance, setBalance] = useState(null);
  const [cobranzaX, setCobranzaX] = useState(
    current ? current.cobranza_x : null
  );
  const alertContext = useContext(AlertContext);
  const [currentFormaCobro, setCurrentFormaCobro] = useState(null);
  const [dropdownClientes, setDropdownClientes] = useState([]);
  const [ventasClientesNoPagas, setVentasClientesNoPagas] = useState(
    ventas_clientes_no_pagas
  );
  const [cobranzaOriginal, setCobranzaOriginal] = useState(null);
  const {
    id,
    cliente_id,
    provedor_id,
    fecha_cobranza,
    fecha_rendicion,
    nro_cobranza,
    rendidor_id,
    n_ruta,
    observaciones,
    ventas,
    cliente,
    grupo,
    rendidor,
    formas_cobro,
    retenciones_sufridas,
    rendicion_id,
  } = cobranza;

  const dirRef = useRef("");
  useEffect(() => {
    dispatch(getClientes());
    dispatch(getGrupos());
    dispatch(getUsuarios());
    dispatch(getCajas());
    dispatch(getCheques());
    dispatch(getRendiciones());
    dispatch(getChequesPropios());
    setVentasClientesNoPagas({});
    if (current && current.cliente_id) {
      dispatch(getVentasClienteNoPagas(current.cliente_id));
    }
    if (current)
      if (current && current.cobranza_x) {
        setCobranzaX(current.cobranza_x);
      } else {
        setCobranzaX(false);
      }
    if (!current || !current.fetch) {
      if (current !== null) {
        setCobranza(current);
      } else {
        setCobranza(emptyCobranza(ultimaRendicion));
      }
    } else {
      //incomplete?
      axios
        .post(
          "/api/cobranzas/getCobranzas",
          { id: current.id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setCobranza(res.data[0]);
        });
    }
  }, [current, open]);

  useEffect(() => {
    if (
      rendicion_id === -1 &&
      rendiciones &&
      fecha_cobranza &&
      rendidor_id &&
      n_ruta
    ) {
      let rendicionTmp = rendiciones.find(
        (r) =>
          r.codigo ===
          moment(fecha_cobranza).format("YYMMDD") +
            (rendidor
              ? rendidor.nombre.toUpperCase()
              : getCurrentUser().nombre.toUpperCase()) +
            n_ruta
      );
      if (rendicionTmp) {
        setCobranza({
          ...cobranza,
          rendicion_id: rendicionTmp.id,
          n_ruta: null,
          fecha_cobranza: "",
        });
        if (setUltimaRendicion) setUltimaRendicion(rendicionTmp.id);
      }
    } else {
      if (setUltimaRendicion) setUltimaRendicion(rendicion_id);
    }
  }, [fecha_cobranza, n_ruta, rendidor_id, rendicion_id]);

  useEffect(() => {
    calcularBalance();
  }, [cobranza]);

  useEffect(() => {
    if (current && current.id) {
      setCobranzaOriginal(current);
    } else {
      setCobranzaOriginal(null);
    }
  }, [current, open]);

  useEffect(() => {}, [ventasClientesNoPagas]);

  useEffect(() => {
    if (current && current.id && ventas_clientes_no_pagas[cliente_id]) {
      //on edit simular ventas como no pagas
      setVentasClientesNoPagas({
        ...ventas_clientes_no_pagas,
        [cliente_id]: {
          ...ventas_clientes_no_pagas[cliente_id],
          ventas: ventas_clientes_no_pagas[cliente_id].ventas
            .concat(current.ventas)
            .concat([
              {
                id: -1,
                numero_factura: "PAGO A CUENTA",
                tipo_comprobante_id: 9,
                balance: 0,
              },
              {
                id: -3,
                numero_factura: "PAGO A CUENTA X",
                tipo_comprobante_id: 10,
                balance: 0,
              },
            ]),
        },
      });
    } else {
      if (cliente_id)
        setVentasClientesNoPagas({
          ...ventas_clientes_no_pagas,
          [cliente_id]: {
            ...ventas_clientes_no_pagas[cliente_id],
            ventas: ventas_clientes_no_pagas[cliente_id].ventas.concat([
              {
                id: -1,
                numero_factura: "PAGO A CUENTA",
                tipo_comprobante_id: 9,
                balance: 0,
              },
              {
                id: -3,
                numero_factura: "PAGO A CUENTA X",
                tipo_comprobante_id: 10,
                balance: 0,
              },
            ]),
          },
        });
    }
  }, [ventas_clientes_no_pagas]);

  useEffect(() => {
    if ((currentFormaCobro || currentFormaCobro === 0) && last_cheque_id)
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, currentFormaCobro),
          {
            ...formas_cobro[currentFormaCobro],
            cheque_id: last_cheque_id,
            importe: cheques.find((ch) => ch.id === last_cheque_id).valor,
          },
          ...formas_cobro.slice(currentFormaCobro + 1),
        ],
      });
  }, [last_cheque_id]);

  const calcularBalance = () => {
    let balTmp =
      cliente_id &&
      ventas &&
      ventas.length > 0 &&
      (ventas[0].importe || ventas[0].importe === 0)
        ? Math.round(
            (ventas.reduce((a, b) => ({ importe: a.importe + b.importe }))
              .importe -
              (formas_cobro.length
                ? formas_cobro.reduce((a, b) => ({
                    importe: (a.importe || 0) + (b.importe || 0),
                  })).importe
                : 0) -
              (retenciones_sufridas.length
                ? retenciones_sufridas.reduce((a, b) => ({
                    importe: (a.importe || 0) + (b.importe || 0),
                  })).importe
                : 0)) *
              100
          ) / 100
        : 0;
    setBalance(balTmp);
  };

  const onChange = (e, special_field = null) => {
    if (!special_field) {
      setCobranza({ ...cobranza, [e.target.name]: e.target.value });
    } else if (special_field === "cliente_id") {
      setCobranzaX(null);
      const clienteTmp = clientes.find((p) => p.id === e.value);
      dispatch(getVentasClienteNoPagas(e.value)).then(() =>
        console.log(ventas_clientes_no_pagas)
      );
      if (e.value[0] !== "g") {
        setCobranza({
          ...cobranza,
          cliente_id: e.value,
          cliente: clienteTmp,
          ventas: [],
          formas_cobro: [],
          retenciones_sufridas: [],
        });
      } else {
        const grupoTmp = grupos.find(
          (p) => p.id === parseInt(e.value.slice(1))
        );
        setCobranza({
          ...cobranza,
          cliente_id: e.value,
          grupo: grupoTmp,
          ventas: [],
          formas_cobro: [],
          retenciones_sufridas: [],
        });
      }
    } else if (special_field === "rendidor_id") {
      if (e) {
        const usuarioTmp = usuarios.find((p) => p.id === e.value);
        setCobranza({
          ...cobranza,
          rendidor_id: e.value,
          rendidor: usuarioTmp,
        });
      } else {
        setCobranza({
          ...cobranza,
          rendidor_id: null,
          rendidor: null,
        });
      }
    } else if (special_field === "rendicion_id") {
      if (e) {
        setCobranza({
          ...cobranza,
          rendicion_id: e.value,
        });
      } else {
        setCobranza({
          ...cobranza,
          rendicion_id: -1,
          fecha_cobranza: "",
          n_ruta: null,
        });
      }
    } else {
      setCobranza({ ...cobranza, [special_field]: e });
    }
  };

  const onChangeVenta = (e, index, campo) => {
    if (campo === "remove") {
      if (cobranza.ventas.length === 1) setCobranzaX(null);
      setCobranza({
        ...cobranza,
        ventas: [...ventas.slice(0, index), ...ventas.slice(index + 1)],
      });
    } else if (campo === "importe") {
      setCobranza({
        ...cobranza,
        ventas: [
          ...ventas.slice(0, index),
          { ...ventas[index], importe: e.floatValue },
          ...ventas.slice(index + 1),
        ],
      });
    } else if (campo === "venta_id") {
      let ventaTmp = ventasClientesNoPagas[cliente_id].ventas.find(
        (p) => p.id === e.value
      );
      setCobranza({
        ...cobranza,
        ventas: [
          ...ventas.slice(0, index),
          {
            ...ventas[index],
            venta_id: e.value,
            importe:
              e.value < 0
                ? 0
                : ventaTmp.clasificacion !== 4 //Venta ficticia
                ? ventaTmp.balance
                : "",
          },
          ...ventas.slice(index + 1),
        ],
      });
      setCobranzaX(esX(ventaTmp.tipo_comprobante_id));
    }
  };

  const agregarVenta = () => {
    if (!cliente_id)
      alertContext.setAlert("Seleccione un cliente primero", "warning");

    setCobranza({
      ...cobranza,
      ventas: [
        ...ventas,
        { importe: "", venta_id: null, fecha_pago: new Date() },
      ],
    });
  };

  const onChangeFormaCobro = (e, index, campo) => {
    if (campo === "importe") {
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          { ...formas_cobro[index], importe: e.floatValue },
          ...formas_cobro.slice(index + 1),
        ],
      });
    } else if (campo === "remove") {
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          ...formas_cobro.slice(index + 1),
        ],
      });
    } else if (campo === "cheque_id") {
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          {
            ...formas_cobro[index],
            cheque_id: e.value,
            importe: cheques.find((ch) => ch.id === e.value).valor,
          },
          ...formas_cobro.slice(index + 1),
        ],
      });
    } else if (campo === "forma_pago_id") {
      let cajasTmp = cajas
        .filter((c) => (cobranzaX ? c.especial : !c.especial))
        .filter((c) => c.caja_tipo_id === formaCobroCajaTipo(e.value));
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          {
            ...formas_cobro[index],
            forma_pago_id: e.value,
            caja_id: cajasTmp.length === 1 ? cajasTmp[0].id : null,
            ...(formas_cobro.length === 1 &&
              !formas_cobro[0].importe && {
                importe: ventas
                  .filter((v) => v.importe)
                  .reduce((a, b) => ({ importe: a.importe + b.importe }), 0)
                  .importe,
              }),
          },
          ...formas_cobro.slice(index + 1),
        ],
      });
    } else if (campo === "caja_id") {
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          {
            ...formas_cobro[index],
            caja_id: e.value,
          },
          ...formas_cobro.slice(index + 1),
        ],
      });
    } else if (campo === "referencia") {
      setCobranza({
        ...cobranza,
        formas_cobro: [
          ...formas_cobro.slice(0, index),
          {
            ...formas_cobro[index],
            referencia: e.target.value,
          },
          ...formas_cobro.slice(index + 1),
        ],
      });
    }
  };

  const agregarFormaCobro = () => {
    if (!cliente_id)
      alertContext.setAlert("Seleccione un cliente primero", "warning");
    let importe = "";
    importe = balance;
    setCobranza({
      ...cobranza,
      formas_cobro: [
        ...formas_cobro,
        {
          forma_pago_id: null,
          caja_id: null,
          importe: importe,
          referencia: "",
        },
      ],
    });
  };

  const agregarRedondeo = () => {
    if (!cliente_id)
      alertContext.setAlert("Seleccione un cliente primero", "warning");
    let importe = "";
    importe = balance;
    setCobranza({
      ...cobranza,
      formas_cobro: [
        ...formas_cobro,
        {
          forma_pago_id: 1,
          caja_id: cobranzaX ? 12 : 11,
          importe: importe,
          referencia: "Redondeo",
        },
      ],
    });
  };

  const onChangeRetencionesSufridas = (e, index, campo) => {
    if (campo === "importe") {
      setCobranza({
        ...cobranza,
        retenciones_sufridas: [
          ...retenciones_sufridas.slice(0, index),
          { ...retenciones_sufridas[index], importe: e.floatValue },
          ...retenciones_sufridas.slice(index + 1),
        ],
      });
    } else if (campo === "remove") {
      setCobranza({
        ...cobranza,
        retenciones_sufridas: [
          ...retenciones_sufridas.slice(0, index),
          ...retenciones_sufridas.slice(index + 1),
        ],
      });
    } else if (campo === "tipo_retencion_id") {
      setCobranza({
        ...cobranza,
        retenciones_sufridas: [
          ...retenciones_sufridas.slice(0, index),
          {
            ...retenciones_sufridas[index],
            tipo_retencion_id: e.value,
          },
          ...retenciones_sufridas.slice(index + 1),
        ],
      });
    } else if (campo === "numero_certificado") {
      setCobranza({
        ...cobranza,
        retenciones_sufridas: [
          ...retenciones_sufridas.slice(0, index),
          {
            ...retenciones_sufridas[index],
            numero_certificado: e.target.value,
          },
          ...retenciones_sufridas.slice(index + 1),
        ],
      });
    } else if (campo === "provincia_id") {
      setCobranza({
        ...cobranza,
        retenciones_sufridas: [
          ...retenciones_sufridas.slice(0, index),
          {
            ...retenciones_sufridas[index],
            provincia_id: e.target.value,
          },
          ...retenciones_sufridas.slice(index + 1),
        ],
      });
    }
  };

  const agregarRetencionesSufridas = () => {
    if (!cliente_id)
      alertContext.setAlert("Seleccione un cliente primero", "warning");
    setCobranza({
      ...cobranza,
      retenciones_sufridas: [
        ...retenciones_sufridas,
        { tipo_retencion_id: null, importe: "", numero_certificado: "" },
      ],
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let error = false;
    if (
      !cliente_id ||
      (rendicion_id === -1 &&
        (!fecha_cobranza || !rendidor_id || !n_ruta || !fecha_rendicion)) ||
      (!balance && balance !== 0) ||
      !ventas.length ||
      (balance !== 0 && !formas_cobro.length) ||
      !formas_cobro.every((fc) => fc.caja_id) ||
      !formas_cobro.every((fc) => fc.importe)
    ) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else if (
      formas_cobro.length &&
      BigNumber(
        +BigNumber(
          formas_cobro.reduce((a, b) => ({ importe: a.importe + b.importe }))
            .importe
        )
          .toNumber()
          .toFixed(2) +
          (retenciones_sufridas.length
            ? +BigNumber(
                retenciones_sufridas.reduce((a, b) => ({
                  importe: a.importe + b.importe,
                })).importe
              )
                .toNumber()
                .toFixed(2)
            : 0)
      ).toFixed(2) <
        +BigNumber(
          ventas.reduce((a, b) => ({ importe: a.importe + b.importe })).importe
        )
          .toNumber()
          .toFixed(2)
    ) {
      alertContext.setAlert(
        "Los cobros tienen que ser mayores o iguales a los comprobantes (" +
          BigNumber(
            +BigNumber(
              formas_cobro.reduce((a, b) => ({
                importe: a.importe + b.importe,
              })).importe
            )
              .toNumber()
              .toFixed(2) +
              (retenciones_sufridas.length
                ? +BigNumber(
                    retenciones_sufridas.reduce((a, b) => ({
                      importe: a.importe + b.importe,
                    })).importe
                  )
                    .toNumber()
                    .toFixed(2)
                : 0)
          ).toFixed(2) +
          " vs " +
          +BigNumber(
            ventas.reduce((a, b) => ({ importe: a.importe + b.importe }))
              .importe
          )
            .toNumber()
            .toFixed(2) +
          ")",
        "error"
      );
    } else {
      try {
        if (!current || !current.id) {
          dispatch(
            addCobranza({ ...cobranza, balance, cobranza_x: cobranzaX })
          ).then((r) => {
            alertContext.setAlert(
              "Nueva cobranza guardada correctamente!",
              "success"
            );
          });
        } else {
          dispatch(
            updateCobranza({ ...cobranza, balance, cobranza_x: cobranzaX })
          ).then((r) => {
            alertContext.setAlert("Cobranza editada correctamente!", "success");
          });
        }
      } catch (error) {}

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setCobranza(emptyCobranza(ultimaRendicion));
    dispatch(clearCurrent());
  };

  useEffect(() => {
    if (!loading_clientes && !loading_grupos) {
      setDropdownClientes(
        clientes
          .filter((c) => !c.grupo || c.grupo.cuenta_corriente_propia)
          //.concat(clientes.filter((c) => c.grupo && !c.grupo.cuenta_corriente_propia))
          .map((cliente) => ({
            value: cliente.id,
            label: cliente.nombre,
          }))
          .concat(
            grupos
              .filter((g) => !g.cuenta_corriente_propia)
              .map((g) => ({
                value: "g" + g.id,
                label: g.nombre,
              }))
          )
          .sort((a, b) => a.label > b.label)
      );
    }
  }, [loading_clientes, loading_grupos]);

  useEffect(() => {}, [cobranza]);

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      style={
        !useMediaQuery(useTheme().breakpoints.down("sm"))
          ? { width: "90%", height: "95%", margin: "auto" }
          : {}
      }
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle
        id="max-width-dialog-title"
        color="primary"
        className={classes.formHeader}
      >
        {!current ? "Nueva Cobranza" : "Editar Cobranza"} - Cod. Rendicion:{" "}
        {rendiciones && rendicion_id && rendicion_id !== -1 ? (
          <Fragment>
            {rendiciones.find((p) => p.id === rendicion_id).codigo}
          </Fragment>
        ) : (
          <Fragment>
            {fecha_cobranza
              ? moment(fecha_cobranza).format("YYMMDD")
              : "AAMMDD"}
            {rendidor
              ? rendidor.nombre.toUpperCase()
              : getCurrentUser().nombre.toUpperCase()}
            {n_ruta ? n_ruta : "X"}
          </Fragment>
        )}
      </DialogTitle>

      <DialogContent dividers={true}>
        <ChequeForm />
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <form onSubmit={onSubmit}>
          {!loading_clientes &&
            !loading_grupos &&
            !loading_usuarios &&
            clientes &&
            usuarios &&
            rendiciones && (
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={3}>
                  {dropdownClientes.length > 0 && (
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "8px",
                      }}
                    >
                      <ReactSelect
                        fullWidth
                        value={
                          cliente_id &&
                          dropdownClientes.find((p) => p.value === cliente_id)
                            ? {
                                label: dropdownClientes.find(
                                  (p) => p.value === cliente_id
                                ).label,
                                value: cliente_id,
                              }
                            : ""
                        }
                        name="cliente_id"
                        placeholder="Seleccionar Cliente/Grupo... *"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            marginTop: "-8px",
                          }),
                        }}
                        menuPortalTarget={document.body}
                        onChange={(e) => onChange(e, "cliente_id")}
                        options={dropdownClientes}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <div>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "8px",
                      }}
                    >
                      <ReactSelect
                        isDisabled={current}
                        fullWidth
                        value={
                          rendicion_id &&
                          (rendiciones.find((p) => p.id === rendicion_id) ||
                            rendicion_id === -1)
                            ? {
                                label:
                                  rendicion_id === -1
                                    ? "Nueva"
                                    : rendiciones.find(
                                        (p) => p.id === rendicion_id
                                      ).codigo,
                                value: rendicion_id,
                              }
                            : ""
                        }
                        name="rendicion_id"
                        isClearable
                        placeholder="Rendición *"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            marginTop: "-8px",
                          }),

                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -15
                                : "50%",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize:
                              (state.hasValue ||
                                state.selectProps.inputValue) &&
                              13,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        onChange={(e) => onChange(e, "rendicion_id")}
                        options={[{ id: -1, codigo: "Nueva" }]
                          .concat(rendiciones)
                          .map((rendicion) => ({
                            value: rendicion.id,
                            label: rendicion.codigo
                              ? rendicion.codigo
                              : rendicion.fecha_cobranza,
                          }))}
                      />
                    </FormControl>
                  </div>
                </Grid>
                {rendicion_id === -1 && (
                  <Fragment>
                    <Grid item xs={12} md={3} style={{ marginTop: "10px" }}>
                      <div>
                        Fecha de Ruta: *{" "}
                        <DatePicker
                          onChange={(date) => onChange(date, "fecha_cobranza")}
                          value={
                            fecha_cobranza
                              ? moment(fecha_cobranza).toDate()
                              : null
                          }
                          format="dd/MM/y"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
                      <div>
                        Fecha de Rendicion: *{" "}
                        <DatePicker
                          onChange={(date) => onChange(date, "fecha_rendicion")}
                          value={
                            fecha_rendicion
                              ? moment(fecha_rendicion).toDate()
                              : null
                          }
                          format="dd/MM/y"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl
                        variant="outlined"
                        style={{
                          height: "40px",
                          width: "100%",
                          paddingTop: "8px",
                        }}
                      >
                        <ReactSelect
                          fullWidth
                          value={
                            rendidor_id &&
                            usuarios.find((p) => p.id === rendidor_id)
                              ? {
                                  label: usuarios.find(
                                    (p) => p.id === rendidor_id
                                  ).nombre,
                                  value: rendidor_id,
                                }
                              : ""
                          }
                          name="rendidor_id"
                          isClearable
                          placeholder="Rendidor *"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: "-8px",
                            }),

                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "50%",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize:
                                (state.hasValue ||
                                  state.selectProps.inputValue) &&
                                13,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={(e) => onChange(e, "rendidor_id")}
                          options={usuarios.map((usuario) => ({
                            value: usuario.id,
                            label: usuario.nombre,
                          }))}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ marginTop: "10px", verticalAlign: "middle" }}
                    >
                      <span
                        style={{ paddingTop: "18px", verticalAlign: "middle" }}
                      >
                        Ruta: *
                      </span>{" "}
                      <FormControl>
                        <Select
                          input={
                            <OutlinedInput
                              style={{
                                height: "35px",
                                width: "100px",
                              }}
                              margin="dense"
                              id="select-ruta"
                            />
                          }
                          onChange={(e) => onChange(e)}
                          value={n_ruta}
                          name="n_ruta"
                        >
                          {[1, 2, 3, 4, "N"].map((tipo, index) => (
                            <MenuItem value={tipo} key={index}>
                              {tipo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    margin="dense"
                    name="observaciones"
                    label="Observaciones"
                    variant="outlined"
                    multiline
                    id="observaciones"
                    value={observaciones || ""}
                    onChange={onChange}
                    fullWidth
                    rowsMax="3"
                  />
                </Grid>
              </Grid>
            )}
          {cliente_id &&
            !loading_ventas &&
            ventasClientesNoPagas[cliente_id] &&
            ventasClientesNoPagas[cliente_id].ventas &&
            ventasClientesNoPagas[cliente_id].ventas.length > 0 && (
              <Fragment>
                <h2 color="primary">
                  Comprobantes a cobrar{" "}
                  {ventas &&
                    ventas.length > 0 &&
                    ventas[0].importe > 0 &&
                    "(Total: $" +
                      toFixed(
                        ventas.reduce((a, b) => ({
                          importe:
                            BigNumber(a.importe).toNumber() +
                            BigNumber(b.importe).toNumber(),
                        })).importe,
                        2
                      ) +
                      ")"}
                </h2>
                {ventasClientesNoPagas[cliente_id] &&
                  ventasClientesNoPagas[cliente_id].ventas.length > 0 &&
                  ventas &&
                  ventas.map((venta, index) => {
                    if (
                      ventasClientesNoPagas[cliente_id].ventas.find(
                        (p) => p.id === venta.venta_id
                      ) ||
                      !venta.venta_id
                    )
                      return (
                        <Grid container className={classes.root} spacing={2}>
                          <Grid item xs={12} md={5}>
                            <FormControl
                              variant="outlined"
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "8px",
                              }}
                            >
                              <ReactSelect
                                fullWidth
                                value={
                                  venta.venta_id &&
                                  ventasClientesNoPagas[cliente_id].ventas.find(
                                    (p) => p.id === venta.venta_id
                                  )
                                    ? {
                                        label:
                                          tiposComprobante.find(
                                            (tc) =>
                                              tc.id ===
                                              ventasClientesNoPagas[
                                                cliente_id
                                              ].ventas.find(
                                                (p) => p.id === venta.venta_id
                                              ).tipo_comprobante_id
                                          ).ini +
                                          " | " +
                                          (ventasClientesNoPagas[
                                            cliente_id
                                          ].ventas.find(
                                            (p) => p.id === venta.venta_id
                                          ).numero_factura
                                            ? ventasClientesNoPagas[
                                                cliente_id
                                              ].ventas.find(
                                                (p) => p.id === venta.venta_id
                                              ).numero_factura
                                            : "X" +
                                              (
                                                ventasClientesNoPagas[
                                                  cliente_id
                                                ].ventas.find(
                                                  (p) => p.id === venta.venta_id
                                                ).id + ""
                                              ).padStart(11, "0")) +
                                          " (Saldo $" +
                                          (esNotaDeCreditoOPagoACuenta(
                                            ventasClientesNoPagas[
                                              cliente_id
                                            ].ventas.find(
                                              (p) => p.id === venta.venta_id
                                            ).tipo_comprobante_id
                                          )
                                            ? (cobranzaOriginal &&
                                              cobranzaOriginal.ventas.find(
                                                (v) =>
                                                  v.venta_id === venta.venta_id
                                              )
                                                ? cobranzaOriginal.ventas.find(
                                                    (v) =>
                                                      v.venta_id ===
                                                      venta.venta_id
                                                  ).importe
                                                : 0) +
                                              ventasClientesNoPagas[
                                                cliente_id
                                              ].ventas.find(
                                                (p) => p.id === venta.venta_id
                                              ).balance
                                            : ventasClientesNoPagas[
                                                cliente_id
                                              ].ventas.find(
                                                (p) => p.id === venta.venta_id
                                              ).balance +
                                              (cobranzaOriginal &&
                                              cobranzaOriginal.ventas.find(
                                                (v) =>
                                                  v.venta_id === venta.venta_id
                                              )
                                                ? cobranzaOriginal.ventas.find(
                                                    (v) =>
                                                      v.venta_id ===
                                                      venta.venta_id
                                                  ).importe
                                                : 0)) +
                                          ")",
                                        value: venta.venta_id,
                                      }
                                    : ""
                                }
                                name={`ventas[${index}].venta_id`}
                                placeholder="Seleccionar Comprobante... *"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    marginTop: "-8px",
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                onChange={(e) =>
                                  onChangeVenta(e, index, "venta_id")
                                }
                                options={ventasClientesNoPagas[
                                  cliente_id
                                ].ventas
                                  .filter(
                                    (v) =>
                                      !ventas.find((cv) => cv.venta_id === v.id)
                                  )
                                  .filter((v) => {
                                    if (cobranzaX === null) return true;
                                    else
                                      return (
                                        cobranzaX === esX(v.tipo_comprobante_id)
                                      );
                                  })
                                  .map((venta) => ({
                                    value: venta.id,
                                    label:
                                      tiposComprobante.find(
                                        (tc) =>
                                          tc.id === venta.tipo_comprobante_id
                                      ).ini +
                                      " | " +
                                      (venta.numero_factura
                                        ? venta.numero_factura
                                        : "X" +
                                          (venta.id + "").padStart(11, "0")) +
                                      " (Saldo: $" +
                                      venta.balance +
                                      ")",
                                  }))}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {venta.venta_id > 0 && (
                              <NumberFormat
                                customInput={TextField}
                                decimalSeparator=","
                                thousandSeparator="."
                                mask=""
                                prefix="$"
                                decimalScale={2}
                                value={
                                  venta.importe
                                    ? venta.importe
                                    : venta.importe === 0
                                    ? 0
                                    : ""
                                }
                                type="text"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label="Valor"
                                style={{ paddingBottom: "10px" }}
                                name={`ventas[${index}].importe`}
                                onValueChange={(e) =>
                                  onChangeVenta(e, index, "importe")
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {venta.venta_id > 0 && (
                              <NumberFormat
                                customInput={TextField}
                                decimalSeparator=","
                                thousandSeparator="."
                                mask=""
                                disabled
                                prefix="$"
                                decimalScale={2}
                                value={
                                  venta.importe
                                    ? ventasClientesNoPagas[
                                        cliente_id
                                      ].ventas.find(
                                        (p) => p.id === venta.venta_id
                                      ).balance -
                                      venta.importe +
                                      (cobranzaOriginal &&
                                      cobranzaOriginal.ventas.find(
                                        (v) => v.venta_id === venta.venta_id
                                      )
                                        ? cobranzaOriginal.ventas.find(
                                            (v) => v.venta_id === venta.venta_id
                                          ).importe
                                        : 0)
                                    : ""
                                }
                                type="text"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label={"Nuevo Saldo"}
                                style={{ paddingBottom: "10px" }}
                                name={`ventas[${index}].saldo`}
                                onValueChange={(e) =>
                                  onChangeVenta(e, index, "saldo")
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Button
                              onClick={(e) => onChangeVenta(e, index, "remove")}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "28px",
                                top: "12px",
                                minWidth: "28px",
                                marginLeft: "10px",
                              }}
                            >
                              <Icon className={classes.listIcon}>cancel</Icon>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                  })}
                <h4>
                  <Button
                    color="primary"
                    variant="outlined"
                    href="#!"
                    onClick={agregarVenta}
                  >
                    Agregar
                  </Button>
                </h4>
              </Fragment>
            )}

          {cliente_id &&
            ventas &&
            ventas.length > 0 &&
            (ventas[0].importe || ventas[0].venta_id < 0) &&
            tiposCobranzasCobro && (
              <Fragment>
                <h2 color="primary">
                  Cobros{" "}
                  {formas_cobro &&
                    formas_cobro.length > 0 &&
                    "(Total: $" +
                      (formas_cobro.length > 1
                        ? formas_cobro
                            .map((fc) => fc.importe)
                            .reduce((a, b) => BigNumber.sum(a, b).toNumber(), 0)
                        : formas_cobro[0].importe
                        ? formas_cobro[0].importe
                        : 0) +
                      ")"}
                </h2>
                {formas_cobro &&
                  cajas &&
                  cajas.length > 0 &&
                  formas_cobro.map((forma, index) => {
                    return (
                      <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12} md={2}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelect
                              fullWidth
                              value={
                                forma.forma_pago_id &&
                                tiposCobranzasCobro.find(
                                  (p) => p.id === forma.forma_pago_id
                                )
                                  ? {
                                      label: tiposCobranzasCobro.find(
                                        (p) => p.id === forma.forma_pago_id
                                      ).nombre,
                                      value: forma.forma_pago_id,
                                    }
                                  : ""
                              }
                              name={`formas_cobro[${index}].forma_pago_id`}
                              placeholder="Seleccionar Forma de Pago... *"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) =>
                                onChangeFormaCobro(e, index, "forma_pago_id")
                              }
                              options={tiposCobranzasCobro
                                .filter((tcc) =>
                                  !cobranzaX || tcc.id === 1 ? true : false
                                )
                                .map((forma) => ({
                                  value: forma.id,
                                  label: forma.nombre,
                                }))}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelect
                              fullWidth
                              value={
                                forma.caja_id &&
                                cajas.find((p) => p.id === forma.caja_id)
                                  ? {
                                      label: cajas.find(
                                        (p) => p.id === forma.caja_id
                                      ).nombre,
                                      value: forma.caja_id,
                                    }
                                  : ""
                              }
                              name={`formas_cobro[${index}].caja_id`}
                              placeholder="Seleccionar Caja... *"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) =>
                                onChangeFormaCobro(e, index, "caja_id")
                              }
                              options={
                                forma.forma_pago_id
                                  ? cajas
                                      .filter(
                                        (c) =>
                                          c.caja_tipo_id ===
                                          formaCobroCajaTipo(
                                            forma.forma_pago_id
                                          )
                                      )
                                      .filter((c) =>
                                        cobranzaX && c.especial === 1
                                          ? true
                                          : !cobranzaX && c.especial !== 1
                                          ? true
                                          : false
                                      )
                                      .map((caja) => ({
                                        value: caja.id,
                                        label: caja.nombre,
                                      }))
                                  : []
                              }
                            />
                          </FormControl>
                        </Grid>

                        {!loading_cheques &&
                          cajas &&
                          cajas.length > 0 &&
                          cheques &&
                          cheques.length > 0 &&
                          cajas.find(
                            (caja) =>
                              caja.id === forma.caja_id &&
                              caja.caja_tipo_id ===
                                cTiposCajas["Cheque terceros"]
                          ) && (
                            <Grid item xs={12} md={3}>
                              <FormControl
                                variant="outlined"
                                style={{
                                  height: "40px",
                                  width: "80%",
                                  paddingTop: "8px",
                                }}
                              >
                                <ReactSelectNonCreate
                                  value={
                                    forma.cheque_id &&
                                    cheques.find(
                                      (p) => p.id === forma.cheque_id
                                    )
                                      ? {
                                          label:
                                            moment(
                                              cheques.find(
                                                (p) => p.id === forma.cheque_id
                                              ).fecha
                                            ).format("DD/MM/YYYY") +
                                            " / N. " +
                                            cheques.find(
                                              (p) => p.id === forma.cheque_id
                                            ).numero +
                                            " / " +
                                            cheques.find(
                                              (p) => p.id === forma.cheque_id
                                            ).razon_social +
                                            " / $" +
                                            cheques.find(
                                              (p) => p.id === forma.cheque_id
                                            ).valor,
                                          value: forma.cheque_id,
                                        }
                                      : ""
                                  }
                                  fullWidth
                                  name={`formas_cobro[${index}].cheque_id`}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                      marginTop: "-8px",
                                    }),
                                  }}
                                  placeholder="Cheques..."
                                  menuPortalTarget={document.body}
                                  onChange={(e) =>
                                    onChangeFormaCobro(e, index, "cheque_id")
                                  }
                                  options={cheques
                                    .filter(
                                      (cheque) =>
                                        cheque.lanzamientoCajaObj ===
                                          undefined ||
                                        cheque.lanzamientoCajaObj.cheque ===
                                          forma.cheque
                                    )
                                    .filter((cheque) => !cheque.cobranza_id)
                                    .map((cheque) => ({
                                      value: cheque.id,
                                      label:
                                        moment(cheque.fecha).format(
                                          "DD/MM/YYYY"
                                        ) +
                                        " - N. " +
                                        cheque.numero +
                                        " - " +
                                        cheque.razon_social +
                                        " - $" +
                                        cheque.valor,
                                    }))}
                                />
                              </FormControl>
                              <Button
                                onClick={(e) => {
                                  setCurrentFormaCobro(index);
                                  dispatch(
                                    setCurrentCheques({
                                      cliente_id,
                                      razon_social: cliente
                                        ? cliente.razon_social
                                        : grupo.razon_social,
                                      cuit: cliente ? cliente.cuit : grupo.cuit,
                                    })
                                  );
                                }}
                                variant="contained"
                                color="primary"
                                style={{
                                  width: "28px",
                                  top: "12px",
                                  minWidth: "28px",
                                  marginLeft: "10px",
                                }}
                              >
                                <Icon className={classes.listIcon}>add</Icon>
                              </Button>
                            </Grid>
                          )}

                        <Grid item xs={12} md={2}>
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix="$"
                            decimalScale={2}
                            value={
                              BigNumber(forma.importe).toNumber()
                                ? BigNumber(forma.importe).toNumber()
                                : ""
                            }
                            type="text"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Valor"
                            style={{ paddingBottom: "10px" }}
                            name={`formas_cobro[${index}].importe`}
                            onValueChange={(e) =>
                              onChangeFormaCobro(e, index, "importe")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            margin="dense"
                            name={`formas_cobro[${index}].referencia`}
                            label="Referencia"
                            variant="outlined"
                            multiline
                            id="referencia"
                            value={forma.referencia || ""}
                            onChange={(e) =>
                              onChangeFormaCobro(e, index, "referencia")
                            }
                            fullWidth
                            rowsMax="3"
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Button
                            onClick={(e) =>
                              onChangeFormaCobro(e, index, "remove")
                            }
                            variant="contained"
                            color="secondary"
                            style={{
                              width: "28px",
                              top: "12px",
                              minWidth: "28px",
                              marginLeft: "10px",
                            }}
                          >
                            <Icon className={classes.listIcon}>cancel</Icon>
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                <h4>
                  <Button
                    color="primary"
                    variant="outlined"
                    href="#!"
                    onClick={agregarFormaCobro}
                  >
                    Agregar
                  </Button>{" "}
                  {balance !== 0 && (
                    <Button
                      color="primary"
                      variant="outlined"
                      href="#!"
                      onClick={agregarRedondeo}
                    >
                      Redondeo
                    </Button>
                  )}
                </h4>
              </Fragment>
            )}

          {cliente_id &&
            ventas &&
            ventas.length > 0 &&
            ventas[0].importe > 0 &&
            ventasClientesNoPagas[cliente_id] &&
            ventasClientesNoPagas[cliente_id].ventas.find(
              (p) => p.id === ventas[0].venta_id
            ) &&
            !esX(
              ventasClientesNoPagas[cliente_id].ventas.find(
                (p) => p.id === ventas[0].venta_id
              ).tipo_comprobante_id
            ) && (
              <Fragment>
                <h2 color="primary">
                  Retenciones Sufridas{" "}
                  {retenciones_sufridas &&
                    retenciones_sufridas.length > 0 &&
                    retenciones_sufridas[0].importe &&
                    "(Total: $" +
                      (retenciones_sufridas.length > 1
                        ? retenciones_sufridas
                            .map((fc) => fc.importe)
                            .reduce((a, b) => BigNumber.sum(a, b).toNumber(), 0)
                        : retenciones_sufridas[0].importe
                        ? retenciones_sufridas[0].importe
                        : 0) +
                      ")"}
                </h2>
                {retenciones_sufridas &&
                  retenciones_sufridas.map((retencion, index) => {
                    return (
                      <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12} md={3}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelect
                              fullWidth
                              value={
                                retencion.tipo_retencion_id &&
                                tiposRetenciones.find(
                                  (p) => p.id === retencion.tipo_retencion_id
                                )
                                  ? {
                                      label: tiposRetenciones.find(
                                        (p) =>
                                          p.id === retencion.tipo_retencion_id
                                      ).nombre,
                                      value: retencion.tipo_retencion_id,
                                    }
                                  : ""
                              }
                              name={`retenciones_sufridas[${index}].tipo_retencion_id`}
                              placeholder="Seleccionar Tipo *"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) =>
                                onChangeRetencionesSufridas(
                                  e,
                                  index,
                                  "tipo_retencion_id"
                                )
                              }
                              options={tiposRetenciones.map((ret) => ({
                                value: ret.id,
                                label: ret.nombre,
                              }))}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix="$"
                            decimalScale={2}
                            value={retencion.importe ? retencion.importe : ""}
                            type="text"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Valor"
                            style={{ paddingBottom: "10px" }}
                            name={`retenciones_sufridas[${index}].importe`}
                            onValueChange={(e) =>
                              onChangeRetencionesSufridas(e, index, "importe")
                            }
                          />
                        </Grid>
                        {retencion.tipo_retencion_id === 2 && (
                          <Grid item xs={12} md={2}>
                            <FormControl
                              variant="outlined"
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "0px",
                              }}
                            >
                              <InputLabel htmlFor="select-tipo_comprobante">
                                Provincia
                              </InputLabel>
                              <Select
                                input={
                                  <OutlinedInput
                                    style={{
                                      height: "40px",
                                      marginTop: "8px",
                                      width: "100%",
                                    }}
                                    id="select-tipo_comprobante"
                                  />
                                }
                                fullWidth
                                value={
                                  retencion.provincia_id
                                    ? retencion.provincia_id
                                    : ""
                                }
                                name={`retenciones_sufridas[${index}].provincia_id`}
                                onChange={(e) =>
                                  onChangeRetencionesSufridas(
                                    e,
                                    index,
                                    "provincia_id"
                                  )
                                }
                              >
                                {provincias.map((prov, index) => (
                                  <MenuItem value={prov.id} key={index}>
                                    {prov.nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                          <TextField
                            margin="dense"
                            name={`retenciones_sufridas[${index}].numero_certificado`}
                            label="Numero Certificado"
                            variant="outlined"
                            multiline
                            id="numero_certificado"
                            value={retencion.numero_certificado || ""}
                            onChange={(e) =>
                              onChangeRetencionesSufridas(
                                e,
                                index,
                                "numero_certificado"
                              )
                            }
                            fullWidth
                            rowsMax="3"
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Button
                            onClick={(e) =>
                              onChangeRetencionesSufridas(e, index, "remove")
                            }
                            variant="contained"
                            color="secondary"
                            style={{
                              width: "28px",
                              top: "12px",
                              minWidth: "28px",
                              marginLeft: "10px",
                            }}
                          >
                            <Icon className={classes.listIcon}>cancel</Icon>
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                <h4>
                  <Button
                    color="primary"
                    variant="outlined"
                    href="#!"
                    onClick={agregarRetencionesSufridas}
                  >
                    Agregar
                  </Button>
                </h4>
              </Fragment>
            )}
        </form>
      </DialogContent>
      {!saving ? (
        <DialogActions className={classes.formFooter}>
          {(balance || balance === 0 || true) && (
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              Balance de Cobranza: $
              <NumberFormat
                displayType="text"
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                value={
                  balance
                  //
                }
              />
            </span>
          )}
          {balance < 0 && (
            <div style={{ color: "red" }}>(Genera saldo a favor)</div>
          )}
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Guardar{" "}
            {balance < 0 && <Fragment> Y GENERAR SALDO A FAVOR</Fragment>}
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      ) : (
        <DialogActions className={classes.formFooter}>
          <CircularProgress color="secondary" />
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.cobranzas.current,
    open: state.cobranzas.open,
    saving: state.cobranzas.saving,
    error: state.cobranzas.error,
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
    cheques: state.cheques.cheques,
    loading_cheques: state.cheques.loading,
    cheques_propios: state.chequesPropios.cheques_propios,
    loading_cheques_propios: state.chequesPropios.loading,
    usuarios: state.usuarios.usuarios,
    loading_usuarios: state.usuarios.loading_usuarios,
    rendiciones: state.rendiciones.rendiciones,
    loading_rendiciones: state.rendiciones.loading_rendiciones,
    loading_ventas: state.ventas.loading_ventas,
    ventas_clientes_no_pagas: state.ventas.ventas_clientes_no_pagas,
    last_cheque_id: state.cheques.last_cheque_id,
    grupos: state.grupos.grupos,
    loading_grupos: state.grupos.loading,
  };
};

export default connect(mapStateToProps)(CobranzaForm);
