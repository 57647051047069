import {
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useState } from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import {
  aprobarMultiples,
  asignarCamioneta
} from "../../actions/ventas";
import { calcWorkingDays } from "../../utils/functions";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  fab2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(12)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ApproveVentaBtn = ({ dispatch, ids, modo }) => {
  const [fecha, setFecha] = useState(calcWorkingDays(new Date(), 1));
  const [camioneta, setCamioneta] = useState(null);
  const classes = useStyles();
  const confirm = useConfirm();

  const onClickAprobar = (confirm, dispatch) => {
    if (modo === "Pendientes")
      confirm({
        description: `Aprobar multiples ventas?`,
        title: "ATENCION",
        cancellationText: "Cancelar",
        confirmationText: "Confirmar"
      }).then(() => dispatch(aprobarMultiples(ids, fecha)));
    else if (modo === "Aprobadas")
      confirm({
        description: `Asignar multiples ventas a camioneta ${camioneta}?`,
        title: "ATENCION",
        cancellationText: "Cancelar",
        confirmationText: "Confirmar"
      }).then(() => dispatch(asignarCamioneta(ids, camioneta)));
  };

  return (
    <div className="fixed-action-btn">
      <div
        style={{
          color: "black",
          backgroundColor: "rgb(237 247 237)",
          padding: "10px",
          border: "1px black solid",
          borderRadius: "10px"
        }}
        className={classes.fab2}
      >
        {(!modo || modo === "Aprobadas") && (
          <Fragment>
            {" "}
            Camioneta:{" "}
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "100px"
                  }}
                  id="select-forma-pago"
                />
              }
              onChange={(e) => setCamioneta(e.target.value)}
              value={camioneta}
              name="camioneta"
            >
              {[1, 2, 3, 4].map((tipo, index) => (
                <MenuItem value={tipo} key={index}>
                  {tipo}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        )}
        {(!modo || modo === "Pendientes") && (
          <Fragment>
            {" "}
            Fecha de Salida{" "}
            <DatePicker
              onChange={(date) => setFecha(date)}
              value={fecha}
              format="dd/MM/y"
            />{" "}
          </Fragment>
        )}
      </div>

      <Fab
        style={{ color: "white", backgroundColor: "blue" }}
        aria-label="Add"
        onClick={(e) => onClickAprobar(confirm, dispatch)}
        className={classes.fab}
      >
        <Icon>check_rounded</Icon>
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    current: state.ventas.current
  };
};

export default connect(mapStateToProps)(ApproveVentaBtn);
