import axios from "axios";

import {
  GET_RENDICIONES,
  ADD_RENDICION,
  DELETE_RENDICION,
  UPDATE_RENDICION,
  CLEAR_RENDICIONES,
  RENDICION_ERROR,
  SET_CURRENT,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_RENDICIONES,
  CLEAR_FILTER,
  SET_SAVING_RENDICION,
  CHEQUEAR_RENDICION
} from "./types";

export const getRendiciones = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/rendiciones", {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          filters: filters
        },
        data: null
      });
      dispatch({ type: GET_RENDICIONES, payload: res.data });
    } catch (error) {
      dispatch({ type: RENDICION_ERROR, payload: error.msg });
    }
  };
};

export function setCurrent(rendicion) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT, payload: rendicion });
  };
}
export const setSavingRendicion = (saving) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVING_RENDICION, payload: saving });
  };
};

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT });
  };
};

export const filterRendiciones = (filter, filterType) => {
  return (dispatch) => {
    dispatch({ type: FILTER_RENDICIONES, filter: filter, filterType: filterType });
  };
};
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

//Add Rendicion
export const addRendicion = (rendicion) => {
  return async (dispatch) => {
    dispatch(setSavingRendicion(true));
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const res = await axios.post("/api/rendiciones", rendicion, config);
      dispatch({ type: ADD_RENDICION, payload: res.data });
    } catch (error) {
      dispatch({ type: RENDICION_ERROR, payload: error.msg });
    }
    dispatch(setSavingRendicion(false));
  };
};

//Delete Rendicion

export const deleteRendicion = (rendicionId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/rendiciones/${rendicionId}`);

      dispatch({ type: DELETE_RENDICION, payload: rendicionId });
    } catch (error) {
      dispatch({ type: RENDICION_ERROR, payload: error.response.msg });
    }
  };
};

export const chequearRendicion = (rendicionId, chequeada) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const res = await axios.post(
        `/api/rendiciones/chequear_rendicion`,
        { id: rendicionId, chequeada: chequeada },
        config
      );

      dispatch({ type: CHEQUEAR_RENDICION, payload: { id: rendicionId, chequeada: chequeada } });
    } catch (error) {
      dispatch({ type: RENDICION_ERROR, payload: error.response.msg });
    }
  };
};

//Set Current Rendicion

//Clear Current Rendicion

//Update Rendicion

export const updateRendicion = (rendicion) => {
  return async (dispatch) => {
    dispatch(setSavingRendicion(true));
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const res = await axios.put(`/api/rendiciones/${rendicion.id}`, rendicion, config);

      dispatch({ type: UPDATE_RENDICION, payload: res.data });
    } catch (error) {
      dispatch({ type: RENDICION_ERROR, payload: error.msg });
    }
    dispatch(setSavingRendicion(false));
  };
};

//Filter Rendicion

//Clear Filter

export const clearRendiciones = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_RENDICIONES });
  };
};
