import axios from 'axios';

import {
  ADD_PRODUCTO,
  DELETE_PRODUCTO,
  SET_CURRENT_PRODUCTO,
  CLEAR_CURRENT_PRODUCTO,
  UPDATE_PRODUCTO,
  FILTER_PRODUCTO,
  CLEAR_FILTER_PRODUCTO,
  ERROR_PRODUCTO,
  GET_PRODUCTO,
  CLEAR_PRODUCTO,
  SET_OPEN_PRODUCTO
} from './types';

//Add Producto
export const addProducto = producto => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/productos', producto, config);
      dispatch({ type: ADD_PRODUCTO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PRODUCTO, payload: error.msg });
    }
  };
};

export const getProductos = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/productos');
      dispatch({ type: GET_PRODUCTO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PRODUCTO, payload: error.msg });
    }
  };
};

//Delete Producto

export const deleteProducto = productoId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/productos/${productoId}`);

      dispatch({ type: DELETE_PRODUCTO, payload: productoId });
    } catch (error) {
      dispatch({ type: ERROR_PRODUCTO, payload: error.response.msg });
    }
  };
};

//Set Current Producto

export function setCurrent(producto) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_PRODUCTO, payload: producto });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_PRODUCTO, payload: open });
  };
}

//Clear Current Producto
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_PRODUCTO });
  };
};
//Update Producto

export const updateProducto = producto => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/productos/${producto.id}`,
        producto,
        config
      );
      dispatch({ type: UPDATE_PRODUCTO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PRODUCTO, payload: error.response.msg });
    }
  };
};

//Filter Producto

export const filterProductos = text => {
  return dispatch => {
    dispatch({ type: FILTER_PRODUCTO, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_PRODUCTO });
  };
};

export const clearProductos = () => {
  return dispatch => {
    dispatch({ type: CLEAR_PRODUCTO });
  };
};
