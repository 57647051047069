import axios from 'axios';

import {
  ADD_VENDEDOR,
  DELETE_VENDEDOR,
  SET_CURRENT_VENDEDOR,
  CLEAR_CURRENT_VENDEDOR,
  UPDATE_VENDEDOR,
  FILTER_VENDEDOR,
  CLEAR_FILTER_VENDEDOR,
  ERROR_VENDEDOR,
  GET_VENDEDOR,
  CLEAR_VENDEDOR,
  SET_OPEN_VENDEDOR
} from './types';

//Add Vendedor
export const addVendedor = vendedor => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/vendedores', vendedor, config);
      dispatch({ type: ADD_VENDEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENDEDOR, payload: error.msg });
    }
  };
};

export const getVendedores = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/vendedores');
      dispatch({ type: GET_VENDEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENDEDOR, payload: error.msg });
    }
  };
};

//Delete Vendedor

export const deleteVendedor = vendedorId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/vendedores/${vendedorId}`);

      dispatch({ type: DELETE_VENDEDOR, payload: vendedorId });
    } catch (error) {
      dispatch({ type: ERROR_VENDEDOR, payload: error.response.msg });
    }
  };
};

//Set Current Vendedor

export function setCurrent(vendedor) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_VENDEDOR, payload: vendedor });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_VENDEDOR, payload: open });
  };
}

//Clear Current Vendedor
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_VENDEDOR });
  };
};
//Update Vendedor

export const updateVendedor = vendedor => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/vendedores/${vendedor.id}`,
        vendedor,
        config
      );
      dispatch({ type: UPDATE_VENDEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENDEDOR, payload: error.response.msg });
    }
  };
};

//Filter Vendedor

export const filterVendedores = text => {
  return dispatch => {
    dispatch({ type: FILTER_VENDEDOR, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_VENDEDOR });
  };
};

export const clearVendedores = () => {
  return dispatch => {
    dispatch({ type: CLEAR_VENDEDOR });
  };
};
