import React, { useState, useEffect, Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { getCajas, clearCurrent, setCurrent } from "../../actions/cajas";
import { getLanzamientosDeCaja } from "../../actions/lanzamientosDeCaja";
import CajaItem from "./CajaItem";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinearProgress,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core/";
import subscribeTo from "../../utils/subscribeTo";
import CajaForm from "../cajas/CajaForm";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import AddCajaBtn from "../cajas/AddCajaBtn";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CajasFilter from "./CajasFilter";
import moment from "moment/moment";
import CobranzaForm from "../cobranzas/CobranzaForm";
import { setCurrentCobranza } from "../../actions/cobranzas";
import { esX } from "../../utils/functions";
import NumberFormat from "react-number-format";
import { getUsuarios } from "../../actions/usuarios";
import { cEstadosChequesObj } from "../../config/constantesServer";
import { filterCajas } from "../../actions/cajas";

export const tiposCaja = [
  { id: 1, nombre: "Efectivo en Pesos" },
  { id: 2, nombre: "Efectivo en Dólares" },
  { id: 3, nombre: "Cuenta corriente en pesos" },
  { id: 4, nombre: "Cheque terceros" },
  { id: 5, nombre: "Cheque" },
  { id: 6, nombre: "Ajuste" },
];

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",

    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },

  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#00FF00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  moneyButton: {
    cursor: "pointer",
    color: "green",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  expansionPanelFilter: {
    padding: "0 10px 20px 10px",
  },

  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    marginLeft: "20px",
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  factura: {
    borderRadius: "20px",
    border: "1px solid rgba(0,255,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(0,255,0,0.1)",
    margin: "auto",
  },
  notaCredito: {
    borderRadius: "20px",
    border: "1px solid rgba(255,0,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,0,0,0.1)",
    margin: "auto",
  },
  facturaX: {
    borderRadius: "20px",
    border: "1px solid rgba(255,255,0,0.9)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,255,0,0.1)",
    margin: "auto",
  },
  h3Subtotal: {
    textAlign: "right",
  },
}));

const Cajas = ({
  cajas,
  filtered,
  filter,
  loading,
  dispatch,
  lanzamientosDeCaja,
  lanzamientosDeCaja_loading,
  usuarios,
  loading_usuarios,
}) => {
  const classes = useStyles();
  useEffect(() => {
    dispatch(getLanzamientosDeCaja());
  }, []);

  useEffect(() => {
    dispatch(getCajas());
    dispatch(getUsuarios());

    if (!loading) {
      subscribeTo("cajasChanged", (err, data) => {
        dispatch(getCajas());
        if (!lanzamientosDeCaja_loading) dispatch(getLanzamientosDeCaja());
      });
      console.log([
        moment().startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ]);
      if (!filter.fechaLanzamiento) {
        setTimeout(
          () =>
            dispatch(
              filterCajas({
                ...filter,
                fechaLanzamiento: [
                  moment().startOf("day").toDate(),
                  moment().endOf("day").toDate(),
                ],
              })
            ),
          10
        );
      }
      return () => {
        subscribeTo("cajasChanged", null);
      };
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (!loading && !loading_usuarios && !lanzamientosDeCaja_loading) {
      console.log(
        "lanzamientosDeCaja",
        lanzamientosDeCaja.filter((lc) => lc.cobranza && !lc.cobranza.cliente)
      );
      console.log("cajas", cajas);
      console.log("usuarios", usuarios);
    }
  }, [cajas, usuarios, lanzamientosDeCaja]);

  const subtotalsPos = {
    12: { titulo: "Efectivo total", cajas: [1, 6] },
    10: { titulo: "Cheques + Entidades", cajas: [4, 2, 3, 10] },
    19: { titulo: "Acuerdos", cajas: [9, 8, 19] },
    18: { titulo: "Total USD", cajas: [16, 17, 18] },
  };

  if (cajas !== null && !cajas.length && !loading) {
    return (
      <Fragment>
        <CajaForm />
        <h4>No hay ninguna caja todavía!</h4> <AddCajaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <CajaForm />
        <CobranzaForm />
      </div>
      <div>
        <div className={classes.root}>
          {lanzamientosDeCaja_loading && (
            <>
              Regenerando lanzamiento de cajas, esto puede demorar 2 minutos...
            </>
          )}
          {cajas !== null && !loading && !lanzamientosDeCaja_loading ? (
            <Fragment>
              <CajasFilter />{" "}
              {(filtered ? filtered : cajas).map((caja, key) => (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={classes.summary}
                    >
                      <Grid container>
                        <Grid md="4">
                          <Typography className={classes.heading}>
                            {caja.nombre}
                          </Typography>
                        </Grid>
                        <Grid md="4">
                          <Typography className={classes.secondaryHeading}>
                            {" "}
                            Total:
                            <NumberFormat
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              displayType="text"
                              prefix="$"
                              decimalScale={2}
                              value={Math.round(caja.balance * 100) / 100}
                            />
                          </Typography>
                        </Grid>
                        <Grid md="4">
                          <Typography className={classes.secondaryHeading2}>
                            {!lanzamientosDeCaja_loading &&
                              ((filter.fechaLanzamiento &&
                                filter.fechaLanzamiento[0] &&
                                filter.fechaLanzamiento[1]) ||
                                filter.zonas.length > 0 ||
                                filter.canales.length > 0) && (
                                <>
                                  Por Fecha:
                                  <NumberFormat
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    mask=""
                                    displayType="text"
                                    prefix="$"
                                    decimalScale={2}
                                    value={lanzamientosDeCaja
                                      .filter(
                                        (c) =>
                                          c.caja_id === caja.id &&
                                          (!filter.fechaLanzamiento ||
                                            (new Date(c.fecha) >=
                                              filter.fechaLanzamiento[0] &&
                                              new Date(c.fecha) <=
                                                filter.fechaLanzamiento[1])) &&
                                          (!filter.zonas.length > 0 ||
                                            (c.cobranza &&
                                              c.cobranza.cliente &&
                                              filter.zonas.indexOf(
                                                c.cobranza.cliente.zona_id
                                              ) !== -1) ||
                                            (c.cobranza &&
                                              c.cobranza.grupo &&
                                              filter.zonas.indexOf(
                                                c.cobranza.grupo.clientes[0]
                                                  .zona_id
                                              ) !== -1)) &&
                                          (!filter.canales.length > 0 ||
                                            (c.cobranza &&
                                              c.cobranza.cliente &&
                                              filter.canales.indexOf(
                                                c.cobranza.cliente.canal_id
                                              ) !== -1) ||
                                            (c.cobranza &&
                                              c.cobranza.grupo &&
                                              filter.canales.indexOf(
                                                c.cobranza.grupo.clientes[0]
                                                  .canal_id
                                              ) !== -1))
                                      )
                                      .reduce(
                                        (acc, lanzam) => acc + lanzam.valor,
                                        0
                                      )}
                                  />
                                </>
                              )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanel}>
                      <Grid container direction="column">
                        <Grid>
                          <center>
                            {" "}
                            <Button
                              color="primary"
                              variant="contained"
                              href="#!"
                              onClick={(e) => dispatch(setCurrent(caja))}
                            >
                              Editar Caja
                            </Button>{" "}
                            <Button
                              color="secondary"
                              variant="contained"
                              href="#!"
                              onClick={(e) =>
                                dispatch(setCurrent({ ...caja, ajuste: true }))
                              }
                            >
                              Ajuste caja
                            </Button>{" "}
                            <Button
                              color="gray"
                              variant="contained"
                              href="#!"
                              onClick={(e) =>
                                dispatch(
                                  setCurrent({
                                    ...caja,
                                    ajuste: true,
                                    es_balance: true,
                                  })
                                )
                              }
                            >
                              Hacer Balance
                            </Button>
                          </center>
                        </Grid>
                        {!lanzamientosDeCaja_loading && !loading_usuarios && (
                          <Grid container>
                            <Grid item xs={12} md={12}>
                              <ReactTable
                                data={lanzamientosDeCaja.filter(
                                  (c) =>
                                    c.caja_id === caja.id &&
                                    (!filter.fechaLanzamiento ||
                                      (new Date(c.fecha) >=
                                        filter.fechaLanzamiento[0] &&
                                        new Date(c.fecha) <=
                                          filter.fechaLanzamiento[1])) &&
                                    (!filter.zonas.length > 0 ||
                                      (c.cobranza &&
                                        c.cobranza.cliente &&
                                        filter.zonas.indexOf(
                                          c.cobranza.cliente.zona_id
                                        ) !== -1) ||
                                      (c.cobranza &&
                                        c.cobranza.grupo &&
                                        filter.zonas.indexOf(
                                          c.cobranza.grupo.clientes[0].zona_id
                                        ) !== -1)) &&
                                    (!filter.canales.length > 0 ||
                                      (c.cobranza &&
                                        c.cobranza.cliente &&
                                        filter.canales.indexOf(
                                          c.cobranza.cliente.canal_id
                                        ) !== -1) ||
                                      (c.cobranza &&
                                        c.cobranza.grupo &&
                                        filter.canales.indexOf(
                                          c.cobranza.grupo.clientes[0].canal_id
                                        ) !== -1))
                                )}
                                previousText="Anterior"
                                nextText="Próxima"
                                loadingText="Cargando..."
                                noDataText="Nada cargado"
                                pageText="Pagina"
                                ofText="de"
                                rowsText="filas"
                                pageJumpText="ir a la pág."
                                rowsSelectorText="filas por pág."
                                getTrProps={(state, rowInfo) => {
                                  if (rowInfo && rowInfo.row) {
                                    return {
                                      onClick: (row, e) => {
                                        if (!rowInfo.original.cobranza_id)
                                          return;
                                        let w = window.open(
                                          "/api/comprobanteCobranza/pdf/" +
                                            rowInfo.original.cobranza_id
                                        );
                                      },
                                    };
                                  } else {
                                    return {};
                                  }
                                }}
                                defaultPageSize={14}
                                columns={[
                                  {
                                    columns: [
                                      {
                                        Header: "Fecha",

                                        id: "fecha",
                                        accessor: (d) => d.fecha,
                                        Cell: (row) =>
                                          moment(row.original.fecha).format(
                                            "DD MMM YYYY"
                                          ),
                                      },
                                      {
                                        Header: "Concepto",
                                        id: "caja",

                                        accessor: (d) => d.caja,
                                        Cell: (row) => {
                                          if (
                                            row.original.cobranza_id &&
                                            (!row.original.cheque_id ||
                                              row.original.cheque
                                                .cheque_estado_id ===
                                                cEstadosChequesObj[
                                                  "En cartera"
                                                ])
                                          ) {
                                            return (
                                              "Cobranza a " +
                                              (row.original.cobranza.cliente
                                                ? row.original.cobranza.cliente
                                                    .nombre
                                                : row.original.cobranza.grupo
                                                ? row.original.cobranza.grupo
                                                    .nombre
                                                : " - ")
                                            );
                                          } else if (row.original.descripcion)
                                            return row.original.descripcion;
                                          else if (row.original.cheque_id)
                                            return row.original.cheque
                                              .cheque_estado_id ===
                                              cEstadosChequesObj["Depositado"]
                                              ? "Cheque depositado"
                                              : row.original.cheque
                                                  .cheque_estado_id ===
                                                cEstadosChequesObj["Acreditado"]
                                              ? "Cheque acreditado"
                                              : row.original.cheque
                                                  .cheque_estado_id ===
                                                cEstadosChequesObj["Rechazado"]
                                              ? "Cheque rechazado"
                                              : row.original.cheque
                                                  .cheque_estado_id ===
                                                cEstadosChequesObj["Utilizado"]
                                              ? "Cheque utilizado"
                                              : row.original.cheque
                                                  .cheque_estado_id ===
                                                cEstadosChequesObj["Vencido"]
                                              ? "Cheque vencido"
                                              : "Lanzamiento ID " +
                                                row.original.id;
                                          else
                                            return (
                                              "UNKNOWN Lanzamiento ID " +
                                              row.original.id
                                            );
                                        },
                                      },
                                      {
                                        Header: "Medio de Pago",

                                        id: "cobranza_forma_pago",
                                        accessor: (d) => d.fecha,
                                        Cell: (row) =>
                                          row.original.tipo_cobranza
                                            ? row.original.tipo_cobranza.nombre
                                            : "",
                                      },
                                      {
                                        Header: "Valor",
                                        id: "valor",
                                        accessor: (d) => d.valor,
                                        Cell: (row) => (
                                          <NumberFormat
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            mask=""
                                            displayType="text"
                                            prefix="$"
                                            decimalScale={2}
                                            value={
                                              Math.round(
                                                row.original.valor * 100
                                              ) / 100
                                            }
                                          />
                                        ),
                                      },
                                      /* {
                                        Header: "Saldo",
                                        id: "saldo",
                                        accessor: (d) => d.balance_caja,
                                        Cell: (row) => (
                                          <NumberFormat
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            mask=""
                                            displayType="text"
                                            prefix="$"
                                            decimalScale={2}
                                            value={row.original.balance_caja}
                                          />
                                        ),
                                      },*/
                                      {
                                        Header: "Usuario",

                                        id: "usuario",
                                        accessor: (d) => d.usuario_id,
                                        Cell: (row) =>
                                          row.original.usuario_id
                                            ? usuarios
                                                .find(
                                                  (u) =>
                                                    u.id ===
                                                    row.original.usuario_id
                                                )
                                                .nombre.toUpperCase()
                                            : "",
                                      },
                                    ],
                                  },
                                ]}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {Object.keys(subtotalsPos).indexOf(caja.id + "") !== -1 && (
                    <h3 className={classes.h3Subtotal}>
                      {subtotalsPos[caja.id].titulo}:{" "}
                      <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        displayType="text"
                        prefix="$"
                        decimalScale={2}
                        value={
                          Math.round(
                            cajas.reduce(
                              (acc, caj) =>
                                acc +
                                (subtotalsPos[caja.id] &&
                                subtotalsPos[caja.id].cajas.indexOf(caj.id) !==
                                  -1
                                  ? caj.balance
                                  : 0),
                              0
                            ) * 100
                          ) / 100
                        }
                      />
                    </h3>
                  )}
                </>
              ))}
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddCajaBtn />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cajas: state.cajas.cajas,
    filter: state.cajas.filter,
    filtered: state.cajas.filtered,
    loading: state.cajas.loading,
    lanzamientosDeCaja: state.lanzamientosDeCaja.lanzamientosDeCaja,
    lanzamientosDeCaja_loading: state.lanzamientosDeCaja.loading,
    usuarios: state.usuarios.usuarios,
    loading_usuarios: state.usuarios.loading_usuarios,
  };
};

export default connect(mapStateToProps)(Cajas);
