import escapeRegExp from "escape-string-regexp";
import {
  ADD_BANCO,
  DELETE_BANCO,
  SET_CURRENT_BANCO,
  CLEAR_CURRENT_BANCO,
  UPDATE_BANCO,
  FILTER_BANCO,
  CLEAR_FILTER_BANCO,
  ERROR_BANCO,
  GET_BANCO,
  CLEAR_BANCO,
  SET_OPEN_BANCO
} from "../actions/types";

const initialState = {
  bancos: null,
  current: null,
  filtered: null,
  filter: "",
  error: null,
  loading: true,
  last_banco_id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANCO:
      return {
        ...state,
        bancos: action.payload,
        loading: false
      };
    case ADD_BANCO:
      return {
        ...state,
        bancos: [action.payload, ...state.bancos],
        last_banco_id: action.payload.id,
        loading: false
      };
    case UPDATE_BANCO:
      return {
        ...state,
        bancos: state.bancos.map((banco) => (banco.id === action.payload.id ? action.payload : banco))
      };
    case DELETE_BANCO:
      return {
        ...state,
        bancos: state.bancos.filter((banco) => banco.id !== action.payload),
        loading: false
      };
    case CLEAR_BANCO:
      return {
        ...state,
        bancos: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_BANCO:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_BANCO:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_BANCO:
      return {
        ...state,
        current: null
      };

    case FILTER_BANCO:
      return {
        ...state,
        filter: action.payload,
        filtered: state.bancos.filter((banco) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          return banco.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_BANCO:
      return {
        ...state,
        filtered: null
      };
    case ERROR_BANCO:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
