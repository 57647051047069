import React from "react";

const getTipoGastos = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user["usuario"]["compania"]["tipo_servicios"].sort();
};

const getFormasPago = () => {
  return [
    { id: 1, nombre: "Efectivo" },
    { id: 2, nombre: "Transferencia" },
    { id: 3, nombre: "Cheque" },
    { id: 4, nombre: "Cheque electrónico" },
  ];
};

const getTipoCobranzas = () => {
  return [
    { id: 1, nombre: "Efectivo" },
    { id: 2, nombre: "Transferencia" },
    { id: 3, nombre: "Cheque" },
    { id: 4, nombre: "Cheque electrónico" },
  ];
};

const getIsAdmin = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) return false;
  return user["usuario"]["admin"];
};

const getRoleCanDo = (action, item = null) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) return false;
  const role_id = user["usuario"]["role_id"];
  if (action === "vend") {
    const vendedor_id = user["usuario"]["vendedor_id"];
    if (
      vendedor_id &&
      item.link !== "/panel_ventas" &&
      item.link !== "/ventas" &&
      item.link !== "/panel_administrativo" &&
      (item.title !== "Cobranzas" || item.link === "/cobranzas") &&
      item.link !== "/saldos_clientes" &&
      item.link !== "/reportes/volumen_ventas" &&
      item.link !== "/reportes/volumen_ventas_bonificadas" &&
      item.title !== "Reportes"
    )
      return false;
    else return true;
  }

  if (user["usuario"]["admin"]) return true;
  else {
    switch (action) {
      case "gastos":
        return role_id === 2;
      case "borrar_venta":
        return user["usuario"]["id"] === 5;

      default:
        return false;
    }
  }
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) return false;
  return user["usuario"];
};

const Usuario = () => {
  return <div />;
};

export {
  getTipoGastos,
  getIsAdmin,
  getTipoCobranzas,
  getFormasPago,
  getCurrentUser,
  getRoleCanDo,
  Usuario as default,
};
