import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import RoomIcon from "@material-ui/icons/Room";
import React from "react";
import { connect } from "react-redux";
import { setOpenPuntoLogistico } from "../../actions/ventas";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(11),
    width: "40px",
    height: "40px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const AddVentaBtn = ({ dispatch }) => {
  const classes = useStyles();

  return (
    <div className="fixed-action-btn">
      <Fab
        onClick={() => dispatch(setOpenPuntoLogistico(true))}
        color="primary"
        aria-label="Add"
        className={classes.fab}
      >
        <RoomIcon />
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    current: state.ventas.current,
  };
};

export default connect(mapStateToProps)(AddVentaBtn);
