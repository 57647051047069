/* eslint-disable import/no-anonymous-default-export */
import escapeRegExp from "escape-string-regexp";

import { emptyCliente } from "../components/clientes/ClienteForm";

import {
  ADD_CLIENTE,
  DELETE_CLIENTE,
  SET_CURRENT_CLIENTE,
  CLEAR_CURRENT_CLIENTE,
  UPDATE_CLIENTE,
  FILTER_CLIENTE,
  FILTER_CLIENTE_DIR,
  FILTER_CLIENTE_ZONA,
  CLEAR_FILTER_CLIENTE,
  ERROR_CLIENTE,
  GET_CLIENTE,
  CLEAR_CLIENTE,
  SET_OPEN_CLIENTE,
  OBTENER_CLIENTE_AFIP,
  SET_LOADING_AFIP,
  SET_SAVING_CLIENTE,
  FILTER_CLIENTES_PANEL_VENTAS,
  ADD_CONTACTO,
  SET_BYPASS_IO_CLIENTES,
} from "../actions/types";
const diasZonas = {
  0: [],
  1: ["CABA1"], //Lunes
  2: ["CABA2"], //Martes
  3: [],
  4: ["DIS", "DISINT"],
  5: ["INT", "SUP", "MAY", "GASTRO"],
  6: [],
};
const initialState = {
  clientes: null,
  clientes_dias: {
    Lunes: [],
    Martes: [],
    Miércoles: [],
    Jueves: [],
    Viernes: [],
  },
  filter_panel_ventas: {
    zonas: diasZonas[new Date().getDay()],
    nombre: "",
    forma_contacto: "",
  },
  current: null,
  filtered: null,
  filter: "",
  filter_dir: "",
  error: null,
  loading: true,
  saving: false,
  loading_afip: false,
};

const clientesToDias = (clientes, state) => {
  let ret = {};

  for (let cliente of clientes) {
    if (cliente.zona && !cliente.desactivado) {
      if (!ret[cliente.zona.nombre]) ret[cliente.zona.nombre] = [];
      ret[cliente.zona.nombre].push(cliente);
    }
  }
  return ret;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTE:
      return {
        ...state,
        clientes: action.payload,
        loading: false,
        saving: false,
        clientes_dias: clientesToDias(action.payload, state),
      };
    case ADD_CLIENTE:
      return {
        ...state,
        clientes: [action.payload, ...state.clientes],
        loading: false,
        saving: false,
      };
    case UPDATE_CLIENTE:
      return {
        ...state,
        clientes: state.clientes.map((cliente) =>
          cliente.id === action.payload.id ? action.payload : cliente
        ),
        saving: false,
        filtered:
          state.filtered === null
            ? state.filtered
            : state.filtered.map((cliente) =>
                cliente.id === action.payload.id ? action.payload : cliente
              ),
      };

    case DELETE_CLIENTE:
      return {
        ...state,
        clientes: state.clientes.map((cliente) =>
          cliente.id !== action.payload
            ? cliente
            : { ...cliente, desactivado: true }
        ),
        loading: false,
      };
    case CLEAR_CLIENTE:
      return {
        ...state,
        clientes: null,
        error: null,
        current: null,
        loading: false,
        saving: false,
      };
    case SET_CURRENT_CLIENTE:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING_AFIP:
      return {
        ...state,
        loading_afip: true,
      };

    case SET_SAVING_CLIENTE:
      return {
        ...state,
        saving: action.payload,
      };

    case SET_BYPASS_IO_CLIENTES:
      return { ...state, bypassIo: action.payload };

    case OBTENER_CLIENTE_AFIP:
      return {
        ...state,
        loading_afip: false,
        current: {
          ...(action.payload.currentCliente || emptyCliente),
          grupo: action.payload.grupo || -1,
          nombre:
            (action.payload.currentCliente &&
              action.payload.currentCliente.nombre) ||
            action.payload.data.RazonSocial,
          razon_social:
            (action.payload.currentCliente &&
              action.payload.currentCliente.razon_social) ||
            action.payload.data.RazonSocial,
          direccion_facturacion:
            (action.payload.currentCliente &&
              action.payload.currentCliente.direccion) ||
            action.payload.data.Domicilio +
              (action.payload.data.CodigoPostal
                ? ", " + action.payload.data.CodigoPostal
                : "") +
              (action.payload.data.CiudadNombre
                ? ", " + action.payload.data.CiudadNombre
                : "") +
              (action.payload.data.ProvinciaNombre
                ? ", " + action.payload.data.ProvinciaNombre
                : ""),
          detalles_facturacion:
            (action.payload.currentCliente &&
              action.payload.currentCliente.detalles_facturacion) ||
            action.payload.data.PisoDto,
          categoria_impositiva_id:
            (action.payload.currentCliente &&
              action.payload.currentCliente.categoria_impositiva_id) ||
            (action.payload.data.CategoriaImpositiva === "RI"
              ? 1
              : action.payload.data.CategoriaImpositiva === "MO"
              ? 2
              : action.payload.data.CategoriaImpositiva === "CF"
              ? 3
              : 4),
          personeria_id:
            (action.payload.currentCliente &&
              action.payload.currentCliente.personeria) ||
            (action.payload.data.Personeria === "F" ? 1 : 2),
          cuit: action.payload.data.NroDoc,
        },
      };
    case SET_OPEN_CLIENTE:
      return {
        ...state,
        open: action.payload,
      };
    case CLEAR_CURRENT_CLIENTE:
      return {
        ...state,
        current: null,
      };

    case FILTER_CLIENTE:
      return {
        ...state,
        filter: action.payload,
        filtered: state.clientes.filter((cliente) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          return (
            (cliente.nombre && cliente.nombre.match(regex)) ||
            (cliente.razon_social && cliente.razon_social.match(regex))
          );
        }),
      };
    case FILTER_CLIENTE_DIR:
      return {
        ...state,
        filter_dir: action.payload,
        filtered: state.clientes.filter((cliente) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          return (
            (cliente.direccion_entrega &&
              cliente.direccion_entrega.match(regex)) ||
            (cliente.localidad_entrega &&
              cliente.localidad_entrega.match(regex))
          );
        }),
      };
    case FILTER_CLIENTE_ZONA:
      return {
        ...state,
        filter_zona: action.payload,
        filtered: state.clientes.filter((cliente) => {
          return (
            cliente.zona.nombre &&
            cliente.zona.nombre.toLowerCase() === action.payload.toLowerCase()
          );
        }),
      };
    case FILTER_CLIENTES_PANEL_VENTAS:
      if (!action.payload) action.payload = state.filter_panel_ventas;
      let filtered = state.clientes;
      if (action.payload.nombre)
        filtered = filtered.filter((cliente) => {
          const regex = new RegExp(
            `${escapeRegExp(action.payload.nombre)}`,
            "gi"
          );
          return (
            (cliente.nombre && cliente.nombre.match(regex)) ||
            (cliente.razon_social && cliente.razon_social.match(regex))
          );
        });
      if (action.payload.zonas.length)
        filtered = filtered.filter((cliente) => {
          return (
            cliente.zona &&
            action.payload.zonas.indexOf(cliente.zona.nombre) !== -1
          );
        });
      if (action.payload.canales.length)
        filtered = filtered.filter((cliente) => {
          console.log("CANAL", cliente.canal, action.payload.canales);
          return (
            cliente.canal &&
            action.payload.canales.indexOf(cliente.canal.nombre) !== -1
          );
        });

      if (action.payload.forma_contacto)
        filtered = filtered.filter((cliente) => {
          let contacto = cliente.contactoComprador
            ? cliente.contactoComprador
            : cliente.telefonos[0]
            ? cliente.telefonos[0]
            : null;

          return (
            contacto &&
            ((action.payload.forma_contacto === "mail" &&
              contacto.tipo_telefono_id === 3) ||
              (action.payload.forma_contacto === "telefono" &&
                contacto.tipo_telefono_id !== 3))
          );
        });

      return {
        ...state,
        filter_panel_ventas: action.payload,
        clientes_dias: clientesToDias(filtered, state),
      };
    case CLEAR_FILTER_CLIENTE:
      return {
        ...state,
        filtered: null,
      };
    case ERROR_CLIENTE:
      return {
        ...state,
        error: action.payload,
        loading_afip: false,
      };
    default:
      return state;
  }
};
