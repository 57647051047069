import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
