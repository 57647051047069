import { CircularProgress } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BigNumber from "bignumber.js";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { getCajas } from "../../actions/cajas";
import { getCheques } from "../../actions/cheques";
import { getChequesPropios } from "../../actions/cheques_propios";
import { getClientes } from "../../actions/clientes";
import { getListasPrecios } from "../../actions/listas_precios";
import { getProductos } from "../../actions/productos";
import { getTransportistas } from "../../actions/transportistas";
import {
  addPuntoLogistico,
  clearCurrentPuntoLogistico,
  clearPuntoLogisticoError,
  getPuntosLogisticos,
  setOpenPuntoLogistico,
  updatePuntoLogistico,
} from "../../actions/ventas";
import { provincias } from "../../config/constantes";
import { getZonas } from "../../actions/zonas";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formHeader: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
  divider: {
    display: "inline-block",
    width: 0,
    height: "1em",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
  },
  abasto: {
    marginLeft: "8px",
    padding: "8px",
    backgroundColor: "lightyellow",
    border: "black 1px dashed",
  },
}));

const PLogisticoForm = ({
  current_punto_logistico,
  saving,
  open_punto_logistico,
  dispatch,
  clientes,
  error_punto_logistico,
}) => {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  BigNumber.config({ DECIMAL_PLACES: 2 });
  const emptyPuntoLogistico = () => ({
    id: 0,
    fecha_salida: null,
    motivo: "Cobros",
    cliente_id: null,
    nombre: null,
    direccion: null,
    localidad: null,
    provincia_id: null,
    entrega: false,
    hora_desde: "08:00",
    hora_hasta: "12:00",
    bultos: 0,
  });

  const [puntoLogistico, setPuntoLogistico] = useState(emptyPuntoLogistico());

  useEffect(() => {
    if (current_punto_logistico !== null || open_punto_logistico) {
      dispatch(getClientes());
    }
    if (current_punto_logistico !== null) {
      setPuntoLogistico(current_punto_logistico);
      puntoLogistico.entrega = puntoLogistico.entrega === 1 ? true : false;
    } else {
      if (open_punto_logistico && open_punto_logistico.id) {
        setPuntoLogistico({
          ...emptyPuntoLogistico(),
        });
      } else {
        setPuntoLogistico(emptyPuntoLogistico());
      }
    }
  }, [current_punto_logistico, open_punto_logistico]);

  useEffect(() => {
    if (puntoLogistico.cliente_id) {
      const clienteSelected = clientes.find(
        (p) => p.id === puntoLogistico.cliente_id
      );
      setPuntoLogistico({
        ...puntoLogistico,
        nombre: clienteSelected.nombre,
        direccion: clienteSelected.direccion_entrega,
        localidad: clienteSelected.localidad_entrega,
        provincia_id: clienteSelected.provincia_entrega_id,
      });
    }
  }, [puntoLogistico.cliente_id]);

  const onValueChange = (e) => {
    if (e.target.name === "entrega")
      setPuntoLogistico({
        ...puntoLogistico,
        [e.target.name]: e.target.checked,
      });
    else if (e.target.name === "bultos")
      setPuntoLogistico({
        ...puntoLogistico,
        [e.target.name]: Number(e.target.value),
      });
    else
      setPuntoLogistico({ ...puntoLogistico, [e.target.name]: e.target.value });
  };

  const onValueChangeDate = (e, field) => {
    setPuntoLogistico({ ...puntoLogistico, [field]: e });
  };

  const onClickGuardar = (e) => {
    if (
      !puntoLogistico.fecha_salida ||
      !puntoLogistico.hora_desde ||
      !puntoLogistico.hora_hasta ||
      !puntoLogistico.motivo ||
      puntoLogistico.bultos === null ||
      !puntoLogistico.nombre ||
      !puntoLogistico.direccion ||
      !puntoLogistico.localidad ||
      !puntoLogistico.provincia_id
    ) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else {
      onSubmit(e);
    }
  };

  const onSubmit = (e, puntoLogisticoCustom = null) => {
    e.preventDefault();

    puntoLogisticoCustom
      ? (puntoLogisticoCustom.fecha_salida = moment(
          puntoLogistico.fecha_salida
        ).format("YYYY-MM-DD HH:MM:SS"))
      : (puntoLogistico.fecha_salida = moment(
          puntoLogistico.fecha_salida
        ).format("YYYY-MM-DD HH:MM:SS"));

    puntoLogisticoCustom
      ? (puntoLogisticoCustom.entrega = puntoLogistico.entrega ? 1 : 0)
      : (puntoLogistico.entrega = puntoLogistico.entrega ? 1 : 0);
    try {
      if (!current_punto_logistico || puntoLogistico._NUEVA) {
        dispatch(
          addPuntoLogistico(
            puntoLogisticoCustom ? puntoLogisticoCustom : puntoLogistico
          )
        ).then((r) => {
          !error_punto_logistico
            ? alertContext.setAlert("Punto Logistico guardado!", "success")
            : alertContext.setAlert(error_punto_logistico.toString(), "error");

          dispatch(getPuntosLogisticos());
        });
      } else {
        dispatch(
          updatePuntoLogistico(
            puntoLogisticoCustom ? puntoLogisticoCustom : puntoLogistico
          )
        ).then((r) => {
          !error_punto_logistico
            ? alertContext.setAlert("Punto Logistico guardado!", "success")
            : alertContext.setAlert(error_punto_logistico.toString(), "error");

          dispatch(getPuntosLogisticos());
        });
      }
    } catch (error) {}
    clearAll();
    handleClose();
  };

  const clearAll = () => {
    setPuntoLogistico(emptyPuntoLogistico());
    dispatch(clearCurrentPuntoLogistico());
    dispatch(clearPuntoLogisticoError());
  };

  function handleClose() {
    setPuntoLogistico(emptyPuntoLogistico());
    dispatch(clearCurrentPuntoLogistico());
    dispatch(setOpenPuntoLogistico(false));
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      style={
        !useMediaQuery(useTheme().breakpoints.down("sm"))
          ? { width: "90%", height: "95%", margin: "auto" }
          : {}
      }
      open={current_punto_logistico || open_punto_logistico ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle
        id="max-width-dialog-title"
        color="primary"
        className={classes.formHeader}
      >
        {!current_punto_logistico
          ? "Nuevo Punto Logistico"
          : "Editar Punto Logistico"}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={(e) => false}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
              Fecha de Salida: *{" "}
              <DatePicker
                onChange={(e) => onValueChangeDate(e, "fecha_salida")}
                value={
                  puntoLogistico.fecha_salida
                    ? moment(puntoLogistico.fecha_salida).toDate()
                    : null
                }
                format="dd/MM/y"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="standard-textarea"
                name="hora_desde"
                style={{ width: "100%" }}
                value={puntoLogistico.hora_desde}
                variant="outlined"
                label={"Horario desde"}
                type="time"
                onChange={onValueChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                defaultValue="08:00"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="standard-textarea"
                name="hora_hasta"
                style={{ width: "100%" }}
                value={puntoLogistico.hora_hasta}
                variant="outlined"
                label={"Horario hasta"}
                type="time"
                onChange={onValueChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                defaultValue="12:00"
              />
            </Grid>
            <TextField
              margin="dense"
              name="motivo"
              label="Motivo"
              variant="outlined"
              id="motivo"
              value={puntoLogistico.motivo || ""}
              onChange={onValueChange}
              fullWidth
              required={true}
            />
            <FormControl
              variant="outlined"
              style={{
                height: "40px",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <ReactSelect
                value={
                  puntoLogistico.cliente_id &&
                  clientes.find((p) => p.id === puntoLogistico.cliente_id)
                    ? {
                        label: clientes.find(
                          (p) => p.id === puntoLogistico.cliente_id
                        ).nombre,
                        value: puntoLogistico.cliente_id,
                      }
                    : { value: 0, label: "NO CLIENTE" }
                }
                isClearable
                name="cliente_id"
                placeholder="Cliente"
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    marginTop: "-8px",
                  }),
                }}
                menuPortalTarget={document.body}
                onChange={(e) => {
                  setPuntoLogistico({
                    ...puntoLogistico,
                    cliente_id: e ? e.value : null,
                  });
                }}
                options={
                  clientes &&
                  clientes
                    .filter((c) => !c.desactivado)
                    .map((cliente) => ({
                      value: cliente.id,
                      label: cliente.nombre,
                    }))
                }
              />
            </FormControl>
            <Grid
              container
              className={classes.root}
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={12} md={3}>
                <TextField
                  style={{ width: "100%" }}
                  margin="dense"
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  id="nombre"
                  value={puntoLogistico.nombre || ""}
                  onChange={onValueChange}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  style={{ width: "100%" }}
                  margin="dense"
                  name="direccion"
                  label="Direccion"
                  variant="outlined"
                  id="direccion"
                  value={puntoLogistico.direccion || ""}
                  onChange={onValueChange}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  style={{ width: "100%" }}
                  margin="dense"
                  name="localidad"
                  label="Localidad"
                  variant="outlined"
                  id="localidad"
                  value={puntoLogistico.localidad || ""}
                  onChange={onValueChange}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "0px",
                  }}
                >
                  <InputLabel htmlFor="select-provincia">Provincia*</InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: "40px",
                          marginTop: "8px",
                          width: "100%",
                        }}
                        id="select-provincia"
                      />
                    }
                    value={
                      puntoLogistico.provincia_id
                        ? puntoLogistico.provincia_id
                        : ""
                    }
                    name="provincia_id"
                    onChange={(e) => {
                      setPuntoLogistico({
                        ...puntoLogistico,
                        provincia_id: e.target.value,
                      });
                    }}
                  >
                    {provincias.map((prov, index) => (
                      <MenuItem value={prov.id} key={index}>
                        {prov.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.root}
              spacing={3}
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  id="standard-textarea"
                  name="bultos"
                  value={puntoLogistico.bultos || 0}
                  style={{ width: "100%" }}
                  variant="outlined"
                  label={"Cantidad de Bultos"}
                  inputProps={{ min: "0" }}
                  type="number"
                  onChange={onValueChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={puntoLogistico.entrega}
                      margin="dense"
                      name="entrega"
                      label="Entrega"
                      className={classes.textField}
                      variant="outlined"
                    />
                  }
                  label="Entrega"
                  labelPlacement="end"
                  onChange={onValueChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      {!saving ? (
        <DialogActions className={classes.formFooter}>
          <Fragment>
            <Button
              onClick={(e) => onClickGuardar(e)}
              variant="contained"
              color="primary"
              type="submit"
            >
              Guardar
            </Button>
          </Fragment>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      ) : (
        <DialogActions className={classes.formFooter}>
          <CircularProgress color="secondary" />
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current_punto_logistico: state.ventas.current_punto_logistico,
    open: state.ventas.open,
    open_punto_logistico: state.ventas.open_punto_logistico,
    error: state.ventas.error,
    error_punto_logistico: state.ventas.error_punto_logistico,
    clientes: state.clientes.clientes,
  };
};

export default connect(mapStateToProps)(PLogisticoForm);
