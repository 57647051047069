import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getListasPrecios } from '../../actions/listas_precios';
import subscribeTo from '../../utils/subscribeTo';
import AddListaPreciosBtn from '../listas_precios/AddListaPreciosBtn';
import ListaPreciosForm from '../listas_precios/ListaPreciosForm';
import ListaPreciosItem from './ListaPreciosItem';

export const tiposListaPrecios = [
  'Efectivo en Pesos',
  'Efectivo en Dólares',
  'Cuenta corriente en pesos',
  'Cheque terceros',
  'Cheque',
];

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const ListasPrecios = ({ listas_precios, filtered, loading, dispatch }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getListasPrecios());
    if (!loading) {
      subscribeTo('listas_preciosChanged', (err, data) => {
        dispatch(getListasPrecios());
      });
      return () => {
        subscribeTo('listas_preciosChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (listas_precios !== null && !listas_precios.length && !loading) {
    return (
      <Fragment>
        <ListaPreciosForm />
        <h4>No hay ninguna Lista de Precios todavía!</h4> <AddListaPreciosBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ListaPreciosForm />
      </div>
      <div>
        <div className={classes.root}>
          {listas_precios !== null && !loading ? (
            <Fragment>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
              >
                {(filtered ? filtered : listas_precios).map((lista_precios, key) => (
                  <ListaPreciosItem key={key} lista_precios={lista_precios} />
                ))}
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddListaPreciosBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    listas_precios: state.listasPrecios.listas_precios,
    filtered: state.listasPrecios.filtered,
    loading: state.listasPrecios.loading
  };
};

export default connect(mapStateToProps)(ListasPrecios);
