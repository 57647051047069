import axios from "axios";

import { addContacto } from "./clientes.js";

import {
  GET_VENTAS,
  ADD_VENTA,
  DELETE_VENTA,
  UPDATE_VENTA,
  CLEAR_VENTAS,
  DELETE_PUNTO_LOGISTICO,
  ERROR_VENTA,
  SET_CURRENT,
  SET_OPEN,
  SET_OPEN_PUNTOLOGISTICO,
  CLEAR_CURRENT,
  FILTER_VENTAS,
  CLEAR_FILTER,
  SET_SAVING_VENTA,
  ADD_PUNTO_LOGISTICO,
  ERROR_PUNTO_LOGISTICO,
  SET_SAVING_PUNTO_LOGISTICO,
  LOADING_VENTAS,
  CAMBIAR_ESTADO_VENTA,
  SET_BYPASS_IO_VENTAS,
  APROBAR_MULTIPLES,
  ASIGNAR_CAMIONETA,
  FILTRAR_VENTAS_FACTURADAS,
  GET_VENTAS_PARA_COBRAR,
  GET_VENTAS_FACTURADAS,
  GET_VENTAS_CLIENTE,
  GET_VENTAS_CLIENTE_NO_PAGAS,
  GET_VENTAS_APROBADAS,
  GET_VENTAS_PENDIENTES,
  CLEAR_CURRENT_PUNTO_LOGISTICO,
  GET_PUNTOS_LOGISTICOS,
  SET_CURRENT_PUNTO_LOGISTICO,
  UPDATE_PUNTO_LOGISTICO,
} from "./types";

export const getVentas = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/ventas", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          filters: { ...filters },
        },
        data: null,
      });
      dispatch({ type: GET_VENTAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasCliente = (cliente_id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/ventas", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          cliente_id: cliente_id,
        },
        data: null,
      });
      dispatch({
        type: GET_VENTAS_CLIENTE,
        payload: { cliente_id, ventas: res.data },
      });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasClienteNoPagas = (cliente_id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/ventas", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          cliente_id: cliente_id,
          no_pagas: true,
        },
        data: null,
      });
      dispatch({
        type: GET_VENTAS_CLIENTE_NO_PAGAS,
        payload: { cliente_id, ventas: res.data },
      });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasFacturadas = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/ventas/ventasFacturadas", filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({ type: GET_VENTAS_FACTURADAS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasAprobadas = (filters = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_VENTAS, payload: true });
      const res = await axios.post("/api/ventas/ventasAprobadas", filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({ type: LOADING_VENTAS, payload: false });
      dispatch({ type: GET_VENTAS_APROBADAS, payload: res.data });
    } catch (error) {
      dispatch({ type: LOADING_VENTAS, payload: false });
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasPendientes = (filters = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_VENTAS, payload: true });
      const res = await axios.post("/api/ventas/ventasPendientes", filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({ type: LOADING_VENTAS, payload: false });
      dispatch({ type: GET_VENTAS_PENDIENTES, payload: res.data });
    } catch (error) {
      dispatch({ type: LOADING_VENTAS, payload: false });
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export const getVentasParaCobrar = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/ventas/ventasParaCobrar", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({ type: GET_VENTAS_PARA_COBRAR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
  };
};

export function setCurrentVenta(venta) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT, payload: venta });
  };
}

export const setSavingVenta = (saving) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVING_VENTA, payload: saving });
  };
};

export const setSavingPuntoLogistico = (saving) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVING_PUNTO_LOGISTICO, payload: saving });
  };
};

export function setOpenVenta(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

export const clearCurrentVenta = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT });
  };
};

export function setCurrentPuntoLogistico(punto_logistico) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PUNTO_LOGISTICO, payload: punto_logistico });
  };
}

export function setOpenPuntoLogistico(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_PUNTOLOGISTICO, payload: open });
  };
}

export const clearCurrentPuntoLogistico = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_PUNTO_LOGISTICO });
  };
};

export const filterVentas = (filter, filterType) => {
  return (dispatch) => {
    dispatch({ type: FILTER_VENTAS, filter: filter, filterType: filterType });
  };
};
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

export const getPuntosLogisticos = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/puntos_logisticos", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          filters: { ...filters },
        },
        data: null,
      });
      dispatch({ type: GET_PUNTOS_LOGISTICOS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: error });
    }
  };
};

//Add Punto Logistico
export const addPuntoLogistico = (puntoLogistico) => {
  return async (dispatch) => {
    dispatch(setSavingPuntoLogistico(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "/api/puntos_logisticos",
        puntoLogistico,
        config
      );
      dispatch({ type: ADD_PUNTO_LOGISTICO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: error });
    }
    dispatch(setSavingPuntoLogistico(false));
  };
};
//Update Punto Logistico
export const updatePuntoLogistico = (puntoLogistico) => {
  return async (dispatch) => {
    dispatch(setSavingPuntoLogistico(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/puntos_logisticos/${puntoLogistico.id}`,
        puntoLogistico,
        config
      );

      dispatch({ type: UPDATE_PUNTO_LOGISTICO, payload: res.data });
      if (res.data.warning) {
        dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: res });
        setTimeout(
          () => dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: "" }),
          3000
        );
      }
    } catch (error) {
      dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: error.msg });
    }
    dispatch(setSavingPuntoLogistico(false));
  };
};
//Delete Punto Logistico
export const deletePuntoLogistico = (puntoLogisticoId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(
        `/api/puntos_logisticos/${puntoLogisticoId}`
      );

      dispatch({ type: DELETE_PUNTO_LOGISTICO, payload: puntoLogisticoId });
    } catch (error) {
      dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: error });
    }
  };
};
//Clear Errores Punto Logistico
export const clearPuntoLogisticoError = () => {
  console.log("ENTRO ERROR");
  return (dispatch) => {
    console.log("ENTRO ERROR 2");
    dispatch({ type: ERROR_PUNTO_LOGISTICO, payload: null });
  };
};

//Add Venta
export const addVenta = (venta) => {
  return async (dispatch) => {
    dispatch(setSavingVenta(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/ventas", venta, config);
      dispatch(
        addContacto({
          ...res.data.cliente,
          nuevoContacto: { cliente_tipo_comunicacion_id: 3 },
        })
      );
      dispatch({ type: ADD_VENTA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
    dispatch(setSavingVenta(false));
  };
};

//Delete Venta
export const deleteVenta = (ventaId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/ventas/${ventaId}`);

      dispatch({ type: DELETE_VENTA, payload: ventaId });
    } catch (error) {
      dispatch({ type: ERROR_VENTA, payload: error.response.msg });
    }
  };
};

export const cambiarEstadoVenta = (
  venta_id,
  estado_id,
  { fecha_salida, noEnviarMail, remito, numeroFactura } = {}
) => {
  return async (dispatch) => {
    dispatch(setBypassIoVentas(false));
    dispatch(setSavingVenta(true));
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const res = await axios.put(
        `/api/ventas/cambiar_estado/${venta_id}`,
        {
          estado_id,
          ...(fecha_salida && { fecha_salida: fecha_salida }),
          noEnviarMail,
          remito,
          numeroFactura,
        },
        config
      );
      dispatch({
        type: CAMBIAR_ESTADO_VENTA,
        payload: { ventas: res.data, estado_id, remito },
      });
      if (res.data.warning) {
        dispatch({ type: ERROR_VENTA, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 5000);
      }
    } catch (error) {
      dispatch(setBypassIoVentas(false));
      dispatch({ type: ERROR_VENTA, payload: error.response.data.error.msg });
      setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 5000);
    }
    dispatch(setSavingVenta(false));
  };
};

export const aprobarMultiples = (ventas, fecha) => {
  return async (dispatch) => {
    dispatch(setBypassIoVentas(true));
    dispatch(setSavingVenta(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/ventas/aprobar_multiples/1`,
        { ventas, fecha },
        config
      );

      dispatch({
        type: APROBAR_MULTIPLES,
        payload: { ventas: res.data.ventas, fecha },
      });
      if (res.data.warning) {
        dispatch({ type: ERROR_VENTA, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 3000);
      }
    } catch (error) {
      dispatch(setBypassIoVentas(false));
      dispatch({ type: ERROR_VENTA, payload: error.response.data.error.msg });
      setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 3000);
    }
    dispatch(setSavingVenta(false));
  };
};

export const asignarCamioneta = (ventas, camioneta) => {
  return async (dispatch) => {
    dispatch(setBypassIoVentas(true));
    dispatch(setSavingVenta(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/ventas/asignar_camioneta/1`,
        { ventas, camioneta },
        config
      );

      dispatch({
        type: ASIGNAR_CAMIONETA,
        payload: { ventas: res.data, camioneta },
      });
      if (res.data.warning) {
        dispatch({ type: ERROR_VENTA, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 5000);
      }
    } catch (error) {
      dispatch(setBypassIoVentas(false));
      dispatch({ type: ERROR_VENTA, payload: error.response.data.error.msg });
      setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 5000);
    }
    dispatch(setSavingVenta(false));
  };
};
//Set Current Venta

//Clear Current Venta

//Update Venta

export const updateVenta = (venta) => {
  return async (dispatch) => {
    dispatch(setSavingVenta(true));
    dispatch(setBypassIoVentas(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/ventas/${venta.id}`, venta, config);

      dispatch({ type: UPDATE_VENTA, payload: res.data });
      if (res.data.warning) {
        dispatch({ type: ERROR_VENTA, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_VENTA, payload: "" }), 3000);
      }
    } catch (error) {
      dispatch(setBypassIoVentas(false));
      dispatch({ type: ERROR_VENTA, payload: error.msg });
    }
    dispatch(setSavingVenta(false));
  };
};

//Filter Venta

//Clear Filter

export const clearVentas = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VENTAS });
  };
};

export const setBypassIoVentas = (should) => {
  return (dispatch) => {
    dispatch({ type: SET_BYPASS_IO_VENTAS, payload: should });
  };
};

//subir Factur
export const subirFactura = (venta_id, formData) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
    };
    try {
      await axios.post(
        `/api/ventas/subirFactura/${venta_id}`,
        formData,
        config
      );
    } catch (error) {
      console.error("hay un error", error);
    }
  };
};

/* export const imprimirFacturaE = (venta_id)=>{
  return async(dispatch)=>{
  try {
    
  } catch (error) {
    
  }  
  }
} */
