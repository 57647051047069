import { Button, CardActions, Grid, Typography } from "@material-ui/core/";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useConfirm } from "material-ui-confirm";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { clearCurrent, deleteGrupo, setCurrent } from "../../actions/grupos";

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: "#f7f7f7"
  },
  addressLink: {
    color: "black",
    textDecorationLine: "none",

    overflow: "hidden",

    "&:hover": {
      textDecorationLine: "underline"
    }
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: "180px",
    overflowY: "auto",
    fontSize: "0.9rem"
  },
  cardTitle: {
    fontSize: "1.15rem"
  }
});

const GrupoItem = ({ grupo, grupos, current, dispatch, listas_precios }) => {
  const { id, nombre, tipo, balance } = grupo;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteGrupo(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, grupo) => {
    confirm({
      description: `Desea borrar la grupo "${grupo.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar"
    }).then(() => onDelete());
  };

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h6" component="h6" className={classes.cardTitle}>
            {nombre}
          </Typography>
          <p color="textSecondary">
            Razón Social: {grupo.razon_social}
            <br />
            CUIT: {grupo.cuit}
            <br />
            Lista de Precios: {listas_precios.find((lista) => lista.id === grupo.lista_precios_id).nombre}
            <br />
            Cuenta corriente propia sucursal? {grupo.cuenta_corriente_propia ? "Si" : "No"}
            <br />
          </p>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button onClick={(e) => onClickBorrar(confirm, dispatch, grupo)} size="small" color="secondary">
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => dispatch(setCurrent(grupo))}
            size="small"
            color="primary"
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

GrupoItem.propTypes = {
  grupo: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    grupos: state.grupos.grupos,
    current: state.grupos.current
  };
};

export default connect(mapStateToProps)(GrupoItem);
