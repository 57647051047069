import React, { useState, useEffect, Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { deleteCheque, getCheques, setCurrent } from "../../actions/cheques";

import { makeStyles } from "@material-ui/core/styles";
import {
  LinearProgress,
  Grid,
  Button,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,
  Paper,
  Checkbox,
  FormControlLabel,
  Icon,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core/";
import subscribeTo from "../../utils/subscribeTo";
import ChequeForm from "../cheques/ChequeForm";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import AddChequeBtn from "../cheques/AddChequeBtn";
import moment from "moment";
import NumberFormat from "react-number-format";
import AcreditarChequeBtn from "./AcreditarChequeBtn";
import { getCajas } from "../../actions/cajas";
import { getIsAdmin, getRoleCanDo } from "../usuarios/Usuario";
import { useConfirm } from "material-ui-confirm";
import ChequesFilter from "./ChequesFilter";
import { cEstadosCheques } from "../../config/constantes";
import { cEstadosChequesObj } from "../../config/constantesServer";

export const tiposCheque = [
  "Efectivo en Pesos",
  "Efectivo en Dólares",
  "Cuenta corriente en pesos",
  "Cheques",
];

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",
    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
  expansionPanelFilter: {
    padding: "0 10px 20px 10px",
  },

  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  colorRemainigRed: {
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
  },
  colorRemainigGreen: {
    color: "green",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
  },
  colorRemainigYellow: {
    color: "yellow",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#CCCC00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },

  disapproveButton: {
    cursor: "pointer",
    color: "grey",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "25%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
}));

const Cheques = ({ cheques, filtered, loading, dispatch, bancos, cajas }) => {
  const [selected, setSelected] = useState([]);

  const [selectedDepo, setSelectedDepo] = useState([]);
  const [modo, setModo] = useState(null);
  const [remainigColor, setRemainigColor] = useState("none");

  const [filters, setFilters] = useState({
    cliente: null,
    fechaVenta: null,
    fechaSalida: null,
    cuit: "",
    razon_social: "",
  });

  const classes = useStyles();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getCheques());
    dispatch(getCajas());
    if (!loading) {
      subscribeTo("chequesChanged", (err, data) => {
        dispatch(getCheques());
      });
      return () => {
        subscribeTo("chequesChanged", null);
      };
    }
  }, [dispatch, loading]);

  const onChangeCheck = (id) => {
    setSelectedDepo([]);
    let index = selected.indexOf(id);
    if (index !== -1) {
      setSelected(selected.filter((i) => i !== id));
    } else {
      setSelected(selected.concat([id]));
    }
  };

  const onChangeCheckDepo = (id) => {
    setSelected([]);
    let index = selectedDepo.indexOf(id);
    if (index !== -1) {
      setSelectedDepo(selectedDepo.filter((i) => i !== id));
    } else {
      setSelectedDepo(selectedDepo.concat([id]));
    }
  };

  if (cheques !== null && !cheques.length && !loading) {
    return (
      <Fragment>
        <ChequeForm />
        <h4>No hay ningun cheque todavía!</h4> <AddChequeBtn />
      </Fragment>
    );
  }

  const onClickBorrar = (confirm, dispatch, cheque) => {
    confirm({
      description: `Desea borrar este cheque?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => dispatch(deleteCheque(cheque.id)));
  };

  function diasRestantesCheque(date) {
    let dias = moment(date, "YYYYMMDD").diff(moment(), "days");

    if (dias <= 10) {
      return classes.colorRemainigRed;
    } else if (dias <= 30) {
      return classes.colorRemainigYellow;
    } else {
      return classes.colorRemainigGreen;
    }
  }

  return (
    <Fragment>
      <div>
        <ChequeForm />
      </div>
      <div>
        <div className={classes.root}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<Icon>filter_list</Icon>}
              id="panel1a-header"
              aria-controls="panel1a-content"
              className={classes.summary}
            >
              <Typography className={classes.heading}>
                Cheques de terceros (
                {(filtered ? filtered : cheques ? cheques : []).length}{" "}
                resultados)
              </Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelFilter}>
              <ChequesFilter />
            </AccordionDetails>
          </Accordion>
          {cheques && cheques.length && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell width="5%" align="center"></TableCell>
                    <TableCell align="center">Numero</TableCell>

                    <TableCell align="center">Razon Social</TableCell>
                    <TableCell align="center">Cliente</TableCell>

                    <TableCell align="center">Fecha de Emision</TableCell>
                    <TableCell width="8%" align="center">
                      Fecha de Cobro
                    </TableCell>
                    <TableCell align="center">Dias a Cobrar</TableCell>
                    <TableCell align="center">Fecha Mov.</TableCell>
                    <TableCell align="center">Valor</TableCell>

                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Fecha de Cobranza</TableCell>

                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cheques &&
                    cheques.length &&
                    (filtered ? filtered : cheques).map((cheque, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell width="5%" align="center">
                          {" "}
                          <FormControlLabel
                            value="fijar"
                            control={
                              <Checkbox
                                margin="dense"
                                disabled={
                                  cheque.cheque_estado_id ===
                                    cEstadosChequesObj["Acreditado"] ||
                                  (selected.length > 0 &&
                                    cheques.find(
                                      (ch) => ch.id === selected[0]
                                    ) &&
                                    cheques.find((ch) => ch.id === selected[0])
                                      .cheque_estado_id !==
                                      cheque.cheque_estado_id)
                                }
                                name="fijar"
                                label="Fijado"
                                value="1"
                                checked={selected.indexOf(cheque.id) !== -1}
                                onChange={(e) =>
                                  onChangeCheck(cheque.id, cheque)
                                }
                                className={classes.textField}
                                variant="outlined"
                              />
                            }
                            label=""
                            labelPlacement="end"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {cheque.numero} {cheque.echeck ? "(E-check)" : ""}
                        </TableCell>
                        <TableCell align="center">
                          {cheque.razon_social}
                        </TableCell>
                        <TableCell align="center">
                          {cheque.cliente
                            ? cheque.cliente.nombre
                            : cheque.grupo.nombre}
                        </TableCell>
                        <TableCell width="8%" align="center">
                          {cheque.fecha_emision
                            ? moment(cheque.fecha_emision).format("DD/MM/YYYY")
                            : ""}
                        </TableCell>
                        <TableCell width="8%" align="center">
                          {moment(cheque.fecha).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell
                          width="8%"
                          align="center"
                          className={diasRestantesCheque(cheque.fecha)}
                        >
                          {moment(cheque.fecha, "YYYYMMDD").fromNow()}
                        </TableCell>

                        <TableCell width="8%" align="center">
                          {cheque.estado.id !== 1 &&
                            cheque.estado.id !== 6 &&
                            moment(
                              cheque.estado.id === 2
                                ? cheque.fecha_deposito
                                : cheque.estado.id === 3
                                ? cheque.fecha_acreditado
                                : cheque.estado.id === 4
                                ? cheque.fecha_rechazado
                                : cheque.estado.id === 5
                                ? cheque.fecha_utilizado
                                : ""
                            ).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            margin="dense"
                            name="horaAtencion2Fin"
                            label="Fin Tarde"
                            value={cheque.valor}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell width="8%" align="center">
                          {cheque.estado.nombre}
                        </TableCell>
                        <TableCell width="8%" align="center">
                          {moment(cheque.created_at).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell width="8%" align="center">
                          <span
                            title="Ver cheque"
                            className={classes.facturarButton}
                            onClick={(e) =>
                              dispatch(
                                setCurrent({ ...cheque, soloVista: true })
                              )
                            }
                          >
                            <Icon className={classes.listIcon}>visibility</Icon>
                          </span>
                          <span
                            title="Editar"
                            className={classes.editButton}
                            onClick={(e) => dispatch(setCurrent(cheque))}
                          >
                            <Icon className={classes.listIcon}>edit</Icon>
                          </span>
                          {getRoleCanDo() && (
                            <span
                              title="Borrar"
                              className={classes.deleteButton}
                              onClick={(e) =>
                                onClickBorrar(confirm, dispatch, cheque)
                              }
                            >
                              <Icon
                                fontSize="large"
                                className={classes.listIcon}
                              >
                                delete
                              </Icon>
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>

      {cajas && cajas.length > 0 && selected.length > 0 && (
        <AcreditarChequeBtn
          cajas={cajas}
          estado={
            cheques.find((ch) => ch.id === selected[0]) &&
            cheques.find((ch) => ch.id === selected[0]).cheque_estado_id
          }
          ids={selected}
          setSelected={setSelected}
          modo="En cartera"
        />
      )}
      {cajas && cajas.length > 0 && selectedDepo.length > 0 && (
        <AcreditarChequeBtn acreditar={true} ids={selectedDepo} />
      )}
      <AddChequeBtn />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cheques: state.cheques.cheques,
    filtered: state.cheques.filtered,
    loading: state.cheques.loading,
    bancos: state.bancos.bancos,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
  };
};

export default connect(mapStateToProps)(Cheques);
