import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Snackbar,
} from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NumberFormat from "react-number-format";
import PlacesAutocomplete from "react-places-autocomplete";
/* global google */
import { connect } from "react-redux";
import ReactSelectNonCreate from "react-select";
import ReactSelect from "react-select/creatable";
import {
  addCliente,
  clearCurrent,
  obtenerClienteAfip,
  setOpen,
  updateCliente,
} from "../../actions/clientes";
import { getGrupos } from "../../actions/grupos";
import { getListasPrecios } from "../../actions/listas_precios";
import { getTransportistas } from "../../actions/transportistas";
import { addVendedor, getVendedores } from "../../actions/vendedores";
import { getZonas } from "../../actions/zonas";
import {
  canales,
  cHabilitaciones,
  condicionIva,
  diasSemana,
  diasSemanaNum,
  personerias,
  provincias,
  tiposCobranzas,
  tiposComprobanteCliente,
  tiposFormasDePago,
} from "../../config/constantes";
import AlertContext from "../../context/alert/alertContext";
import TelefonosInput from "../utils/TelefonosInput";

const useStyles = makeStyles((theme) => ({
  panel: {
    width: "100%",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: "15%",
  },
  column: {
    flexBasis: "20%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  textFieldError: { color: "red" },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  btnAfip: {
    marginTop: "5px",
  },
  locationInput: {
    height: "41px",
    marginTop: "8px",
  },
  formHeader: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const emptyCliente = {
  nombre: "",
  lista_precios_id: "",
  cuit: "",
  razon_social: "",
  categoria_impositiva_id: "",
  tipo_comprobante_id: "",
  transportista_id: null,
  notas: "",
  zonas_distribucion: "",
  notas_logisticas: "",
  tiempo_descarga: "",
  transportista_destino_id: null,
  dias_entrega: [],
  personeria_id: "",
  email_facturacion: "",
  limite_descubierto: "",
  clasificacion: 1,
  telefonos: [],
  grupo_id: "",
  plazo_pago: "",
  plazo_pago_x: "",
  formas_pago: [],
  zona_id: "",
  vendedor_id: "",
  comision_vendedor: "",
  tipo_cobranza_id: "",
  descuento: "",
  motivo_descuento: "",
  obs_precios:"",
  bonificacion: "",
  horaAtencion1Ini: "08:00",
  horaAtencion1Fin: "12:00",
  horaAtencion2Ini: "",
  horaAtencion2Fin: "",
  canal_id: "",
  estado: 1,
  desactivado: 0,
  enviar_notificacion: 1,
  generar_remito: 1,
  habilitacion: 0,
  fijar: 0,
  mi_pyme: 0,
  mostrar_saldo_remito: 0,
  motivo_desactivado: "",
  contabilium_id: null,
};

const ClienteForm = ({
  current,
  listas_precios,
  grupos,
  loading_grupos,
  transportistas,
  loading_transportistas,
  loading_listas_precios,
  vendedores,
  loading_vendedores,
  ultimo_vendedor,
  open,
  error,
  loading_afip,
  zonas,
  loading_zonas,
  saving,
  addVendedor,
  getListasPrecios,
  getTransportistas,
  getGrupos,
  getZonas,
  getVendedores,
  addCliente,
  updateCliente,
  clearCurrent,
  setOpen,
  obtenerClienteAfip,
}) => {
  const [cliente, setCliente] = useState(emptyCliente);
  const map = useRef();

  const {
    id,
    nombre,
    lista_precios_id,
    cuit,
    razon_social,
    categoria_impositiva_id,
    tipo_comprobante_id,
    notas,
    zonas_distribucion,
    notas_logisticas,
    tiempo_descarga,
    dias_entrega,
    transportista_destino_id,
    transportista_id,
    personeria_id,
    email_facturacion,
    limite_descubierto,
    clasificacion,
    telefonos,
    direccion_facturacion,
    place_id_facturacion,
    detalles_facturacion,
    localidad_facturacion,
    provincia_facturacion_id,
    direccion_entrega,
    contabilium_id,
    place_id_entrega,
    detalles_entrega,
    localidad_entrega,
    provincia_entrega_id,
    grupo_id,
    plazo_pago,
    plazo_pago_x,
    formas_pago,
    zona_id,
    vendedor_id,
    comision_vendedor,
    tipo_cobranza_id,
    descuento,
    motivo_descuento,obs_precios,
    bonificacion,
    enviar_notificacion,
    generar_remito,
    habilitacion,
    fijar,
    mi_pyme,
    horaAtencion1Ini,
    horaAtencion1Fin,
    horaAtencion2Ini,
    horaAtencion2Fin,
    canal_id,
    estado,
    desactivado,
    mostrar_saldo_remito,
    motivo_desactivado,
  } = cliente;

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    getListasPrecios();
    getTransportistas();
    getGrupos();
    getZonas();
    getVendedores();

    if (current !== null) {
      setCliente(current);
    } else {
      setCliente(emptyCliente);
    }
  }, [current, open]);

  useEffect(() => {
    if (!saving && !error) {
      clearAll();
      handleClose();
    }
  }, [saving]);

  let gMap;

  function initMap(placeId) {
    return new Promise(function (resolve, reject) {
      gMap = new google.maps.Map(map.current, {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });
      var request = {
        placeId: placeId,
      };

      let service = new google.maps.places.PlacesService(gMap);
      service.getDetails(request, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(
            place.address_components.filter(
              (a) =>
                a.types.indexOf("sublocality") !== -1 ||
                a.types.indexOf("neighborhood") !== -1 ||
                a.types.indexOf("locality") !== -1
            )[0]?.long_name
          );
        } else {
          reject("");
        }
      });
    });
  }

  const createOptionVendedor = (label) => ({
    nombre: label,
    id: -Math.floor(Math.random() * 100000),
  });

  const handleCreateVendedor = async (inputValue) => {
    const newOption = createOptionVendedor(inputValue);

    addVendedor({
      nombre: inputValue,
      id: -Math.floor(Math.random() * 100000),
    }).then((t) => {
      /*vendedores.push({
        nombre: inputValue,
        id: ultimo_vendedor
      });*/
    });
  };

  useEffect(() => {
    setCliente({ ...cliente, vendedor_id: ultimo_vendedor });
  }, [ultimo_vendedor]);

  const onChange = (e, special_field = null) => {
    if (special_field === "grupo_id") {
      let grupo_tmp = grupos.find((p) => p.id === e.value);
      if (grupo_tmp) {
        let extraFields = {};
        if (grupo_tmp.id === 28) {
          extraFields = {
            zona_id: 8,
            canal_id: 1,
            categoria_impositiva_id: 3,
            tipo_comprobante_id: 3,
            clasificacion: 3,
            direccion_entrega: "Nutree Amigos",
            direccion_facturacion: "Nutree Amigos",
            formas_pago: [{ id: 1 }],
            tipo_cobranza_id: 5,
            telefonos: [
              {
                nombre: "Nutree",
                email: "nutree@nutree.com.ar",
                numero: "1111111111",
                tipos_contacto: [
                  { id: 1, nombre: "Comprador", pos: 10 },
                  { id: 2, nombre: "Pagos", pos: 20 },
                ],
              },
            ],
          };
        }
        setCliente({
          ...cliente,
          cuit: cuit || grupo_tmp.cuit,
          razon_social: razon_social || grupo_tmp.razon_social,
          formas_pago: formas_pago.length ? formas_pago : grupo_tmp.formas_pago,
          plazo_pago:
            plazo_pago || plazo_pago === 0 ? plazo_pago : grupo_tmp.plazo_pago,
          plazo_pago_x:
            plazo_pago_x || plazo_pago_x === 0
              ? plazo_pago_x
              : grupo_tmp.plazo_pago,
          lista_precios_id: lista_precios_id || grupo_tmp.lista_precios_id,
          grupo_id: e.value,
          ...extraFields,
        });
      } else
        setCliente({
          ...cliente,
          grupo_id: e.value,
        });
    } else if (special_field === "zona_id") {
      setCliente({ ...cliente, zona_id: e.value });
    } else if (special_field === "transportista_id") {
      setCliente({
        ...cliente,
        transportista_id: e.value,
        dias_entrega: [],
        transportista_destino_id: "",
      });
    } else if (
      special_field === "horaAtencion1Ini" ||
      special_field === "horaAtencion1Fin" ||
      special_field === "horaAtencion2Ini" ||
      special_field === "horaAtencion2Fin"
    ) {
      setCliente({
        ...cliente,
        [special_field]: e.getHours() + ":" + e.getMinutes(),
      });
    } else if (special_field === "transportista_destino_id") {
      const transportistaDTmp = transportistas
        .find((p) => p.id === cliente.transportista_id)
        .destinos.find((d) => d.id === e.target.value);
      const convertToHour = (str) => {
        let strTmp = str.split(":");
        let ret = "";
        if (strTmp[0].length === 1) ret = "0" + str.split(":")[0];
        else ret = str.split(":")[0];
        ret += ":";
        if (strTmp[1].length === 1) ret += str.split(":")[1] + "0";
        else ret += str.split(":")[1];
        return ret;
      };
      setCliente({
        ...cliente,
        transportista_destino_id: e.target.value,
        ...(transportistaDTmp && {
          horaAtencion1Ini: convertToHour(transportistaDTmp.hora_ini),
          horaAtencion1Fin: convertToHour(transportistaDTmp.hora_fin),
          horaAtencion2Ini: "",
          horaAtencion2Fin: "",
        }),
      });
    } else if (special_field === "vendedor_id") {
      const vendedorTmp = vendedores.find((p) => p.id === e.value);
      setCliente({
        ...cliente,
        vendedor_id: e.value,
      });
    } else if (e.target.name === "direccion_detalles") {
      setCliente({
        ...cliente,
        detalles_facturacion: e.target.value,
      });
    } else if (e.target.name === "direccion_localidad") {
      setCliente({
        ...cliente,
        localidad_facturacion: e.target.value,
      });
    } else if (e.target.name === "direccion_entrega_detalles") {
      setCliente({
        ...cliente,
        detalles_entrega: e.target.value,
      });
    } else if (e.target.name === "direccion_entrega_localidad") {
      setCliente({
        ...cliente,
        localidad_entrega: e.target.value,
      });
    } else if (e.target.name === "formas_pago") {
      setCliente({
        ...cliente,
        formas_pago: e.target.value.map((fp) => ({ id: fp })),
      });
    } else if (e.target.name === "categoria_impositiva_id") {
      let tmpComprobante;
      if (e.target.value === 1) {
        tmpComprobante = 1;
      } else {
        tmpComprobante = 1;
      }
      setCliente({
        ...cliente,
        categoria_impositiva_id: e.target.value,
        tipo_comprobante_id: tmpComprobante,
      });
    } else if (e.target.name === "tipo_comprobante_id") {
      let tmpClasificacion;
      if (e.target.value === 3) {
        tmpClasificacion = 3;
      } else if (clasificacion === 3) {
        tmpClasificacion = "";
      } else {
        tmpClasificacion = clasificacion;
      }
      setCliente({
        ...cliente,
        tipo_comprobante_id: e.target.value,
        clasificacion: tmpClasificacion,
      });
    } else if (e.target.name === "plazo_pago") {
      setCliente({
        ...cliente,
        plazo_pago: e.target.value,
        plazo_pago_x: cliente.plazo_pago_x || e.target.value,
      });
    } else setCliente({ ...cliente, [e.target.name]: e.target.value });
  };

  const onValueChange = (values, field_name) => {
    setCliente({
      ...cliente,
      [field_name]: values.floatValue,
      ...(field_name === "cuit" && { cambioCuit: true }),
    });
  };

  const onChangeCheck = (name) => (event) => {
    setCliente({ ...cliente, [name]: event.target.checked ? 1 : 0 });
  };
  const onChangeCheckRemito = (name) => (event) => {
    setCliente({
      ...cliente,
      mostrar_saldo_remito: event.target.checked ? 1 : 0,
    });
  };

  const onChangeTel = (e, index, campo) => {
    let val = e.target.value;

    if (campo === "tipos_contacto") {
      val = val.map((tc) => ({ id: tc }));
    }

    setCliente({
      ...cliente,
      telefonos: [
        ...telefonos.slice(0, index),
        { ...telefonos[index], [campo]: val },
        ...telefonos.slice(index + 1),
      ],
    });
  };

  const onDeleteTelefono = (e, index) => {
    setCliente({
      ...cliente,
      telefonos: [...telefonos.slice(0, index), ...telefonos.slice(index + 1)],
    });
  };

  const agregarTelefono = (index) => {
    setCliente({
      ...cliente,
      telefonos: [
        ...telefonos,
        {
          nombre: "",
          apellido: "",
          email: "",
          numero: "",
          descripcion: "",
          tipo: "",
        },
      ],
    });
  };

  const onChangeDir = (e, fromSelect = false, object = {}) => {
    let direccion, placeId;

    //Typed by the user!
    direccion = e;
    placeId = null;
    setCliente({
      ...cliente,
      direccion_facturacion: direccion,
      place_id_facturacion: placeId,
    });
  };

  const onChangeDirEntrega = (e, fromSelect = false, object = {}) => {
    let direccion, placeId;

    //Typed by the user!
    direccion = e;
    placeId = null;

    setCliente({
      ...cliente,
      direccion_entrega: direccion,
      place_id_entrega: placeId,
    });
  };

  const onSelectAddress = async (direccion, placeId, test, other) => {
    let tmpMap = await initMap(placeId);
    setCliente({
      ...cliente,
      direccion_facturacion: direccion,
      place_id_facturacion: placeId,
      localidad_facturacion: tmpMap,
    });
  };

  const onSelectAddressEntrega = async (direccion, placeId) => {
    let tmpMap = await initMap(placeId);
    setCliente({
      ...cliente,
      direccion_entrega: direccion,
      place_id_entrega: placeId,
      localidad_entrega: tmpMap,
    });
  };

  const [errors, setErrors] = useState([]);

  const mandatoryFields = [
    { field: "nombre", msg: "Nombre" },
    { field: "cuit", msg: "CUIT" },
    { field: "zona_id", msg: "Grupo de Contacto" },
    { field: "razon_social", msg: "Razón Social" },
    { field: "canal_id", msg: "Canal" },
    { field: "categoria_impositiva_id", msg: "Categoría impositiva" },
    { field: "clasificacion", msg: "Clasificación" },
    { field: "direccion_facturacion", msg: "Direccion de Facturación" },
    { field: "direccion_entrega", msg: "Dirección de Entrega" },
    { field: "tipo_cobranza_id", msg: "Cobranza" },
    { field: "lista_precios_id", msg: "Lista de Precios" },
    { field: "formas_pago", msg: "Forma de Pago" },
    { field: "tipo_comprobante_id", msg: "Tipo de Comprobante" },
    { field: "localidad_entrega", msg: "Localidad de Entrega" },
    { field: "provincia_entrega_id", msg: "Provincia de Entrega" },

    {
      field: "plazo_pago",
      msg: "Plazo de Pago",
      dynamic: (cliente) => {
        return !plazo_pago && plazo_pago !== 0;
      },
    },
    {
      field: "plazo_pago_x",
      msg: "Plazo de Pago X",
      dynamic: (cliente) => {
        return !plazo_pago_x && plazo_pago_x !== 0;
      },
    },

    {
      field: "telefonos",
      msg: "Contacto comprador y pagos",
      dynamic: (cliente) => {
        if (!cliente.telefonos || !cliente.telefonos.length) return true;
        let found_comprador = false;
        let found_pagos = false;
        cliente.telefonos.forEach((c) => {
          if (c.tipos_contacto && c.tipos_contacto.length) {
            c.tipos_contacto.forEach((tc) => {
              if (tc.id === 1) found_pagos = true;
              if (tc.id === 2) found_comprador = true;
            });
          }
        });
        return !found_pagos || !found_comprador;
      },
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    let errorsTmp = [];

    for (let campo of mandatoryFields) {
      if (campo.dynamic) {
        if (campo.dynamic(cliente)) errorsTmp.push(campo);
        error = error || campo.dynamic(cliente);
      } else if (campo.field.indexOf(".") === -1) {
        if (!cliente[campo.field]) {
          errorsTmp.push(campo);
          error = error || true;
        }
      } else {
        let tmpCampo = campo.field.split(".");
        if (!cliente[tmpCampo[0]] || !cliente[tmpCampo[0]][tmpCampo[1]]) {
          errorsTmp.push(campo);
          error = error || true;
        }
      }
    }
    setErrors(errorsTmp);

    if (error) {
      alertContext.setAlert(
        "Completar campos obligatorios: " +
          errorsTmp.map((e) => e.msg).join(", "),
        "danger"
      );
    } else {
      try {
        if (!current || !current.id) {
          await addCliente(cliente);
        } else {
          await updateCliente(cliente);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const clearAll = () => {
    setCliente(emptyCliente);
    clearCurrent();
  };

  const classes = useStyles();

  function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
      val = "0" + val;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = "01";

        //this can happen when user paste number
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  }

  function timeFormat(val) {
    let hour = limit(val.substring(0, 2), "24");
    let minute = val.substring(2, 4);

    return hour + (minute.length ? ":" + minute : ":00");
  }

  async function handleClose() {
    setOpen(false);
    clearCurrent();
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      //fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
      fullScreen={true}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" className={classes.formHeader}>
        {!current || !id
          ? "Nuevo cliente"
          : "Editar cliente: (" + id + ") - " + nombre}
      </DialogTitle>

      <DialogContent dividers={true} style={{ padding: "0px" }}>
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <div ref={map} />
        <form onSubmit={onSubmit}>
          <fieldset disabled={false}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.summary}
              >
                <Typography className={classes.heading}>Principal</Typography>
                <Typography className={classes.secondaryHeading}>
                  Grupo, <strong>Nombre de Fantasía</strong>,{" "}
                  <strong>Grupo de Contacto</strong>, <strong>Canal</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  {!loading_grupos && (
                    <Grid item xs={12} md={3}>
                      <FormControl
                        variant="outlined"
                        style={{
                          height: "40px",
                          width: "100%",
                          paddingTop: "8px",
                        }}
                      >
                        <ReactSelectNonCreate
                          id="select-cliente"
                          placeholder="Grupo..."
                          value={
                            grupo_id && grupos.find((p) => p.id === grupo_id)
                              ? {
                                  label: grupos.find((p) => p.id === grupo_id)
                                    .nombre,
                                  value: grupo_id,
                                }
                              : ""
                          }
                          fullWidth
                          name="grupo_id"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: "-8px",
                            }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={(e) => onChange(e, "grupo_id")}
                          options={[{ value: "-1", label: "-" }].concat(
                            grupos.map((grupo) => ({
                              value: grupo.id,
                              label: grupo.nombre,
                            }))
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3}>
                    <TextField
                      margin="dense"
                      name="nombre"
                      label="Nombre de Fantasía *"
                      value={nombre}
                      onChange={onChange}
                      className={
                        errors.nombre
                          ? classes.textFieldError
                          : classes.textField
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  {!loading_zonas && (
                    <Grid item xs={12} md={2}>
                      <FormControl
                        variant="outlined"
                        style={{
                          height: "40px",
                          width: "100%",
                          paddingTop: "8px",
                        }}
                      >
                        <ReactSelectNonCreate
                          id="select-zona"
                          placeholder="Grupo de Contacto *"
                          value={
                            zona_id && zonas.find((p) => p.id === zona_id)
                              ? {
                                  label: zonas.find((p) => p.id === zona_id)
                                    .nombre,
                                  value: zona_id,
                                }
                              : ""
                          }
                          fullWidth
                          name="zona_id"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: "-8px",
                            }),
                          }}
                          menuPortalTarget={document.body}
                          onChange={(e) => onChange(e, "zona_id")}
                          options={[{ value: "-1", label: "-" }].concat(
                            zonas.map((zona) => ({
                              value: zona.id,
                              label: zona.nombre,
                            }))
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={11} md={1}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-canal">Canal *</InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-canal"
                          />
                        }
                        fullWidth
                        value={canal_id ? canal_id : ""}
                        name="canal_id"
                        onChange={(e) => onChange(e)}
                      >
                        {canales.map((c) => (
                          <MenuItem value={c.id} key={c.id}>
                            {c.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <FormControlLabel
                      value="fijar"
                      control={
                        <Checkbox
                          margin="dense"
                          name="fijar"
                          label="Fijado"
                          value="1"
                          checked={fijar === 1}
                          onChange={onChangeCheck("fijar")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Fijar Cliente"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <FormControlLabel
                      value="estado"
                      control={
                        <Checkbox
                          margin="dense"
                          name="desactivado"
                          label="Desactivado"
                          value="1"
                          checked={desactivado === 1}
                          onChange={onChangeCheck("desactivado")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Desactivar Cliente"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <FormControlLabel
                      value="estado"
                      control={
                        <Checkbox
                          margin="dense"
                          name="mi_pyme"
                          label="MiPyme"
                          value="1"
                          checked={mi_pyme === 1}
                          onChange={onChangeCheck("mi_pyme")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Mi Pyme"
                      labelPlacement="end"
                    />
                  </Grid>

                  {desactivado === 1 && (
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        name="motivo_desactivado"
                        label="Motivo desactivacion"
                        variant="outlined"
                        multiline
                        id="motivo_desactivado"
                        value={motivo_desactivado || ""}
                        onChange={onChange}
                        fullWidth
                        rowsMax="10"
                      />
                    </Grid>
                  )}
                  {canal_id === 2 && (
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        name="zonas_distribucion"
                        label="Zonas de Distribución"
                        variant="outlined"
                        multiline
                        id="zonas_distribucion"
                        value={zonas_distribucion || ""}
                        onChange={onChange}
                        fullWidth
                        rowsMax="10"
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.summary}
              >
                <Typography className={classes.heading}>
                  Datos Facturación
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  <strong>CUIT</strong>, <strong>Razón Social</strong>,
                  Personería, <strong>Categoría Impositiva</strong>, E-mail
                  facturación, <strong>Dirección Fiscal</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12} md={3}>
                    <NumberFormat
                      customInput={TextField}
                      format="##-########-#"
                      mask="_"
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="cuit"
                      label="CUIT *"
                      value={cuit || ""}
                      onValueChange={(e) => onValueChange(e, "cuit")}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() =>
                        obtenerClienteAfip(cuit, grupo_id, cliente)
                      }
                      className={classes.btnAfip}
                      disabled={
                        cuit !== undefined &&
                        (cuit.toString().length === 11 ? false : true)
                      }
                    >
                      {loading_afip ? "Cargando..." : "Obtener datos de AFIP"}
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="dense"
                      name="razon_social"
                      label="Razón Social *"
                      value={razon_social}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <NumberFormat
                      customInput={TextField}
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="limite_descubierto"
                      label="Limite descubierto (x packs)"
                      value={limite_descubierto || ""}
                      onValueChange={(e) =>
                        onValueChange(e, "limite_descubierto")
                      }
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-categoria-impositiva">
                        Personeria
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-personeria"
                          />
                        }
                        fullWidth
                        value={personeria_id ? personeria_id : ""}
                        name="personeria_id"
                        onChange={(e) => onChange(e)}
                      >
                        {personerias.map((p) => (
                          <MenuItem value={p.id} key={p.id}>
                            {p.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-categoria-impositiva">
                        Categoria Impositiva *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-categoria-impositiva"
                          />
                        }
                        fullWidth
                        value={
                          categoria_impositiva_id ? categoria_impositiva_id : ""
                        }
                        name="categoria_impositiva_id"
                        onChange={(e) => onChange(e)}
                      >
                        {condicionIva.map((ci) => (
                          <MenuItem value={ci.id} key={ci.id}>
                            {ci.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-tipo_comprobante">
                        Tipo de Comprobante *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-tipo_comprobante"
                          />
                        }
                        fullWidth
                        value={tipo_comprobante_id ? tipo_comprobante_id : ""}
                        name="tipo_comprobante_id"
                        onChange={(e) => onChange(e)}
                      >
                        {tiposComprobanteCliente.map((tipo, index) => (
                          <MenuItem value={tipo.id} key={index}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      margin="dense"
                      type="email"
                      name="email_facturacion"
                      label="E-mail Facturacion"
                      variant="outlined"
                      id="email_facturacion"
                      value={(email_facturacion && email_facturacion) || ""}
                      onChange={onChange}
                      className={classes.textField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-clasificacion">
                        Clasificación *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-clasificacion"
                          />
                        }
                        fullWidth
                        value={clasificacion ? clasificacion : ""}
                        name="clasificacion"
                        onChange={(e) => onChange(e)}
                      >
                        {(tipo_comprobante_id === 3 ? [3] : [1, 2]).map(
                          (tipo, index) => (
                            <MenuItem value={tipo} key={index}>
                              {tipo}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PlacesAutocomplete
                      value={direccion_facturacion || ""}
                      onChange={onChangeDir}
                      onSelect={onSelectAddress}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Direccion *"
                            autoComplete="nope"
                            {...getInputProps({
                              placeholder: "Buscar direccion ...",
                            })}
                            InputProps={{
                              className: classes.locationInput,
                            }}
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Cargando...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      margin="dense"
                      name="localidad_facturacion"
                      label="Localidad/Barrio"
                      value={localidad_facturacion || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-tipo_comprobante">
                        Provincia
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-tipo_comprobante"
                          />
                        }
                        fullWidth
                        value={
                          provincia_facturacion_id
                            ? provincia_facturacion_id
                            : ""
                        }
                        name="provincia_facturacion_id"
                        onChange={(e) => onChange(e)}
                      >
                        {provincias.map((prov, index) => (
                          <MenuItem value={prov.id} key={index}>
                            {prov.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      margin="dense"
                      name="detalles_facturacion"
                      label="Detalles (depto/referencia/etc)"
                      value={detalles_facturacion || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.summary}
              >
                <Typography className={classes.heading}>
                  Datos Logísticos
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  <strong>Dirección Comercial</strong>,{" "}
                  <strong>Horarios de Entrega</strong>, Transportista,{" "}
                  <strong>Cobranza</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12} md={5}>
                    <PlacesAutocomplete
                      value={direccion_entrega || ""}
                      onChange={onChangeDirEntrega}
                      onSelect={onSelectAddressEntrega}
                      className={classes.locationInput}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Direccion Comercial *"
                            autoComplete="nope"
                            {...getInputProps({
                              placeholder: "Buscar direccion ...",
                            })}
                            InputProps={{
                              className: classes.locationInput,
                            }}
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Cargando...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      margin="dense"
                      name="localidad_entrega"
                      label="Localidad/Barrio *"
                      value={localidad_entrega || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-tipo_comprobante">
                        Provincia *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-tipo_comprobante"
                          />
                        }
                        fullWidth
                        value={provincia_entrega_id ? provincia_entrega_id : ""}
                        name="provincia_entrega_id"
                        onChange={(e) => onChange(e)}
                      >
                        {provincias.map((prov, index) => (
                          <MenuItem value={prov.id} key={index}>
                            {prov.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      margin="dense"
                      name="detalles_entrega"
                      label="Detalles (depto/referencia/etc)"
                      value={detalles_entrega || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="tiempo_descarga"
                      label="Minutos Descarga"
                      value={tiempo_descarga || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      format={timeFormat}
                      mask="_"
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="horaAtencion1Ini"
                      label="Ini Mañana"
                      value={horaAtencion1Ini || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      format={timeFormat}
                      mask="_"
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="horaAtencion1Fin"
                      label="Fin Mañana"
                      value={horaAtencion1Fin || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      format={timeFormat}
                      mask="_"
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="horaAtencion2Ini"
                      label="Ini Tarde"
                      value={horaAtencion2Ini || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      format={timeFormat}
                      mask="_"
                      decimalScale={0}
                      allowNegative={false}
                      margin="dense"
                      name="horaAtencion2Fin"
                      label="Fin Tarde"
                      value={horaAtencion2Fin || ""}
                      onChange={onChange}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-cobranza">
                        Cobranza *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-cobranza"
                          />
                        }
                        fullWidth
                        value={tipo_cobranza_id ? tipo_cobranza_id : ""}
                        name="tipo_cobranza_id"
                        onChange={(e) => onChange(e)}
                      >
                        {tiposCobranzas.map((tipo, index) => (
                          <MenuItem value={tipo.id} key={index}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {!loading_transportistas &&
                    transportistas &&
                    transportistas.length && (
                      <Fragment>
                        <Grid item xs={12} md={2}>
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelectNonCreate
                              id="select-transportista"
                              placeholder="Transportista..."
                              value={
                                transportista_id &&
                                transportistas.find(
                                  (p) => p.id === transportista_id
                                )
                                  ? {
                                      label: transportistas.find(
                                        (p) => p.id === transportista_id
                                      ).nombre,
                                      value: transportista_id,
                                    }
                                  : ""
                              }
                              fullWidth
                              name="transportista_id"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) => onChange(e, "transportista_id")}
                              options={transportistas.map((tra) => ({
                                value: tra.id,
                                label: tra.nombre,
                              }))}
                            />
                          </FormControl>
                        </Grid>
                        {transportista_id && (
                          <Grid item md="2">
                            <FormControl
                              variant="outlined"
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "8px",
                              }}
                            >
                              <InputLabel htmlFor="select-tipo-comprobante">
                                Destino
                              </InputLabel>
                              <Select
                                input={
                                  <OutlinedInput
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    id="select-tipo-comprobante"
                                  />
                                }
                                value={transportista_destino_id}
                                name="transportista_destino_id"
                                fullWidth
                                onChange={(e) =>
                                  onChange(e, "transportista_destino_id")
                                }
                              >
                                {transportistas
                                  .find((tra) => tra.id === transportista_id)
                                  .destinos.map((d) => ({
                                    id: d.id,
                                    direccion: d.direccion,
                                  }))
                                  .map((d) => (
                                    <MenuItem value={d.id} key={d.id}>
                                      {d.direccion}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        {transportista_id && transportista_destino_id && (
                          <Grid item md="1">
                            <FormControl
                              variant="outlined"
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "8px",
                              }}
                            >
                              <InputLabel htmlFor="select-tipo-comprobante">
                                Dias de entrega
                              </InputLabel>
                              <Select
                                input={
                                  <OutlinedInput
                                    style={{
                                      height: "40px",
                                      width: "100%",
                                    }}
                                    id="select-tipo-comprobante"
                                  />
                                }
                                value={
                                  dias_entrega.map((d) =>
                                    d.dia ? d.dia : d
                                  ) || []
                                }
                                name="dias_entrega"
                                fullWidth
                                multiple={true}
                                onChange={(e) => onChange(e, "dias_entrega")}
                              >
                                {diasSemanaNum
                                  .filter(
                                    (dia) =>
                                      transportistas
                                        .find(
                                          (tra) => tra.id === transportista_id
                                        )
                                        .destinos.find(
                                          (d) =>
                                            d.id === transportista_destino_id
                                        )
                                        .dias.indexOf(dia) !== -1
                                  )
                                  .map((dia, index) => (
                                    <MenuItem value={dia} key={dia}>
                                      {diasSemana[dia]}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  <Grid item xs={7}>
                    <TextField
                      margin="dense"
                      name="notas_logisticas"
                      label="Notas Logísticas"
                      variant="outlined"
                      multiline
                      id="notas_logisticas"
                      value={notas_logisticas || ""}
                      onChange={onChange}
                      fullWidth
                      rowsMax="10"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      value="enviar_notificacion"
                      control={
                        <Checkbox
                          margin="dense"
                          name="enviar_notificacion"
                          label="Enviar Notificación"
                          value="1"
                          checked={enviar_notificacion === 1}
                          onChange={onChangeCheck("enviar_notificacion")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Enviar Notificación"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <FormControlLabel
                      value="generar_remito"
                      control={
                        <Checkbox
                          margin="dense"
                          name="generar_remito"
                          label="Generar Remito"
                          value="1"
                          checked={generar_remito === 1}
                          onChange={onChangeCheck("generar_remito")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Remito?"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-canal">
                        Habilitacion
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-canal"
                          />
                        }
                        fullWidth
                        value={habilitacion ? habilitacion : ""}
                        name="habilitacion"
                        onChange={(e) => onChange(e)}
                      >
                        <MenuItem value={0} key={0}>
                          -
                        </MenuItem>
                        {cHabilitaciones.map((c) => (
                          <MenuItem value={c.id} key={c.id}>
                            {c.nombre.replace("HABILITACION ", "")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.summary}
              >
                <Typography className={classes.heading}>Precios</Typography>
                <Typography className={classes.secondaryHeading}>
                  <strong>Lista de Precios</strong>,{" "}
                  <strong>Forma de Pago</strong>, <strong>Plazo de Pago</strong>
                  , Vendedor, Comisión Vendedor, Descuentos/Recargos
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  {!loading_listas_precios &&
                    listas_precios &&
                    listas_precios.length && (
                      <Grid item xs={12} md={3}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "0px",
                          }}
                        >
                          <InputLabel htmlFor="select-lista-precios">
                            Lista de Precios *
                          </InputLabel>
                          <Select
                            input={
                              <OutlinedInput
                                style={{
                                  height: "40px",
                                  marginTop: "8px",
                                  width: "100%",
                                }}
                                id="select-lista-precios"
                              />
                            }
                            fullWidth
                            value={lista_precios_id ? lista_precios_id : ""}
                            name="lista_precios_id"
                            onChange={(e) => onChange(e)}
                          >
                            {listas_precios.map((lista_precios) => (
                              <MenuItem
                                value={lista_precios.id}
                                key={lista_precios.id}
                              >
                                {lista_precios.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  <Grid item xs={12} md={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-forma-de-pago">
                        Forma de pago *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-forma-de-pago"
                          />
                        }
                        fullWidth
                        value={
                          formas_pago ? formas_pago.map((fp) => fp.id) : []
                        }
                        name="formas_pago"
                        multiple
                        onChange={(e) => onChange(e, "formas_pago")}
                      >
                        {tiposFormasDePago.map((tipo, index) => (
                          <MenuItem value={tipo.id} key={tipo.id}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-plazo-pago">
                        Plazo de Pago *
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-plazo-pago"
                          />
                        }
                        fullWidth
                        value={plazo_pago || plazo_pago === 0 ? plazo_pago : ""}
                        name="plazo_pago"
                        onChange={(e) => onChange(e)}
                      >
                        <MenuItem value={0} key={0}>
                          Pronto Pago
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          1 día
                        </MenuItem>
                        {[7, 10, 14, 15, 25, 30, 45, 52, 60].map((d) => (
                          <MenuItem value={d} key={d}>
                            {d} días
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>{" "}
                  <Grid item xs={12} md={2}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "0px",
                      }}
                    >
                      <InputLabel htmlFor="select-plazo-pago">
                        Plazo de Pago X*
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%",
                            }}
                            id="select-plazo-pago"
                          />
                        }
                        fullWidth
                        value={
                          plazo_pago_x || plazo_pago_x === 0 ? plazo_pago_x : ""
                        }
                        name="plazo_pago_x"
                        onChange={(e) => onChange(e)}
                      >
                        <MenuItem value={0} key={0}>
                          Pronto Pago
                        </MenuItem>
                        <MenuItem value={1} key={1}>
                          1 día
                        </MenuItem>
                        {[7, 10, 14, 15, 25, 30, 45, 52, 60].map((d) => (
                          <MenuItem value={d} key={d}>
                            {d} días
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControlLabel
                      value="mostrar_saldo_remito"
                      control={
                        <Checkbox
                          margin="dense"
                          name="mostrar_saldo_remito"
                          label="Mostrar saldo en Remito"
                          value="1"
                          checked={mostrar_saldo_remito === 1}
                          onChange={onChangeCheckRemito("1")}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label="Mostrar saldo en Remito"
                      labelPlacement="end"
                    />
                  </Grid>
                  {!loading_vendedores && (
                    <Grid item xs={12} md={4}>
                      <FormControl
                        variant="outlined"
                        style={{
                          height: "40px",
                          width: "100%",
                          paddingTop: "8px",
                        }}
                      >
                        <ReactSelect
                          fullWidth
                          value={
                            vendedor_id &&
                            vendedores.find((p) => p.id === vendedor_id)
                              ? {
                                  label: vendedores.find(
                                    (p) => p.id === vendedor_id
                                  ).nombre,
                                  value: vendedor_id,
                                }
                              : ""
                          }
                          name="vendedor_id"
                          placeholder="Vendedor: Seleccionar o nuevo..."
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: "-8px",
                            }),
                          }}
                          menuPortalTarget={document.body}
                          formatCreateLabel={(value) =>
                            "Nuevo provedor: " + value
                          }
                          onChange={(e) => onChange(e, "vendedor_id")}
                          onCreateOption={handleCreateVendedor}
                          options={vendedores.map((vendedor) => ({
                            value: vendedor.id,
                            label: vendedor.nombre,
                          }))}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={2}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      mask=""
                      suffix="%"
                      decimalScale={2}
                      allowNegative={false}
                      margin="dense"
                      name="comision_vendedor"
                      label="Comisión vendedor %"
                      value={comision_vendedor || ""}
                      onValueChange={(e) =>
                        onValueChange(e, "comision_vendedor")
                      }
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      mask=""
                      suffix="%"
                      decimalScale={2}
                      allowNegative={true}
                      margin="dense"
                      name="descuento"
                      label="Descuento/Recargo %"
                      value={descuento || ""}
                      onValueChange={(e) => onValueChange(e, "descuento")}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      margin="dense"
                      name="motivo_descuento"
                      label="Motivo del descuento/recargo"
                      variant="outlined"
                      multiline
                      id="motivo_descuento"
                      value={motivo_descuento || ""}
                      onChange={onChange}
                      fullWidth
                      rowsMax="3"
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      mask=""
                      suffix="%"
                      decimalScale={2}
                      allowNegative={true}
                      margin="dense"
                      name="bonificacion"
                      label="Bonificacion %"
                      value={bonificacion || ""}
                      onValueChange={(e) => onValueChange(e, "bonificacion")}
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      margin="dense"
                      name="obs_precios"
                      label="Observaciones"
                      variant="outlined"
                      multiline
                      id="obs_precios"
                      value={obs_precios || ""}
                      onChange={onChange}
                      fullWidth
                      rowsMax="3"
                    />
                  </Grid>
                </Grid>

              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.summary}
              >
                <Typography className={classes.heading}>Contactos</Typography>

                <Typography className={classes.secondaryHeading}>
                  Telefonos/E-mails
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  {telefonos &&
                    telefonos.map((telefono, index) => {
                      return (
                        <Fragment key={`telefonos[${index}]`}>
                          <TelefonosInput
                            index={index}
                            classes={classes}
                            telefono={telefono}
                            onChange={onChangeTel}
                            onDeleteTelefono={onDeleteTelefono}
                          />
                        </Fragment>
                      );
                    })}
                  <h4>
                    <Button color="primary" href="#!" onClick={agregarTelefono}>
                      Agregar Contacto
                    </Button>
                  </h4>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {id && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Contabilium
                  </Typography>

                  <Typography className={classes.secondaryHeading}>
                    ID Contabilium/Link
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        margin="dense"
                        name="contabilium_id"
                        label="ID Contabilium"
                        variant="outlined"
                        multiline
                        id="contabilium_id"
                        value={contabilium_id || ""}
                        onChange={onChange}
                        rowsMax="3"
                      />{" "}
                      <div>
                        {contabilium_id ? (
                          <a
                            href={
                              "https://app.contabilium.com/personase.aspx?ID=" +
                              contabilium_id +
                              "&tipo=c"
                            }
                            style={{ marginTop: "10px" }}
                            target="_blank"
                          >
                            Ver en Contabilium
                          </a>
                        ) : (
                          <a
                            href={
                              "https://app.contabilium.com/personas.aspx?tipo=c"
                            }
                            style={{ marginTop: "10px" }}
                            target="_blank"
                          >
                            Buscar en Contabilium
                          </a>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </fieldset>
        </form>
      </DialogContent>

      {!saving ? (
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Guardar
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      ) : (
        <DialogActions className={classes.formFooter}>
          <CircularProgress color="secondary" />
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.clientes.current,
    clientes: state.clientes.clientes,
    loading: state.clientes.loading,
    saving: state.clientes.saving,
    error: state.clientes.error,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    transportistas: state.transportistas.transportistas,
    loading_transportistas: state.transportistas.loading,
    open: state.clientes.open,
    loading_afip: state.clientes.loading_afip,
    grupos: state.grupos.grupos,
    loading_grupos: state.grupos.loading,
    zonas: state.zonas.zonas,
    loading_zonas: state.zonas.loading,
    vendedores: state.vendedores.vendedores,
    ultimo_vendedor: state.vendedores.ultimo,
    loading_vendedores: state.vendedores.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addVendedor: (v) => dispatch(addVendedor(v)),
  getListasPrecios: () => dispatch(getListasPrecios()),
  getTransportistas: () => dispatch(getTransportistas()),
  getGrupos: () => dispatch(getGrupos()),
  getZonas: () => dispatch(getZonas()),
  getVendedores: () => dispatch(getVendedores()),
  addCliente: (cliente) => dispatch(addCliente(cliente)),
  updateCliente: (cliente) => dispatch(updateCliente(cliente)),
  clearCurrent: () => dispatch(clearCurrent()),
  setOpen: () => dispatch(setOpen()),
  obtenerClienteAfip: (cuit, grupo, cliente) =>
    dispatch(obtenerClienteAfip(cuit, grupo, cliente)),
});

export { emptyCliente };
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
