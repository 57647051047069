import { Button, CardActions, Grid, Typography } from "@material-ui/core/";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useConfirm } from "material-ui-confirm";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  clearCurrent,
  deleteListaPrecios,
  setCurrent,
} from "../../actions/listas_precios";

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: "#f7f7f7",
  },
  addressLink: {
    color: "black",
    textDecorationLine: "none",

    overflow: "hidden",

    "&:hover": {
      textDecorationLine: "underline",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    height: "80px",
    overflowY: "auto",
    fontSize: "0.9rem",
  },
  cardTitle: {
    fontSize: "1.15rem",
  },
});

const ListaPreciosItem = ({
  lista_precios,
  listas_precios,
  current,
  dispatch,
}) => {
  const { id, nombre, tipo, balance } = lista_precios;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteListaPrecios(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, lista_precios) => {
    confirm({
      description: `Desea borrar la lista de precios "${lista_precios.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => onDelete());
  };

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        {lista_precios.items[0] && (
          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              className={classes.cardTitle}
            >
              {nombre} - ${lista_precios.items[0].precio}
            </Typography>
          </CardContent>
        )}

        <CardActions className={classes.cardActions}>
          <Button
            onClick={(e) => onClickBorrar(confirm, dispatch, lista_precios)}
            size="small"
            color="secondary"
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => dispatch(setCurrent(lista_precios))}
            size="small"
            color="primary"
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

ListaPreciosItem.propTypes = {
  lista_precios: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    listas_precios: state.listasPrecios.listas_precios,
    current: state.listasPrecios.current,
  };
};

export default connect(mapStateToProps)(ListaPreciosItem);
