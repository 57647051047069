import React, { useState, useEffect, Fragment, useRef, useContext } from "react";
import { connect } from "react-redux";
import { addCheque, updateCheque, clearCurrent, setOpen, setCurrent, getCheques } from "../../actions/cheques";
import { addBanco, getBancos } from "../../actions/bancos";
import { getClientes } from "../../actions/clientes";
import { getGrupos } from "../../actions/grupos";
import { getCajas } from "../../actions/cajas";
import ObjectID from "bson-objectid";
import PlacesInput from "../utils/PlacesInput";
import TelefonosInput from "../utils/TelefonosInput";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TimeInput from "material-ui-time-picker";
import moment from "moment";
import NumberFormat from "react-number-format";
import DatePicker from "react-date-picker";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { tiposCheque } from "./Cheques";
import AlertContext from "../../context/alert/alertContext";
import { cEstadosCheques } from "../../config/constantes";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  dialogMain: {
    minHeight: "50vh"
  }
}));

const emptyCheque = {
  nota: "",
  valor: "",
  fecha: new Date(),
  cliente_id: "",
  caja_id: "",
  fecha_deposito: new Date(),
  banco_id: "",
  numero: "",
  razon_social: "",
  cuit: "",
  echeck: 0,
  //AUX VARIABLE
  saving: false
};

const ChequeForm = ({
  children,
  current,
  open,
  dispatch,
  clientes,
  grupos,
  loading_clientes,
  cajas,
  loading_cajas,
  bancos,
  loading_bancos,
  last_banco_id,
  loading_grupos
}) => {
  const [cheque, setCheque] = useState(emptyCheque);
  const [dropdownClientes, setDropdownClientes] = useState([]);
  const alertContext = useContext(AlertContext);

  const {
    nota,
    fecha,
    fecha_emision,
    valor,
    cliente_id,
    caja_id,
    fecha_deposito,
    lanzamientoCajaObj,
    banco_id,
    numero,
    razon_social,
    cuit,
    echeck,
    saving
  } = cheque;

  useEffect(() => {
    dispatch(getClientes());
    dispatch(getGrupos());
    dispatch(getCajas());
    dispatch(getBancos());
    if (current !== null) {
      setCheque(current);
    } else {
      setCheque(emptyCheque);
    }
  }, [current, open]);

  const onChange = (e, tipo = null) => {
    setCheque({ ...cheque, saving: true });

    if (tipo === "fecha" || tipo === "fecha_emision" || tipo === "fecha_deposito") {
      setCheque({ ...cheque, [tipo]: e });
    } else if (tipo === "cliente_id") {
      setCheque({ ...cheque, cliente_id: e.value });
    } else if (tipo === "banco_id") {
      setCheque({ ...cheque, banco_id: e.value });
    } else setCheque({ ...cheque, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!loading_clientes && !loading_grupos) {
   
      setDropdownClientes(
        clientes
          .filter((c) => !c.grupo || c.grupo.cuenta_corriente_propia)
          //.concat(clientes.filter((c) => c.grupo && !c.grupo.cuenta_corriente_propia))
          .map((cliente) => ({
            value: cliente.id,
            label: cliente.nombre
          }))
          .concat(
            grupos
              .filter((g) => !g.cuenta_corriente_propia)
              .map((g) => ({
                value: "g" + g.id,
                label: g.nombre
              }))
          )
          .sort((a, b) => a.label > b.label)
      );
    }
  }, [loading_clientes, loading_grupos]);

  const onValueChange = (e, tipo) => {
    if (tipo === "cuit") {
      setCheque({ ...cheque, cuit: e.floatValue });
    } else if (tipo === "numero") {
      setCheque({ ...cheque, numero: e.value });
    } else setCheque({ ...cheque, valor: e.floatValue });
  };

  const onChangeCheck = (event, name) => {
    setCheque({ ...cheque, [name]: event.target.checked });
  };

  const createOptionBanco = (label) => ({
    nombre: label,
    id: new Date().getTime()
  });

  const handleCreateBanco = async (inputValue) => {
    dispatch(addBanco({ nombre: inputValue })).then(() => {
      const newOption = createOptionBanco(inputValue);
      bancos.push(newOption);
      setCheque({ ...cheque, banco_id: newOption.id });
    });
  };

  useEffect(() => {
    setCheque({ ...cheque, banco_id: last_banco_id });
  }, [last_banco_id]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!cliente_id || !numero || !fecha || !fecha_emision || !banco_id || !valor) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else {
      setCheque({ ...cheque, saving: true });

      try {
        if (!current || !current.id) {
          await dispatch(addCheque(cheque));
        } else {
          await dispatch(updateCheque(cheque));
        }
      } catch (error) {
      }

      await dispatch(getCheques(cheque));
      setCheque({ ...cheque, saving: false });
      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setCheque(emptyCheque);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      classes={{ paper: classes.dialogMain }}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current ? "Nuevo cheque" : !current.soloVista ? "Editar cheque" : "Cheque " + current.numero}
      </DialogTitle>
      {!current || !current.soloVista ? (
        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={5}>
                <NumberFormat
                  customInput={TextField}
                  decimalScale={0}
                  allowNegative={false}
                  type="text"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  style={{ paddingBottom: "10px" }}
                  value={numero || ""}
                  name="numero"
                  label="Numero *"
                  allowLeadingZeros={true}
                  onValueChange={(values) => onValueChange(values, "numero")}
                />
              </Grid>
              {!loading_bancos && (
                <Fragment>
                  <Grid item xs={12} md={5}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "8px"
                      }}
                    >
                      <ReactSelectCreatable
                        fullWidth
                        value={
                          banco_id && bancos.find((p) => p.id === banco_id)
                            ? {
                                label: bancos.find((p) => p.id === banco_id).nombre,
                                value: banco_id
                              }
                            : ""
                        }
                        name="banco_id"
                        placeholder="Banco: Seleccionar o nuevo... *"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            marginTop: "-8px"
                          })
                        }}
                        menuPortalTarget={document.body}
                        formatCreateLabel={(value) => "Nuevo banco: " + value}
                        onChange={(e) => onChange(e, "banco_id")}
                        onCreateOption={handleCreateBanco}
                        options={bancos.map((banco) => ({
                          value: banco.id,
                          label: banco.nombre
                        }))}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  value="especial"
                  control={
                    <Checkbox
                      margin="dense"
                      name="echeck"
                      label="E-check"
                      value="1"
                      checked={echeck ? true : false}
                      onChange={(e) => onChangeCheck(e, "echeck")}
                      className={classes.textField}
                      variant="outlined"
                    />
                  }
                  label="E-check"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="razon_social"
                  label="Razón Social"
                  value={razon_social || ""}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <NumberFormat
                  customInput={TextField}
                  format="##-########-#"
                  mask="_"
                  decimalScale={0}
                  allowNegative={false}
                  type="text"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  style={{ paddingBottom: "10px" }}
                  value={cuit || ""}
                  name="cuit"
                  label="CUIT"
                  onValueChange={(values) => onValueChange(values, "cuit")}
                />
              </Grid>
              {!loading_clientes && !loading_grupos && (
                <Fragment>
                  <Grid item xs={12} md={5}>
                    {dropdownClientes.length > 0 && (
                      <FormControl
                        variant="outlined"
                        style={{
                          height: "40px",
                          width: "100%",
                          paddingTop: "8px"
                        }}
                      >
                        <ReactSelect
                          fullWidth
                          value={
                            cliente_id && dropdownClientes.find((p) => p.value === cliente_id)
                              ? {
                                  label: dropdownClientes.find((p) => p.value === cliente_id).label,
                                  value: cliente_id
                                }
                              : ""
                          }
                          name="cliente_id"
                          placeholder="Seleccionar Cliente/Grupo... *"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: "-8px"
                            })
                          }}
                          menuPortalTarget={document.body}
                          onChange={(e) => onChange(e, "cliente_id")}
                          options={dropdownClientes}
                        />
                      </FormControl>
                    )}
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={12} md={2}>
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator=","
                  thousandSeparator="."
                  mask=""
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  margin="dense"
                  name="valor"
                  label="Valor $ *"
                  value={valor}
                  onValueChange={onValueChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  margin="dense"
                  name="nota"
                  label="Notas"
                  value={nota || ""}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                Fecha de Emision*:{" "}
                <DatePicker
                  onChange={(date) => onChange(date, "fecha_emision")}
                  value={fecha_emision ? moment(fecha_emision).toDate() : null}
                  format="dd/MM/y"
                />{" "}
                Fecha de Cobro*:{" "}
                <DatePicker
                  onChange={(date) => onChange(date, "fecha")}
                  value={fecha ? moment(fecha).toDate() : null}
                  format="dd/MM/y"
                />{" "}
              </Grid>
              {lanzamientoCajaObj && (
                <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                  <h3>Cheque Depositado</h3>
                </Grid>
              )}
              {!loading_cajas && !lanzamientoCajaObj && false && (
                <Fragment>
                  <Grid item xs={12}>
                    <h3 color="primary">Depositar en caja: </h3>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      variant="outlined"
                      style={{
                        height: "40px",
                        width: "100%",
                        paddingTop: "8px"
                      }}
                    >
                      <InputLabel htmlFor="select-tipo-comprobante">Caja</InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              width: "100%"
                            }}
                            id="select-tipo-comprobante"
                          />
                        }
                        fullWidth
                        value={caja_id ? caja_id : ""}
                        name="caja_id"
                        onChange={(e) => onChange(e)}
                      >
                        {cajas.map((caja) => (
                          <MenuItem value={caja.id} key={caja.id}>
                            {caja.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
                    Fecha Acreditación:{" "}
                    <DatePicker
                      onChange={(date) => onChange(date, "fecha_deposito")}
                      value={fecha_deposito ? moment(fecha_deposito).toDate() : null}
                      format="dd/MM/y"
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </form>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={12}>
              <strong>Estado:</strong> {cEstadosCheques.find((e) => e.id === current.cheque_estado_id).nombre}{" "}
              {current.cheque_estado_id !== 1 &&
                current.cheque_estado_id !== 6 &&
                "(" +
                  moment(
                    current.cheque_estado_id === 2
                      ? current.fecha_deposito
                      : current.cheque_estado_id === 3
                      ? current.fecha_acreditado
                      : current.cheque_estado_id === 4
                      ? current.fecha_rechazado
                      : current.cheque_estado_id === 5
                      ? current.fecha_utilizado
                      : ""
                  ).format("DD/MM/YYYY") +
                  ")"}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Numero:</strong> {current.numero}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Banco:</strong> {bancos.find((p) => p.id === current.banco_id).nombre}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Razón Social:</strong> {current.razon_social}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>CUIT:</strong> {current.cuit}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>E-check:</strong> {current.echeck ? "Si" : "No"}
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Valor:</strong>{" "}
              <NumberFormat
                displayType="text"
                decimalSeparator=","
                thousandSeparator="."
                mask=""
                prefix="$"
                decimalScale={2}
                allowNegative={false}
                margin="dense"
                name="valor"
                label="Valor $ *"
                value={current.valor}
                fullWidth
              />
            </Grid>
            {current.cobranza && (
              <Grid item xs={12} md={12}>
                <strong>Cobranza:</strong> {current.cobranza.id} -{" "}
                {current.cobranza.grupo ? current.cobranza.grupo.nombre : current.cobranza.cliente.nombre} -{" "}
                {moment(current.cobranza.fecha).format("DD/MM/YYYY")}
              </Grid>
            )}
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        {(!current || !current.soloVista) && (
          <Button onClick={onSubmit} variant="contained" color="primary" type="submit" disabled={saving}>
            Guardar
          </Button>
        )}
        <Button onClick={handleClose} variant="contained" color="secondary" disabled={saving}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.cheques.current,
    open: state.cheques.open,
    loading_clientes: state.clientes.loading,
    clientes: state.clientes.clientes,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
    bancos: state.bancos.bancos,
    last_banco_id: state.bancos.last_banco_id,
    loading_bancos: state.bancos.loading,
    grupos: state.grupos.grupos,
    loading_grupos: state.grupos.loading
  };
};

export default connect(mapStateToProps)(ChequeForm);
