import {
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormControl,
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core/";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import ReactSelectNonCreate from "react-select";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import momentTz from "moment-timezone";
import { getClientes } from "../../actions/clientes";
import {
  cambiarEstadoVenta,
  deleteVenta,
  getVentas,
  setBypassIoVentas,
  setCurrentVenta,
  getVentasAprobadas,
  getVentasPendientes,
  getPuntosLogisticos,
} from "../../actions/ventas";
import { getZonas } from "../../actions/zonas";
import {
  cEstados,
  cHabilitaciones,
  cTiposComprobantes,
} from "../../config/constantes";
import NoAfip from "../../static/images/noafip.png";
import NoContabilium from "../../static/images/nocontabilium.png";
import { calcWorkingDays, checkNotas } from "../../utils/functions";
import subscribeTo from "../../utils/subscribeTo";
import { getIsAdmin, getRoleCanDo } from "../usuarios/Usuario";
import AddVentaBtn from "./AddVentaBtn";
import ApproveVentaBtn from "./ApproveVentaBtn";
import VentaForm from "./VentaForm";
import AuthContext from "../../context/auth/authContext";
import AddPLogisticoBtn from "./AddPLogisticoBtn";
import PLogisticoForm from "./PLogisticoForm";
import PLogisticoTable from "./PLogisticoTable";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",
    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#CCCC00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },

  disapproveButton: {
    cursor: "pointer",
    color: "grey",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  factura: {
    borderRadius: "20px",
    border: "1px solid rgba(0,255,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(0,255,0,0.1)",
    margin: "auto",
  },
  notaCredito: {
    borderRadius: "20px",
    border: "1px solid rgba(255,0,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,0,0,0.1)",
    margin: "auto",
  },
  facturaX: {
    borderRadius: "20px",
    border: "1px solid rgba(255,255,0,0.9)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,255,0,0.1)",
    margin: "auto",
  },
  pendingClientSelect: {
    width: "20%",
  },
}));
const onClickBorrar = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea borrar esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }"?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(deleteVenta(venta.id)));
};

const onClickAprobar = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea aprobar esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }"?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() =>
    dispatch(
      cambiarEstadoVenta(venta.id, cEstados.Aprobada, {
        fecha_salida: venta.fecha_salida,
      })
    )
  );
};

const onClickDesaprobar = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea volver esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }" a estado PENDIENTE?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(cambiarEstadoVenta(venta.id, cEstados.Pendiente)));
};

const onClickFacturar = (confirm, dispatch, venta) => {
  confirm({
    description:
      `Desea facturar esta venta de "${
        venta.cliente ? venta.cliente.razon_social : "BORRADO"
      }"?\n` +
      (venta.cliente
        ? venta.cliente.email_facturacion
          ? "La factura se va a enviar a: " + venta.cliente.email_facturacion
          : "El e-mail de facturación no fue configurado."
        : ""),
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(cambiarEstadoVenta(venta.id, cEstados.Facturada)));
};

const Ventas = ({
  dispatch,
  loading,
  saving,
  ventas,
  ventasAprobadas,
  ventasPendientes,
  error,
  bypassIo,
  zonas,
  clientes,
  filtered,
  loading_ventas_facturadas,
  loading_clientes,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [aprobando, setAprobando] = useState(null);
  const [fechaSalida, setFechaSalida] = useState(null);
  const [clicked, setClicked] = useState([]);
  const authContext = useContext(AuthContext);

  const [filters, setFilters] = useState({
    cliente: null,
    fechaVenta: null,
    fechaSalida: null,
  });

  const [filterPending, setFilterPending] = useState({
    cliente: null,
    fechaVenta: null,
    fechaSalida: null,
    zonas: [],
  });

  const [ventasPendientesLocalFilter, setVentasPendientesLocalFilter] =
    useState([]);

  useEffect(() => {
    dispatch(getVentas({ not_estado_id: 3 }));
    dispatch(getZonas());
    dispatch(getClientes());
    dispatch(getVentasAprobadas(filters));
    dispatch(getVentasPendientes(filterPending));
    if (!loading) {
      subscribeTo("ventasChanged", (err, data) => {
        if (!bypassIo) {
          dispatch(getVentas({ not_estado_id: 3 }));
          dispatch(getClientes());
          dispatch(getVentasAprobadas(filters));
          dispatch(getVentasPendientes(filterPending));
        } else {
          dispatch(setBypassIoVentas(false));
        }
      });

      return () => {
        subscribeTo("ventasChanged", null);
      };
    }
  }, [dispatch, loading]);

  useEffect(() => {
    console.log(filterPending.zonas, ventasPendientes);
    setVentasPendientesLocalFilter(
      ventasPendientes.filter(
        (v) =>
          !filterPending.zonas ||
          !filterPending.zonas.length ||
          filterPending.zonas.indexOf(v.cliente.zona_id) !== -1
      )
    );
  }, [ventasPendientes, filterPending.zonas]);

  useEffect(() => {
    setClicked([]);
  }, [ventas]);

  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const printTable = (ventas, estado_id, sort = null) => {
    const tableCells = (venta) => {
      let balance_a = venta.cliente.balance_a
        ? parseFloat(venta.cliente.balance_a).toFixed(2)
        : 0;
      let balance_x = venta.cliente.balance_x
        ? parseFloat(venta.cliente.balance_x).toFixed(2)
        : 0;

      let balance = "$" + balance_a + " / $" + balance_x;
      return (
        <Fragment>
          {!authContext.usuario.vendedor_id && (
            <TableCell align="center">
              <center>
                <FormControlLabel
                  value="fijar"
                  control={
                    <Checkbox
                      margin="dense"
                      name="fijar"
                      label="Fijado"
                      value="1"
                      checked={
                        clicked.find((i) => i.id === venta.id) ? true : false
                      }
                      onChange={(e) => {
                        if (
                          !clicked.length ||
                          clicked[0].estado_id === venta.estado_id
                        )
                          setClicked(
                            clicked.find((vent) => vent.id === venta.id)
                              ? clicked.filter((vent) => vent.id !== venta.id)
                              : [...clicked, venta]
                          );
                      }}
                      className={classes.textField}
                      variant="outlined"
                    />
                  }
                  label=""
                  labelPlacement="end"
                />
              </center>
            </TableCell>
          )}
          <TableCell align="center">{venta.id}</TableCell>
          <TableCell align="center">{venta.fecha_venta_formatted}</TableCell>
          <TableCell>
            {venta.grupo ? venta.grupo.nombre : venta.cliente.nombre}
          </TableCell>
          <TableCell>{venta.cliente.nombre}</TableCell>
          <TableCell>
            {moment(venta.fecha_salida).format("DDMM") +
              (venta.camioneta || "X") +
              (venta.orden ? "-" + venta.orden : "")}
          </TableCell>

          <TableCell align="center">
            {zonas.find((z) => z.id === venta.cliente.zona_id).nombre}
          </TableCell>
          <TableCell align="right">{venta.bultos}</TableCell>

          <TableCell align="right">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              mask=""
              prefix="$"
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
              value={venta.total !== undefined ? parseFloat(venta.total) : ""}
            />
          </TableCell>
          <TableCell align="right">{balance}</TableCell>
          <TableCell align="center">
            {checkNotas(venta) && (
              <PopupState variant="popover" popupId="demoPopover">
                {(popupState) => (
                  <div>
                    <Icon
                      {...bindHover(popupState)}
                      className={classes.listIcon}
                    >
                      notes
                    </Icon>
                    <Popover
                      {...bindPopover(popupState)}
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      disableRestoreFocus
                    >
                      <Typography>
                        <>
                          {venta.notas && (
                            <div>Nota de Ventas: {venta.notas} </div>
                          )}

                          {venta.cliente.notas_logisticas && (
                            <div>
                              Notas Logísticas:
                              {venta.cliente.notas_logisticas}{" "}
                            </div>
                          )}

                          {venta.cliente.habilitacion && (
                            <div>
                              {
                                cHabilitaciones.find(
                                  (h) => h.id === venta.cliente.habilitacion
                                ).nombre
                              }
                            </div>
                          )}
                        </>
                      </Typography>
                    </Popover>
                  </div>
                )}
              </PopupState>
            )}
            {!venta.contabilium_id && (
              <img
                src={NoContabilium}
                style={{ display: "none" }}
                title="Venta no sincronizada con Contabilium"
                alt="Venta no sincronizada con Contabilium"
              />
            )}
            {venta.estado_id === cEstados.Facturada &&
              venta.contabilium_id &&
              !venta.cae && (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    window.open(
                      "https://app.contabilium.com/comprobantesv.aspx?ID=" +
                        venta.contabilium_id,
                      "_blank"
                    )
                  }
                  src={NoAfip}
                  title="Sin datos de AFIP"
                  alt="Sin datos de AFIP"
                />
              )}
            {venta.venta_padre_id && (
              <Icon
                onClick={(e) =>
                  dispatch(
                    setCurrentVenta(
                      ventas.find((vent) => vent.id === venta.venta_padre_id)
                    )
                  )
                }
                className={classes.listIcon}
              >
                filecopy
              </Icon>
            )}
          </TableCell>
          <TableCell id="acciones" align="center">
            {acciones(venta)}
          </TableCell>
        </Fragment>
      );
    };

    const acciones = (venta) =>
      venta.estado_id !== cEstados.Facturada ? (
        <Fragment>
          {aprobando === venta.id ? (
            <Fragment>
              Fecha de Salida
              <br />
              <input
                type="date"
                id="start"
                name="trip-start"
                onChange={(e) => setFechaSalida(e.target.value)}
                value={
                  fechaSalida
                    ? fechaSalida
                    : moment
                        .utc(
                          venta.fecha_salida
                            ? venta.fecha_salida
                            : calcWorkingDays(new Date(), 2)
                        )
                        .format("YYYY-MM-DD")
                }
              />
              <br />
              <span
                title="Aprobar"
                className={classes.approveButton}
                onClick={(e) => {
                  onClickAprobar(confirm, dispatch, {
                    ...venta,
                    fecha_salida: fechaSalida
                      ? moment.utc(fechaSalida).format("YYYY-MM-DD")
                      : moment
                          .utc(
                            venta.fecha_salida
                              ? venta.fecha_salida
                              : calcWorkingDays(new Date(), 2)
                          )
                          .format("YYYY-MM-DD"),
                  });
                  setAprobando(null);
                }}
              >
                <Icon className={classes.listIcon}>check-circle</Icon>
              </span>
              <span
                title="Cancelar"
                className={classes.disapproveButton}
                onClick={(e) => setAprobando(null)}
              >
                <Icon className={classes.listIcon}>undo</Icon>
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <span
                title="Editar"
                className={classes.editButton}
                onClick={(e) => dispatch(setCurrentVenta(venta))}
              >
                <Icon className={classes.listIcon}>edit</Icon>
              </span>

              {(!venta.estado_id || venta.estado_id === cEstados.Pendiente) && (
                <span
                  title="Aprobar"
                  className={classes.approveButton}
                  onClick={(e) => setAprobando(venta.id)}
                >
                  <Icon className={classes.listIcon}>check-circle</Icon>
                </span>
              )}
              {venta.estado_id && venta.estado_id === cEstados.Aprobada && (
                <span
                  title="Desprobar"
                  className={classes.disapproveButton}
                  onClick={(e) => onClickDesaprobar(confirm, dispatch, venta)}
                >
                  <Icon className={classes.listIcon}>undo</Icon>
                </span>
              )}
              {getRoleCanDo("borrar_venta") && (
                <span
                  title="Borrar"
                  className={classes.deleteButton}
                  onClick={(e) => onClickBorrar(confirm, dispatch, venta)}
                >
                  <Icon fontSize="large" className={classes.listIcon}>
                    delete
                  </Icon>
                </span>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <span
            title="Ver"
            className={classes.editButton}
            onClick={(e) => dispatch(setCurrentVenta(venta))}
          >
            <Icon className={classes.listIcon}>visibility</Icon>
          </span>
          {venta.tipo_comprobante.indexOf("Factura") !== -1 && (
            <span
              title="Generar Nota de Credito"
              className={classes.deleteButton}
              onClick={(e) =>
                dispatch(
                  setCurrentVenta({
                    ...venta,
                    id: null,
                    tipo_comprobante_id:
                      venta.tipo_comprobante_id ===
                      cTiposComprobantes["Factura A"]
                        ? cTiposComprobantes["Nota de Credito A"]
                        : venta.tipo_comprobante ===
                          cTiposComprobantes["Factura B"]
                        ? cTiposComprobantes["Nota de Credito B"]
                        : venta.tipo_comprobante ===
                          cTiposComprobantes["Factura E"]
                        ? cTiposComprobantes["Nota de Credito E"]
                        : cTiposComprobantes["Nota de Credito X"],
                    estado_id: cEstados.Pendiente,
                    pagos: [],
                    cae: "",
                    fecha_cae: "",
                    numero_factura: "",
                    fecha_vencimiento_cae: "",
                    _NUEVA: true,
                    venta_padre_id: venta.id,
                  })
                )
              }
            >
              NC
            </span>
          )}
        </Fragment>
      );
    let all_ventas = ventas;
    ventas = ventas.filter((venta) => venta.estado_id === estado_id);
    if (sort) {
      ventas = ventas.sort((a, b) => {
        if (a[sort] < b[sort]) return -1;
        if (a[sort] > b[sort]) return 1;
        return 0;
      });
    }
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow width="3%" className={classes.tableHeader}>
              {!authContext.usuario.vendedor_id && (
                <TableCell align="center">
                  {" "}
                  <center>
                    <FormControlLabel
                      value="fijar"
                      control={
                        <Checkbox
                          margin="dense"
                          name="fijar"
                          label="Fijado"
                          value="1"
                          checked={
                            clicked.length &&
                            clicked.filter((v) => v.estado_id === estado_id)
                              .length === ventas.length
                          }
                          onChange={(e) => {
                            if (
                              clicked.filter((v) => v.estado_id === estado_id)
                                .length === ventas.length
                            )
                              setClicked([]);
                            else setClicked(ventas);
                          }}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </center>
                </TableCell>
              )}
              <TableCell width="5%" align="center">
                Cod
              </TableCell>
              <TableCell width="5%" align="center">
                Fecha
              </TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Sucursal</TableCell>
              <TableCell width="7%" align="center">
                Ruteo
              </TableCell>

              <TableCell align="center">Zona</TableCell>
              <TableCell align="center">Bultos</TableCell>
              <TableCell align="center">Total $</TableCell>
              <TableCell align="center">Saldo Pendiente</TableCell>
              <TableCell width="5%" align="center">
                Notas
              </TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estado_id !== cEstados.Facturada &&
              ventas
                .filter((vent) => vent.estado_id !== cEstados.Facturada)
                .map((vent, index) => (
                  <Fragment>
                    <TableRow className={classes.tableRow} key={index}>
                      {tableCells(vent)}
                    </TableRow>
                  </Fragment>
                ))}
            {estado_id === cEstados.Facturada &&
              ventas.map(
                (venta, index) =>
                  !venta.venta_padre_id && (
                    <Fragment>
                      <TableRow
                        className={classes.tableRow}
                        key={index}
                        onClick={(e) => {
                          setClicked(
                            clicked.find((vent) => vent.id === venta.id)
                              ? clicked.filter((vent) => vent.id !== venta.id)
                              : [...clicked, venta]
                          );
                        }}
                      >
                        {tableCells(venta)}
                      </TableRow>
                      {venta.ventasHijas &&
                        venta.ventasHijas.map((venta_hija) => {
                          if (
                            all_ventas.find((vent) => vent.id === venta_hija.id)
                          )
                            return (
                              <TableRow
                                style={{
                                  ...(clicked.find(
                                    (vent) =>
                                      vent.id === venta_hija.venta_padre_id
                                  )
                                    ? { display: "table-row" }
                                    : { display: "none" }),
                                }}
                              >
                                {tableCells(
                                  all_ventas.find(
                                    (vent) => vent.id === venta_hija.id
                                  )
                                )}
                              </TableRow>
                            );
                          else;
                        })}
                    </Fragment>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (ventas !== null && !ventas.length && !loading) {
    return (
      <Fragment>
        <VentaForm panel="logistico" />
        <h4>No hay ninguna venta todavía!</h4>
        <AddVentaBtn />
        <ApproveVentaBtn />
      </Fragment>
    );
  }
  let openFilter = false;
  return (
    <Fragment>
      <VentaForm panel="logistico" />
      <PLogisticoForm panel="logistico" />
      {error && (
        <Snackbar
          open={error ? true : false}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: error }}
            ></div>
          </Alert>
        </Snackbar>
      )}
      <Grid
        container
        className={
          mobile
            ? classes.gridContainer
            : saving
            ? classes.gridContainerLoading
            : ""
        }
      >
        <Grid item xs={12} md={12}>
          {saving && <LinearProgress className={classes.root} />}
          {ventas !== null &&
          zonas &&
          !loading &&
          !loading_clientes &&
          !loading_ventas_facturadas ? (
            <Fragment>
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>filter_list</Icon>}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Pendientes
                  </Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelFilter}>
                  {clientes && clientes.length > 0 && (
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "8px",
                          }}
                        >
                          <ReactSelectNonCreate
                            fullWidth
                            value={
                              filterPending.cliente &&
                              clientes.find(
                                (p) => p.id === filterPending.cliente
                              )
                                ? {
                                    label: clientes.find(
                                      (p) => p.id === filterPending.cliente
                                    ).nombre,
                                    value: filterPending.cliente,
                                  }
                                : ""
                            }
                            name="cliente"
                            isClearable
                            placeholder="Cliente..."
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                                marginTop: "-8px",
                              }),
                            }}
                            menuPortalTarget={document.body}
                            formatCreateLabel={(value) =>
                              "Nuevo cliente: " + value
                            }
                            onChange={(e) =>
                              setFilterPending({
                                ...filterPending,
                                cliente: e ? e.value : null,
                              })
                            }
                            options={clientes.map((cliente) => ({
                              value: cliente.id,
                              label: cliente.nombre,
                            }))}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "120%",
                            paddingTop: "8px",
                          }}
                        >
                          <InputLabel htmlFor="select-tipo-comprobante">
                            Zona
                          </InputLabel>
                          <Select
                            input={
                              <OutlinedInput
                                style={{
                                  height: "40px",
                                  width: "80%",
                                }}
                                id="select-tipo-comprobante"
                              />
                            }
                            value={filterPending.zonas}
                            name="zona"
                            multiple
                            onChange={(e) => {
                              setFilterPending({
                                ...filterPending,
                                zonas: e.target.value,
                              });
                            }}
                          >
                            {zonas.map((zona) => (
                              <MenuItem value={zona.id} key={zona.id}>
                                {zona.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <div
                          style={{
                            height: "50px",
                            width: "100%",
                            paddingTop: "12px",
                          }}
                        >
                          Fecha salida estimada:{" "}
                          <DateRangePicker
                            value={filterPending.fechaSalida}
                            onChange={(ranges) => {
                              setFilterPending({
                                ...filterPending,
                                fechaSalida:
                                  ranges && ranges[0] && ranges[1]
                                    ? [
                                        moment(ranges[0]).format(),
                                        moment(ranges[1]).format(),
                                      ]
                                    : ranges,
                              });
                            }}
                            locale="es-AR"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <div
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "10px",
                          }}
                        >
                          <Button
                            onClick={(e) =>
                              dispatch(getVentasPendientes(filterPending))
                            }
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
              {printTable(ventasPendientesLocalFilter, cEstados.Pendiente)}
              {!authContext.usuario.vendedor_id && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Icon>filter_list</Icon>}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className={classes.summary}
                    >
                      <Typography className={classes.heading}>
                        Aprobadas
                      </Typography>
                      <Typography
                        className={classes.secondaryHeading}
                      ></Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionPanelFilter}>
                      {clientes && clientes.length > 0 && (
                        <Grid container className={classes.root} spacing={2}>
                          <Grid item xs={12} md={3}>
                            <FormControl
                              variant="outlined"
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "8px",
                              }}
                            >
                              <ReactSelectNonCreate
                                fullWidth
                                value={
                                  filters.cliente &&
                                  clientes.find((p) => p.id === filters.cliente)
                                    ? {
                                        label: clientes.find(
                                          (p) => p.id === filters.cliente
                                        ).nombre,
                                        value: filters.cliente,
                                      }
                                    : ""
                                }
                                name="cliente"
                                isClearable
                                placeholder="Cliente..."
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    marginTop: "-8px",
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                formatCreateLabel={(value) =>
                                  "Nuevo cliente: " + value
                                }
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    cliente: e ? e.value : null,
                                  })
                                }
                                options={clientes.map((cliente) => ({
                                  value: cliente.id,
                                  label: cliente.nombre,
                                }))}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <div
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "12px",
                              }}
                            >
                              Fecha Venta:{" "}
                              <DateRangePicker
                                value={filters.fechaVenta}
                                onChange={(ranges) =>
                                  setFilters({
                                    ...filters,
                                    fechaVenta: ranges,
                                  })
                                }
                                locale="es-AR"
                              />{" "}
                              Fecha Salida:{" "}
                              <DateRangePicker
                                value={filters.fechaSalida}
                                onChange={(ranges) =>
                                  setFilters({
                                    ...filters,
                                    fechaSalida: ranges,
                                  })
                                }
                                locale="es-AR"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <div
                              style={{
                                height: "40px",
                                width: "100%",
                                paddingTop: "10px",
                              }}
                            >
                              <Button
                                onClick={(e) =>
                                  dispatch(getVentasAprobadas(filters))
                                }
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Filtrar
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  {printTable(
                    ventasAprobadas,
                    cEstados.Aprobada,
                    "fecha_salida"
                  )}
                </>
              )}
              {!authContext.usuario.vendedor_id && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Icon>filter_list</Icon>}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className={classes.summary}
                    >
                      <Typography className={classes.heading}>
                        Puntos Logisticos
                      </Typography>
                      <Typography
                        className={classes.secondaryHeading}
                      ></Typography>
                    </AccordionSummary>
                  </Accordion>
                  <PLogisticoTable />
                </>
              )}
              <div>
                <br />
                <br />
                &nbsp;
              </div>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </Grid>
      </Grid>

      {clicked && clicked.length > 0 ? (
        <ApproveVentaBtn
          ids={clicked.map((c) => c.id)}
          modo={
            clicked.every((c) => c.estado_id === cEstados.Pendiente)
              ? "Pendientes"
              : clicked.every((c) => c.estado_id === cEstados.Aprobada)
              ? "Aprobadas"
              : false
          }
        />
      ) : (
        <>
          <AddVentaBtn />
          <AddPLogisticoBtn />
        </>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    ventasAprobadas: state.ventas.aprobadas,
    ventasPendientes: state.ventas.pendientes,
    zonas: state.zonas.zonas,
    filtered: state.ventas.filtered,
    loading: state.ventas.loading,
    filters: state.ventas.filters,
    error: state.ventas.error,
    saving: state.ventas.saving,
    bypassIo: state.ventas.bypassIo,
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    loading_ventas_facturadas: state.ventas.loading_ventas_facturadas,
  };
};

export default connect(mapStateToProps)(Ventas);
