import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useContext, useEffect, useState } from "react";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "green",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "green",
  },
}));

const Login = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.clientes && props.clientes.length) window.location.reload();
  }, []);

  const [usuario, setUsuario] = useState({
    email: "",
    password: "",
  });

  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { email, password } = usuario;

  useEffect(() => {
    const checkUsuario = async () => {
      if (localStorage.getItem("token") && !authContext.usuario)
        await authContext.loadUsuario();
      if (authContext.isAuthenticated) {
        setTimeout(() => {
          if (props.location.state)
            props.history.push(props.location.state.from);
          else props.history.push("/panel_ventas");
        }, 500);//timeout to delay
      }
      if (authContext.error) {
        alertContext.setAlert(authContext.error, "danger");
        authContext.clearErrors();
      }
    };

    checkUsuario();
    // eslint-disable-next-line
  }, [authContext, props.history]);

  const onChange = (e) =>
    setUsuario({ ...usuario, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    authContext.login({
      email,
      password,
    });
  };

  return (
    !localStorage.getItem("token") && (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Ingresar:
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={onChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Ingresar
            </Button>
          </form>
        </div>
      </Container>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
  };
};

export default connect(mapStateToProps)(Login);
