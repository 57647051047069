import { cTiposComprobantes, cValorRgIVA } from "../config/constantes";

export const discriminaIva = (tipo_comprobante_id) => {
  let val = !(
    tipo_comprobante_id === cTiposComprobantes["Factura X"] ||
    tipo_comprobante_id === cTiposComprobantes["Factura C"] ||
    tipo_comprobante_id === cTiposComprobantes["Factura E"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito X"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito E"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito C"]
  );

  return val;
};

export const esX = (tipo_comprobante_id) => {
  let val =
    tipo_comprobante_id === cTiposComprobantes["Factura X"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito X"] ||
    tipo_comprobante_id === cTiposComprobantes["Pago a Cuenta X"];

  return val;
};

export const getTotalGravado = (venta) => {
  return (
    venta.items.reduce(
      (a, b) =>
        a +
        (b.iva ? b.precio * b.cantidad * (1 - b.bonificacion / 100) : 0) * 100,
      0
    ) / 100
  );
};

export const tieneRgIva = (venta, cliente) => {
  if (
    (venta.id && venta.id < 14261) ||
    (venta.venta_padre_id && venta.venta_padre_id < 14261)
  )
    return false; //for old sales
  //no makro, no cencosud
  if (
    cliente.grupo_id === 27 ||
    cliente.grupo_id === 21 ||
    cliente.grupo_id === 35 ||
    cliente.grupo_id === 37 ||
    cliente.grupo_id === 42 ||
    cliente.grupo_id === 40 ||
    cliente.grupo_id === 44 ||
    cliente.id === 976 ||
    cliente.id === 340 ||
    cliente.id === 831 ||
    cliente.id === 817 ||
    cliente.id === 836 ||
    cliente.id === 891 ||
    cliente.id === 911 ||
    cliente.id === 974
  )
    return false;
  return (
    (!venta.tipo_comprobante_id ||
      venta.tipo_comprobante_id === cTiposComprobantes["Factura A"] ||
      venta.tipo_comprobante_id === cTiposComprobantes["Nota de Credito A"]) &&
    cliente.categoria_impositiva_id === 1 &&
    getTotalGravado(venta) >= cValorRgIVA
  );
};

export const formaCobroCajaTipo = (forma_cobro) => {
  if (forma_cobro === 1) return 1;
  if (forma_cobro === 2) return 3;
  if (forma_cobro === 4) return 4;
};

export const discriminaIvaCasificacion = (clasificacion) => {
  //cuando tipo_comprobante es clasificacion
  if (clasificacion === 1 || clasificacion === 2) return true;
  else if (clasificacion === 3) return true;
};
export const esNotaDeDebito = (tipo_comprobante_id) => {
  tipo_comprobante_id =
    tipo_comprobante_id === cTiposComprobantes["Nota de Débito A"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Débito B"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Débito C"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Débito E"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Débito X"];

  return tipo_comprobante_id;
};

export const esNotaDeCredito = (tipo_comprobante_id) => {
  tipo_comprobante_id =
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito A"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito B"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito C"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito E"] ||
    tipo_comprobante_id === cTiposComprobantes["Nota de Credito X"];

  return tipo_comprobante_id;
};

export const devuelveStock = (venta) => {
  return esNotaDeCredito(venta.tipo_comprobante_id) && venta.ajusta_stock;
};

export const esPagoACuenta = (tipo_comprobante_id) => {
  tipo_comprobante_id =
    tipo_comprobante_id === cTiposComprobantes["Pago a Cuenta"] ||
    tipo_comprobante_id === cTiposComprobantes["Pago a Cuenta X"];

  return tipo_comprobante_id;
};

export const esNotaDeCreditoOPagoACuenta = (tipo_comprobante_id) => {
  return (
    esNotaDeCredito(tipo_comprobante_id) || esPagoACuenta(tipo_comprobante_id)
  );
};

export const toFixed = (num, fix) => {
  if (num && !isNaN(num)) {
    return Number(num).toFixed(fix);
  } else return num;
};

export const getNextDayOfTheWeek = (
  dayNumber,
  excludeToday = true,
  refDate = new Date()
) => {
  const dayOfWeek = dayNumber;
  if (dayOfWeek < 0) return;
  refDate.setHours(0, 0, 0, 0);
  refDate.setDate(
    refDate.getDate() +
      +!!excludeToday +
      ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  );
  return refDate;
};

export const calcWorkingDays = (fromDate, days) => {
  var count = 0;
  while (count < days) {
    fromDate.setDate(fromDate.getDate() + 1);
    if (fromDate.getDay() !== 0 && fromDate.getDay() !== 6)
      // Skip weekends
      count++;
  }
  return fromDate;
};

export const validaCuit = (sCUIT) => {
  var aMult = "5432765432";
  aMult = aMult.split("");

  if (sCUIT && sCUIT.length == 11) {
    let aCUIT = sCUIT.split("");
    var iResult = 0;
    for (let i = 0; i <= 9; i++) {
      iResult += aCUIT[i] * aMult[i];
    }
    iResult = iResult % 11;
    iResult = 11 - iResult;

    if (iResult == 11) iResult = 0;
    if (iResult == 10) iResult = 9;

    if (iResult == aCUIT[10]) {
      return true;
    }
  }
  return false;
};

export const calcularBultos = (venta) => {
  return (venta.items
    .map((it) => it.cantidad)
    .reduce(function (a, b) {
      if (!b) b = 0;
      return a + b;
    }, 0) /
    24) %
    1 ===
    0
    ? venta.items
        .map((it) => it.cantidad)
        .reduce(function (a, b) {
          if (!b) b = 0;
          return a + b;
        }, 0) / 24
    : Math.ceil(
        venta.items
          .map((it) => it.cantidad)
          .reduce(function (a, b) {
            if (!b) b = 0;
            return a + b;
          }, 0) / 25
      );
};

export const checkNotas = (venta) => {
  if (
    venta.notas ||
    venta.cliente.notas_logisticas ||
    venta.cliente.habilitacion
  )
    return true;
  else return false;
};
