import openSocket from "socket.io-client";
const socket = openSocket("/", { rejectUnauthorized: false });
function subscribeTo(event, cb) {
  if (!cb) socket.removeAllListeners();
  else {
    socket.on(event, (data) => cb(null, data));
    socket.emit(event, 2000);
  }
}
export default subscribeTo;
