import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import {
  GET_VENTAS,
  GET_PUNTOS_LOGISTICOS,
  ERROR_VENTA,
  DELETE_VENTA,
  SET_CURRENT,
  SET_CURRENT_PUNTO_LOGISTICO,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_VENTAS,
  CLEAR_FILTER,
  ADD_VENTA,
  UPDATE_VENTA,
  SET_SAVING_VENTA,
  CAMBIAR_ESTADO_VENTA,
  SET_BYPASS_IO_VENTAS,
  APROBAR_MULTIPLES,
  ASIGNAR_CAMIONETA,
  FILTRAR_VENTAS_FACTURADAS,
  LOADING_VENTAS,
  GET_VENTAS_FACTURADAS,
  GET_VENTAS_CLIENTE,
  GET_VENTAS_CLIENTE_NO_PAGAS,
  GET_VENTAS_PARA_COBRAR,
  GET_VENTAS_APROBADAS,
  GET_VENTAS_PENDIENTES,
  SET_OPEN_PUNTOLOGISTICO,
  CLEAR_CURRENT_PUNTO_LOGISTICO,
  ADD_PUNTO_LOGISTICO,
  ERROR_PUNTO_LOGISTICO,
  UPDATE_PUNTO_LOGISTICO,
} from "../actions/types";
import { cEstados } from "../config/constantes";

const initialState = {
  ventas: [],
  puntos_logisticos: [],
  ventas_clientes: {},
  ventas_clientes_no_pagas: {},
  facturadas: [],
  aprobadas: [],
  pendientes: [],
  facturadasFilter: { cliente: null },
  ventas_para_cobrar: [],
  ultimoRemito: 0,
  current: null,
  current_punto_logistico: null,
  filters: { descripcion: "", tipo: "" },
  filtered: null,
  error: null,
  error_punto_logistico: null,
  loading: true,
  bypassIo: false,
  loading_ventas_facturadas: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  switch (action.type) {
    case GET_VENTAS:
      return {
        ...state,
        ventas: action.payload.ventas,
        ultimoRemito: action.payload.ultimoRemito,
        loading: false,
      };
    case GET_VENTAS_CLIENTE:
      return {
        ...state,
        ventas_clientes: {
          ...state.ventas_clientes,
          [action.payload.cliente_id]: action.payload.ventas,
        },
        loading: false,
      };
    case GET_VENTAS_CLIENTE_NO_PAGAS:
      return {
        ...state,
        ventas_clientes_no_pagas: {
          ...state.ventas_clientes_no_pagas,
          [action.payload.cliente_id]: {
            ...action.payload.ventas,
            ventas: action.payload.ventas.ventas
              .filter((v) => v.clasificacion === 4)
              .concat(
                action.payload.ventas.ventas.filter(
                  (v) => v.clasificacion !== 4
                )
              ), //para mostrar primero factura ficticia
          },
        },
        loading: false,
      };
    case GET_VENTAS_FACTURADAS:
      return {
        ...state,
        facturadas: action.payload.ventas,
        ultimoRemito: action.payload.ultimoRemito,
        loading: false,
      };
    case GET_VENTAS_PENDIENTES:
      return {
        ...state,
        pendientes: action.payload.ventas,
        loading: false,
      };
    case GET_VENTAS_APROBADAS:
      return {
        ...state,
        aprobadas: action.payload.ventas,
        loading: false,
      };
    case GET_VENTAS_PARA_COBRAR:
      return {
        ...state,
        ventas_para_cobrar: action.payload.ventas,
        loading: false,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_CURRENT_PUNTO_LOGISTICO:
      return {
        ...state,
        current_punto_logistico: action.payload,
      };
    case LOADING_VENTAS:
      return {
        ...state,
        loading_ventas_facturadas: action.payload,
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case SET_OPEN_PUNTOLOGISTICO:
      return {
        ...state,
        open_punto_logistico: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CLEAR_CURRENT_PUNTO_LOGISTICO:
      return {
        ...state,
        current_punto_logistico: null,
        error_punto_logistico: null,
      };
    case SET_SAVING_VENTA:
      return {
        ...state,
        saving: action.payload,
      };

    case FILTER_VENTAS:
      const shouldFilter = (venta, property, value) => {
        switch (property) {
          case "fecha_venta":
            return (
              formatDate(venta[property]) >= formatDate(value[0]) &&
              formatDate(venta[property]) <= formatDate(value[1])
            );
          default:
            break;
        }
      };
      return {
        ...state,
        filters: action.filter,
        filtered: state.ventas.filter((venta) => {
          let ok = true;
          for (var property in action.filter) {
            //if (action.filterType === property) continue;
            if (
              action.filter.hasOwnProperty(property) &&
              action.filter[property]
            ) {
              ok = ok && shouldFilter(venta, property, action.filter[property]);
            }
          }
          //ok = ok && shouldFilter(venta, action.filterType, action.payload);
          return ok;
        }),
      };
    case SET_BYPASS_IO_VENTAS:
      return { ...state, bypassIo: action.payload };

    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ADD_VENTA:
      if (!action.payload.venta_padre_id)
        return {
          ...state,
          ventas: [...state.ventas, action.payload],
          loading: false,
        };
      else {
        return {
          ...state,
          ventas: state.ventas.map((v) => {
            if (v.id !== action.payload.venta_padre_id) return v;
            else {
              return {
                ...v,
                ventasHijas: [...v.ventasHijas, action.payload],
              };
            }
          }),
          loading: false,
        };
      }
    case GET_PUNTOS_LOGISTICOS:
      return {
        ...state,
        puntos_logisticos: action.payload,
        loading: false,
      };
    case ADD_PUNTO_LOGISTICO:
      return {
        ...state,
        ultimo: action.payload.id,
        loading: false,
      };
    case UPDATE_PUNTO_LOGISTICO:
      const newPuntosLogisticos = state.puntos_logisticos.map(
        (puntoLogistico) =>
          puntoLogistico.id === action.payload.id
            ? action.payload
            : puntoLogistico
      );
      return {
        ...state,
        puntos_logisticos: newPuntosLogisticos,
      };
    case ERROR_PUNTO_LOGISTICO:
      console.log("ENTRO ERROR 3");
      console.log(action.payload);
      return {
        ...state,
        error_punto_logistico: action.payload,
      };
    case CAMBIAR_ESTADO_VENTA:
      return {
        ...state,
        ...(action.payload.estado_id === cEstados.Facturada &&
          action.payload.remito &&
          action.payload.remito > state.ultimoRemito && {
            ultimoRemito: action.payload.remito,
          }),
        ...(action.payload.estado_id === cEstados.Facturada && {
          facturadas: [...action.payload.ventas, ...state.facturadas],
        }),
        ventas:
          action.payload.estado_id !== cEstados.Entregada
            ? state.ventas
                .filter(
                  (venta) =>
                    !action.payload.ventas.find((v) => v.id === venta.id)
                )
                .concat(action.payload.ventas)
            : state.ventas.filter(
                (venta) => !action.payload.ventas.find((v) => v.id === venta.id)
              ),
      };
    case APROBAR_MULTIPLES:
      return {
        ...state,
        ventas: state.ventas
          .filter(
            (venta) => !action.payload.ventas.find((v) => v.id === venta.id)
          )
          .concat(action.payload.ventas),
      };
    case ASIGNAR_CAMIONETA:
      return {
        ...state,
        ventas: state.ventas
          .filter(
            (venta) => !action.payload.ventas.find((v) => v.id === venta.id)
          )
          .concat(action.payload.ventas),
      };
    case UPDATE_VENTA:
      const newVentas = state.ventas.map((venta) =>
        venta.id === action.payload.id ? action.payload : venta
      );
      return {
        ...state,
        ventas: newVentas,
        pendientes: newVentas,
        aprobadas: newVentas,
        ventas_para_cobrar: newVentas,
      };
    case DELETE_VENTA:
      return {
        ...state,
        ventas: state.ventas.filter((venta) => venta.id !== action.payload),
        loading: false,
      };
    case ERROR_VENTA:
      return {
        ...state,
        error: action.payload,
      };
    /*

    case CLEAR_CONTACTS:
      return {
        ...state,
        transportistas: null,
        error: null,
        current: null,
        loading: null
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };

    case FILTER_CONTACTS:
      return {
        ...state,
        filtered: state.transportistas.filter(transportista => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return (
            transportista.nombre.match(regex) ||
            transportista.email.match(regex)
          );
        })
      };

    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload
      };
      */

    default:
      return state;
  }
};
