import { Grid, LinearProgress, Snackbar } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useConfirm } from 'material-ui-confirm';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { deleteGrupo, filterGrupos, getGrupos } from '../../actions/grupos';
import { getListasPrecios } from '../../actions/listas_precios';
import subscribeTo from '../../utils/subscribeTo';
import AddGrupoBtn from '../grupos/AddGrupoBtn';
import GrupoForm from '../grupos/GrupoForm';
import GruposFilter from '../grupos/GruposFilter';
import GrupoItem from './GrupoItem';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const Grupos = ({ grupos, filter, filtered, loading, dispatch,listas_precios, loading_listas_precios, error }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  
  const clickDelete = (grupo)=>{
    confirm(
      {
        description: `Desea borrar el grupo "${grupo.nombre}"?`,
        title: 'ATENCION',
        cancellationText: 'Cancelar',
        confirmationText: 'Confirmar'
      }
    ).then(() => dispatch(deleteGrupo(grupo.id)))
  }

  useEffect(() => {
    dispatch(getGrupos());
    dispatch(getListasPrecios());
    if (!loading) {
      subscribeTo('gruposChanged', (err, data) => {
        dispatch(getGrupos());
        dispatch(filterGrupos(filter))
      });
      return () => {
        subscribeTo('gruposChanged', null);
      };
    }
  }, [dispatch, loading, filter]);

  if (grupos !== null && !grupos.length && !loading) {
    return (
      <Fragment>
        <GrupoForm />
        <h4>No hay ningun grupo todavía!</h4> <AddGrupoBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <GrupoForm />
      </div>
      <div>
        <GruposFilter />
        {error && (<Snackbar open={error?true:false} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  >
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>)}
        <div className={classes.root}>
          {grupos !== null && !loading && !loading_listas_precios ? (
            <Fragment>

              <Grid
                container
                spacing={2}
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
              >
              {(filtered ? filtered : grupos).map((grupo, key) => (
                <GrupoItem key={key} grupo={grupo} listas_precios={listas_precios} />
              ))}
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddGrupoBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    grupos: state.grupos.grupos,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    filtered: state.grupos.filtered,
    filter: state.grupos.filter,
    loading: state.grupos.loading,
    error: state.grupos.error
  };
};

export default connect(mapStateToProps)(((Grupos)));
