import axios from 'axios';

import {
  ADD_LISTA_PRECIOS,
  DELETE_LISTA_PRECIOS,
  SET_CURRENT_LISTA_PRECIOS,
  CLEAR_CURRENT_LISTA_PRECIOS,
  UPDATE_LISTA_PRECIOS,
  FILTER_LISTA_PRECIOS,
  CLEAR_FILTER_LISTA_PRECIOS,
  ERROR_LISTA_PRECIOS,
  GET_LISTA_PRECIOS,
  CLEAR_LISTA_PRECIOS,
  SET_OPEN_LISTA_PRECIOS
} from './types';

//Add ListaPrecios
export const addListaPrecios = lista_precios => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/listas_precios', lista_precios, config);
      dispatch({ type: ADD_LISTA_PRECIOS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_LISTA_PRECIOS, payload: error.msg });
    }
  };
};

export const getListasPrecios = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/listas_precios');
      dispatch({ type: GET_LISTA_PRECIOS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_LISTA_PRECIOS, payload: error.msg });
    }
  };
};

//Delete ListaPrecios

export const deleteListaPrecios = lista_preciosId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/listas_precios/${lista_preciosId}`);

      dispatch({ type: DELETE_LISTA_PRECIOS, payload: lista_preciosId });
    } catch (error) {
      dispatch({ type: ERROR_LISTA_PRECIOS, payload: error.response });
    }
  };
};

//Set Current ListaPrecios

export function setCurrent(lista_precios) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_LISTA_PRECIOS, payload: lista_precios });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_LISTA_PRECIOS, payload: open });
  };
}

//Clear Current ListaPrecios
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_LISTA_PRECIOS });
  };
};
//Update ListaPrecios

export const updateListaPrecios = lista_precios => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/listas_precios/${lista_precios.id}`,
        lista_precios,
        config
      );
      dispatch({ type: UPDATE_LISTA_PRECIOS, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_LISTA_PRECIOS, payload: error.response.msg });
    }
  };
};

//Filter ListaPrecios

export const filterListasPrecios = text => {
  return dispatch => {
    dispatch({ type: FILTER_LISTA_PRECIOS, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_LISTA_PRECIOS });
  };
};

export const clearListasPrecios = () => {
  return dispatch => {
    dispatch({ type: CLEAR_LISTA_PRECIOS });
  };
};
