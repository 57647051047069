import escapeRegExp from 'escape-string-regexp';
import {
  ADD_CHEQUE_PROPIO,
  DELETE_CHEQUE_PROPIO,
  SET_CURRENT_CHEQUE_PROPIO,
  CLEAR_CURRENT_CHEQUE_PROPIO,
  UPDATE_CHEQUE_PROPIO,
  FILTER_CHEQUE_PROPIO,
  CLEAR_FILTER_CHEQUE_PROPIO,
  ERROR_CHEQUE_PROPIO,
  GET_CHEQUE_PROPIO,
  CLEAR_CHEQUE_PROPIO,
  SET_OPEN_CHEQUE_PROPIO
} from '../actions/types';

const initialState = {
  cheques_propios: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHEQUE_PROPIO:
      return {
        ...state,
        cheques_propios: action.payload,
        loading: false
      };
    case ADD_CHEQUE_PROPIO:
      return {
        ...state,
        cheques_propios: [action.payload, ...state.cheques_propios],
        loading: false
      };
    case UPDATE_CHEQUE_PROPIO:
      return {
        ...state,
        cheques_propios: state.cheques_propios.map(cheque_propio =>
          cheque_propio.id === action.payload.id ? action.payload : cheque_propio
        )
      };
    case DELETE_CHEQUE_PROPIO:
      return {
        ...state,
        cheques_propios: state.cheques_propios.filter(cheque_propio => cheque_propio.id !== action.payload),
        loading: false
      };
    case CLEAR_CHEQUE_PROPIO:
      return {
        ...state,
        cheques_propios: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_CHEQUE_PROPIO:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_CHEQUE_PROPIO:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_CHEQUE_PROPIO:
      return {
        ...state,
        current: null
      };

    case FILTER_CHEQUE_PROPIO:
      return {
        ...state,
        filter: action.payload,
        filtered: state.cheques_propios.filter(cheque_propio => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return cheque_propio.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_CHEQUE_PROPIO:
      return {
        ...state,
        filtered: null
      };
    case ERROR_CHEQUE_PROPIO:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
