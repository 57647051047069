import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core/";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { filterClientes } from "../../actions/clientes";
import { getZonas } from "../../actions/zonas";

const ClientesFilter = ({
  clientes,
  filter,
  filter_dir,
  filter_zona,
  filtered,
  zonas,
  dispatch,
}) => {
  useEffect(() => {}, [filtered]);

  useEffect(() => {
    dispatch(getZonas());
  }, []);

  const onChange = (e) => {
    dispatch(filterClientes(e.target.value));
  };

  const onChangeDir = (e) => {
    dispatch(filterClientes(e.target.value, true));
  };

  const onChangeZona = (e) => {
    const zonaSelected = zonas.find((item) => item.id === e.target.value);
    dispatch(filterClientes(zonaSelected.nombre, false, true));
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <TextField
        type="text"
        label="Filtrar clientes"
        margin="dense"
        variant="outlined"
        onChange={onChange}
        style={{ paddingBottom: "10px", width: "30%", paddingRight: "10px" }}
        value={filter}
      />{" "}
      <TextField
        type="text"
        label="Filtrar direccion/localidad"
        margin="dense"
        variant="outlined"
        onChange={onChangeDir}
        value={filter_dir}
        style={{ paddingBottom: "10px", width: "30%", paddingRight: "10px" }}
      />
      <FormControl
        variant="outlined"
        style={{
          height: "40px",
          width: "30%",
          paddingTop: "8px",
        }}
      >
        <InputLabel htmlFor="select-tipo-comprobante">Zona</InputLabel>
        <Select
          input={
            <OutlinedInput
              style={{
                height: "40px",
                width: "80%",
              }}
              id="select-tipo-comprobante"
            />
          }
          value={filter_zona}
          name="zona"
          onChange={(e) => onChangeZona(e)}
        >
          {zonas ? (
            zonas.map((zona) => (
              <MenuItem value={zona.id} key={zona.id}>
                {zona.nombre}
              </MenuItem>
            ))
          ) : (
            <MenuItem>NO ZONA</MenuItem>
          )}
        </Select>
      </FormControl>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.clientes.filter,
    filter_dir: state.clientes.filter_dir,
    filtered: state.clientes.filtered,
    clientes: state.clientes,
    zonas: state.zonas.zonas,
  };
};

export default connect(mapStateToProps)(ClientesFilter);
