import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';

const Register = props => {
  const [usuario, setUsuario] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  });

  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem('token') && !authContext.usuario)
      authContext.loadUsuario();

    if (authContext.isAuthenticated) {
      props.history.push('/');
    }
    if (authContext.error) {
      alertContext.setAlert(authContext.error, 'danger');
      authContext.clearErrors();
    }
    // eslint-disable-next-line
  }, [alertContext, authContext, props.history]);

  const { name, email, password, password2 } = usuario;

  const onChange = e => {
    setUsuario({ ...usuario, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (name === '' || email === '' || password === '') {
      alertContext.setAlert('Por favor complete todo los campos', 'danger');
    }
    if (password !== password2) {
      alertContext.setAlert('Los passwords no coinciden', 'danger');
    } else {
      authContext.register({
        name,
        email,
        password
      });
    }
  };
  return (
    <div className='form-container'>
      <h1>
        Registrar <span className='text-primary'>Nueva Cuenta</span>
      </h1>
      <form onSubmit={onSubmit}>
        <div className='form-group'>
          <label htmlFor='name'>Nombre</label>
          <input
            type='text'
            name='name'
            value={name}
            onChange={onChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email'>E-mail</label>
          <input
            type='text'
            name='email'
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            name='password'
            value={password}
            onChange={onChange}
            required
            minLength={6}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='password2'>Confirmar password</label>
          <input
            type='password'
            name='password2'
            value={password2}
            onChange={onChange}
            required
          />
        </div>
        <input
          type='submit'
          value='Registrar'
          className='btn btn-primary btn-block'
        />
      </form>
    </div>
  );
};

export default Register;
