import {
  Button, CardActions, Grid,
  Typography
} from "@material-ui/core/";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useConfirm } from "material-ui-confirm";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  clearCurrent, deleteTransportista,

  setCurrent,
  setOpen
} from "../../actions/transportistas";
import WhatsAppIcon from "../utils/WhatsAppIcon";

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: "#f7f7f7"
  },
  addressLink: {
    color: "black",
    textDecorationLine: "none",

    overflow: "hidden",

    "&:hover": {
      textDecorationLine: "underline"
    }
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: "180px",
    overflowY: "auto",
    fontSize: "0.9rem"
  },
  cardTitle: {
    fontSize: "1.15rem",
    textDecoration: "underline",
    cursor: "pointer"
  }
});

const TransportistaItem = ({
  transportista,
  transportistas,
  current,
  dispatch
}) => {
  const { id, nombre, email, telefonos, type } = transportista;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteTransportista(id));
    dispatch(clearCurrent());
  };
  const onClickBorrar = (confirm, dispatch, transportista) => {
    confirm({
      description: `Desea borrar al transportista "${nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar"
    }).then(() => onDelete());
  };

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            className={classes.cardTitle}
            onClick={(e) => {
              dispatch(setCurrent(transportista, true));
              dispatch(setOpen(transportista));
            }}
          >
            {nombre}
          </Typography>
          {transportista.direccion && (
            <a
              className={classes.addressLink}
              href={`https://www.google.com/maps/search/?api=1&query=${transportista.direccion}&query_place_id=${transportista.placeId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon style={{ verticalAlign: "text-bottom" }}>map</Icon>
              {transportista.direccion}{" "}
            </a>
          )}
          {email && (
            <div>
              <Icon style={{ verticalAlign: "text-bottom" }}>email</Icon>{" "}
              <a
                className={classes.addressLink}
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {email}
              </a>
            </div>
          )}
          <Grid container>
            {telefonos &&
              telefonos
                .sort((a, b) => {
                  if (b.tipo_telefono_id === 1) return 1;
                  else return 0;
                })
                .map((telefono, index) => {
                  let icon;
                  switch (telefono.tipo_telefono_id) {
                    case "1":
                      icon = "chat";
                      break;
                    default:
                      icon = "call";
                      break;
                  }
                  const returnjsx = (
                    <Grid item xs={12} md={6} key={`telefono${index}`}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          paddingRight: "10px"
                        }}
                      >
                        {telefono.tipo_telefono_id !== 1 ? (
                          <Icon style={{ verticalAlign: "text-bottom" }}>
                            {icon}
                          </Icon>
                        ) : (
                          <WhatsAppIcon />
                        )}{" "}
                        {telefono.numero}
                      </span>
                    </Grid>
                  );

                  if (telefono.tipo_telefono_id === 1)
                    return (
                      <a
                        className={classes.addressLink}
                        key={`whatsapp${index}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://wa.me/${telefono.numero.replace(
                          /\D/g,
                          ""
                        )}`}
                      >
                        {returnjsx}
                      </a>
                    );
                  else return returnjsx;
                })}
          </Grid>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            onClick={(e) => onClickBorrar(confirm, dispatch, transportista)}
            size="small"
            color="secondary"
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => {
              dispatch(setCurrent(transportista));
              dispatch(setOpen(transportista));
            }}
            size="small"
            color="primary"
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

TransportistaItem.propTypes = {
  transportista: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    transportistas: state.transportistas.transportistas,
    current: state.transportistas.current
  };
};

export default connect(mapStateToProps)(TransportistaItem);
