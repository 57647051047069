import { Grid, LinearProgress, Snackbar } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import {
  deleteVendedor,
  filterVendedores, getVendedores
} from "../../actions/vendedores";
import subscribeTo from "../../utils/subscribeTo";
import AddVendedorBtn from "../vendedores/AddVendedorBtn";
import VendedoresFilter from "../vendedores/VendedoresFilter";
import VendedorForm from "../vendedores/VendedorForm";
import VendedorItem from "./VendedorItem";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px"
  }
});

const Vendedores = ({
  vendedores,
  filter,
  filtered,
  loading,
  dispatch,
  listas_precios,
  loading_listas_precios,
  error
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const clickDelete = (vendedor) => {
    confirm({
      description: `Desea borrar el vendedor "${vendedor.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar"
    }).then(() => dispatch(deleteVendedor(vendedor.id)));
  };

  useEffect(() => {
    dispatch(getVendedores());

    if (!loading) {
      subscribeTo("vendedoresChanged", (err, data) => {
        dispatch(getVendedores());
        dispatch(filterVendedores(filter));
      });
      return () => {
        subscribeTo("vendedoresChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  if (vendedores !== null && !vendedores.length && !loading) {
    return (
      <Fragment>
        <VendedorForm />
        <h4>No hay ningun vendedor todavía!</h4> <AddVendedorBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <VendedorForm />
      </div>
      <div>
        <VendedoresFilter />
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <div className={classes.root}>
          {vendedores !== null && !loading && !loading_listas_precios ? (
            <Fragment>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                {(filtered ? filtered : vendedores).map((vendedor, key) => (
                  <VendedorItem key={key} vendedor={vendedor} />
                ))}
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddVendedorBtn />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    vendedores: state.vendedores.vendedores,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    filtered: state.vendedores.filtered,
    filter: state.vendedores.filter,
    loading: state.vendedores.loading,
    error: state.vendedores.error
  };
};

export default connect(mapStateToProps)(Vendedores);
