import Grid from "@material-ui/core/Grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
const pieOptions = {
  title: "",
  pieHole: 0.6,
  slices: [
    {
      color: "#2BB673",
    },
    {
      color: "#d91e48",
    },
    {
      color: "#007fad",
    },
    {
      color: "#e9a227",
    },
  ],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "233238",
      fontSize: 14,
    },
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Roboto",
};

const data = [
  ["Mes", "Ventas $", "Gastos $"],
  ["Dic 2019", 200000, 400000],
  ["Ene 2020", 400000, 460000],
  ["Feb 2020", 700000, 500000],
  ["Mar 2020", 1100000, 640000],
  ["Abr 2020", 1600000, 740000],
  ["May 2020", 2500000, 840000],
  ["Jun 2020", 4000000, 940000],
];
const options = {
  curveType: "",
  legend: { position: "none" },
  vAxis: {
    format: {
      prefix: "$",
      decimalSymbol: ',',
      
      negativeColor: "red",
      negativeParens: true,
    },
  },
};
/*

*/

const Dashboard = () => {
  const [dataLine, setDataLine] = useState(null);

  useEffect(() => {
    const res = axios
      .post("/api/reportes/total_ventas_por_semana", {
        fecha: ["2023-01-01", "2023-05-31"],
        bonificadas: false,
      })
      .then((res) => {
        console.log(
          Object.keys(res.data.semanas).map((key) => [
            key,
            res.data.semanas[key],
          ])
        );
        setDataLine([
          ["Semana", ""],
          ...Object.keys(res.data.semanas)
            .sort((a, b) => {
              return a > b ? 1 : -1;
            })
            .map((key) => [key, res.data.semanas[key]]),
        ]);
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <h3 color="primary">Volumen de ventas</h3>

        <Chart
          chartType="PieChart"
          data={[
            ["Zona", "Cantidad"],
            ["DIS", 4000],
            ["MAY", 1800],
            ["SUP", 2500],
            ["MIN", 1500],
          ]}
          options={pieOptions}
          graph_id="PieChart"
          width={"100%"}
          height={"400px"}
          legend_toggle
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <h3 color="primary">Evolución compra ultimos meses</h3>
        {dataLine && (
          <Chart
            chartLanguage="es"
            chartType="LineChart"
            formatters={[
              {
                type: "NumberFormat",
                column: 1,
                options: {
                  prefix: "$",

                  negativeColor: "red",
                  negativeParens: true,
                },
              },
            ]}
            width="100%"
            height="400px"
            data={dataLine}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
