import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { filterGrupos } from '../../actions/grupos';

const GruposFilter = ({ grupos, filter, filtered, dispatch }) => {
  useEffect(() => {}, [filtered]);

  const onChange = e => {
    dispatch(filterGrupos(e.target.value));
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <TextField
        type='text'
        label='Filtrar grupos'
        margin='dense'
        variant='outlined'
        onChange={onChange}
        fullWidth
        value={filter}
        style={{ paddingBottom: '10px' }}
      />
    </form>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.grupos.filter,
    filtered: state.grupos.filtered,
    grupos: state.grupos
  };
};

export default connect(mapStateToProps)(GruposFilter);
