import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteCliente,
  filterClientes,
  getClientes,
} from "../../actions/clientes";
import { getZonas } from "../../actions/zonas";
import { canales, cCanales } from "../../config/constantes";
import subscribeTo from "../../utils/subscribeTo";
import AddClienteBtn from "../clientes/AddClienteBtn";
import ClienteForm from "../clientes/ClienteForm";
import PanelVentasFilter from "../panel_ventas/PanelVentasFilter";
import VentaForm from "../ventas/VentaForm";
import PanelVentasItem from "./PanelVentasItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",
    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  tableHeaderCell: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const PanelVentas = ({
  clientes,
  filter,
  filtered,
  loading,
  saving,
  dispatch,
  zonas,
  loading_zonas,
  error,
  clientes_dias,
  filter_panel_ventas,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getClientes());
    dispatch(getZonas());
    if (!loading) {
      subscribeTo("clientesChanged", (err, data) => {
        dispatch(getClientes());
        if (filter) dispatch(filterClientes(filter));
      });
      return () => {
        subscribeTo("clientesChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const [alert, setAlert] = useState("");

  const [sort, setSort] = useState(); //{ field: "st", reverse: true }

  const [contactosPendientes, setContactosPendientes] = React.useState([]);

  useEffect(() => {
    if (alert && !saving) {
      toast(<div dangerouslySetInnerHTML={{ __html: alert }}></div>, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setAlert("");
    }
  }, [alert, saving]);

  useEffect(() => {
    let tmpC = [];
    Object.keys(clientes_dias).map((dia) => {
      if (
        filter_panel_ventas.zonas &&
        filter_panel_ventas.zonas.length &&
        filter_panel_ventas.zonas.indexOf(dia) !== -1
      ) {
        tmpC = tmpC.concat(clientes_dias[dia]);
      }
    });

    setContactosPendientes(
      tmpC
        .sort((a, b) => {
          if (a.clasificacion_vol < b.clasificacion_vol) return -1;
          if (a.clasificacion_vol > b.clasificacion_vol) return 1;
          return 0;
        })
        .sort((a, b) => {
          if (a.indicador_vol < b.indicador_vol) return -1;
          if (a.indicador_vol > b.indicador_vol) return 1;
          return 0;
        })

        .sort(function (a, b) {
          let af = a.fijar || 0;
          let bf = b.fijar || 0;
          return af === bf ? 0 : af ? -1 : 1;
        })
        .filter((c) => c.clasificacion_vol !== "I" || c.fijar)
        .concat(tmpC.filter((c) => c.clasificacion_vol === "I" && !c.fijar))
    );
  }, [clientes_dias, filter_panel_ventas]);

  const onClickSort = () => {
    let tmpArray = [...contactosPendientes].sort((a, b) => {
      if (a.semanas_ultima_compra < b.semanas_ultima_compra) return 1;
      if (a.semanas_ultima_compra > b.semanas_ultima_compra) return -1;
      return 0;
    });
    setContactosPendientes(tmpArray);
  };

  const onClickBorrar = (confirm, dispatch, cliente) => {
    confirm({
      description: `Desea borrar el cliente "${cliente.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => dispatch(deleteCliente(cliente.id)));
  };

  if (clientes !== null && !clientes.length && !loading) {
    return (
      <Fragment>
        <ClienteForm />
        <h4>No hay ningun cliente todavía!</h4> <AddClienteBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ClienteForm />
        <VentaForm panel="ventas" setAlert={setAlert} />
      </div>
      <div>
        {!loading_zonas && (
          <PanelVentasFilter zonas={zonas} canales={canales} />
        )}
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <div
          className={
            mobile
              ? classes.gridContainer
              : saving
              ? classes.gridContainerLoading
              : ""
          }
        >
          {clientes !== null && !loading ? (
            <Fragment>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Contactos Pendientes
                  </Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanel}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableHeader}>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "nombre",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            Cliente
                          </TableCell>
                          <TableCell align="center">Contacto</TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "balance",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            Saldo Pendiente
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "clasificacion_vol",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            Clasificación
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "indicador_vol",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            Volumen
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "semanas_ultima_compra",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            Semanas
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "st",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            ST
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHeaderCell}
                            onClick={() =>
                              setSort({
                                field: "total_compras_ultimo_ano",
                                reverse: sort && !sort.reverse,
                              })
                            }
                          >
                            FC
                          </TableCell>
                          <TableCell align="center" width="15%">
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactosPendientes
                          .map((cliente) => {
                            let indicador_vol = 0;
                            let promedio_8w = 0;
                            if (cliente.total_items_8w) {
                              promedio_8w = cliente.total_items_8w / 2;
                            }

                            if (cliente.total_items_4w && promedio_8w) {
                              indicador_vol =
                                cliente.total_items_4w / promedio_8w;
                            }
                            return {
                              ...cliente,
                              indicador_vol,
                            };
                          })
                          .sort((a, b) => {
                            if (!sort) return 0;
                            let res = 0;
                            if (sort.field === "st") {
                              let st_a =
                                a.total_items_ultima_semana -
                                a.semanas_ultima_compra *
                                  a.consumo_semanal_estimado;
                              let st_b =
                                b.total_items_ultima_semana -
                                b.semanas_ultima_compra *
                                  b.consumo_semanal_estimado;
                              if (st_a < st_b) res = -1;
                              if (st_b < st_a) res = 1;
                            } else if (sort.field === "nombre") {
                              const nombre_a = a.grupo
                                ? a.grupo.nombre
                                : a.nombre;
                              const nombre_b = b.grupo
                                ? b.grupo.nombre
                                : b.nombre;

                              if (nombre_a < nombre_b) res = -1;
                              if (nombre_a > nombre_b) res = 1;
                            } else if (sort.field === "balance") {
                              const balance_a =
                                (a.balance_a ? parseFloat(a.balance_a) : 0) +
                                (a.balance_x ? parseFloat(a.balance_x) : 0);
                              const balance_b =
                                (b.balance_a ? parseFloat(b.balance_a) : 0) +
                                (b.balance_x ? parseFloat(b.balance_x) : 0);
                              if (balance_a < balance_b) res = -1;
                              if (balance_a > balance_b) res = 1;
                            } else {
                              let sortField = sort.field;
                              if (a[sortField] < b[sortField]) res = -1;
                              if (a[sortField] > b[sortField]) res = 1;
                            }

                            return sort.reverse ? -res : res;
                          })

                          .map(
                            (cliente, index) =>
                              (!cliente.comunicacionesSemana ||
                                cliente.comunicacionesSemana.length === 0) && (
                                <PanelVentasItem
                                  cliente={cliente}
                                  classes={classes}
                                  key={cliente.id + "A"}
                                />
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Contactos Realizados
                  </Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanel}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableHeader}>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Contacto</TableCell>
                          <TableCell align="center">Saldo Pendiente</TableCell>
                          <TableCell align="center">Clasificación</TableCell>
                          <TableCell align="center">Volumen</TableCell>
                          <TableCell align="center">Semanas</TableCell>
                          <TableCell align="center">ST</TableCell>
                          <TableCell align="center">FC</TableCell>
                          <TableCell align="center" width="15%">
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactosPendientes
                          .map((c) => ({
                            ...c,
                            pos:
                              c.comunicacionesSemana &&
                              c.comunicacionesSemana.find(
                                (cont) =>
                                  cont.cliente_tipo_comunicacion_id === 1
                              )
                                ? 2
                                : c.comunicacionesSemana &&
                                  c.comunicacionesSemana.find(
                                    (cont) =>
                                      cont.cliente_tipo_comunicacion_id === 3
                                  )
                                ? 3
                                : 1,
                          }))
                          .sort((a, b) => a.pos - b.pos)
                          .map(
                            (cliente, index) =>
                              cliente.comunicacionesSemana &&
                              cliente.comunicacionesSemana.length > 0 && (
                                <PanelVentasItem
                                  cliente={cliente}
                                  classes={classes}
                                  key={cliente.id + "B"}
                                />
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <ToastContainer />
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    clientes_dias: state.clientes.clientes_dias,
    filtered: state.clientes.filtered,
    filter: state.clientes.filter,
    loading: state.clientes.loading,
    saving: state.clientes.saving,
    error: state.clientes.error,
    zonas: state.zonas.zonas,
    loading_zonas: state.zonas.loading,
    filter_panel_ventas: state.clientes.filter_panel_ventas,
  };
};

export default connect(mapStateToProps)(PanelVentas);
