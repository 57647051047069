const tiposComprobante = [
  "Factura A",
  "Factura B",
  "Factura X",
  "Nota de Credito A",
  "Nota de Credito B",
  "Nota de Credito X",
];

const tiposComprobanteGastos = [
  "Factura A",
  "Factura B",
  "Factura X",
  "Nota de Credito A",
  "Nota de Credito B",
  "Nota de Credito C",
  "Nota de Credito X",
];

const tiposFormasDePago = [
  { id: 1, nombre: "Efectivo" },
  { id: 2, nombre: "Transferencia" },
  { id: 3, nombre: "Cheque" },
  { id: 4, nombre: "Cheque electrónico" },
];

const tiposCobranzas = ["Efectivo contraentrega", "No cobrar", "Cheque"];

const cTiposCobranzasCobro = {
  1: "Efectivo",
  2: "Transferencia",
  4: "Cheque",
};

const cTiposFormasDePago = {
  Efectivo: 1,
  Transferencia: 2,
  Cheque: 3,
  "Cheque electrónico": 4,
  "No Paga": 5,
};

const cTiposCajas = {
  "Efectivo en Pesos": 1,
  "Efectivo en Dólares": 2,
  "Cuenta corriente en pesos": 3,
  "Cheque terceros": 4,
  Cheque: 5,
  Ajuste: 6,
};

const cProductos = [
  {
    id: 1,
    nombre: "Indian",
    cod: "256",
  },
  {
    id: 2,
    nombre: "Espinaca",
    cod: "294",
  },
  {
    id: 3,
    nombre: "Lentil",
    cod: "263",
  },
  {
    id: 4,
    nombre: "Criolla",
    cod: "270",
  },
  {
    id: 5,
    nombre: "Brocoli",
    cod: "287",
  },
  {
    id: 7,
    nombre: "Mix de hongos ",
    cod: "131",
  },
  {
    id: 8,
    nombre: "Mila CLASICA sTACC FlowPack 2un",
    cod: "110",
  },
  {
    id: 9,
    nombre: "Mila ESPINACA sTACC FlowPack 2un",
    cod: "127",
  },
];

const tiposComprobanteCliente = [
  "Factura A",
  "Factura B",
  "Factura X",
  "Factura E",
];
/*
const condicionIva = {
  RI: "Responsable Inscripto",
  MO: "Monotributista",
  CF: "Consumidor Final",
  EX: "Exento de IVA"
};*/

const condicionIva = [
  { id: 1, nombre: "Responsable Inscripto" },
  { id: 2, nombre: "Monotributista" },
  { id: 3, nombre: "Consumidor Final" },
  { id: 4, nombre: "Exento de IVA" },
];

const estados = ["Pendiente", "Aprobada", "Facturada", "Entregada"];

const cIdProductoOtros = 6;

const cEstados = {
  Pendiente: 1,
  Aprobada: 2,
  Facturada: 3,
  Entregada: 4,
};
const cTiposComprobantes = {
  "Factura A": 1,
  "Factura B": 2,
  "Factura E": 7,
  "Factura X": 3,
  "Nota de Credito A": 4,
  "Nota de Credito B": 5,
  "Nota de Credito E": 8,
  "Nota de Credito X": 6,
  "Pago a Cuenta": 9,
  "Pago a Cuenta X": 10,
  "Nota de Débito A": 11,
  "Nota de Débito B": 12,
  "Nota de Débito X": 13,
  Facturas: [1, 2, 3, 7],
};

const cTiposTelefonos = { 1: "Whatsapp", 2: "Llamada", 3: "E-mail" };

const cProvincias = [
  { id: 1, nombre: "Buenos Aires" },
  { id: 2, nombre: "Capital Federal" },
  { id: 3, nombre: "Catamarca" },
  { id: 4, nombre: "Chaco" },
  { id: 5, nombre: "Chubut" },
  { id: 6, nombre: "Córdoba" },
  { id: 7, nombre: "Corrientes" },
  { id: 8, nombre: "Entre Ríos" },
  { id: 9, nombre: "Formosa" },
  { id: 10, nombre: "Jujuy" },
  { id: 11, nombre: "La Pampa" },
  { id: 12, nombre: "La Rioja" },
  { id: 13, nombre: "Mendoza" },
  { id: 14, nombre: "Misiones" },
  { id: 15, nombre: "Neuquén" },
  { id: 16, nombre: "Río Negro" },
  { id: 17, nombre: "Salta" },
  { id: 18, nombre: "San Juan" },
  { id: 19, nombre: "San Luis" },
  { id: 20, nombre: "Santa Cruz" },
  { id: 21, nombre: "Santa Fe" },
  { id: 22, nombre: "Santiago del Estero" },
  { id: 23, nombre: "Tierra del Fuego" },
  { id: 24, nombre: "Tucumán" },
];

const cCanales = {
  1: "MIN",
  2: "MAY",
  3: "DIS",
  4: "S",
  5: "Petit May",
  6: "SPMI",
  7: "GASTRO",
};

const TIPO_CONTACTO_FACTURACION = 6;

const cEstadosCheques = [
  { id: 1, nombre: "En cartera" },
  { id: 2, nombre: "Depositado" },
  { id: 3, nombre: "Acreditado" },
  { id: 4, nombre: "Rechazado" },
  { id: 5, nombre: "Utilizado" },
  { id: 6, nombre: "Vencido" },
];

const cEstadosChequesObj = {
  "En cartera": 1,
  Depositado: 2,
  Acreditado: 3,
  Rechazado: 4,
  Utilizado: 5,
  Vencido: 6,
};

const cHabilitaciones = [
  { id: 1, nombre: "HABILITACION SAN ISIDRO" },
  { id: 2, nombre: "HABILITACION SAN FERNANDO" },
  { id: 3, nombre: "HABILITACION ESCOBAR" },
  { id: 4, nombre: "HABILITACION MALVINAS ARGENTINAS" },
  { id: 5, nombre: "HABILITACION AVELLANEDA" },
  { id: 6, nombre: "HABILITACION MORON" },
];

const esAjuste = (caja_id) => {
  return caja_id === 11 || caja_id === 12;
};

const cValorRgIVA = 100000; //100000;

module.exports = {
  condicionIva,
  cEstados,
  cTiposComprobantes,
  cTiposTelefonos,
  cProvincias,
  cCanales,
  TIPO_CONTACTO_FACTURACION,
  cTiposCobranzasCobro,
  cTiposFormasDePago,
  cEstadosCheques,
  cProductos,
  cIdProductoOtros,
  cHabilitaciones,
  cTiposCajas,
  cEstadosChequesObj,
  esAjuste,
  cValorRgIVA,
};
