import escapeRegExp from "escape-string-regexp";
import {
  ADD_CONTACT,
  DELETE_CONTACT,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_CONTACT,
  FILTER_CONTACTS,
  CLEAR_FILTER,
  CONTACT_ERROR,
  GET_CONTACTS,
  CLEAR_CONTACTS,
  SET_OPEN,
  SET_TIME,
} from "../actions/types";

const initialState = {
  transportistas: null,
  current: null,
  filtered: null,
  filter: "",
  error: null,
  loading: true,
  tiempos: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        transportistas: action.payload,
        loading: false,
      };
    case SET_TIME:
      return {
        ...state,
        tiempos: action.payload,
        loading: false,
      };
    case ADD_CONTACT:
      return {
        ...state,
        transportistas: [action.payload, ...state.transportistas],
        loading: false,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        transportistas: state.transportistas.map((transportista) =>
          transportista.id === action.payload.id
            ? action.payload
            : transportista
        ),
      };
    case DELETE_CONTACT:
      return {
        ...state,
        transportistas: state.transportistas.filter(
          (transportista) => transportista.id !== action.payload
        ),
        loading: false,
      };
    case CLEAR_CONTACTS:
      return {
        ...state,
        transportistas: null,
        error: null,
        current: null,
        loading: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };

    case FILTER_CONTACTS:
      return {
        ...state,
        filter: action.payload,
        filtered: state.transportistas.filter((transportista) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          let found = false;
          transportista.destinos.forEach((destino) => {
            found =
              found ||
              (destino.direccion &&
                destino.direccion.direccion &&
                destino.direccion.direccion.match(regex));
          });
          if (found) return true;
          return (
            transportista.nombre.match(regex) ||
            (transportista.direccion &&
              transportista.direccion.direccion &&
              transportista.direccion.direccion.match(regex))
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
