import axios from 'axios';

import { GET_LANZAMIENTOS_DE_CAJA, ERROR_LANZAMIENTOS_DE_CAJA } from './types';

export const getLanzamientosDeCaja = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/lanzamientosDeCaja');
      dispatch({ type: GET_LANZAMIENTOS_DE_CAJA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_LANZAMIENTOS_DE_CAJA, payload: error.msg });
    }
  };
};
