import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { Fragment, useContext, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect, useDispatch } from "react-redux";
import {
  clearPuntoLogisticoError,
  deletePuntoLogistico,
  getPuntosLogisticos,
  setCurrentPuntoLogistico,
} from "../../actions/ventas";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import { getClientes } from "../../actions/clientes";
import { provincias } from "../../config/constantes";
import AlertContext from "../../context/alert/alertContext";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",
    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
    height: "52px",
  },
  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#CCCC00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },

  disapproveButton: {
    cursor: "pointer",
    color: "grey",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  factura: {
    borderRadius: "20px",
    border: "1px solid rgba(0,255,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(0,255,0,0.1)",
    margin: "auto",
  },
  notaCredito: {
    borderRadius: "20px",
    border: "1px solid rgba(255,0,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,0,0,0.1)",
    margin: "auto",
  },
  facturaX: {
    borderRadius: "20px",
    border: "1px solid rgba(255,255,0,0.9)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,255,0,0.1)",
    margin: "auto",
  },
  pendingClientSelect: {
    width: "20%",
  },
}));
const PLogisticoTable = ({
  puntos_logisticos,
  loading,
  clientes,
  error_punto_logistico,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const alertContext = useContext(AlertContext);
  useEffect(() => {
    dispatch(getPuntosLogisticos());
    dispatch(getClientes());
  }, [dispatch, loading]);

  const onClickBorrar = (punto_logistico) => {
    confirm({
      description: `Desea borrar este punto logistico de "${
        punto_logistico.cliente_id
          ? clientes.find((p) => p.id === punto_logistico.cliente_id).nombre
          : punto_logistico.nombre
      }"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => {
      dispatch(deletePuntoLogistico(punto_logistico.id)).then(() => {
        !error_punto_logistico
          ? alertContext.setAlert("Punto Logistico eliminado!", "success")
          : alertContext.setAlert(error_punto_logistico.toString(), "error");

        dispatch(getPuntosLogisticos());
        dispatch(clearPuntoLogisticoError());
      });
    });
  };

  const acciones = (punto_logistico) => {
    return (
      <Fragment>
        <Fragment>
          <span
            title="Editar"
            className={classes.editButton}
            onClick={(e) => dispatch(setCurrentPuntoLogistico(punto_logistico))}
          >
            <Icon className={classes.listIcon}>edit</Icon>
          </span>
          {
            <span
              title="Borrar"
              className={classes.deleteButton}
              onClick={(e) => {
                onClickBorrar(punto_logistico);
              }}
            >
              <Icon fontSize="large" className={classes.listIcon}>
                delete
              </Icon>
            </span>
          }
        </Fragment>
      </Fragment>
    );
  };

  const tableCells = (punto_logistico) => {
    return (
      <Fragment>
        <TableCell align="center">{punto_logistico.id}</TableCell>
        <TableCell align="center">
          {moment(punto_logistico.fecha_salida).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align="center">{punto_logistico.hora_desde}</TableCell>
        <TableCell align="center">{punto_logistico.hora_hasta}</TableCell>
        <TableCell align="center">{punto_logistico.motivo}</TableCell>
        <TableCell width={"20%"} align="center">
          {punto_logistico.nombre}
        </TableCell>
        <TableCell align="center">{punto_logistico.direccion}</TableCell>
        <TableCell align="center">{punto_logistico.localidad}</TableCell>
        <TableCell align="center">
          {punto_logistico.provincia_id &&
          provincias.find((p) => p.id === punto_logistico.provincia_id)
            ? provincias.find((p) => p.id === punto_logistico.provincia_id)
                .nombre
            : "NO PROVINCIA"}
        </TableCell>
        <TableCell align="center">
          {punto_logistico.entrega === 1 ? (
            <Icon fontSize="large" className={classes.listIcon}>
              check
            </Icon>
          ) : (
            <Icon fontSize="large" className={classes.listIcon}>
              close
            </Icon>
          )}
        </TableCell>
        <TableCell align="center">{punto_logistico.bultos}</TableCell>
        <TableCell id="acciones" align="center">
          {acciones(punto_logistico)}
        </TableCell>
      </Fragment>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow width="3%" height="52px" className={classes.tableHeader}>
            <TableCell align="center">ID</TableCell>
            <TableCell width="10%" align="center">
              Fecha Salida
            </TableCell>
            <TableCell align="center">Hora Desde</TableCell>
            <TableCell align="center">Hora Hasta</TableCell>
            <TableCell width="7%" align="center">
              Motivo
            </TableCell>
            <TableCell align="center">Nombre</TableCell>
            <TableCell align="center">Direccion</TableCell>
            <TableCell align="center">Localidad</TableCell>
            <TableCell align="center">Provincia</TableCell>
            <TableCell align="center">Entrega </TableCell>
            <TableCell align="center">Bultos </TableCell>
            <TableCell width="15%" align="center">
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody height={"100%"}>
          {puntos_logisticos &&
            puntos_logisticos.map((punt, index) => (
              <Fragment key={punt.id}>
                <TableRow className={classes.tableRow} key={index}>
                  {tableCells(punt)}
                </TableRow>
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    puntos_logisticos: state.ventas.puntos_logisticos,
    loading: state.ventas.loading,
    clientes: state.clientes.clientes,
    error_punto_logistico: state.ventas.error_punto_logistico,
  };
};

export default connect(mapStateToProps)(PLogisticoTable);
