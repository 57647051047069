import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  TextField,
  FormControlLabel,
  Typography,
} from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import ReactSelectNonCreate from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { getGrupos } from "../../actions/grupos";
import {
  deleteCobranza,
  getCobranzas,
  setCurrent,
  setCurrentCobranza,
} from "../../actions/cobranzas";
import { getVentasParaCobrar, setCurrentVenta } from "../../actions/ventas";
import { getUsuarios } from "../../actions/usuarios";
import { getClientes } from "../../actions/clientes";
import AlertContext from "../../context/alert/alertContext";
import subscribeTo from "../../utils/subscribeTo";
import { getIsAdmin, getRoleCanDo } from "../usuarios/Usuario";
import AddCobranzaBtn from "./AddCobranzaBtn";
import CobranzaForm, { emptyCobranza } from "./CobranzaForm";
import CobranzasFilter from "./CobranzasFilter";
import NumberFormat from "react-number-format";
import VentaForm from "../ventas/VentaForm";
import { esX } from "../../utils/functions";
import BigNumber from "bignumber.js";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",
    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
  tableCell: {
    padding: "11px",
  },

  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  moneyButton: {
    cursor: "pointer",
    color: "green",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  comprobanteButton: {
    cursor: "pointer",
    color: "#00FF00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#CCCC00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },

  disapproveButton: {
    cursor: "pointer",
    color: "grey",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
}));

const Cobranzas = ({
  dispatch,
  loading,
  cobranzas,
  usuarios,
  grupos,
  ventas_para_cobrar,
  clientes,
  loading_clientes,
  loading_grupos,
}) => {
  const confirm = useConfirm();

  const [filterOpen, setFilterOpen] = useState(false);
  const [ultimaRendicion, setUltimaRendicion] = useState(null);
  const alertContext = useContext(AlertContext);

  const [dropdownClientes, setDropdownClientes] = useState([]);

  const [filters, setFilters] = useState({
    cliente: null,
    fechaRendicion: [
      moment().subtract(1, "week").toDate().toISOString(),
      moment().toDate().toISOString(),
    ],
  });

  const [ruta, setRuta] = useState(null);

  useEffect(() => {
    dispatch(getCobranzas(filters));
    dispatch(getVentasParaCobrar());
    dispatch(getUsuarios());
    dispatch(getGrupos());
    dispatch(getClientes());
    if (!loading) {
      subscribeTo("cobranzasChanged", (err, data) => {
        dispatch(getCobranzas(filters));
        dispatch(getVentasParaCobrar());
      });

      return () => {
        subscribeTo("cobranzasChanged", null);
      };
    }
  }, [dispatch, loading]);
  const classes = useStyles();

  const onClickBorrar = (confirm, dispatch, cobranza) => {
    confirm({
      description: `Desea borrar este cobranza de "${
        cobranza.cliente
          ? cobranza.cliente.nombre
          : cobranza.grupo
          ? cobranza.grupo.nombre
          : "BORRADO"
      }"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => dispatch(deleteCobranza(cobranza.id)));
  };

  useEffect(() => {
    if (!loading_clientes && !loading_grupos) {
      setDropdownClientes(
        clientes
          .filter((c) => !c.grupo || c.grupo.cuenta_corriente_propia)
          //.concat(clientes.filter((c) => c.grupo && !c.grupo.cuenta_corriente_propia))
          .map((cliente) => ({
            value: cliente.id,
            label: cliente.nombre,
          }))
          .concat(
            grupos
              .filter((g) => !g.cuenta_corriente_propia)
              .map((g) => ({
                value: "g" + g.id,
                label: g.nombre,
              }))
          )
          .sort((a, b) => a.label > b.label)
      );
    }
  }, [loading_clientes, loading_grupos]);

  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  let openFilter = false;

  return (
    <Fragment>
      <div>
        <VentaForm />
        <CobranzaForm
          ultimaRendicion={ultimaRendicion}
          setUltimaRendicion={(id) => {
            setUltimaRendicion(id);
          }}
        />
      </div>

      <div>
        <div className={classes.root}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<Icon>expand_more</Icon>}
              id="panel1a-header"
              aria-controls="panel1a-content"
              className={classes.summary}
            >
              <Typography className={classes.heading}>
                Clientes a cobrar
              </Typography>
              <Typography className={classes.secondaryHeading}>
                <Button
                  color="primary"
                  variant={!ruta ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRuta(null);
                  }}
                >
                  Todas
                </Button>{" "}
                <Button
                  color="primary"
                  variant={ruta === 1 ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRuta(1);
                  }}
                >
                  R 1
                </Button>{" "}
                <Button
                  color="primary"
                  variant={ruta === 2 ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRuta(2);
                  }}
                >
                  R 2
                </Button>{" "}
                <Button
                  color="primary"
                  variant={ruta === 3 ? "contained" : "outlined"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRuta(3);
                  }}
                >
                  R 3
                </Button>{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanel}>
              {ventas_para_cobrar && ventas_para_cobrar.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow width="3%" className={classes.tableHeader}>
                        <TableCell width="5%" align="center">
                          Cod
                        </TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell width="8%" align="center">
                          Saldo
                        </TableCell>
                        <TableCell width="5%" align="center">
                          Ruta
                        </TableCell>
                        <TableCell align="center">Observaciones</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ventas_para_cobrar &&
                        ventas_para_cobrar.length &&
                        ventas_para_cobrar
                          .filter((v) => !ruta || ruta === v.camioneta)
                          .sort(
                            (a, b) =>
                              a.camioneta - b.camioneta || a.orden - b.orden
                          )
                          .map((venta, index) => (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell
                                className={classes.tableCell}
                                width="5%"
                                align="center"
                              >
                                {venta.id}
                              </TableCell>
                              <TableCell align="center">
                                {venta.cliente
                                  ? venta.cliente.nombre
                                  : venta.grupo.nombre}
                              </TableCell>
                              <TableCell width="8%" align="center">
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  mask=""
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  margin="dense"
                                  name="horaAtencion2Fin"
                                  label="Fin Tarde"
                                  value={venta.balance}
                                  variant="outlined"
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell width="5%" align="center">
                                {venta.camioneta || "X"}
                              </TableCell>
                              <TableCell align="center">
                                {venta.notas}
                              </TableCell>
                              <TableCell align="center">
                                <span
                                  title="Ver Venta"
                                  className={classes.editButton}
                                  onClick={(e) =>
                                    dispatch(setCurrentVenta(venta))
                                  }
                                >
                                  <Icon className={classes.listIcon}>
                                    visibility
                                  </Icon>
                                </span>
                                <span
                                  title="Nueva cobranza"
                                  className={classes.facturarButton}
                                  onClick={(e) =>
                                    dispatch(
                                      setCurrentCobranza({
                                        ...emptyCobranza(),
                                        cliente_id:
                                          !venta.cliente.grupo ||
                                          venta.cliente.grupo
                                            .cuenta_corriente_propia
                                            ? venta.cliente_id
                                            : "g" + venta.cliente.grupo.id,
                                        n_ruta: venta.camioneta,
                                        cobranza_x: esX(
                                          venta.tipo_comprobante_id
                                        ),
                                        cliente:
                                          !venta.cliente.grupo ||
                                          venta.cliente.grupo
                                            .cuenta_corriente_propia
                                            ? venta.cliente
                                            : grupos.find(
                                                (g) =>
                                                  g.id ===
                                                  venta.cliente.grupo.id
                                              ),
                                        ...(!venta.saldada && {
                                          ventas: [
                                            {
                                              venta_id: venta.id,
                                              importe: venta.balance,
                                            },
                                          ],
                                        }),
                                      })
                                    )
                                  }
                                >
                                  <Icon className={classes.listIcon}>
                                    add_circle
                                  </Icon>
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                "Ningún cliente para cobrar hoy"
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<Icon>filter_list</Icon>}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>Cobranzas</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelFilter}>
          {clientes && clientes.length > 0 && (
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "8px",
                  }}
                >
                  <ReactSelectNonCreate
                    fullWidth
                    value={
                      filters.cliente &&
                      dropdownClientes.find((p) => p.value === filters.cliente)
                        ? {
                            label: dropdownClientes.find(
                              (p) => p.value === filters.cliente
                            ).label,
                            value: filters.cliente,
                          }
                        : ""
                    }
                    placeholder="Seleccionar Cliente/Grupo... *"
                    name="cliente"
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        marginTop: "-8px",
                      }),
                    }}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    formatCreateLabel={(value) => "Nuevo cliente: " + value}
                    onChange={(e) =>
                      setFilters({ ...filters, cliente: e ? e.value : null })
                    }
                    options={dropdownClientes}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                Fecha de Cobranza:{" "}
                <DateRangePicker
                  value={filters.fechaRendicion}
                  onChange={(ranges) =>
                    setFilters({
                      ...filters,
                      fechaRendicion: ranges,
                    })
                  }
                  locale="es-AR"
                />
              </Grid>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={1}>
                <div
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    onClick={(e) => dispatch(getCobranzas(filters))}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <CobranzaForm
        ultimaRendicion={ultimaRendicion}
        setUltimaRendicion={(id) => {
          setUltimaRendicion(id);
        }}
      />
      {cobranzas && cobranzas.length > 0 && usuarios.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow width="3%" className={classes.tableHeader}>
                <TableCell width="5%" align="center">
                  Cod
                </TableCell>
                <TableCell width="8%" align="center">
                  Rendicion
                </TableCell>
                <TableCell align="center">Cliente</TableCell>
                <TableCell align="center">Total cobrado</TableCell>
                <TableCell align="center">Observaciones</TableCell>

                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cobranzas &&
                cobranzas.length &&
                cobranzas.map((cobranza, index) => (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell
                      className={classes.tableCell}
                      width="7%"
                      align="center"
                    >
                      {cobranza.id}
                    </TableCell>
                    <TableCell width="8%" align="center">
                      {moment(cobranza.rendicion.fecha_cobranza).format(
                        "YYMMDD"
                      ) +
                        usuarios
                          .find((u) => u.id === cobranza.rendicion.rendidor_id)
                          .nombre.toUpperCase() +
                        cobranza.rendicion.n_ruta}
                    </TableCell>
                    <TableCell align="center">
                      {cobranza.cliente
                        ? cobranza.cliente.nombre
                        : cobranza.grupo.nombre}
                    </TableCell>
                    <TableCell align="center">
                      <NumberFormat
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        prefix="$"
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={
                          cobranza.formas_cobro.length > 0
                            ? cobranza.formas_cobro.reduce((a, b) => ({
                                importe: BigNumber(a.importe)
                                  .plus(BigNumber(b.importe))
                                  .toNumber(),
                              })).importe
                            : 0
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      {cobranza.observaciones}
                    </TableCell>

                    <TableCell align="center">
                      <span
                        title="Comprobante saldo"
                        className={classes.moneyButton}
                        onClick={(e) => {
                          let w = window.open(
                            "/api/comprobanteSaldo/pdf/" +
                              (cobranza.cliente ? cobranza.cliente.id : "")
                          );
                        }}
                      >
                        <Icon className={classes.listIcon}>attach_money</Icon>
                      </span>
                      {cobranza.formas_cobro &&
                        cobranza.formas_cobro.filter(
                          (fc) => fc.caja_id !== 12 && fc.caja_id !== 11
                        ).length > 0 && (
                          <span
                            title="Comprobantes de Cobranza"
                            className={classes.comprobanteButton}
                            onClick={(e) => {
                              let w = window.open(
                                "/api/comprobanteCobranza/pdf/" + cobranza.id
                              );
                            }}
                          >
                            <Icon className={classes.listIcon}>receipt</Icon>
                          </span>
                        )}
                      {getRoleCanDo() && (
                        <span
                          title="Editar"
                          className={classes.editButton}
                          onClick={(e) =>
                            dispatch(
                              setCurrentCobranza({
                                ...cobranza,
                                cobranza_x: esX(
                                  cobranza.ventas[0].tipo_comprobante_id
                                ),
                                ...(cobranza.grupo_id && {
                                  cliente: cobranza.grupo,
                                  cliente_id: "g" + cobranza.grupo_id,
                                }),
                              })
                            )
                          }
                        >
                          <Icon className={classes.listIcon}>edit</Icon>
                        </span>
                      )}
                      {getRoleCanDo() && (
                        <span
                          title="Borrar"
                          className={classes.deleteButton}
                          onClick={(e) =>
                            onClickBorrar(confirm, dispatch, cobranza)
                          }
                        >
                          <Icon fontSize="large" className={classes.listIcon}>
                            delete
                          </Icon>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddCobranzaBtn />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuarios: state.usuarios.usuarios,
    cobranzas: state.cobranzas.cobranzas,
    filtered: state.cobranzas.filtered,
    loading: state.cobranzas.loading,
    grupos: state.grupos.grupos,
    ventas_para_cobrar: state.ventas.ventas_para_cobrar,
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    loading_grupos: state.grupos.loading,
  };
};

export default connect(mapStateToProps)(Cobranzas);
