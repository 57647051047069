import React, { Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import { tiposTelefonos, clienteTiposContacto } from "../../config/constantes";

const TelefonosInput = (props) => {
  return (
    <Fragment>
      <Grid
        container
        className={props.classes.root}
        spacing={2}
        style={{ borderBottom: "1px solid lightGray" }}
      >
        {!props.soloTelefono && (
          <Fragment>
            <Grid item xs={12} md={2}>
              <FormControl
                variant="outlined"
                style={{
                  height: "40px",
                  width: "100%",
                  paddingTop: "0px"
                }}
              >
                <InputLabel
                  htmlFor={`telefonos[${props.index}].tipos_contacto`}
                >
                  Tipo de contacto
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      name={`tipos_contacto${props.index}`}
                      id={`telefonos[${props.index}].tipos_contacto`}
                      style={{
                        height: "40px",
                        marginTop: "8px",
                        width: "100%"
                      }}
                    />
                  }
                  multiple
                  value={
                    (props.telefono &&
                      props.telefono.tipos_contacto &&
                      props.telefono.tipos_contacto.map((tc) => tc.id)) ||
                    []
                  }
                  onChange={(e) =>
                    props.onChange(e, props.index, "tipos_contacto")
                  }
                  placeholder="Cargo"
                  autoWidth={true}
                >
                  {clienteTiposContacto.map((tc) => (
                    <MenuItem value={tc.id} key={tc.id}>
                      {tc.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                margin="dense"
                label="Nombre"
                id={`nombre${props.index}`}
                name={`telefonos[${props.index}].nombre` || ""}
                value={props.telefono && props.telefono.nombre}
                onChange={(e) => props.onChange(e, props.index, "nombre")}
                className={props.classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                margin="dense"
                label="Apellido"
                id={`apellido${props.index}`}
                name={`telefonos[${props.index}].apellido` || ""}
                value={props.telefono && props.telefono.apellido}
                onChange={(e) => props.onChange(e, props.index, "apellido")}
                className={props.classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                label="E-mail"
                id={`email${props.index}`}
                name={`telefonos[${props.index}].descripcion` || ""}
                value={props.telefono && props.telefono.email}
                onChange={(e) => props.onChange(e, props.index, "email")}
                className={props.classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} md={2}>
          <Button
            onClick={(e) => props.onDeleteTelefono(e, props.index)}
            size="small"
            color="secondary"
            style={{ marginTop: "10px" }}
            variant="contained"
            fullWidth
          >
            Borrar <DeleteOutlinedIcon />
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl
            variant="outlined"
            style={{
              height: "40px",
              width: "100%",
              paddingTop: "0px"
            }}
          >
            <InputLabel htmlFor={`telefonos[${props.index}].tipo_telefono_id`}>
              Forma de Contacto
            </InputLabel>
            <Select
              input={
                <OutlinedInput
                  name={`tipo${props.index}`}
                  id={`telefonos[${props.index}].tipo_telefono_id`}
                  style={{
                    height: "40px",
                    marginTop: "8px",
                    width: "100%"
                  }}
                />
              }
              value={props.telefono && props.telefono.tipo_telefono_id}
              onChange={(e) =>
                props.onChange(e, props.index, "tipo_telefono_id")
              }
              placeholder="Tipo"
              autoWidth={true}
            >
              {tiposTelefonos.map((t) => (
                <MenuItem value={t.id} key={t.id}>
                  {t.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <NumberFormat
            customInput={TextField}
            format="+54 ###########"
            mask=""
            margin="dense"
            label="Numero"
            id={`numero${props.index}`}
            name={`telefonos[${props.index}].numero` || ""}
            value={props.telefono && props.telefono.numero}
            onChange={(e) => props.onChange(e, props.index, "numero")}
            className={props.classes.textField}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            margin="dense"
            label="Nota"
            id={`descripcion${props.index}`}
            name={`telefonos[${props.index}].descripcion` || ""}
            value={props.telefono && props.telefono.descripcion}
            onChange={(e) => props.onChange(e, props.index, "descripcion")}
            className={props.classes.textField}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TelefonosInput;
