import { Snackbar } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
  addGrupo,
  clearCurrent,
  setOpen,
  updateGrupo,
} from "../../actions/grupos";
import { getListasPrecios } from "../../actions/listas_precios";
import { tiposFormasDePago } from "../../config/constantes";
import AlertContext from "../../context/alert/alertContext";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  btnAfip: {
    marginTop: "5px",
  },
}));

const emptyGrupo = {
  nombre: "",
  lista_precios_id: null,
  cuit: "",
  razon_social: "",
  plazo_pago: "",
  formas_pago: [],
  cuenta_corriente_propia: false,
  mi_pyme: false,
};

const GrupoForm = ({
  children,
  current,
  loading,
  listas_precios,
  grupos,
  transportistas,
  loading_transportistas,
  loading_listas_precios,
  open,
  dispatch,
  error,
  loading_afip,
}) => {
  const [grupo, setGrupo] = useState(emptyGrupo);

  const {
    nombre,
    lista_precios_id,
    cuit,
    razon_social,
    plazo_pago,
    formas_pago,
    cuenta_corriente_propia,
    mi_pyme,
  } = grupo;
  const alertContext = useContext(AlertContext);
  useEffect(() => {
    dispatch(getListasPrecios());

    if (current !== null) {
      setGrupo(current);
    } else {
      setGrupo(emptyGrupo);
    }
  }, [current, open]);

  const onChange = (e, special_field = null) => {
    if (special_field === "formas_pago") {
      setGrupo({
        ...grupo,
        formas_pago: e.target.value.map((fp) => ({ id: fp })),
      });
    } else setGrupo({ ...grupo, [e.target.name]: e.target.value });
  };

  const onValueChange = (values, field_name) => {
    setGrupo({ ...grupo, [field_name]: values.floatValue });
  };

  const onChangeCheck = (event, name) => {
    setGrupo({ ...grupo, [name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!nombre || !lista_precios_id || !cuit || !razon_social) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else {
      try {
        if (!current || !current.id) {
          await dispatch(addGrupo(grupo));
        } else {
          await dispatch(updateGrupo(grupo));
        }
      } catch (error) {
        alert(error);
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setGrupo(emptyGrupo);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="secondary">
        {!current ? "Nuevo grupo" : "Editar grupo"}
      </DialogTitle>

      <DialogContent dividers={true}>
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                name="nombre"
                label="Nombre *"
                value={nombre}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberFormat
                customInput={TextField}
                format="##-########-#"
                mask="_"
                decimalScale={0}
                allowNegative={false}
                margin="dense"
                name="cuit"
                label="CUIT *"
                value={cuit || ""}
                onValueChange={(e) => onValueChange(e, "cuit")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                name="razon_social"
                label="Razón Social *"
                value={razon_social}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            {!loading_listas_precios && (
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "0px",
                  }}
                >
                  <InputLabel htmlFor="select-lista-precios">
                    Lista de Precios *
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: "40px",
                          marginTop: "8px",
                          width: "100%",
                        }}
                        id="select-lista-precios"
                      />
                    }
                    fullWidth
                    value={lista_precios_id ? lista_precios_id : ""}
                    name="lista_precios_id"
                    onChange={(e) => onChange(e)}
                  >
                    {listas_precios.map((lista_precios) => (
                      <MenuItem value={lista_precios.id} key={lista_precios.id}>
                        {lista_precios.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                style={{
                  height: "40px",
                  width: "100%",
                  paddingTop: "0px",
                }}
              >
                <InputLabel htmlFor="select-forma-de-pago">
                  Forma de pago
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      style={{
                        height: "40px",
                        marginTop: "8px",
                        width: "100%",
                      }}
                      id="select-forma-de-pago"
                    />
                  }
                  fullWidth
                  value={formas_pago ? formas_pago.map((fp) => fp.id) : []}
                  name="formas_pago"
                  multiple
                  onChange={(e) => onChange(e, "formas_pago")}
                >
                  {tiposFormasDePago.map((tipo) => (
                    <MenuItem value={tipo.id} key={tipo.id}>
                      {tipo.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                style={{
                  height: "40px",
                  width: "100%",
                  paddingTop: "0px",
                }}
              >
                <InputLabel htmlFor="select-plazo-pago">
                  Plazo de Pago
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      style={{
                        height: "40px",
                        marginTop: "8px",
                        width: "100%",
                      }}
                      id="select-plazo-pago"
                    />
                  }
                  fullWidth
                  value={plazo_pago || plazo_pago === 0 ? plazo_pago : ""}
                  name="plazo_pago"
                  onChange={(e) => onChange(e)}
                >
                  <MenuItem value={0} key={0}>
                    Pronto Pago
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    1 día
                  </MenuItem>
                  {[7, 10, 14, 15, 25, 30, 45, 52, 60].map((d) => (
                    <MenuItem value={d} key={d}>
                      {d} días
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                value="especial"
                control={
                  <Checkbox
                    margin="dense"
                    name="cuenta_corriente_propia"
                    label="Cuenta Corriente Propia Sucursal"
                    value="1"
                    checked={cuenta_corriente_propia ? true : false}
                    onChange={(e) =>
                      onChangeCheck(e, "cuenta_corriente_propia")
                    }
                    className={classes.textField}
                    variant="outlined"
                  />
                }
                label="Cuenta Corriente Propia Sucursal"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                value="estado"
                control={
                  <Checkbox
                    margin="dense"
                    name="mi_pyme"
                    label="MiPyme"
                    value="1"
                    checked={mi_pyme ? true : false}
                    onChange={(e) => onChangeCheck(e, "mi_pyme")}
                    className={classes.textField}
                    variant="outlined"
                  />
                }
                label="Mi Pyme"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.grupos.current,
    grupos: state.grupos.grupos,
    loading: state.grupos.loading,
    error: state.grupos.error,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    transportistas: state.transportistas.transportistas,
    loading_transportistas: state.transportistas.loading,
    open: state.grupos.open,
    loading_afip: state.grupos.loading_afip,
  };
};

export default connect(mapStateToProps)(GrupoForm);
