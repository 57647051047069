import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import {
  ADD_CHEQUE,
  DELETE_CHEQUE,
  SET_CURRENT_CHEQUE,
  CLEAR_CURRENT_CHEQUE,
  UPDATE_CHEQUE,
  FILTER_CHEQUE,
  CLEAR_FILTER_CHEQUE,
  ERROR_CHEQUE,
  GET_CHEQUE,
  CLEAR_CHEQUE,
  SET_OPEN_CHEQUE,
  DEPOSITAR_MULTIPLES_CHEQUES,
} from "../actions/types";

const initialState = {
  cheques: null,
  current: null,
  filtered: null,
  filter: { estados: [1, 2] },
  error: null,
  loading: true,
  last_cheque_id: null,
  echeck: "",
};

const applyFilter = (cheques, filter) => {
  console.log("FILTER", filter);
  return cheques.filter((cheque) => {
    let show = true;
    if (filter.nombre) {
      const regex = new RegExp(`${escapeRegExp(filter.nombre)}`, "gi");
      show =
        cheque.razon_social.match(regex) || cheque.cliente.nombre.match(regex);
    }
    if (filter.numero) {
      const regex = new RegExp(`${escapeRegExp(filter.numero)}`, "gi");
      show =
        (show && cheque.numero.match(regex)) ||
        cheque.valor.toString().match(regex);
    }
    if (filter.estados) {
      show = show && filter.estados.indexOf(cheque.cheque_estado_id) !== -1;
    }
    if (filter.fechaEmision) {
      show =
        show &&
        cheque.fecha_emision >=
          moment(filter.fechaEmision[0]).format("YYYY-MM-DD") &&
        cheque.fecha_emision <=
          moment(filter.fechaEmision[1]).format("YYYY-MM-DD");
    }
    if (filter.fechaCobro) {
      show =
        show &&
        cheque.fecha >= moment(filter.fechaCobro[0]).format("YYYY-MM-DD") &&
        cheque.fecha <= moment(filter.fechaCobro[1]).format("YYYY-MM-DD");
    }
    if (filter.echeck && filter.echeck !== "-") {
      show =
        show &&
        ((filter.echeck === "Si" && cheque.echeck) ||
          (filter.echeck === "No" && !cheque.echeck));
    }
    return show;
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHEQUE:
      return {
        ...state,
        cheques: action.payload,
        filtered: applyFilter(action.payload, state.filter),
        loading: false,
      };
    case ADD_CHEQUE:
      return {
        ...state,
        cheques: [action.payload, ...state.cheques],
        last_cheque_id: action.payload.id,
        loading: false,
      };
    case UPDATE_CHEQUE:
      return {
        ...state,
        cheques: state.cheques.map((cheque) =>
          cheque.id === action.payload.id ? action.payload : cheque
        ),
      };
    case DELETE_CHEQUE:
      return {
        ...state,
        cheques: state.cheques.filter((cheque) => cheque.id !== action.payload),
        loading: false,
      };
    case CLEAR_CHEQUE:
      return {
        ...state,
        cheques: null,
        error: null,
        current: null,
        loading: null,
      };
    case SET_CURRENT_CHEQUE:
      return {
        ...state,
        current: action.payload,
      };
    case SET_OPEN_CHEQUE:
      return {
        ...state,
        open: action.payload,
      };
    case CLEAR_CURRENT_CHEQUE:
      return {
        ...state,
        current: null,
      };
    case DEPOSITAR_MULTIPLES_CHEQUES:
      return {
        ...state,
        current: null,
      };

    case FILTER_CHEQUE:
      return {
        ...state,
        filter: action.payload,
        filtered: applyFilter(state.cheques, action.payload),
      };
    case CLEAR_FILTER_CHEQUE:
      return {
        ...state,
        filtered: null,
      };
    case ERROR_CHEQUE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
