import escapeRegExp from "escape-string-regexp";
import {
  ADD_VENDEDOR,
  DELETE_VENDEDOR,
  SET_CURRENT_VENDEDOR,
  CLEAR_CURRENT_VENDEDOR,
  UPDATE_VENDEDOR,
  FILTER_VENDEDOR,
  CLEAR_FILTER_VENDEDOR,
  ERROR_VENDEDOR,
  GET_VENDEDOR,
  CLEAR_VENDEDOR,
  SET_OPEN_VENDEDOR
} from "../actions/types";

const initialState = {
  vendedores: null,
  current: null,
  filtered: null,
  filter: "",
  error: null,
  loading: true,
  ultimo: -1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDEDOR:
      return {
        ...state,
        vendedores: action.payload,
        loading: false
      };
    case ADD_VENDEDOR:
      return {
        ...state,
        vendedores: [...state.vendedores, action.payload],
        ultimo: action.payload.id,
        loading: false
      };
    case UPDATE_VENDEDOR:
      return {
        ...state,
        vendedores: state.vendedores.map((vendedor) =>
          vendedor.id === action.payload.id ? action.payload : vendedor
        )
      };
    case DELETE_VENDEDOR:
      return {
        ...state,
        vendedores: state.vendedores.filter(
          (vendedor) => vendedor.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_VENDEDOR:
      return {
        ...state,
        vendedores: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_VENDEDOR:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_VENDEDOR:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_VENDEDOR:
      return {
        ...state,
        current: null
      };

    case FILTER_VENDEDOR:
      return {
        ...state,
        filter: action.payload,
        filtered: state.vendedores.filter((vendedor) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          return (
            (vendedor.razon_social && vendedor.razon_social.match(regex)) ||
            (vendedor.nombre && vendedor.nombre.match(regex))
          );
        })
      };
    case CLEAR_FILTER_VENDEDOR:
      return {
        ...state,
        filtered: null
      };
    case ERROR_VENDEDOR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
