import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Alerts from "./components/layout/Alerts";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Gastos from "./components/gastos/Gastos";
import Cobranzas from "./components/cobranzas/Cobranzas";
import Ventas from "./components/ventas/Ventas";
import PanelVentas from "./components/panel_ventas/PanelVentas";
import Transportistas from "./components/transportistas/Transportistas";
import OptimoCamino from "./components/transportistas/OptimoCamino";
import Provedores from "./components/provedores/Provedores";
import Afip from "./components/pages/Afip";
import Cajas from "./components/cajas/Cajas";
import Cheques from "./components/cheques/Cheques";
import Productos from "./components/productos/Productos";
import ListasPrecios from "./components/listas_precios/ListasPrecios";
import ChequesPropios from "./components/cheques_propios/ChequesPropios";
import Clientes from "./components/clientes/Clientes";
import Grupos from "./components/grupos/Grupos";
import Zonas from "./components/zonas/Zonas";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";
import MainNavigation from "./components/layout/MainNavigation";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import configureStore from "./stores/configureStore";
import { Provider } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import { ConfirmProvider } from "material-ui-confirm";
import PanelAdministrativo from "./components/panel_administrativo/PanelAdministrativo";
import Vendedores from "./components/vendedores/Vendedores";
import Rendiciones from "./components/rendiciones/Rendiciones";
import SaldosClientes from "./components/cobranzas/SaldosClientes";
import PanelStock from "./components/stock/PanelStock";
import VentasEstimadas from "./components/stock/VentasEstimadas";
import AjustesStock from "./components/stock/AjustesStock";
import { getRoleCanDo } from "./components/usuarios/Usuario";
import VolumenVentas from "./components/reportes/VolumenVentas";
import TotalVentas from "./components/reportes/TotalVentas";
import Dashboard from "./components/pages/Dashboard";
import BuscadorDieteticas from "./components/buscador_dieteticas/BuscadorDieteticas";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const store = configureStore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: "-20px",
    padding: "20px",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: green[500],
      main: green[500],
      dark: green[900],
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "5px",
        fontSize: "0.8rem",

        border: "1px solid #f7f7f7",
        borderBottom: "1px solid #f7f7f7",
      },
    },
    MuiIcon: {
      root: {
        fontSize: "14px !important",
      },
    },
    MuiButton: {
      text: { padding: "4px 4px" },
    },
  },
  /* overrides: {
    MuiButton: {
      root: {
        fontWeight: 'bold',
        backgroundColor: green[500],
        margin: '10px',
        '&:hover': {
          backgroundColor: 'black'
        }
      },
      label: {
        color: 'white'
      }
    }
  }*/
});
const VolumenVentasBonificadas = (props) => (
  <VolumenVentas {...props} bonificadas />
);
const App = (props) => {
  const classes = useStyles();

  /*return (<Fragment>Modo de mantenimiento</Fragment>);*/
  return (
    <AuthState>
      <Provider store={store}>
        <AlertState>
          <Router>
            <Fragment>
              <Alerts />
              <ThemeProvider theme={theme}>
                <ConfirmProvider>
                  <div className={classes.root}>
                    <CssBaseline />
                    <MainNavigation />
                    <main className={classes.content}>
                      <div className={classes.toolbar} />
                      <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute
                          exact
                          path="/transportistas"
                          component={Transportistas}
                        />
                        <PrivateRoute
                          exact
                          path="/optimo_camino"
                          component={OptimoCamino}
                        />
                        <PrivateRoute exact path="/gastos" component={Gastos} />
                        <PrivateRoute
                          exact
                          path="/panel_ventas"
                          component={PanelVentas}
                        />
                        <PrivateRoute
                          exact
                          path="/panel_administrativo"
                          component={PanelAdministrativo}
                        />{" "}
                        <PrivateRoute
                          exact
                          path="/cobranzas"
                          component={Cobranzas}
                        />
                        <PrivateRoute
                          exact
                          path="/saldos_clientes"
                          component={SaldosClientes}
                        />
                        <PrivateRoute
                          exact
                          path="/rendiciones"
                          component={Rendiciones}
                        />
                        <PrivateRoute exact path="/ventas" component={Ventas} />
                        {getRoleCanDo("cajas") && (
                          <PrivateRoute exact path="/cajas" component={Cajas} />
                        )}
                        <PrivateRoute
                          exact
                          path="/vendedores"
                          component={Vendedores}
                        />
                        <PrivateRoute
                          exact
                          path="/cheques_propios"
                          component={ChequesPropios}
                        />
                        <PrivateRoute
                          exact
                          path="/cheques"
                          component={Cheques}
                        />
                        <PrivateRoute
                          exact
                          path="/clientes"
                          component={Clientes}
                        />
                        <PrivateRoute
                          exact
                          path="/provedores"
                          component={Provedores}
                        />
                        <PrivateRoute
                          exact
                          path="/listasPrecios"
                          component={ListasPrecios}
                        />
                        <PrivateRoute
                          exact
                          path="/productos"
                          component={Productos}
                        />
                        <PrivateRoute
                          exact
                          path="/stock"
                          component={PanelStock}
                        />
                        <PrivateRoute
                          exact
                          path="/ventas_estimadas"
                          component={VentasEstimadas}
                        />
                        <PrivateRoute
                          exact
                          path="/ajustes_stock"
                          component={AjustesStock}
                        />
                        <PrivateRoute
                          exact
                          path="/reportes/volumen_ventas"
                          component={VolumenVentas}
                        />
                        <PrivateRoute
                          exact
                          path="/reportes/volumen_ventas_bonificadas"
                          component={VolumenVentasBonificadas}
                        />
                        <PrivateRoute
                          exact
                          path="/reportes/total_ventas"
                          component={TotalVentas}
                        />
                        <PrivateRoute exact path="/grupos" component={Grupos} />
                        <PrivateRoute exact path="/zonas" component={Zonas} />
                        <PrivateRoute exact path="/buscador_dieteticas" component={BuscadorDieteticas} />
                        <PrivateRoute exact path="/afip" component={Afip} />
                        <PrivateRoute exact path="/about" component={About} />
                        <PrivateRoute
                          exact
                          path="/register"
                          component={Register}
                        />
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          component={Dashboard}
                        />
                        <Route exact path="/login" component={Login} />
                      </Switch>
                    </main>
                  </div>
                </ConfirmProvider>
              </ThemeProvider>
            </Fragment>
          </Router>
        </AlertState>
      </Provider>
    </AuthState>
  );
};

export default App;
