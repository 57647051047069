import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { getProvedores } from '../../actions/provedores';
import subscribeTo from '../../utils/subscribeTo';
import AddProvedorBtn from '../provedores/AddProvedorBtn';
import ProvedoresFilter from '../provedores/ProvedoresFilter';
import ProvedorForm from '../provedores/ProvedorForm';
import ProvedorItem from './ProvedorItem';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const Provedores = ({ provedores, filtered, loading, dispatch }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getProvedores());
    if (!loading) {
      subscribeTo('provedoresChanged', (err, data) => {
        dispatch(getProvedores());
      });
      return () => {
        subscribeTo('provedoresChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (provedores !== null && !provedores.length && !loading) {
    return (
      <Fragment>
        <ProvedorForm />
        <h4>No hay ningún provedor todavía!</h4> <AddProvedorBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ProvedorForm />
      </div>
      <div>
        <ProvedoresFilter />
        <div className={classes.root}>
          {provedores !== null && !loading ? (
            <Grid
              container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
            {(filtered ? filtered : provedores).map((provedor, key) => (
              <ProvedorItem key={key} provedor={provedor} />
            ))}
            </Grid>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddProvedorBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    provedores: state.provedores.provedores,
    filtered: state.provedores.filtered,
    loading: state.provedores.loading
  };
};

export default connect(mapStateToProps)((Provedores));
