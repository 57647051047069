import axios from 'axios';

import {
  GET_GASTOS,
  ADD_GASTO,
  DELETE_GASTO,
  UPDATE_GASTO,
  CLEAR_GASTOS,
  GASTO_ERROR,
  SET_CURRENT,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_GASTOS,
  CLEAR_FILTER,
  SET_SAVING_GASTO
} from './types';

export const getGastos = (filters = null) => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/gastos', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          filters: filters
        },
        data: null
      });
      dispatch({ type: GET_GASTOS, payload: res.data });
    } catch (error) {
      dispatch({ type: GASTO_ERROR, payload: error.msg });
    }
  };
};

export function setCurrent(gasto) {
  return dispatch => {
    dispatch({ type: SET_CURRENT, payload: gasto });
  };
}
export const setSavingGasto = (saving) => {
  return dispatch => {
    dispatch({ type: SET_SAVING_GASTO, payload: saving });
  };
};

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT });
  };
};

export const filterGastos = (filter, filterType) => {
  return dispatch => {
    dispatch({ type: FILTER_GASTOS, filter: filter, filterType: filterType });
  };
};
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER });
  };
};

//Add Gasto
export const addGasto = gasto => {
  return async dispatch => {
    dispatch(setSavingGasto(true));
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/gastos', gasto, config);
      dispatch({ type: ADD_GASTO, payload: res.data });
    } catch (error) {
      dispatch({ type: GASTO_ERROR, payload: error.msg });
    }
    dispatch(setSavingGasto(false));
  };
};

//Delete Gasto

export const deleteGasto = gastoId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/gastos/${gastoId}`);

      dispatch({ type: DELETE_GASTO, payload: gastoId });
    } catch (error) {
      dispatch({ type: GASTO_ERROR, payload: error.response.msg });
    }
  };
};

//Set Current Gasto

//Clear Current Gasto

//Update Gasto

export const updateGasto = gasto => {
  return async dispatch => {
    dispatch(setSavingGasto(true));
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(`/api/gastos/${gasto._id}`, gasto, config);

      dispatch({ type: UPDATE_GASTO, payload: res.data });
    } catch (error) {
      dispatch({ type: GASTO_ERROR, payload: error.msg });
    }
    dispatch(setSavingGasto(false));
  };
};

//Filter Gasto

//Clear Filter

export const clearGastos = () => {
  return dispatch => {
    dispatch({ type: CLEAR_GASTOS });
  };
};
