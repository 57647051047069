import escapeRegExp from 'escape-string-regexp';
import {
  ADD_ZONA,
  DELETE_ZONA,
  SET_CURRENT_ZONA,
  CLEAR_CURRENT_ZONA,
  UPDATE_ZONA,
  FILTER_ZONA,
  CLEAR_FILTER_ZONA,
  ERROR_ZONA,
  GET_ZONA,
  CLEAR_ZONA,
  SET_OPEN_ZONA
} from '../actions/types';

const initialState = {
  zonas: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ZONA:
      return {
        ...state,
        zonas: action.payload,
        loading: false
      };
    case ADD_ZONA:
      return {
        ...state,
        zonas: [action.payload, ...state.zonas],
        loading: false
      };
    case UPDATE_ZONA:
      return {
        ...state,
        zonas: state.zonas.map(zona =>
          zona.id === action.payload.id ? action.payload : zona
        )
      };
    case DELETE_ZONA:
      return {
        ...state,
        zonas: state.zonas.filter(
          zona => zona.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_ZONA:
      return {
        ...state,
        zonas: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_ZONA:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_ZONA:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_ZONA:
      return {
        ...state,
        current: null
      };

    case FILTER_ZONA:
      return {
        ...state,
        filter: action.payload,
        filtered: state.zonas.filter(zona => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return zona.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_ZONA:
      return {
        ...state,
        filtered: null
      };
    case ERROR_ZONA:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
