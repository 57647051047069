import escapeRegExp from 'escape-string-regexp';
import {
  ADD_LISTA_PRECIOS,
  DELETE_LISTA_PRECIOS,
  SET_CURRENT_LISTA_PRECIOS,
  CLEAR_CURRENT_LISTA_PRECIOS,
  UPDATE_LISTA_PRECIOS,
  FILTER_LISTA_PRECIOS,
  CLEAR_FILTER_LISTA_PRECIOS,
  ERROR_LISTA_PRECIOS,
  GET_LISTA_PRECIOS,
  CLEAR_LISTA_PRECIOS,
  SET_OPEN_LISTA_PRECIOS
} from '../actions/types';

const initialState = {
  listas_precios: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LISTA_PRECIOS:
      return {
        ...state,
        listas_precios: action.payload,
        loading: false
      };
    case ADD_LISTA_PRECIOS:
      return {
        ...state,
        listas_precios: [action.payload, ...state.listasPrecios],
        loading: false
      };
    case UPDATE_LISTA_PRECIOS:
      return {
        ...state,
        listas_precios: state.listasPrecios.map(lista_precios =>
          lista_precios.id === action.payload.id ? action.payload : lista_precios
        )
      };
    case DELETE_LISTA_PRECIOS:
      return {
        ...state,
        listas_precios: state.listasPrecios.filter(
          lista_precios => lista_precios.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_LISTA_PRECIOS:
      return {
        ...state,
        listas_precios: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_LISTA_PRECIOS:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_LISTA_PRECIOS:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_LISTA_PRECIOS:
      return {
        ...state,
        current: null
      };

    case FILTER_LISTA_PRECIOS:
      return {
        ...state,
        filter: action.payload,
        filtered: state.listasPrecios.filter(lista_precios => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return lista_precios.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_LISTA_PRECIOS:
      return {
        ...state,
        filtered: null
      };
    case ERROR_LISTA_PRECIOS:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
