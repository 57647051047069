import { Grid, LinearProgress } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
/* global google */

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
});

const BuscadorDieteticas = ({ zonas, filtered, loading, dispatch }) => {
  const classes = useStyles();
  const map = useRef();
  let gMap;

  // Function to initialize the map
  function initMap() {
    // Create a map centered on a specific city (latitude and longitude)
    var mm = new google.maps.Map(map.current, {
      center: { lat: -34.5734316, lng: -58.441615 },
      zoom: 15,
    });
    
    // Dietetica, mercado saludable, supermercado

    // Nombre, direccion, telefono, place ID, mayoristas, coordenadas, link google
    
    // Request to find gyms in the specified city
    var request = {
      location: mm.getCenter(),
      radius: "5000", // Search within a 5km radius
      //type: ["dietética"], // Search for gyms
      keyword: "Dietética"
    };

    // Create a Places Service instance
    var service = new google.maps.places.PlacesService(mm);
    service.nearbySearch(request, callback);
  }
  useEffect(() => {
    initMap();
  }, []);

  // Callback function to process the search results
  function callback(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      for (var i = 0; i < results.length; i++) {
        var place = results[i];
        // Log the name and address of each gym
        console.log();
        console.log(
          place.name + "," + place.vicinity + "," + place.formatted_phone_number
        );
        console.log(place);
      }
    }
  }

  return (
    <Fragment>
      <div ref={map} />
      <div>AA</div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    zonas: state.zonas.zonas,
    filtered: state.zonas.filtered,
    loading: state.zonas.loading,
  };
};

export default connect(mapStateToProps)(BuscadorDieteticas);
