import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useConfirm } from 'material-ui-confirm';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import {
  deleteGasto, getGastos,

  setCurrent
} from '../../actions/gastos';
import AlertContext from '../../context/alert/alertContext';
import subscribeTo from '../../utils/subscribeTo';
import { getIsAdmin, getRoleCanDo } from '../usuarios/Usuario';
import AddGastoBtn from './AddGastoBtn';
import GastoForm from './GastoForm';
import GastosFilter from './GastosFilter';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  },
  gridContainer: {
    width: '96vw',
    overflowX: 'auto'
  }
});


const Gastos = ({ dispatch, loading, gastos, filters, filtered }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [filterOpen, setFilterOpen] = useState(false);
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    dispatch(getGastos());
    if (!loading) {
      subscribeTo('gastosChanged', (err, data) => {
        dispatch(getGastos());
      });

      return () => {
        subscribeTo('gastosChanged', null);
      };
    }
  }, [dispatch, loading]);

  const onClickBorrar = (confirm, dispatch, gasto ) => {
    confirm( {
                                description: `Desea borrar este gasto de "${
                                  gasto.provedorObj
                                    ? gasto.provedorObj.razon_social
                                    : 'BORRADO'
                                }"?`,
                                title: 'ATENCION',
                                cancellationText: 'Cancelar',
                                confirmationText: 'Confirmar'
                              })

    .then(() => dispatch(deleteGasto(gasto._id)));
}

  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  if (gastos !== null && !gastos.length && !loading) {
    return (
      <Fragment>
        <GastoForm />
        <h4>No hay ningún gasto todavía!</h4>
        <AddGastoBtn />
      </Fragment>
    );
  }
  let openFilter = false;
  return (
    <Fragment>
      <GastoForm />

      <GastosFilter open={filterOpen} setOpen={setFilterOpen} />
      <Grid container className={mobile ? classes.gridContainer : ''}>
        <Grid item xs={12} md={12}>
          {gastos !== null && !loading ? (
            <Fragment>
              <ReactTable
                data={filtered ? filtered : gastos || []}
                loading={loading}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (column.id !== 'acciones') {
                        if (rowInfo) dispatch(setCurrent(rowInfo.original));
                      }
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
                previousText='Anterior'
                nextText='Próxima'
                loadingText='Cargando...'
                noDataText='Nada cargado'
                pageText='Pagina'
                ofText='de'
                rowsText='filas'
                pageJumpText='ir a la pág.'
                rowsSelectorText='filas por pág.'
                defaultPageSize={14}
                columns={[
                  {
                    columns: [
                      {
                        Header: 'Fecha Factura',
                        accessor: 'fecha_gasto_formatted'
                      },
                      {
                        Header: 'Provedor',
                        id: 'provedor',
                        accessor: d =>
                          d.provedorObj ? d.provedorObj.razon_social : 'BORRADO'
                      },

                      {
                        Header: 'Tipo',
                        id: 'tipo',
                        accessor: d => d.tipo
                      },

                      {
                        Header: 'Débito',
                        id: 'total',
                        style: { textAlign: 'right' },
                        accessor: d => d.total,
                        Cell: row =>
                          '$' +
                          (row.original.total !== undefined
                            ? parseFloat(row.original.total.toFixed(2))
                            : '')
                      },
                      {
                        Header: 'Crédito',
                        id: 'total',
                        style: { textAlign: 'right' },
                        accessor: d => d.credito,
                        Cell: row =>
                          '$' +
                          (row.original.credito !== undefined
                            ? parseFloat(row.original.credito.toFixed(2))
                            : '')
                      },
                      {
                        Header: 'Balance',
                        id: 'balance',
                        style: { textAlign: 'right' },
                        accessor: d => d.balance,
                        Cell: row => (
                          <span
                            style={{
                              color:
                                Math.round(row.original.balance * 100) / 100 ===
                                0
                                  ? 'green'
                                  : 'red'
                            }}
                          >
                            $
                            {row.original.balance !== undefined
                              ? parseFloat(row.original.balance.toFixed(2))
                              : ''}
                          </span>
                        )
                      },

                      {
                        Header: 'Alic IVA',
                        id: 'iva',
                        style: { textAlign: 'right' },
                        accessor: d => d.alic_iva,
                        Cell: row =>
                          !isNaN(parseFloat(row.original.alic_iva)) &&
                          parseFloat(row.original.alic_iva).toFixed(2) + '%'
                      },

                      {
                        Header: 'Alic Retenciones',
                        id: 'alic_retenciones',
                        style: { textAlign: 'right' },
                        accessor: d => d.alic_retenciones,
                        Cell: row =>
                          !isNaN(parseFloat(row.original.alic_retenciones)) &&
                          parseFloat(row.original.alic_retenciones).toFixed(2) +
                            '%'
                      },

                      {
                        Header: 'Neto Gravado',
                        id: 'neto_grav',
                        style: { textAlign: 'right' },
                        accessor: d => d.neto_gravado && '$' + d.neto_gravado
                      },
                      {
                        Header: 'No Gravado',
                        id: 'no_gravado',
                        style: { textAlign: 'right' },
                        accessor: d => d.no_gravado && '$' + d.no_gravado
                      },
                      {
                        Header: 'IIBB',
                        id: 'iibb',
                        style: { textAlign: 'right' },
                        accessor: d => d.iibb && '$' + d.iibb
                      },

                      {
                        Header: 'Descripcion',
                        id: 'descripcion',
                        accessor: d => d.descripcion
                      },
                      {
                        Header: 'Acciones',
                        id: 'acciones',
                        style: { textAlign: 'center' },
                        accessor: str => 'acciones',
                        show: getRoleCanDo(),
                        Cell: row => (
                          <span
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                              textDecoration: 'underline'
                            }}
                            onClick={e=>onClickBorrar(confirm, dispatch, row.original)}
                          >
                            Borrar
                          </span>
                        )
                      }
                    ]
                  }
                ]}
                className='-striped -highlight'
              />
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </Grid>
      </Grid>
      <AddGastoBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    gastos: state.gastos.gastos,
    filtered: state.gastos.filtered,
    loading: state.gastos.loading,
    filters: state.gastos.filters
  };
};

export default connect(mapStateToProps)((Gastos));
