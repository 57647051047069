import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { getProductos } from "../../actions/productos";
import moment from "moment";
import {
  addVentaEstimada,
  clearCurrent,
  setOpen,
  updateVentaEstimada,
  ajustarStock,
} from "../../actions/ventas_estimadas";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const EditarStockForm = ({
  children,
  productos,
  current,
  setOpenStock,
  openStock,
  dispatch,
}) => {
  useEffect(() => {}, [current]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!true) {
    } else {
      //SUBMIT
      dispatch(ajustarStock(openStock, true));
      handleClose();
    }
  };

  const onValueChange = (e, producto_id, name) => {
    let prodTmp = {};
    console.log(e.floatValue);
    setOpenStock({
      ...openStock,
      productos: {
        ...openStock.productos,
        [producto_id]: {
          ...(openStock.productos && openStock.productos[producto_id]),
          [name]: e.floatValue || e.floatValue === 0 ? e.floatValue : null,
          cantidad_anterior: productos.find((prod) => prod.id === producto_id)
            .stock,
        },
      },
    });
    console.log("AAA", {
      ...openStock,
      productos: {
        ...openStock.productos,
        [producto_id]: {
          ...(openStock.productos && openStock.productos[producto_id]),
          [name]: e.floatValue || e.floatValue === 0 ? e.floatValue : null,
        },
      },
    });
  };

  const onChange = (e, option) => {
    if (option === "motivo_ajuste_stock_id") {
      setOpenStock({ ...openStock, motivo_ajuste_stock_id: e.target.value });
    }
    if (option === "fecha") {
      setOpenStock({ ...openStock, fecha: e });
    } else {
      setOpenStock({ ...openStock, [option]: e.target.value });
    }
  };

  const classes = useStyles();

  function handleClose() {
    setOpenStock(null);
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || openStock ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      {openStock && (
        <>
          <DialogTitle id="max-width-dialog-title" color="primary">
            {"Control stock múltiple"}
          </DialogTitle>
          <DialogContent dividers={true}>
            <form onSubmit={onSubmit}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                  <div>
                    Fecha de Ajuste: *{" "}
                    <DatePicker
                      onChange={(date) => onChange(date, "fecha")}
                      value={moment(openStock.fecha).toDate()}
                      format="dd/MM/y"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl
                    variant="outlined"
                    style={{ height: "55px", paddingTop: "8px", width: "100%" }}
                  >
                    <InputLabel htmlFor="select-cobranza">Motivo *</InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          name={`motivo_ajuste_stock_id`}
                          id={`motivo_ajuste_stock_id`}
                        />
                      }
                      value={openStock.motivo_ajuste_stock_id || 2}
                      onChange={(e) => onChange(e, "motivo_ajuste_stock_id")}
                      label="Motivo"
                      fullWidth
                      margin="dense"
                      autoWidth={false}
                    >
                      <MenuItem value={1}>Produccion</MenuItem>
                      <MenuItem value={2}>Control de stock</MenuItem>
                      <MenuItem value={3}>Descarte</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {openStock.motivo_ajuste_stock_id &&
                  openStock.motivo_ajuste_stock_id === 1 && (
                    <Grid item xs={12} md={12}>
                      <NumberFormat
                        customInput={TextField}
                        decimalSeparator=","
                        thousandSeparator=""
                        max={53}
                        min={1}
                        mask=""
                        prefix=""
                        decimalScale={2}
                        allowNegative={false}
                        margin="dense"
                        name="batch"
                        value={openStock.batch}
                        label="Batch"
                        onValueChange={(e) => onValueChange(e, "batch")}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )}
                {openStock.motivo_ajuste_stock_id &&
                (openStock.motivo_ajuste_stock_id === 2 ||
                  openStock.motivo_ajuste_stock_id === 3) ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <TextField
                        margin="dense"
                        name="motivo_ajuste"
                        value={openStock.motivo_ajuste}
                        label="Notas"
                        onChange={(e) => onChange(e, "motivo_ajuste")}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {productos
                  .filter((prod) => prod.id !== 6)
                  .map((prod) => (
                    <>
                      {" "}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        container
                        style={{ borderTop: "1px solid lightgray" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontWeight: "bold",
                          }}
                        >
                          {prod.nombre}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{ marginLeft: "20px" }}
                        >
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix=""
                            decimalScale={0}
                            allowNegative={false}
                            margin="dense"
                            name="valor_ajuste"
                            label={"Caja c/24"}
                            value={null}
                            onValueChange={(e) =>
                              onValueChange(e, prod.id, "valor_ajuste_caja")
                            }
                            className={classes.textField}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{ marginLeft: "20px" }}
                        >
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix=""
                            decimalScale={0}
                            allowNegative={false}
                            margin="dense"
                            name="valor_ajuste"
                            label={
                              "Canasto c/" +
                              (prod.cod === 127 || prod.cod === 110 ? 40 : 24)
                            }
                            value={null}
                            onValueChange={(e) =>
                              onValueChange(e, prod.id, "valor_ajuste_canasto")
                            }
                            className={classes.textField}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{ marginLeft: "20px" }}
                        >
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix=""
                            decimalScale={0}
                            allowNegative={false}
                            margin="dense"
                            name="valor_ajuste"
                            label={"Packs"}
                            value={null}
                            onValueChange={(e) =>
                              onValueChange(e, prod.id, "valor_ajuste_pack")
                            }
                            className={classes.textField}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          style={{
                            marginLeft: "20px",
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontWeight: "bold",
                            color: "darkgreen",
                            ...((!openStock.productos ||
                              !openStock.productos[prod.id] ||
                              (!openStock.productos[prod.id]
                                .valor_ajuste_caja &&
                                openStock.productos[prod.id]
                                  .valor_ajuste_caja !== 0 &&
                                !openStock.productos[prod.id]
                                  .valor_ajuste_canasto &&
                                openStock.productos[prod.id]
                                  .valor_ajuste_canasto !== 0 &&
                                !openStock.productos[prod.id]
                                  .valor_ajuste_pack &&
                                openStock.productos[prod.id]
                                  .valor_ajuste_pack !== 0)) && {
                              visibility: "hidden",
                            }),
                          }}
                        >
                          Nueva cantidad:{" "}
                          {openStock.productos && openStock.productos[prod.id]
                            ? (openStock.productos[prod.id].valor_ajuste_caja *
                                (prod.cod === 127 || prod.cod === 110
                                  ? 24
                                  : 24) || 0) +
                              (openStock.productos[prod.id]
                                .valor_ajuste_canasto *
                                (prod.cod === 127 || prod.cod === 110
                                  ? 40
                                  : 24) || 0) +
                              (openStock.productos[prod.id].valor_ajuste_pack ||
                                0)
                            : 0}
                        </Grid>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              type="submit"
            >
              Guardar
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    mix_ventas: state.ventasEstimadas.mix_ventas,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(EditarStockForm);
