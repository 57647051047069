import escapeRegExp from 'escape-string-regexp';
import {
  ADD_GRUPO,
  DELETE_GRUPO,
  SET_CURRENT_GRUPO,
  CLEAR_CURRENT_GRUPO,
  UPDATE_GRUPO,
  FILTER_GRUPO,
  CLEAR_FILTER_GRUPO,
  ERROR_GRUPO,
  GET_GRUPO,
  CLEAR_GRUPO,
  SET_OPEN_GRUPO
} from '../actions/types';

const initialState = {
  grupos: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GRUPO:
      return {
        ...state,
        grupos: action.payload,
        loading: false
      };
    case ADD_GRUPO:
      return {
        ...state,
        grupos: [...state.grupos, action.payload],
        loading: false
      };
    case UPDATE_GRUPO:
      return {
        ...state,
        grupos: state.grupos.map(grupo =>
          grupo.id === action.payload.id ? action.payload : grupo
        )
      };
    case DELETE_GRUPO:
      return {
        ...state,
        grupos: state.grupos.filter(
          grupo => grupo.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_GRUPO:
      return {
        ...state,
        grupos: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_GRUPO:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_GRUPO:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_GRUPO:
      return {
        ...state,
        current: null
      };

    case FILTER_GRUPO:
      return {
        ...state,
        filter: action.payload,
        filtered: state.grupos.filter(grupo => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return (grupo.razon_social && grupo.razon_social.match(regex)) || (grupo.nombre && grupo.nombre.match(regex));
        })
      };
    case CLEAR_FILTER_GRUPO:
      return {
        ...state,
        filtered: null
      };
    case ERROR_GRUPO:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
