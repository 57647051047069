import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import {
  GET_COBRANZAS,
  COBRANZA_ERROR,
  DELETE_COBRANZA,
  SET_CURRENT_COBRANZA,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_COBRANZAS,
  CLEAR_FILTER,
  ADD_COBRANZA,
  UPDATE_COBRANZA,
  SET_SAVING_COBRANZA,
  LOADING_CUENTA_CORRIENTE,
  GET_SALDOS_CLIENTES,
  GET_CUENTA_CORRIENTE,
  LOADING_COBRANZAS,
} from "../actions/types";

const initialState = {
  cobranzas: null,
  current: null,
  filters: { descripcion: "", tipo: "" },
  filtered: null,
  error: null,
  loading: true,
  ultima_rendicion: null,
  loading_cuenta_corriente: false,
  saldos_clientes: [],
  cuenta_corriente: null,
};

export default (state = initialState, action) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  switch (action.type) {
    case GET_COBRANZAS:
      return {
        ...state,
        cobranzas: action.payload,
        loading: false,
      };
    case SET_CURRENT_COBRANZA:
      return {
        ...state,
        current: action.payload,
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case SET_SAVING_COBRANZA:
      return {
        ...state,
        saving: action.payload,
      };
    case GET_SALDOS_CLIENTES:
      return {
        ...state,
        saldos_clientes: action.payload,
      };
    case GET_CUENTA_CORRIENTE:
      return {
        ...state,
        cuenta_corriente: action.payload,
      };
    case LOADING_CUENTA_CORRIENTE:
      return {
        ...state,
        loading_cuenta_corriente: action.payload,
      };

    case LOADING_COBRANZAS:
      return {
        ...state,
        loading: action.payload,
      };

    case FILTER_COBRANZAS:
      const shouldFilter = (cobranza, property, value) => {
        switch (property) {
          case "descripcion":
            return cobranza.descripcion.match(
              new RegExp(`${escapeRegExp(value)}`, "gi")
            );
            break;
          case "tipo":
            return cobranza.tipo.match(value);
            break;
          case "fecha_cobranza":
          case "fecha_pago":
            return (
              formatDate(cobranza[property]) >= formatDate(value[0]) &&
              formatDate(cobranza[property]) <= formatDate(value[1])
            );
            break;
          default:
            break;
        }
      };
      return {
        ...state,
        filters: action.filter,
        filtered: state.cobranzas.filter((cobranza) => {
          let ok = true;
          for (var property in action.filter) {
            //if (action.filterType === property) continue;
            if (
              action.filter.hasOwnProperty(property) &&
              action.filter[property]
            ) {
              ok =
                ok && shouldFilter(cobranza, property, action.filter[property]);
            }
          }
          //ok = ok && shouldFilter(cobranza, action.filterType, action.payload);
          return ok;
        }),
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case ADD_COBRANZA:
      return {
        ...state,
        cobranzas: [action.payload, ...state.cobranzas],
        loading: false,
      };
    case UPDATE_COBRANZA:
      return {
        ...state,
        cobranzas: state.cobranzas.map((cobranza) =>
          cobranza.id === action.payload.id ? action.payload : cobranza
        ),
      };
    case DELETE_COBRANZA:
      return {
        ...state,
        cobranzas: state.cobranzas.filter(
          (cobranza) => cobranza.id !== action.payload
        ),
        loading: false,
      };
    /*

    case CLEAR_CONTACTS:
      return {
        ...state,
        transportistas: null,
        error: null,
        current: null,
        loading: null
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };

    case FILTER_CONTACTS:
      return {
        ...state,
        filtered: state.transportistas.filter(transportista => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return (
            transportista.nombre.match(regex) ||
            transportista.email.match(regex)
          );
        })
      };

    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload
      };
      */
    default:
      return state;
  }
};
