import axios from "axios";

import {
  GET_COBRANZAS,
  ADD_COBRANZA,
  DELETE_COBRANZA,
  UPDATE_COBRANZA,
  CLEAR_COBRANZAS,
  COBRANZA_ERROR,
  SET_CURRENT_COBRANZA,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_COBRANZAS,
  CLEAR_FILTER,
  SET_SAVING_COBRANZA,
  GET_RENDICIONES,
  GET_SALDOS_CLIENTES,
  GET_CUENTA_CORRIENTE,
  LOADING_CUENTA_CORRIENTE,
  LOADING_COBRANZAS,
} from "./types";

export const getCobranzas = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/cobranzas/getCobranzas",
        filters || {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({ type: GET_COBRANZAS, payload: res.data });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
  };
};

export const getSaldosClientes = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/cobranzas/saldos_clientes", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          filters: filters,
        },
        data: null,
      });
      dispatch({ type: GET_SALDOS_CLIENTES, payload: res.data });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
  };
};

export const getCuentaCorriente = (cliente_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_CUENTA_CORRIENTE, payload: true });
      const res = await axios.get(
        "/api/cobranzas/cuenta_corriente/" + cliente_id,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: null,
        }
      );
      dispatch({ type: GET_CUENTA_CORRIENTE, payload: res.data });
      dispatch({ type: LOADING_CUENTA_CORRIENTE, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_CUENTA_CORRIENTE, payload: false });
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
  };
};

export const getRendiciones = (filters = null) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/cobranzas/rendiciones", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          filters: filters,
        },
        data: null,
      });
      dispatch({ type: GET_RENDICIONES, payload: res.data });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
  };
};

export function setCurrentCobranza(cobranza) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_COBRANZA, payload: cobranza });
  };
}
export const setSavingCobranza = (saving) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVING_COBRANZA, payload: saving });
  };
};

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT });
  };
};

export const filterCobranzas = (filter, filterType) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_COBRANZAS,
      filter: filter,
      filterType: filterType,
    });
  };
};
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

//Add Cobranza
export const addCobranza = (cobranza) => {
  return async (dispatch) => {
    dispatch(setSavingCobranza(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/cobranzas", cobranza, config);
      dispatch({ type: ADD_COBRANZA, payload: res.data });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
    dispatch(setSavingCobranza(false));
  };
};

//Delete Cobranza

export const deleteCobranza = (cobranzaId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/cobranzas/${cobranzaId}`);

      dispatch({ type: DELETE_COBRANZA, payload: cobranzaId });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.response.msg });
    }
  };
};

//Set Current Cobranza

//Clear Current Cobranza

//Update Cobranza

export const updateCobranza = (cobranza) => {
  return async (dispatch) => {
    dispatch(setSavingCobranza(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/cobranzas/${cobranza.id}`,
        cobranza,
        config
      );

      dispatch({ type: UPDATE_COBRANZA, payload: res.data });
    } catch (error) {
      dispatch({ type: COBRANZA_ERROR, payload: error.msg });
    }
    dispatch(setSavingCobranza(false));
  };
};

//Filter Cobranza

//Clear Filter

export const clearCobranzas = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_COBRANZAS });
  };
};
