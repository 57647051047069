import escapeRegExp from "escape-string-regexp";
import {
  ADD_VENTAS_ESTIMADAS,
  DELETE_VENTAS_ESTIMADAS,
  SET_CURRENT_VENTAS_ESTIMADAS,
  CLEAR_CURRENT_VENTAS_ESTIMADAS,
  UPDATE_VENTAS_ESTIMADAS,
  FILTER_VENTAS_ESTIMADAS,
  CLEAR_FILTER_VENTAS_ESTIMADAS,
  ERROR_VENTAS_ESTIMADAS,
  GET_VENTAS_ESTIMADAS,
  CLEAR_VENTAS_ESTIMADAS,
  SET_OPEN_VENTAS_ESTIMADAS,
  GET_MIX_VENTAS,
  AJUSTAR_STOCK,
  GET_AJUSTES_STOCK
} from "../actions/types";

const initialState = {
  ventas_estimadas: null,
  mix_ventas: null,
  ajustes_stock: null,
  current: null,
  filtered: null,
  filter: "",
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VENTAS_ESTIMADAS:
      return {
        ...state,
        ventas_estimadas: action.payload,
        loading: false
      };
    case GET_MIX_VENTAS:
      return {
        ...state,
        mix_ventas: action.payload,
        loading: false
      };
    case GET_AJUSTES_STOCK:
      return {
        ...state,
        ajustes_stock: action.payload,
        loading: false
      };

    case ADD_VENTAS_ESTIMADAS:
      if (action.payload.nueva)
        return {
          ...state,
          ventas_estimadas: [action.payload, ...state.ventasEstimadas],
          loading: false
        };
      else {
        return {
          ...state,
          ventas_estimadas: state.ventasEstimadas.map((venta_estimada) =>
            venta_estimada.id === action.payload.id ? action.payload : venta_estimada
          )
        };
      }
    case UPDATE_VENTAS_ESTIMADAS:
      return {
        ...state,
        ventas_estimadas: state.ventasEstimadas.map((venta_estimada) =>
          venta_estimada.id === action.payload.id ? action.payload : venta_estimada
        )
      };
    case DELETE_VENTAS_ESTIMADAS:
      return {
        ...state,
        ventas_estimadas: state.ventasEstimadas.filter((venta_estimada) => venta_estimada.id !== action.payload),
        loading: false
      };
    case CLEAR_VENTAS_ESTIMADAS:
      return {
        ...state,
        ventas_estimadas: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_VENTAS_ESTIMADAS:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_VENTAS_ESTIMADAS:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_VENTAS_ESTIMADAS:
      return {
        ...state,
        current: null
      };

    case FILTER_VENTAS_ESTIMADAS:
      return {
        ...state,
        filter: action.payload,
        filtered: state.ventasEstimadas.filter((venta_estimada) => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, "gi");
          return venta_estimada.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_VENTAS_ESTIMADAS:
      return {
        ...state,
        filtered: null
      };
    case ERROR_VENTAS_ESTIMADAS:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
