import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TimeInput from "material-ui-time-picker";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import PlacesAutocomplete from "react-places-autocomplete";
/* global google */
import { connect } from "react-redux";
import {
  addTransportista,

  clearCurrent,
  setOpen, updateTransportista
} from "../../actions/transportistas";
import TelefonosInput from "../utils/TelefonosInput";


const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const emptyTransportista = {
  nombre: "",
  email: "",
  direccion: "",
  place_id: null,
  observaciones: "",
  costo: "",
  telefonos: [],
  destinos: []
};

const TransportistaForm = ({ children, current, open, dispatch }) => {
  const [transportista, setTransportista] = useState(emptyTransportista);

  const {
    nombre,
    email,
    telefonos,
    observaciones,
    destinos,
    costo,
    direccion,
    place_id
  } = transportista;
  const map = useRef();

  let gMap;

  function initMap(place_id) {
    return new Promise(function (resolve, reject) {
      gMap = new google.maps.Map(map.current, {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8
      });
      var request = {
        placeId: place_id
      };

      let service = new google.maps.places.PlacesService(gMap);
      service.getDetails(request, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {

          resolve(
            place.address_components.filter(
              (a) =>
                a.types.indexOf("sublocality") !== -1 ||
                a.types.indexOf("neighborhood") !== -1 ||
                a.types.indexOf("locality") !== -1
            )[0]?.long_name
          );
        } else {
          reject("");
        }
      });
    });
  }

  useEffect(() => {
    if (current !== null) {
      setTransportista(current);
    } else {
      setTransportista({
        nombre: "",
        email: "",
        direccion: "",
        place_id: null,
        observaciones: "",
        costo: "",
        telefonos: [],
        destinos: []
      });
    }
  }, [current, open]);

  const onChange = (e) => {
    setTransportista({ ...transportista, [e.target.name]: e.target.value });
  };

  const onChangeTel = (e, index, campo) => {
    setTransportista({
      ...transportista,
      telefonos: [
        ...telefonos.slice(0, index),
        { ...telefonos[index], [campo]: e.target.value },
        ...telefonos.slice(index + 1)
      ]
    });
  };
  const onDeleteDestino = (e, index) => {
    setTransportista({
      ...transportista,
      destinos: [...destinos.slice(0, index), ...destinos.slice(index + 1)]
    });
  };

  const onDeleteTelefono = (e, index) => {
    setTransportista({
      ...transportista,
      telefonos: [...telefonos.slice(0, index), ...telefonos.slice(index + 1)]
    });
  };

  const onChangeDestino = (e, index, campo) => {
    switch (campo) {
      case "direccion":
        setTransportista({
          ...transportista,
          destinos: [
            ...destinos.slice(0, index),
            { ...destinos[index], direccion: e, place_id: null },
            ...destinos.slice(index + 1)
          ]
        });
        break;

      case "hora_ini":
      case "hora_fin":
        setTransportista({
          ...transportista,
          destinos: [
            ...destinos.slice(0, index),
            {
              ...destinos[index],
              [campo]: e.getHours() + ":" + e.getMinutes()
            },
            ...destinos.slice(index + 1)
          ]
        });
        break;
      case "abasto":
        setTransportista({
          ...transportista,
          destinos: [
            ...destinos.slice(0, index),
            { ...destinos[index], abasto: e.floatValue },
            ...destinos.slice(index + 1)
          ]
        });
        break;
      default:
        setTransportista({
          ...transportista,
          destinos: [
            ...destinos.slice(0, index),
            { ...destinos[index], [campo]: e.target.value },
            ...destinos.slice(index + 1)
          ]
        });
        break;
    }
  };

  const onChangeDir = (e, fromSelect = false, object = {}) => {
    let direccion, place_id;

    //Typed by the user!
    direccion = e;
    place_id = null;
    setTransportista({
      ...transportista,
      direccion,
      place_id
    });
  };

  const onChangeLoc = (e) => {
    let direccion, place_id;

    setTransportista({
      ...transportista,
      localidad: e.target.value
    });
  };

  const onSelectAddress = async (direccion, place_id) => {
    setTransportista({
      ...transportista,
      direccion,
      place_id,
      localidad: await initMap(place_id)
    });
  };

  const onSelectDestino = async (direccion, place_id, index) => {
    setTransportista({
      ...transportista,
      destinos: [
        ...destinos.slice(0, index),
        {
          ...destinos[index],

          direccion,
          place_id
        },
        ...destinos.slice(index + 1)
      ]
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!nombre) {
    } else {
      try {
        if (!current) {
          dispatch(addTransportista(transportista));
        } else {
          dispatch(updateTransportista(transportista));
        }
      } catch (error) {
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setTransportista({
      nombre: "",
      email: "",

      direccion: "",
      place_id: null,
      localidad: "",

      observaciones: "",
      telefonos: [],
      destinos: []
    });
    dispatch(clearCurrent());
  };

  const agregarTelefono = (index) => {
    setTransportista({
      ...transportista,
      telefonos: [
        ...telefonos,
        { numero: "", descripcion: "", tipo_telefono_id: "" }
      ]
    });
  };

  const agregarDestino = (index) => {
    const hora_ini = destinos.length
      ? destinos[destinos.length - 1].hora_ini
      : "8:00";
    const hora_fin = destinos.length
      ? destinos[destinos.length - 1].hora_fin
      : "17:00";
    const dias = destinos.length ? destinos[destinos.length - 1].dias : [];
    setTransportista({
      ...transportista,
      destinos: [
        ...destinos,
        { direccion: "", dias: dias, hora_ini: hora_ini, hora_fin: hora_fin }
      ]
    });
  };

  const classes = useStyles();

  const transportistaForm = (
    <Fragment>
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current ? "Nuevo transportista" : "Editar transportista"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <div ref={map} />
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                name="nombre"
                label="Nombre"
                value={nombre}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                type="email"
                name="email"
                label="E-mail"
                variant="outlined"
                id="email"
                value={email}
                onChange={onChange}
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <PlacesAutocomplete
                value={transportista.direccion || ""}
                onChange={onChangeDir}
                onSelect={onSelectAddress}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Direccion"
                      autoComplete="nope"
                      {...getInputProps({
                        placeholder: "Buscar direccion ...",
                        className: "location-search-input"
                      })}
                    />
                    <div className={"autocomplete-dropdown-container"}>
                      {loading && <div>Cargando...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>{" "}
            <Grid item md="4">
              <TextField
                name={"localidad"}
                label="Localidad"
                value={transportista.localidad || ""}
                onChange={(e) => onChangeLoc(e)}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="observaciones"
                label="Observaciones"
                variant="outlined"
                multiline
                id="observaciones"
                value={observaciones || ""}
                onChange={onChange}
                fullWidth
                rowsMax="10"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="costo"
                label="Costo"
                variant="outlined"
                multiline
                id="costo"
                value={costo || ""}
                onChange={onChange}
                fullWidth
                rowsMax="10"
              />
            </Grid>
          </Grid>
          <h3 color="primary">Destinos: </h3>

          {destinos &&
            destinos.map((destino, index) => {
              return (
                <Grid
                  container
                  className={classes.root}
                  spacing={2}
                  key={`destinos[${index}]`}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={8}>
                    <PlacesAutocomplete
                      value={destino.direccion || "" || ""}
                      onChange={(e) => onChangeDestino(e, index, "direccion")}
                      onSelect={(e, place_id) =>
                        onSelectDestino(e, place_id, index)
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <Fragment>
                          <TextField
                            autoFocus={!destino.direccion}
                            variant="outlined"
                            label="Provincia/Zona"
                            {...getInputProps({
                              placeholder: "Buscar direccion ...",
                              className: "location-search-input"
                            })}
                            autoComplete="nope"
                            fullWidth
                          />
                          <div className={"autocomplete-dropdown-container"}>
                            {loading && <div>Cargando...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Fragment>
                      )}
                    </PlacesAutocomplete>
                  </Grid>

                  <Grid item md="3">
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      thousandSeparator="."
                      mask=""
                      prefix="$"
                      decimalScale={2}
                      allowNegative={false}
                      name={`destinos[${index}].abasto`}
                      label="Abasto $/kg"
                      value={destino.abasto}
                      onValueChange={(e) =>
                        onChangeDestino(e, index, "abasto", true)
                      }
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md="1">
                    <Button
                      onClick={(e) => onDeleteDestino(e, index)}
                      size="small"
                      color="secondary"
                    >
                      <DeleteOutlinedIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      variant="outlined"
                      style={{ height: "55px", width: "100%" }}
                    >
                      <Select
                        input={
                          <OutlinedInput
                            name={`dias${index}`}
                            id={`destinos[${index}].dias`}
                          />
                        }
                        value={destino && destino.dias}
                        onChange={(e) => onChangeDestino(e, index, "dias")}
                        label="Dias"
                        fullWidth
                        autoWidth={false}
                        multiple={true}
                      >
                        <MenuItem value={1}>Lunes</MenuItem>
                        <MenuItem value={2}>Martes</MenuItem>
                        <MenuItem value={3}>Miércoles</MenuItem>
                        <MenuItem value={4}>Jueves</MenuItem>
                        <MenuItem value={5}>Viernes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor={`destinos[${index}].hora_ini`}>
                        Hora Inicio
                      </InputLabel>
                      <TimeInput
                        id={`destinos[${index}].hora_ini`}
                        mode="24h"
                        autoOk={true}
                        value={
                          destino && moment(destino.hora_ini, "HH:mm").toDate()
                        }
                        onChange={(e) => onChangeDestino(e, index, "hora_ini")}
                        input={<OutlinedInput />}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor={`destinos[${index}].hora_ini`}>
                        Hora Fin
                      </InputLabel>
                      <TimeInput
                        mode="24h"
                        id={`destinos[${index}].hora_fin`}
                        autoOk={true}
                        value={
                          destino && moment(destino.hora_fin, "HH:mm").toDate()
                        }
                        onChange={(e) => onChangeDestino(e, index, "hora_fin")}
                        input={<OutlinedInput />}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              );
            })}

          <h4>
            <Button color="primary" href="#!" onClick={agregarDestino}>
              Agregar Destino
            </Button>
          </h4>
          <h3 color="primary">Telefonos: </h3>
          {telefonos &&
            telefonos.map((telefono, index) => {
              return (
                <Fragment key={`telefonos[${index}]`}>
                  <TelefonosInput
                    index={index}
                    classes={classes}
                    telefono={telefono}
                    onChange={onChangeTel}
                    onDeleteTelefono={onDeleteTelefono}
                    soloTelefono={true}
                  />
                </Fragment>
              );
            })}
          <h4>
            <Button color="primary" href="#!" onClick={agregarTelefono}>
              Agregar Teléfono
            </Button>
          </h4>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Fragment>
  );

  const transportistaView = (
    <Fragment>
      <DialogTitle id="max-width-dialog-title" color="primary">
        {nombre}
      </DialogTitle>
      <DialogContent dividers={true}>
        <br />
        <Grid container className={classes.root} spacing={2}>
          {email && (
            <Grid item xs={12}>
              <span className="view-label">E-mail:</span> {email}
            </Grid>
          )}
          {transportista.direccion && (
            <Grid item xs={12}>
              <span className="view-label">Dirección:</span>{" "}
              {transportista.direccion}
            </Grid>
          )}
          {observaciones && (
            <Grid item xs={12}>
              <span className="view-label">Observaciones:</span> {observaciones}
            </Grid>
          )}
          {costo && (
            <Grid item xs={12}>
              <span className="view-label">Costo:</span> {costo}
            </Grid>
          )}
        </Grid>
        <br />
        {destinos.length !== 0 && (
          <Fragment>
            <h3 color="primary">Destinos: </h3>
            <ul>
              {destinos.map((destino, index) => {
                return (
                  <li key={index}>
                    <span className="view-label">{destino.direccion}</span>
                    {destino.dias.length && (
                      <Fragment>
                        {": "}

                        {destino.dias.map((dia, index) => {
                          return (
                            (index !== 0
                              ? index !== destino.dias.length - 1
                                ? ", "
                                : " y "
                              : "") + dia
                          );
                        })}
                      </Fragment>
                    )}
                    {destino.hora_ini && (
                      <Fragment>
                        {" de "}
                        {destino.hora_ini}
                        {" hasta las "}
                        {destino.hora_fin}
                      </Fragment>
                    )}
                  </li>
                );
              })}
            </ul>
          </Fragment>
        )}
        <br />

        {telefonos.length !== 0 && (
          <Fragment>
            <h3 color="primary">Telefonos: </h3>
            <ul>
              {telefonos.map((telefono, index) => {
                return (
                  <li key={index}>
                    {telefono.numero} ({telefono.tipo_telefono_id})
                    {telefono.descripcion && ` [${telefono.descripcion}]`}
                  </li>
                );
              })}
            </ul>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cerrar
        </Button>
      </DialogActions>
    </Fragment>
  );

  function handleClose() {
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      {current && current.__view ? transportistaView : transportistaForm}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.transportistas.current,
    open: state.transportistas.open
  };
};

export default connect(mapStateToProps)(TransportistaForm);
