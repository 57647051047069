import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { chequearRendicion, deleteRendicion, filterRendiciones, getRendiciones } from "../../actions/rendiciones";
import { getListasPrecios } from "../../actions/listas_precios";
import subscribeTo from "../../utils/subscribeTo";
import AddRendicionBtn from "../rendiciones/AddRendicionBtn";
import RendicionForm from "../rendiciones/RendicionForm";
import RendicionesFilter from "../rendiciones/RendicionesFilter";
import RendicionItem from "./RendicionItem";
import moment from "moment";
import { getUsuarios } from "../../actions/usuarios";
import NumberFormat from "react-number-format";
import { setCurrentVenta } from "../../actions/ventas";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px"
  },
  tableRowChequeada: {
    backgroundColor: "#dae7da"
  }
});

const Rendiciones = ({
  rendiciones,
  filter,
  filtered,
  loading,
  dispatch,
  listas_precios,
  loading_listas_precios,
  usuarios,
  loading_usuarios,
  error
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const clickDelete = (rendicion) => {
    confirm({
      description: `Desea borrar el rendicion "${rendicion.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar"
    }).then(() => dispatch(deleteRendicion(rendicion.id)));
  };

  useEffect(() => {
    dispatch(getRendiciones());
    dispatch(getListasPrecios());
    dispatch(getUsuarios());
    if (!loading) {
      subscribeTo("rendicionesChanged", (err, data) => {
        dispatch(getRendiciones());
        dispatch(filterRendiciones(filter));
      });
      return () => {
        subscribeTo("rendicionesChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  const onChangeCheck = (e, id) => {
    dispatch(chequearRendicion(id, e.target.checked));
  };

  if (rendiciones !== null && !rendiciones.length && !loading) {
    return (
      <Fragment>
        <RendicionForm />
        <h4>No hay ningun rendicion todavía!</h4> <AddRendicionBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <RendicionForm />
      </div>

      <h1 className={classes.title}>Rendiciones</h1>

      {rendiciones && rendiciones.length && usuarios.length && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow width="3%" className={classes.tableHeader}>
                <TableCell width="5%" align="center">
                  Código
                </TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Importe Efectivo</TableCell>
                <TableCell align="center">Importe Depósito</TableCell>
                <TableCell align="center">Importe Cheque</TableCell>
                <TableCell align="center">Importe Total</TableCell>
                <TableCell align="center">Verificar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rendiciones &&
                rendiciones.length &&
                rendiciones.map((rendicion, index) => (
                  <>
                    <TableRow
                      className={rendicion.chequeada ? classes.tableRowChequeada : classes.tableRow}
                      key={index}
                    >
                      <TableCell width="8%" align="center">
                        {moment(rendicion.fecha_cobranza).format("YYMMDD") +
                          usuarios.find((u) => u.id === rendicion.rendidor_id).nombre.toUpperCase() +
                          rendicion.n_ruta}
                      </TableCell>
                      <TableCell align="center">{moment(rendicion.fecha_cobranza).format("DD/MM/YYYY")}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          mask=""
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          margin="dense"
                          name="horaAtencion2Fin"
                          label="Fin Tarde"
                          value={parseFloat(rendicion.importe_efectivo)}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>

                      <TableCell align="right">
                        {" "}
                        <NumberFormat
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          mask=""
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          margin="dense"
                          name="horaAtencion2Fin"
                          label="Fin Tarde"
                          value={parseFloat(rendicion.importe_deposito)}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <NumberFormat
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          mask=""
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          margin="dense"
                          name="horaAtencion2Fin"
                          label="Fin Tarde"
                          value={parseFloat(rendicion.importe_cheque)}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <NumberFormat
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          mask=""
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          margin="dense"
                          name="horaAtencion2Fin"
                          label="Fin Tarde"
                          value={parseFloat(rendicion.importe_efectivo) + parseFloat(rendicion.importe_deposito) + parseFloat(rendicion.importe_cheque)}
                          variant="outlined"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <FormControlLabel
                          value="fijar"
                          control={
                            <Checkbox
                              margin="dense"
                              name="fijar"
                              label="Fijado"
                              value="1"
                              checked={rendicion.chequeada}
                              onChange={(e) => onChangeCheck(e, rendicion.id)}
                              className={classes.textField}
                              variant="outlined"
                            />
                          }
                          label=""
                          labelPlacement="end"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    rendiciones: state.rendiciones.rendiciones,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    filtered: state.rendiciones.filtered,
    filter: state.rendiciones.filter,
    loading: state.rendiciones.loading,
    error: state.rendiciones.error,
    usuarios: state.usuarios.usuarios,
    loading_usuarios: state.usuarios.loading_usuarios
  };
};

export default connect(mapStateToProps)(Rendiciones);
