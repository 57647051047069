export const GET_CONTACTS = "GET_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const SET_CURRENT = "SET_CURRENT";
export const SET_CURRENT_PUNTO_LOGISTICO = "SET_CURRENT_PUNTO_LOGISTICO";
export const SET_OPEN = "SET_OPEN";
export const SET_OPEN_PUNTOLOGISTICO = "SET_OPEN_PUNTOLOGISTICO";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_CURRENT_PUNTO_LOGISTICO = "CLEAR_CURRENT_PUNTO_LOGISTICO";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const FILTER_CONTACTS = "FILTER_CONTACTS";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_TIME = "SET_TIME";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const CONTACT_ERROR = "CONTACT_ERROR";

export const GET_GASTOS = "GET_GASTOS";
export const ADD_GASTO = "ADD_GASTO";
export const UPDATE_GASTO = "UPDATE_GASTO";
export const DELETE_GASTO = "DELETE_GASTO";
export const CLEAR_GASTOS = "CLEAR_GASTOS";
export const GASTO_ERROR = "GASTO_ERROR";
export const FILTER_GASTOS = "FILTER_GASTOS";
export const SET_SAVING_GASTO = "SET_SAVING_GASTO";

export const GET_COBRANZAS = "GET_COBRANZAS";
export const ADD_COBRANZA = "ADD_COBRANZA";
export const SET_CURRENT_COBRANZA = "SET_CURRENT_COBRANZA";
export const LOADING_CUENTA_CORRIENTE = "LOADING_CUENTA_CORRIENTE";
export const LOADING_COBRANZAS = "LOADING_COBRANZAS";
export const UPDATE_COBRANZA = "UPDATE_COBRANZA";
export const DELETE_COBRANZA = "DELETE_COBRANZA";
export const CLEAR_COBRANZAS = "CLEAR_COBRANZAS";
export const COBRANZA_ERROR = "COBRANZA_ERROR";
export const FILTER_COBRANZAS = "FILTER_COBRANZAS";
export const GET_SALDOS_CLIENTES = "GET_SALDOS_CLIENTES";
export const SET_SAVING_COBRANZA = "SET_SAVING_COBRANZA";
export const GET_CUENTA_CORRIENTE = "GET_CUENTA_CORRIENTE";

export const GET_RENDICIONES = "GET_RENDICIONES";
export const ADD_RENDICION = "ADD_RENDICION";
export const UPDATE_RENDICION = "UPDATE_RENDICION";
export const DELETE_RENDICION = "DELETE_RENDICION";
export const CLEAR_RENDICIONES = "CLEAR_RENDICIONES";
export const RENDICION_ERROR = "RENDICION_ERROR";
export const FILTER_RENDICIONES = "FILTER_RENDICIONES";
export const SET_SAVING_RENDICION = "SET_SAVING_RENDICION";
export const CHEQUEAR_RENDICION = "CHEQUEAR_RENDICION";

export const GET_USUARIOS = "GET_USUARIOS";
export const ADD_USUARIO = "ADD_USUARIO";
export const UPDATE_USUARIO = "UPDATE_USUARIO";
export const DELETE_USUARIO = "DELETE_USUARIO";
export const CLEAR_USUARIOS = "CLEAR_USUARIOS";
export const USUARIO_ERROR = "USUARIO_ERROR";
export const FILTER_USUARIOS = "FILTER_USUARIOS";
export const SET_SAVING_USUARIO = "SET_SAVING_USUARIO";

export const GET_PROVEDOR = "GET";
export const ADD_PROVEDOR = "ADD";
export const DELETE_PROVEDOR = "DELETE";

export const UPDATE_PROVEDOR = "UPDATE_PROVEDOR";
export const FILTER_PROVEDOR = "FILTER_PROVEDOR";
export const CLEAR_PROVEDOR = "CLEAR_PROVEDOR";
export const CLEAR_FILTER_PROVEDOR = "CLEAR_FILTER_PROVEDOR";
export const ERROR_PROVEDOR = "ERROR_PROVEDOR";
export const SET_CURRENT_PROVEDOR = "SET_CURRENT_PROVEDOR";
export const CLEAR_CURRENT_PROVEDOR = "CLEAR_CURRENT_PROVEDOR";
export const SET_OPEN_PROVEDOR = "SET_OPEN_PROVEDOR";

export const ADD_CAJA = "ADD_CAJA";
export const DELETE_CAJA = "DELETE_CAJA";
export const SET_CURRENT_CAJA = "SET_CURRENT_CAJA";
export const CLEAR_CURRENT_CAJA = "CLEAR_CURRENT_CAJA";
export const UPDATE_CAJA = "UPDATE_CAJA";
export const FILTER_CAJA = "FILTER_CAJA";
export const CLEAR_FILTER_CAJA = "CLEAR_FILTER_CAJA";
export const ERROR_CAJA = "ERROR_CAJA";
export const GET_CAJA = "GET_CAJA";
export const CLEAR_CAJA = "CLEAR_CAJA";
export const SET_OPEN_CAJA = "SET_OPEN_CAJA";
export const TRANSFER_CAJA = "TRANSFER_CAJA";

export const ADD_CHEQUE = "ADD_CHEQUE";
export const DELETE_CHEQUE = "DELETE_CHEQUE";
export const SET_CURRENT_CHEQUE = "SET_CURRENT_CHEQUE";
export const CLEAR_CURRENT_CHEQUE = "CLEAR_CURRENT_CHEQUE";
export const UPDATE_CHEQUE = "UPDATE_CHEQUE";
export const FILTER_CHEQUE = "FILTER_CHEQUE";
export const CLEAR_FILTER_CHEQUE = "CLEAR_FILTER_CHEQUE";
export const ERROR_CHEQUE = "ERROR_CHEQUE";
export const GET_CHEQUE = "GET_CHEQUE";
export const CLEAR_CHEQUE = "CLEAR_CHEQUE";
export const SET_OPEN_CHEQUE = "SET_OPEN_CHEQUE";
export const DEPOSITAR_MULTIPLES_CHEQUES = "DEPOSITAR_MULTIPLES_CHEQUES";

export const ADD_CHEQUE_PROPIO = "ADD_CHEQUE_PROPIO";
export const DELETE_CHEQUE_PROPIO = "DELETE_CHEQUE_PROPIO";
export const SET_CURRENT_CHEQUE_PROPIO = "SET_CURRENT_CHEQUE_PROPIO";
export const CLEAR_CURRENT_CHEQUE_PROPIO = "CLEAR_CURRENT_CHEQUE_PROPIO";
export const UPDATE_CHEQUE_PROPIO = "UPDATE_CHEQUE_PROPIO";
export const FILTER_CHEQUE_PROPIO = "FILTER_CHEQUE_PROPIO";
export const CLEAR_FILTER_CHEQUE_PROPIO = "CLEAR_FILTER_CHEQUE_PROPIO";
export const ERROR_CHEQUE_PROPIO = "ERROR_CHEQUE_PROPIO";
export const GET_CHEQUE_PROPIO = "GET_CHEQUE_PROPIO";
export const CLEAR_CHEQUE_PROPIO = "CLEAR_CHEQUE_PROPIO";
export const SET_OPEN_CHEQUE_PROPIO = "SET_OPEN_CHEQUE_PROPIO";

export const ADD_CLIENTE = "ADD_CLIENTE";
export const DELETE_CLIENTE = "DELETE_CLIENTE";
export const SET_CURRENT_CLIENTE = "SET_CURRENT_CLIENTE";
export const CLEAR_CURRENT_CLIENTE = "CLEAR_CURRENT_CLIENTE";
export const UPDATE_CLIENTE = "UPDATE_CLIENTE";
export const FILTER_CLIENTE = "FILTER_CLIENTE";
export const CLEAR_FILTER_CLIENTE = "CLEAR_FILTER_CLIENTE";
export const FILTER_CLIENTE_DIR = "FILTER_CLIENTE_DIR";
export const FILTER_CLIENTE_ZONA = "FILTER_CLIENTE_ZONA";

export const ERROR_CLIENTE = "ERROR_CLIENTE";
export const GET_CLIENTE = "GET_CLIENTE";
export const CLEAR_CLIENTE = "CLEAR_CLIENTE";
export const SET_OPEN_CLIENTE = "SET_OPEN_CLIENTE";
export const OBTENER_CLIENTE_AFIP = "OBTENER_CLIENTE_AFIP";
export const SET_LOADING_AFIP = "SET_LOADING_AFIP";
export const SET_SAVING_CLIENTE = "SET_SAVING_CLIENTE";
export const FILTER_CLIENTES_PANEL_VENTAS = "FILTER_CLIENTES_PANEL_VENTAS";
export const ADD_CONTACTO = "ADD_CONTACTO";
export const SET_BYPASS_IO_CLIENTES = "SET_BYPASS_IO_CLIENTES";

export const ADD_BANCO = "ADD_BANCO";
export const DELETE_BANCO = "DELETE_BANCO";
export const SET_CURRENT_BANCO = "SET_CURRENT_BANCO";
export const CLEAR_CURRENT_BANCO = "CLEAR_CURRENT_BANCO";
export const UPDATE_BANCO = "UPDATE_BANCO";
export const FILTER_BANCO = "FILTER_BANCO";
export const CLEAR_FILTER_BANCO = "CLEAR_FILTER_BANCO";
export const ERROR_BANCO = "ERROR_BANCO";
export const GET_BANCO = "GET_BANCO";
export const CLEAR_BANCO = "CLEAR_BANCO";
export const SET_OPEN_BANCO = "SET_OPEN_BANCO";

export const GET_LANZAMIENTOS_DE_CAJA = "GET_LANZAMIENTOS_DE_CAJA";
export const ERROR_LANZAMIENTOS_DE_CAJA = "ERROR_LANZAMIENTOS_DE_CAJA";

export const ADD_LISTA_PRECIOS = "ADD_LISTA_PRECIOS";
export const DELETE_LISTA_PRECIOS = "DELETE_LISTA_PRECIOS";
export const SET_CURRENT_LISTA_PRECIOS = "SET_CURRENT_LISTA_PRECIOS";
export const CLEAR_CURRENT_LISTA_PRECIOS = "CLEAR_CURRENT_LISTA_PRECIOS";
export const UPDATE_LISTA_PRECIOS = "UPDATE_LISTA_PRECIOS";
export const FILTER_LISTA_PRECIOS = "FILTER_LISTA_PRECIOS";
export const CLEAR_FILTER_LISTA_PRECIOS = "CLEAR_FILTER_LISTA_PRECIOS";
export const ERROR_LISTA_PRECIOS = "ERROR_LISTA_PRECIOS";
export const GET_LISTA_PRECIOS = "GET_LISTA_PRECIOS";
export const CLEAR_LISTA_PRECIOS = "CLEAR_LISTA_PRECIOS";
export const SET_OPEN_LISTA_PRECIOS = "SET_OPEN_LISTA_PRECIOS";

export const ADD_VENTAS_ESTIMADAS = "ADD_VENTAS_ESTIMADAS";
export const DELETE_VENTAS_ESTIMADAS = "DELETE_VENTAS_ESTIMADAS";
export const SET_CURRENT_VENTAS_ESTIMADAS = "SET_CURRENT_VENTAS_ESTIMADAS";
export const CLEAR_CURRENT_VENTAS_ESTIMADAS = "CLEAR_CURRENT_VENTAS_ESTIMADAS";
export const UPDATE_VENTAS_ESTIMADAS = "UPDATE_VENTAS_ESTIMADAS";
export const FILTER_VENTAS_ESTIMADAS = "FILTER_VENTAS_ESTIMADAS";
export const CLEAR_FILTER_VENTAS_ESTIMADAS = "CLEAR_FILTER_VENTAS_ESTIMADAS";
export const ERROR_VENTAS_ESTIMADAS = "ERROR_VENTAS_ESTIMADAS";
export const GET_VENTAS_ESTIMADAS = "GET_VENTAS_ESTIMADAS";
export const CLEAR_VENTAS_ESTIMADAS = "CLEAR_VENTAS_ESTIMADAS";
export const SET_OPEN_VENTAS_ESTIMADAS = "SET_OPEN_VENTAS_ESTIMADAS";
export const GET_MIX_VENTAS = "GET_MIX_VENTAS";
export const AJUSTAR_STOCK = "AJUSTAR_STOCK";
export const GET_AJUSTES_STOCK = "GET_AJUSTES_STOCK";

export const ADD_PRODUCTO = "ADD_PRODUCTO";
export const DELETE_PRODUCTO = "DELETE_PRODUCTO";
export const SET_CURRENT_PRODUCTO = "SET_CURRENT_PRODUCTO";
export const CLEAR_CURRENT_PRODUCTO = "CLEAR_CURRENT_PRODUCTO";
export const UPDATE_PRODUCTO = "UPDATE_PRODUCTO";
export const FILTER_PRODUCTO = "FILTER_PRODUCTO";
export const CLEAR_FILTER_PRODUCTO = "CLEAR_FILTER_PRODUCTO";
export const ERROR_PRODUCTO = "ERROR_PRODUCTO";
export const GET_PRODUCTO = "GET_PRODUCTO";
export const CLEAR_PRODUCTO = "CLEAR_PRODUCTO";
export const SET_OPEN_PRODUCTO = "SET_OPEN_PRODUCTO";

export const GET_VENTAS = "GET_VENTAS";
export const GET_VENTAS_CLIENTE = "GET_VENTAS_CLIENTE";
export const GET_VENTAS_CLIENTE_NO_PAGAS = "GET_VENTAS_CLIENTE_NO_PAGAS";

export const GET_PUNTOS_LOGISTICOS = "GET_PUNTOS_LOGISTICOS";

export const ADD_VENTA = "ADD_VENTA";
export const UPDATE_VENTA = "UPDATE_VENTA";
export const DELETE_VENTA = "DELETE_VENTA";
export const CLEAR_VENTAS = "CLEAR_VENTAS";
export const FILTER_VENTAS = "FILTER_VENTAS";
export const SET_SAVING_VENTA = "SET_SAVING_VENTA";
export const ERROR_VENTA = "ERROR_VENTA";
export const CAMBIAR_ESTADO_VENTA = "CAMBIAR_ESTADO_VENTA";
export const SET_BYPASS_IO_VENTAS = "SET_BYPASS_IO_VENTAS";
export const ADD_PUNTO_LOGISTICO = "ADD_PUNTO_LOGISTICO";
export const UPDATE_PUNTO_LOGISTICO = "UPDATE_PUNTO_LOGISTICO";
export const DELETE_PUNTO_LOGISTICO = "DELETE_PUNTO_LOGISTICO";
export const SET_SAVING_PUNTO_LOGISTICO = "SET_SAVING_PUNTO_LOGISTICO";
export const ERROR_PUNTO_LOGISTICO = "ERROR_PUNTO_LOGISTICO";
export const ADD_GRUPO = "ADD_GRUPO";
export const DELETE_GRUPO = "DELETE_GRUPO";
export const SET_CURRENT_GRUPO = "SET_CURRENT_GRUPO";
export const CLEAR_CURRENT_GRUPO = "CLEAR_CURRENT_GRUPO";
export const UPDATE_GRUPO = "UPDATE_GRUPO";
export const FILTER_GRUPO = "FILTER_GRUPO";
export const CLEAR_FILTER_GRUPO = "CLEAR_FILTER_GRUPO";
export const ERROR_GRUPO = "ERROR_GRUPO";
export const GET_GRUPO = "GET_GRUPO";
export const CLEAR_GRUPO = "CLEAR_GRUPO";
export const SET_OPEN_GRUPO = "SET_OPEN_GRUPO";
export const APROBAR_MULTIPLES = "APROBAR_MULTIPLES";
export const ASIGNAR_CAMIONETA = "ASIGNAR_CAMIONETA";
export const FILTRAR_VENTAS_FACTURADAS = "FILTRAR_VENTAS_FACTURADAS";
export const GET_VENTAS_FACTURADAS = "GET_VENTAS_FACTURADAS";
export const GET_VENTAS_PENDIENTES = "GET_VENTAS_PENDIENTES";
export const GET_VENTAS_PARA_COBRAR = "GET_VENTAS_PARA_COBRAR";
export const GET_VENTAS_APROBADAS = "GET_VENTAS_APROBADAS";
export const LOADING_VENTAS = "LOADING_VENTAS";

export const ADD_VENDEDOR = "ADD_VENDEDOR";
export const DELETE_VENDEDOR = "DELETE_VENDEDOR";
export const SET_CURRENT_VENDEDOR = "SET_CURRENT_VENDEDOR";
export const CLEAR_CURRENT_VENDEDOR = "CLEAR_CURRENT_VENDEDOR";
export const UPDATE_VENDEDOR = "UPDATE_VENDEDOR";
export const FILTER_VENDEDOR = "FILTER_VENDEDOR";
export const CLEAR_FILTER_VENDEDOR = "CLEAR_FILTER_VENDEDOR";
export const ERROR_VENDEDOR = "ERROR_VENDEDOR";
export const GET_VENDEDOR = "GET_VENDEDOR";
export const CLEAR_VENDEDOR = "CLEAR_VENDEDOR";
export const SET_OPEN_VENDEDOR = "SET_OPEN_VENDEDOR";

export const ADD_ZONA = "ADD_ZONA";
export const DELETE_ZONA = "DELETE_ZONA";
export const SET_CURRENT_ZONA = "SET_CURRENT_ZONA";
export const CLEAR_CURRENT_ZONA = "CLEAR_CURRENT_ZONA";
export const UPDATE_ZONA = "UPDATE_ZONA";
export const FILTER_ZONA = "FILTER_ZONA";
export const CLEAR_FILTER_ZONA = "CLEAR_FILTER_ZONA";
export const ERROR_ZONA = "ERROR_ZONA";
export const GET_ZONA = "GET_ZONA";
export const CLEAR_ZONA = "CLEAR_ZONA";
export const SET_OPEN_ZONA = "SET_OPEN_ZONA";
