import { Grid, Icon, LinearProgress, Snackbar } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import {
  deleteCliente,
  filterClientes,
  getClientes,
  setBypassIoClientes,
  setCurrent,
} from "../../actions/clientes";
import subscribeTo from "../../utils/subscribeTo";
import AddClienteBtn from "../clientes/AddClienteBtn";
import ClienteForm from "../clientes/ClienteForm";
import ClientesFilter from "../clientes/ClientesFilter";
import { getIsAdmin, getRoleCanDo } from "../usuarios/Usuario";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
});

const Clientes = ({
  clientes,
  filter,
  filtered,
  loading,
  dispatch,
  error,
  bypassIo,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(getClientes());
    if (!loading) {
      subscribeTo("clientesChanged", (err, data) => {
        if (bypassIo) {
          dispatch(getClientes());
        } else {
          dispatch(setBypassIoClientes(false));
        }

        if (filter) dispatch(filterClientes(filter));
      });
      return () => {
        subscribeTo("clientesChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  const onClickBorrar = (confirm, dispatch, cliente) => {
    confirm({
      description: `Desea desactivar el cliente "${cliente.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => dispatch(deleteCliente(cliente.id)));
  };

  if (clientes !== null && !clientes.length && !loading) {
    return (
      <Fragment>
        <ClienteForm />
        <h4>No hay ningun cliente todavía!</h4> <AddClienteBtn />
      </Fragment>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    console.log(clientes);
  }, [clientes]);

  return (
    <Fragment>
      <div>
        <ClienteForm />
      </div>
      <div>
        <ClientesFilter />
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <div className={classes.root}>
          {clientes !== null && !loading ? (
            <Fragment>
              <ReactTable
                data={
                  filtered
                    ? filtered
                    : clientes.filter((c) => !c.desactivado) || []
                }
                loading={loading}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (column.id !== "acciones" && column.id !== "email") {
                        if (rowInfo) dispatch(setCurrent(rowInfo.original));
                      }
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                  };
                }}
                previousText="Anterior"
                nextText="Próxima"
                loadingText="Cargando..."
                noDataText="Nada cargado"
                pageText="Pagina"
                ofText="de"
                rowsText="filas"
                pageJumpText="ir a la pág."
                rowsSelectorText="filas por pág."
                defaultPageSize={clientes.length}
                showPagination={false}
                columns={[
                  {
                    columns: [
                      {
                        Header: "ID",
                        accessor: "id",
                        style: { textAlign: "center" },
                        width: Math.round(window.innerWidth * (5 / 100)),
                      },
                      {
                        Header: "Nombre de Fantasía",
                        accessor: "nombre",
                        Cell: (row) =>
                          !row.original.contabilium_id &&
                          row.original.grupo_id !== 28 ? (
                            <span
                              style={{
                                cursor: "pointer",
                                backgroundColor: "red",
                              }}
                            >
                              {row.original.nombre}
                            </span>
                          ) : (
                            <span>{row.original.nombre}</span>
                          ),
                      },
                      {
                        Header: "Dirección Comercial",
                        accessor: "direccion_entrega",
                        width: Math.round(window.innerWidth * (35 / 100)),
                      },
                      {
                        Header: "Localidad",
                        accessor: "localidad_entrega",
                        width: Math.round(window.innerWidth * (10 / 100)),
                      },
                      {
                        Header: "CUIT",
                        style: { textAlign: "center" },
                        id: "cuit",
                        accessor: (row) => {
                          let tmp = row.cuit
                            ? [
                                row.cuit.toString().slice(0, 2),
                                "-",
                                row.cuit.toString().slice(2),
                              ].join("")
                            : "";
                          return [
                            tmp.toString().slice(0, 11),
                            "-",
                            tmp.slice(11),
                          ].join("");
                        },
                      },
                      {
                        Header: "Zona",
                        style: { textAlign: "center" },
                        accessor: "zona.nombre",
                        width: Math.round(window.innerWidth * (5 / 100)),
                      },
                      {
                        Header: "Acciones",
                        id: "acciones",
                        style: { textAlign: "center" },
                        width: Math.round(window.innerWidth * (5 / 100)),
                        accessor: (str) => "acciones",
                        show: getRoleCanDo(),
                        Cell: (row) =>
                          row.desactivado ? (
                            <span
                              style={{
                                cursor: "pointer",
                                color: "red",
                                textDecoration: "underline",
                              }}
                              onClick={(e) =>
                                onClickBorrar(confirm, dispatch, row.original)
                              }
                            >
                              Activar
                            </span>
                          ) : (
                            <span
                              style={{
                                cursor: "pointer",
                                color: "red",
                                textDecoration: "underline",
                              }}
                              onClick={(e) =>
                                onClickBorrar(confirm, dispatch, row.original)
                              }
                            >
                              <Icon>highlight_off</Icon>
                            </span>
                          ),
                      },
                    ],
                  },
                ]}
                className="-striped -highlight"
              />
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              ></Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddClienteBtn />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    filtered: state.clientes.filtered,
    filter: state.clientes.filter,
    loading: state.clientes.loading,
    error: state.clientes.error,
    bypassIo: state.clientes.bypassIo,
  };
};

export default connect(mapStateToProps)(Clientes);
