import {
  Icon,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import { default as React, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClientes } from "../../actions/clientes";
import {
  clearCurrent,
  getCobranzas,
  setOpen,
  getCuentaCorriente,
  setCurrentCobranza,
} from "../../actions/cobranzas";
import moment from "moment";
import NumberFormat from "react-number-format";
import { esNotaDeCredito, esPagoACuenta, esX } from "../../utils/functions";
import { getVentasCliente } from "../../actions/ventas";
import CobranzaForm from "./CobranzaForm";
import { tiposComprobante } from "../../config/constantes";
/** SQL
 
UPDATE ventas SET saldada=1 WHERE id NOT IN (SELECT id FROM `ventas` WHERE cliente_id IN (SELECT id FROM clientes WHERE grupo_id=21)) AND cliente_id<>301 AND cliente_id<>210 AND clasificacion <> 4;
UPDATE ventas SET saldada=1 WHERE cliente_id<>301 AND cliente_id<>210;
  
 */
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formHeader: {
    backgroundColor: "#4caf50",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",

    padding: "10px",
  },
}));

const CuentaCorriente = ({
  children,
  current,
  isGroup = false,
  setCurrent,
  saving,
  dispatch,
  error,
  clientes,
  loading_clientes,
  ventas_clientes,
  loading_ventas,
  cobranzas,
  loading_cuenta_corriente,
  cuenta_corriente,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (current && current.id)
      dispatch(getCuentaCorriente((isGroup ? "g" : "") + current.id));
  }, [current]);

  const [sortedResults, setSortedresults] = useState([]);
  const [originalSort, setOriginalSort] = useState(false);

  useEffect(() => {
    if (!loading_cuenta_corriente && cuenta_corriente && current) {
      console.log(cuenta_corriente);
      let sorted = [];
      cuenta_corriente.ventas
        .filter(
          (v) =>
            v.estado_id == 3 &&
            !v.saldada &&
            (current.x
              ? v.clasificacion == 3 ||
                (v.clasificacion == 4 && v.tipo_comprobante_id === 3)
              : v.clasificacion == 1 ||
                (v.clasificacion == 4 && v.tipo_comprobante_id === 1))
        )
        .sort((a, b) => {
          let propA = moment(a.fecha_venta).format("YYYYMMDD");
          let propB = moment(a.fecha_venta).format("YYYYMMDD");
          if (a.rendicion_id)
            propA = moment(a.rendicion.fecha_cobranza).format("YYYYMMDD");
          if (b.rendicion_id)
            propB = moment(b.rendicion.fecha_cobranza).format("YYYYMMDD");
          if (propA < propB) return -1;
          if (propA > propB) return 1;
          return 0;
        })
        .map((v, i, allVentas) => {
          let total = 0;
          if (i > 0) {
            if (esPagoACuenta(v.tipo_comprobante_id))
              total = -v.credito; //NC ya viene negativo el valor
            else total = v.total;
            allVentas[i].saldo =
              total +
              allVentas[i - 1].saldo -
              v.cobranzas.reduce((a, b) => a + b.importe, 0);
            return {
              ...v,
              total,
              ...(originalSort
                ? {
                    saldo:
                      total +
                      allVentas[i - 1].saldo -
                      v.cobranzas.reduce((a, b) => a + b.importe, 0),
                  }
                : { saldo: -total - allVentas[i - 1].saldo }),
            };
          } else {
            if (
              esNotaDeCredito(v.tipo_comprobante_id) ||
              esPagoACuenta(v.tipo_comprobante_id)
            )
              total = v.total; //NC ya viene negativo el valor
            else total = v.total;
            allVentas[i].saldo =
              total - v.cobranzas.reduce((a, b) => a + b.importe, 0);
            return {
              ...v,
              total,
              ...(originalSort
                ? {
                    saldo: -total,
                  }
                : {
                    saldo: -total,
                  }),
            };
          }
        })

        .forEach((venta) => {
          if (originalSort) sorted.push(venta);
          venta.cobranzas.forEach((cobranza) =>
            sorted.push({
              ...cobranza,
              es_cobranza: true,
              tipo_comprobante_id: venta.tipo_comprobante_id,
              numero_factura: venta.numero_factura,
            })
          );
          if (!originalSort) sorted.push(venta);
        });

      sorted = !originalSort
        ? sorted.sort(
            (a, b) =>
              moment(
                b.rendicion_id ? b.rendicion.fecha_cobranza : b.fecha_venta
              ).format("YYYYMMDD") -
              moment(
                a.rendicion_id ? a.rendicion.fecha_cobranza : a.fecha_venta
              ).format("YYYYMMDD")
          )
        : sorted;
      let finalSorted = [];
      let currentSaldo = 0;
      for (let v of sorted) {
        if (v.es_cobranza) {
          currentSaldo += v.importe;
        } else {
          currentSaldo -= v.total;
        }
        finalSorted.push({ ...v, saldo: currentSaldo });
      }
      if (originalSort) setSortedresults(finalSorted.reverse());
      else setSortedresults(finalSorted);
      console.log("sorted", sorted);
    }
  }, [loading_cuenta_corriente, cuenta_corriente, originalSort]);

  function handleClose() {
    setCurrent(null);
  } // To disable any eslint 'google not defined' errors

  console.log("cuentaC", cuenta_corriente);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      style={
        !useMediaQuery(useTheme().breakpoints.down("sm"))
          ? { width: "90%", height: "95%", margin: "auto" }
          : {}
      }
      open={current ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      {current && cuenta_corriente && (
        <Fragment>
          <CobranzaForm />
          <DialogTitle
            id="max-width-dialog-title"
            color="primary"
            className={classes.formHeader}
          >
            {!loading_cuenta_corriente && (
              <>
                {cuenta_corriente.nombre} - Cuenta Corriente{" "}
                {current.x ? "X" : "A"}
              </>
            )}
          </DialogTitle>
          <DialogContent dividers={true}>
            {error && (
              <Snackbar
                open={error ? true : false}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <Table>
                  <TableHead>
                    <TableRow width="3%" className={classes.tableHeader}>
                      <TableCell align="center">Cod.</TableCell>
                      <TableCell align="center">Fecha</TableCell>
                      <TableCell align="center">Comprobante</TableCell>
                      <TableCell align="center">Valor Facturado</TableCell>
                      <TableCell align="center">Valor Cobrado</TableCell>
                      <TableCell align="center">Saldo</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading_cuenta_corriente && <>Cargando...</>}
                    {!loading_cuenta_corriente &&
                      sortedResults.map((venta, index) => {
                        return (
                          <>
                            <TableRow
                              key={venta.id + "_" + venta.rendicion_id}
                              style={{
                                backgroundColor: "#ececec",
                              }}
                            >
                              <TableCell
                                className={classes.tableCell}
                                width="5%"
                                align="center"
                              >
                                {venta.rendicion_id ? "" : venta.id}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                {venta.rendicion_id
                                  ? moment(
                                      venta.rendicion.fecha_cobranza
                                    ).format("DD/MM/YYYY")
                                  : moment(venta.fecha_venta).format(
                                      "DD/MM/YYYY"
                                    )}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                {venta.rendicion_id
                                  ? ""
                                  : venta.clasificacion === 4
                                  ? "Factura Ficticia"
                                  : venta.tipo_comprobante_id === 9 ||
                                    venta.tipo_comprobante_id === 10
                                  ? "Pago a cuenta " + venta.id
                                  : venta.numero_factura
                                  ? venta.numero_factura === "A-0000000000" ||
                                    venta.numero_factura === "X-0000000000"
                                    ? "Saldo pendiente"
                                    : tiposComprobante[
                                        venta.tipo_comprobante_id
                                      ].ini +
                                      " " +
                                      venta.numero_factura
                                  : `X${"0".repeat(
                                      9 - venta.id.toString().length
                                    )}${venta.id}`}
                                {venta.es_cobranza &&
                                  tiposComprobante[venta.tipo_comprobante_id]
                                    .ini +
                                    " " +
                                    (venta.numero_factura
                                      ? venta.numero_factura
                                      : `X${"0".repeat(
                                          9 - venta.id.toString().length
                                        )}${venta.id}`) +
                                    " (COB-" +
                                    venta.id +
                                    ")"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                width="8%"
                                align="center"
                              >
                                {venta.tipo_comprobante_id !== 9 &&
                                  venta.tipo_comprobante_id !== 10 && (
                                    <NumberFormat
                                      displayType="text"
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      mask=""
                                      prefix="$"
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      margin="dense"
                                      name="horaAtencion2Fin"
                                      label="Fin Tarde"
                                      value={
                                        !venta.es_cobranza
                                          ? venta.total
                                          : venta.balance
                                      }
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                width="8%"
                                align="center"
                              >
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  mask=""
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  margin="dense"
                                  name="horaAtencion2Fin"
                                  label="Fin Tarde"
                                  value={
                                    venta.tipo_comprobante_id === 9 ||
                                    venta.tipo_comprobante_id === 10
                                      ? -venta.total
                                      : !venta.es_cobranza
                                      ? venta.rendicion_id
                                        ? venta.ventas.reduce(
                                            (a, b) => a + b.importe,
                                            0
                                          )
                                        : ""
                                      : venta.rendicion_id
                                      ? venta.importe
                                      : ""
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                width="8%"
                                align="center"
                              >
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  mask=""
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  margin="dense"
                                  name="horaAtencion2Fin"
                                  label="Fin Tarde"
                                  value={-venta.saldo}
                                  variant="outlined"
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell align="center" width="5%">
                                {!venta.es_cobranza ? (
                                  <span
                                    title="Comprobante"
                                    className={classes.editButton}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      let w = window.open(
                                        "/api/invoice/pdf/original/" + venta.id
                                      );
                                    }}
                                  >
                                    <Icon className={classes.listIcon}>
                                      receipt
                                    </Icon>
                                  </span>
                                ) : (
                                  <span
                                    title="Comprobante"
                                    className={classes.editButton}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      let w = window.open(
                                        "/api/comprobanteCobranza/pdf/" +
                                          venta.id
                                      );
                                    }}
                                  >
                                    <Icon className={classes.listIcon}>
                                      receipt
                                    </Icon>
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.formFooter}>
            {(typeof current.id !== "string" ||
              current.id.indexOf("g") === -1) && (
              <Button
                onClick={() => setOriginalSort(!originalSort)}
                variant="contained"
                style={{ backgroundColor: "#f5f5f5", color: "#000" }}
              >
                {!originalSort ? "Cambiar orden" : "Orden cronológico"}
              </Button>
            )}
            <Button
              onClick={(e) => {
                let w = window.open(
                  "/api/cobranzas/exportar_cuenta_corriente/" +
                    current.id +
                    "/" +
                    (current.x ? "1" : "0")
                );
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <Icon>table_view</Icon> Exportar
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cerrar
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    loading_clientes: state.clientes.loading,
    ventas_clientes: state.ventas.ventas_clientes,
    loading_ventas: state.ventas.loading,
    cobranzas: state.cobranzas.cobranzas,
    cuenta_corriente: state.cobranzas.cuenta_corriente,
    loading_cuenta_corriente: state.cobranzas.loading_cuenta_corriente,
  };
};

export default connect(mapStateToProps)(CuentaCorriente);
