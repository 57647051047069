import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteCaja, clearCurrent, setCurrent } from "../../actions/cajas";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Grid,
  Typography,
  CardHeader,
  CardActions,
  Button,
  CardActionArea
} from "@material-ui/core/";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import { green } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import WhatsAppIcon from "../utils/WhatsAppIcon";
import { tiposCaja } from "./Cajas";

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: "#f7f7f7"
  },
  addressLink: {
    color: "black",
    textDecorationLine: "none",

    overflow: "hidden",

    "&:hover": {
      textDecorationLine: "underline"
    }
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: "180px",
    overflowY: "auto",
    fontSize: "0.9rem"
  },
  cardTitle: {
    fontSize: "1.15rem"
  }
});

const CajaItem = ({ caja, cajas, current, dispatch }) => {
  const { id, nombre, caja_tipo_id, balance } = caja;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteCaja(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, caja) => {
    confirm({
      description: `Desea borrar la caja "${caja.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar"
    }).then(() => onDelete());
  };

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            className={classes.cardTitle}
          >
            {nombre}
          </Typography>

          <p color="textSecondary">
            Tipo: {tiposCaja.find((tc) => tc.id === caja_tipo_id).nombre}
          </p>
          <p color="textSecondary">
            Balance: ${Math.round(balance * 100) / 100}
          </p>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            onClick={(e) => onClickBorrar(confirm, dispatch, caja)}
            size="small"
            color="secondary"
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => dispatch(setCurrent(caja))}
            size="small"
            color="primary"
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

CajaItem.propTypes = {
  caja: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    cajas: state.cajas.cajas,
    current: state.cajas.current
  };
};

export default connect(mapStateToProps)(CajaItem);
