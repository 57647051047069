import axios from "axios";

import {
  ADD_CONTACT,
  DELETE_CONTACT,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_CONTACT,
  FILTER_CONTACTS,
  CLEAR_FILTER,
  CONTACT_ERROR,
  GET_CONTACTS,
  CLEAR_CONTACTS,
  SET_OPEN,
  SET_TIME,
} from "./types";

//Add Transportista
export const addTransportista = (transportista) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "/api/transportistas",
        transportista,
        config
      );
      dispatch({ type: ADD_CONTACT, payload: res.data });
    } catch (error) {
      dispatch({ type: CONTACT_ERROR, payload: error.msg });
    }
  };
};

export const getTransportistas = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/transportistas");
      dispatch({ type: GET_CONTACTS, payload: res.data });
    } catch (error) {
      dispatch({ type: CONTACT_ERROR, payload: error.msg });
    }
  };
};

//Delete Transportista

export const deleteTransportista = (transportistaId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/transportistas/${transportistaId}`);

      dispatch({ type: DELETE_CONTACT, payload: transportistaId });
    } catch (error) {
      dispatch({ type: CONTACT_ERROR, payload: error.response.msg });
    }
  };
};

export const getTiempoPedidos = (tiempo) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "/api/transportistas/tiempos",
        { fecha: tiempo },
        config
      );
      dispatch({ type: SET_TIME, payload: res.data });
    } catch (error) {
      dispatch({ type: CONTACT_ERROR, payload: error.msg });
    }
  };
};

//Set Current Transportista

export function setCurrent(transportista, view = false) {
  if (view) {
    transportista.__view = true;
  } else {
    transportista.__view = false;
  }
  return (dispatch) => {
    dispatch({ type: SET_CURRENT, payload: transportista });
  };
}

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

//Clear Current Transportista
export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT });
  };
};
//Update Transportista

export const updateTransportista = (transportista) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/transportistas/${transportista.id}`,
        transportista,
        config
      );
      dispatch({ type: UPDATE_CONTACT, payload: res.data });
    } catch (error) {
      dispatch({ type: CONTACT_ERROR, payload: error.response.msg });
    }
  };
};

//Filter Transportista

export const filterTransportistas = (text) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CONTACTS, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

export const clearTransportistas = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CONTACTS });
  };
};
