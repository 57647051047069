import {
  Button,
  Accordion,
  AccordionDetails,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { clearFilter, filterCobranzas } from "../../actions/cobranzas";
import { getTipoCobranzas } from "../usuarios/Usuario";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  listIcon: {
    paddingRight: "25px",
  },
}));

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CobranzasFilter = ({
  cobranzas,
  filters,
  setOpen,
  filtered,
  dispatch,
  open,
}) => {
  const descripcionTxt = useRef(null);
  const tipo = useRef(null);

  const classes = useStyles();

  const [internalFilters, setInternalFilters] = useState(filters);

  useEffect(() => {}, [open]);

  const onChange = (e, tipo) => {
    setInternalFilters({ ...internalFilters, [tipo]: e.target.value });
  };
  const handleClose = () => {
    setInternalFilters({ descripcion: "", tipo: "" });
    dispatch(clearFilter());
    setOpen(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(filterCobranzas(internalFilters, tipo));
    setOpen(false);
  };
  const handleSelect = (ranges, filter) => {
    setInternalFilters({
      ...internalFilters,
      [filter]: ranges,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          color="secondary"
        >
          <Icon className={classes.listIcon}>search</Icon>{" "}
          <Typography className={classes.heading}>Filtrar</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.root}>
            <Grid item xs={12} md={6}>
              <Grid container className={classes.root}>
                <Grid item xs={12} md={2} align="right">
                  Fecha Factura:{" "}
                </Grid>
                <Grid item xs={12} md={10}>
                  <DateRangePicker
                    value={internalFilters.fecha_cobranza}
                    onChange={(ranges) =>
                      handleSelect(ranges, "fecha_cobranza")
                    }
                    locale="es-AR"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  &nbsp;
                </Grid>

                <Grid item xs={12} md={2} align="right">
                  <p>Descripcion: </p>
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    inputRef={descripcionTxt}
                    type="text"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    style={{ paddingBottom: "10px" }}
                    value={internalFilters.descripcion}
                    onChange={(e) => onChange(e, "descripcion")}
                  />
                </Grid>

                <Grid item xs={12} md={2} align="right">
                  <p>Tipo:&nbsp;</p>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormControl
                    variant="outlined"
                    style={{
                      height: "40px",
                      width: "100%",
                      paddingTop: "8px",
                    }}
                  >
                    <Select
                      inputRef={tipo}
                      input={
                        <OutlinedInput
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                        />
                      }
                      fullWidth
                      value={internalFilters.tipo}
                      onChange={(e) => onChange(e, "tipo")}
                    >
                      {getTipoCobranzas().map((tipo) => (
                        <MenuItem value={tipo} key={tipo}>
                          {tipo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} md={12} align="right">
                  <Button
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="secondary"
                  >
                    Limpiar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    filters: state.cobranzas.filters,
    filtered: state.cobranzas.filtered,
    cobranzas: state.cobranzas,
  };
};

export default connect(mapStateToProps)(CobranzasFilter);
