import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Snackbar,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
} from "@material-ui/core/";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import axios from "axios";
import fileDownload from "js-file-download";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { Fragment, useEffect, useState, useContext } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import ReactSelectNonCreate from "react-select";

import { getClientes } from "../../actions/clientes";
import { getProductos } from "../../actions/productos";
import {
  cambiarEstadoVenta,
  deleteVenta,
  getVentas,
  getVentasFacturadas,
  setBypassIoVentas,
  setCurrentVenta,
  subirFactura,
} from "../../actions/ventas";
import { cEstados, tiposFormasDePago } from "../../config/constantes";
import { cTiposComprobantes } from "../../config/constantesServer";
import { esNotaDeCredito } from "../../utils/functions";
import subscribeTo from "../../utils/subscribeTo";
import { getIsAdmin, getRoleCanDo } from "../usuarios/Usuario";
import ImprimirMultipleBtn from "../ventas/ImprimirMultipleBtn";
import VentaForm from "../ventas/VentaForm";
import AddVentaBtn from "./AddVentaBtn";
import Box from "@material-ui/core/Box";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(),
  },
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  gridContainer: {
    width: "96vw",
    overflowX: "auto",
  },
  gridContainerLoading: {
    cursor: "progress!important",
    opacity: "0.5",

    "-webkit-filter": "blur(2px)",
    filter: "blur(2px)",
  },
  listIcon: {
    //paddingRight: '25px'
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },

  deleteButton: {
    cursor: "pointer",
    color: "red",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginLeft: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,0,0,0.1)",
    },
  },
  editButton: {
    cursor: "pointer",
    color: "blue",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  facturarButton: {
    cursor: "pointer",
    color: "#00FF00",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(204,204,0,0.1)",
    },
  },

  approveButton: {
    cursor: "pointer",
    color: "green",
    padding: "5px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,204,0,0.1)",
    },
  },
  moneyButton: {
    cursor: "pointer",
    color: "green",
    filter: "brightness(75%)",
    "-webkit-filter": "brightness(75%)",
    marginRight: "5px",
    padding: "10px",
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: "rgba(0,0,204,0.1)",
    },
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  expansionPanelFilter: {
    padding: "0 10px 20px 10px",
  },

  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  factura: {
    borderRadius: "20px",
    border: "1px solid rgba(0,255,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(0,255,0,0.1)",
    margin: "auto",
  },
  notaCredito: {
    borderRadius: "20px",
    border: "1px solid rgba(255,0,0,0.5)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,0,0,0.1)",
    margin: "auto",
  },
  facturaX: {
    borderRadius: "20px",
    border: "1px solid rgba(255,255,0,0.9)",
    minWidth: "110px",
    padding: "0px",
    backgroundColor: "rgba(255,255,0,0.1)",
    margin: "auto",
  },
}));
const onClickBorrar = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea borrar esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }"?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(deleteVenta(venta.id)));
};

const onClickAprobar = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea aprobar esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }"?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(cambiarEstadoVenta(venta.id, cEstados.Aprobada)));
};

const onClickEntregada = (confirm, dispatch, venta) => {
  confirm({
    description: `Desea marcar como ENTREGADA esta venta de "${
      venta.cliente ? venta.cliente.razon_social : "BORRADO"
    }"?`,
    title: "ATENCION",
    cancellationText: "Cancelar",
    confirmationText: "Confirmar",
  }).then(() => dispatch(cambiarEstadoVenta(venta.id, cEstados.Entregada)));
};

const PanelAdministrativo = ({
  dispatch,
  loading,
  saving,
  ventas,
  error,
  bypassIo,
  filtered,
  ultimoRemito,
  clientes,
  ventasFacturadas,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [filterOpen, setFilterOpen] = useState(false);
  const [clicked, setClicked] = useState([]);
  const [openX, setOpenX] = useState([]);
  const [venta, setVenta] = useState(null);
  const [remito, setRemito] = useState(ultimoRemito + 1);
  const [numeroFactura, setNumeroFactura] = useState(null);
  const [filtroElegido, setFiltroElegido] = useState("");
  const [ventasFiltradas, setVentasFiltradas] = useState({});
  const [archivoFactura, setArchivoFactura] = useState(null);
  const [open, setOpen] = React.useState(false);

  const [cantidad, setCantidad] = useState(100);

  const [filters, setFilters] = useState({
    cliente: null,
    fechaVenta: null,
    fechaSalida: [
      moment().subtract(2, "weeks").toDate().toISOString(),
      null,
    ],
    cuit: "",
    razon_social: "",
  });

  useEffect(() => {
    dispatch(getVentasFacturadas(filters));
    dispatch(getVentas({ not_estado_id: 3 }));
    dispatch(getClientes());
    dispatch(getProductos());

    if (!loading) {
      subscribeTo("ventasChanged", (err, data) => {
        if (!bypassIo) {
          dispatch(getVentasFacturadas(filters));
          dispatch(getClientes());
        } else {
          dispatch(setBypassIoVentas(false));
        }
      });

      return () => {
        subscribeTo("ventasChanged", null);
      };
    }
  }, [dispatch, loading]);

  useEffect(() => {
    setRemito(ultimoRemito + 1);
  }, [ultimoRemito]);
  useEffect(() => {}, [ventasFiltradas]);

  const authContext = useContext(AuthContext);

  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const alertContext = useContext(AlertContext);

  const utcDateRange = (dateRange) =>
    dateRange.map((date) => {
      return date && typeof date.getMonth === "function"
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : date
        ? new Date(
            moment(date).toDate().getTime() -
              moment(date).toDate().getTimezoneOffset() * 60000
          )
        : date;
    });

  const printTable = (ventas, estado_id) => {
    const tableCells = (venta, hija = false) => (
      <Fragment>
        {estado_id === cEstados.Facturada && (
          <TableCell align="center">
            <center>
              <FormControlLabel
                value="fijar"
                control={
                  <Checkbox
                    margin="dense"
                    name="fijar"
                    label="Fijado"
                    value="1"
                    checked={
                      clicked.find((i) => i.id === venta.id) ? true : false
                    }
                    onChange={(e) => {
                      if (
                        !clicked.length ||
                        clicked[0].estado_id === venta.estado_id
                      )
                        setClicked(
                          clicked.find((vent) => vent.id === venta.id)
                            ? clicked.filter((vent) => vent.id !== venta.id)
                            : [...clicked, venta]
                        );
                    }}
                    className={classes.textField}
                    variant="outlined"
                  />
                }
                label=""
                labelPlacement="end"
              />
            </center>
          </TableCell>
        )}
        <TableCell align="center">
          {!hija && (venta.grupo ? venta.grupo.nombre : venta.cliente.nombre)}
        </TableCell>
        <TableCell align="center">
          {!hija && (venta.cliente ? venta.cliente.nombre : "BORRADO")}
        </TableCell>
        <TableCell
          align="center"
          onClick={(e) => {
            if (openX.find((vent) => vent === venta.id))
              setOpenX(openX.filter((vent) => vent !== venta.id));
            else setOpenX([...openX, venta.id]);
          }}
        >
          {venta.id}{" "}
          {venta.ventas_hijas && venta.ventas_hijas.length > 0 && (
            <Icon>add_circle_outlined</Icon>
          )}
        </TableCell>
        <TableCell align="center">{venta.fecha_salida_formatted}</TableCell>
        <TableCell align="right">{venta.clasificacion}</TableCell>
        <TableCell align="right">
          {moment(venta.fecha_salida).format("DDMM") +
            (venta.camioneta || "X") +
            (venta.orden ? "-" + venta.orden : "")}
        </TableCell>
        {estado_id === cEstados.Facturada && (
          <TableCell align="center">
            {venta.numero_factura
              ? venta.numero_factura
              : `X${"0".repeat(9 - venta.id.toString().length)}${venta.id}`}
          </TableCell>
        )}
        <TableCell align="right">
          <NumberFormat
            decimalSeparator=","
            thousandSeparator="."
            mask=""
            prefix="$"
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
            value={
              !esNotaDeCredito(venta.tipo_comprobante_id)
                ? venta.total !== undefined
                  ? parseFloat(venta.total)
                  : ""
                : parseFloat(venta.totalPagos)
            }
          />
        </TableCell>

        {acciones(venta)}
      </Fragment>
    );
    const acciones = (venta) =>
      venta.estado_id !== cEstados.Facturada ? (
        !authContext.usuario.vendedor_id && (
          <TableCell id="acciones" align="center">
            <span
              title="Editar"
              className={classes.editButton}
              onClick={(e) => dispatch(setCurrentVenta(venta))}
            >
              <Icon className={classes.listIcon}>edit</Icon>
            </span>

            {(!venta.estado_id || venta.estado_id === cEstados.Pendiente) && (
              <span
                title="Aprobar"
                className={classes.approveButton}
                onClick={(e) => onClickAprobar(confirm, dispatch, venta)}
              >
                <Icon className={classes.listIcon}>check-circle</Icon>
              </span>
            )}

            {estado_id === cEstados.Aprobada && (
              <span
                title="Facturar"
                className={classes.facturarButton}
                onClick={(e) => setVenta(venta)}
              >
                <Icon className={classes.listIcon}>receipt</Icon>
              </span>
            )}

            {getRoleCanDo("borrar_venta") && (
              <span
                title="Borrar"
                className={classes.deleteButton}
                onClick={(e) => onClickBorrar(confirm, dispatch, venta)}
              >
                <Icon fontSize="large" className={classes.listIcon}>
                  delete
                </Icon>
              </span>
            )}
          </TableCell>
        )
      ) : (
        <TableCell id="acciones" align="center">
          <span
            title="Comprobante saldo"
            className={classes.moneyButton}
            onClick={(e) => {
              let w = window.open(
                "/api/comprobanteSaldo/pdf/" +
                  (venta.cliente_id ? venta.cliente_id : "") +
                  "/" +
                  venta.clasificacion
              );
            }}
          >
            <Icon className={classes.listIcon}>attach_money</Icon>
          </span>
          <span
            title="Ver"
            className={classes.editButton}
            onClick={(e) => dispatch(setCurrentVenta(venta))}
          >
            <Icon className={classes.listIcon}>visibility</Icon>
          </span>
          {cTiposComprobantes["Facturas"].indexOf(venta.tipo_comprobante_id) !==
            -1 &&
            !authContext.usuario.vendedor_id && (
              <span
                title="Generar Nota de Credito"
                className={classes.deleteButton}
                onClick={(e) =>
                  dispatch(
                    setCurrentVenta({
                      ...venta,
                      id: null,
                      tipo_comprobante_id:
                        venta.tipo_comprobante_id ===
                        cTiposComprobantes["Factura A"]
                          ? cTiposComprobantes["Nota de Credito A"]
                          : venta.tipo_comprobante_id ===
                            cTiposComprobantes["Factura B"]
                          ? cTiposComprobantes["Nota de Credito B"]
                          : venta.tipo_comprobante_id ===
                            cTiposComprobantes["Factura C"]
                          ? cTiposComprobantes["Nota de Credito C"]
                          : venta.tipo_comprobante_id ===
                            cTiposComprobantes["Factura E"]
                          ? cTiposComprobantes["Nota de Credito E"]
                          : cTiposComprobantes["Nota de Credito X"],
                      estado_id: cEstados.Pendiente,
                      pagos: [],
                      cae: "",
                      fecha_cae: "",
                      numero_factura: "",
                      fecha_vencimiento_cae: "",
                      _NUEVA: true,
                      venta_padre_id: venta.venta_padre_id
                        ? venta.venta_padre_id
                        : venta.id,
                    })
                  )
                }
              >
                NC
              </span>
            )}
        </TableCell>
      );
    let all_ventas = ventas;
    ventas = ventas.filter((venta) => venta.estado_id === estado_id);

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {estado_id === cEstados.Facturada && (
                <TableCell align="center">
                  {" "}
                  <center>
                    <FormControlLabel
                      value="fijar"
                      control={
                        <Checkbox
                          margin="dense"
                          name="fijar"
                          label="Fijado"
                          value="1"
                          checked={
                            clicked.length && clicked.length === ventas.length
                          }
                          onChange={(e) => {
                            if (clicked.length === ventas.length)
                              setClicked([]);
                            else setClicked(ventas);
                          }}
                          className={classes.textField}
                          variant="outlined"
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  </center>
                </TableCell>
              )}
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Sucursal</TableCell>
              <TableCell align="center">Cod</TableCell>
              <TableCell align="center">Salida Estim.</TableCell>
              <TableCell align="center" width="5%">
                Clasif.
              </TableCell>
              <TableCell align="center">Ruteo</TableCell>
              {estado_id === cEstados.Facturada && (
                <TableCell align="center"># Factura</TableCell>
              )}

              <TableCell align="center">$ Venta</TableCell>
              {(!authContext.usuario.vendedor_id ||
                estado_id === cEstados.Facturada) && (
                <TableCell align="center">Acciones</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {estado_id !== cEstados.Facturada &&
              ventas
                .filter((vent) => vent.estado_id !== cEstados.Facturada)
                .map((vent, index) => (
                  <Fragment key={index}>
                    <TableRow className={classes.tableRow}>
                      {tableCells(vent)}
                    </TableRow>
                  </Fragment>
                ))}
            {estado_id === cEstados.Facturada &&
              ventas
                .filter(
                  (vent) =>
                    vent.estado_id === cEstados.Facturada &&
                    !vent.venta_padre_id
                )
                .map((venta, index) => (
                  <Fragment key={index}>
                    <TableRow className={classes.tableRow}>
                      {tableCells(venta)}
                    </TableRow>
                    {venta.ventas_hijas &&
                      venta.ventas_hijas.map((venta_hija, index) => {
                        return (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: "#ececec",
                              ...(openX.find(
                                (vent) => vent === venta_hija.venta_padre_id
                              )
                                ? { display: "table-row" }
                                : { display: "none" }),
                            }}
                          >
                            {tableCells(venta_hija, true)}
                          </TableRow>
                        );
                      })}
                  </Fragment>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (ventas !== null && !ventas.length && !loading) {
    return (
      <Fragment>
        <VentaForm panel="administrativo" />
        <h4>Cargando...</h4>
        <AddVentaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {venta && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={venta}
          onClose={(e) => setVenta(null)}
          aria-labelledby="max-width-dialog-title"
          scroll="paper"
        >
          <DialogTitle id="max-width-dialog-title" color="primary">
            Confirmar emisión de{" "}
            {Object.keys(cTiposComprobantes).find(
              (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
            )}{" "}
            (
            {
              tiposFormasDePago.find((tipo) => tipo.id === venta.forma_pago_id)
                .nombre
            }
            )
          </DialogTitle>

          <DialogContent dividers={false}>
            {Object.keys(cTiposComprobantes).find(
              (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
            ) === "Factura E" ? (
              <>
                <h3>Esta es una Factura E realizarla por afip</h3>
              </>
            ) : (
              <></>
            )}
            {`Desea facturar esta venta de "${
              venta.cliente ? venta.cliente.nombre : "BORRADO"
            }"?\n`}
            <br />
            <br />
            {venta.cliente &&
            venta.clasificacion !== 3 &&
            Object.keys(cTiposComprobantes).find(
              (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
            ) !== "Factura E"
              ? venta.cliente.email_facturacion
                ? "La factura se va a enviar a: " +
                  venta.cliente.email_facturacion
                : "El e-mail de facturación no fue configurado."
              : ""}
            {venta.clasificacion !== 3 && (
              <Fragment>
                <TextField
                  style={{ float: "right", marginTop: "10px" }}
                  margin="dense"
                  name="remito"
                  label="Remito"
                  variant="outlined"
                  value={remito || ""}
                  onChange={(e) => setRemito(e.target.value)}
                />
                <br />
                <br />
              </Fragment>
            )}{" "}
            {Object.keys(cTiposComprobantes).find(
              (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
            ) === "Factura E" && (
              <Fragment>
                <NumberFormat
                  format="0004-#########"
                  mask="_"
                  customInput={TextField}
                  style={{ float: "left", marginTop: "-30px" }}
                  margin="dense"
                  name="numeroFactura"
                  label="Numero de Factura"
                  variant="outlined"
                  className={classes.textField}
                  value={numeroFactura || ""}
                  onChange={(e) => setNumeroFactura(e.target.value)}
                />
                <br />
              </Fragment>
            )}{" "}
            {Object.keys(cTiposComprobantes).find(
              (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
            ) === "Factura E" && (
              <Fragment>
                <>
                  <Button variant="contained" component="label">
                    Cargar Factura
                    <input
                      type="file"
                      name="archivo"
                      style={{ marginLeft: "40px" }}
                      onChange={(e) => setArchivoFactura(e.target.files[0])}
                      hidden
                    />
                  </Button>
                  {archivoFactura && (
                    <Fragment>
                      <p style={{ marginLeft: "170px", marginTop: "-30px" }}>
                        {archivoFactura.name}
                        <span
                          title="Borrar"
                          className={classes.deleteButton}
                          onClick={(e) => setArchivoFactura(null)}
                        >
                          <Icon fontSize="large" className={classes.listIcon}>
                            delete
                          </Icon>
                        </span>
                      </p>
                    </Fragment>
                  )}
                </>
                <br />
                <br />
              </Fragment>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => setVenta(null)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                if (
                  Object.keys(cTiposComprobantes).find(
                    (key) =>
                      cTiposComprobantes[key] === venta.tipo_comprobante_id
                  ) === "Factura E"
                ) {
                  const formData = new FormData();
                  formData.append("archivoFactura", archivoFactura);
                  if (!numeroFactura || !archivoFactura) {
                    alertContext.setAlert(
                      !numeroFactura
                        ? "Completar numero de factura"
                        : "Cargar factura",
                      "error"
                    );
                    return;
                  }
                  await dispatch(subirFactura(venta.id, formData));
                }
                dispatch(
                  cambiarEstadoVenta(venta.id, cEstados.Facturada, {
                    noEnviarMail: true,
                    remito: venta.clasificacion !== 3 ? remito : null,
                    numeroFactura: numeroFactura,
                  })
                ).then(() => {
                  axios
                    .get("/api/invoice/pdf/triplicado/" + venta.id, {
                      responseType: "blob",
                    })
                    .then((res) => {
                      let updatedVenta = ventas.find((v) => v.id === venta.id);
                      if (
                        Object.keys(cTiposComprobantes).find(
                          (key) =>
                            cTiposComprobantes[key] ===
                            venta.tipo_comprobante_id
                        ) !== "Factura E"
                      )
                        fileDownload(
                          res.data,
                          (updatedVenta.numero_factura
                            ? updatedVenta.numero_factura
                            : `X${"0".repeat(9 - venta.id.toString().length)}${
                                venta.id
                              }`) +
                            " - " +
                            venta.cliente.nombre +
                            ".pdf"
                        );
                    });
                  setVenta(null);
                });
              }}
            >
              <Icon>receipt</Icon> Emitir
            </Button>
            {venta.clasificacion !== 3 &&
              Object.keys(cTiposComprobantes).find(
                (key) => cTiposComprobantes[key] === venta.tipo_comprobante_id
              ) !== "Factura E" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    dispatch(
                      cambiarEstadoVenta(venta.id, cEstados.Facturada, {
                        noEnviarMail: false,
                        remito,
                      })
                    ).then(() => {
                      axios
                        .get("/api/invoice/pdf/triplicado/" + venta.id, {
                          responseType: "blob",
                        })
                        .then((res) => {
                          let updatedVenta = ventas.find(
                            (v) => v.id === venta.id
                          );
                          fileDownload(
                            res.data,
                            (updatedVenta.numero_factura
                              ? updatedVenta.numero_factura
                              : `X${"0".repeat(
                                  9 - venta.id.toString().length
                                )}${venta.id}`) +
                              " - " +
                              venta.cliente.nombre +
                              ".pdf"
                          );
                        });
                    });
                    setVenta(null);
                  }}
                >
                  <Icon>email</Icon> Emitir y enviar mail
                </Button>
              )}
          </DialogActions>
        </Dialog>
      )}
      <VentaForm panel="administrativo" />

      {/*<VentasFilter open={filterOpen} setOpen={setFilterOpen} /> */}
      {error && (
        <Snackbar
          open={error ? true : false}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: error }}
            ></div>
          </Alert>
        </Snackbar>
      )}
      <Grid
        container
        className={
          mobile
            ? classes.gridContainer
            : saving
            ? classes.gridContainerLoading
            : ""
        }
      >
        <Grid item xs={12} md={12}>
          {saving && <LinearProgress className={classes.root} />}
          {ventas !== null && !loading ? (
            <Fragment>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>Aprobadas</Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanel}>
                  <Grid container direction="column">
                    {filtroElegido ? (
                      <Grid>
                        {printTable(ventasFiltradas, cEstados.Aprobada)}
                      </Grid>
                    ) : (
                      <Grid>{printTable(ventas, cEstados.Aprobada)}</Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>filter_list</Icon>}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className={classes.summary}
                >
                  <Typography className={classes.heading}>
                    Facturadas
                  </Typography>
                  <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelFilter}>
                  {clientes && clientes.length > 0 && (
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl
                          variant="outlined"
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "8px",
                          }}
                        >
                          <ReactSelectNonCreate
                            fullWidth
                            value={
                              filters.cliente &&
                              clientes.find((p) => p.id === filters.cliente)
                                ? {
                                    label: clientes.find(
                                      (p) => p.id === filters.cliente
                                    ).nombre,
                                    value: filters.cliente,
                                  }
                                : ""
                            }
                            isClearable={true}
                            name="cliente"
                            placeholder="Cliente..."
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                                marginTop: "-8px",
                              }),
                            }}
                            menuPortalTarget={document.body}
                            formatCreateLabel={(value) =>
                              "Nuevo cliente: " + value
                            }
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                cliente: e ? e.value : null,
                              })
                            }
                            options={clientes.map((cliente) => ({
                              value: cliente.id,
                              label: cliente.nombre,
                            }))}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          margin="dense"
                          name="razon_social"
                          label="Razón Social"
                          value={filters.razon_social || ""}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              razon_social: e.target.value,
                            })
                          }
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          margin="dense"
                          name="cuit"
                          label="CUIT"
                          value={filters.cuit || ""}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              cuit: e.target.value,
                            })
                          }
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={1} justifyContent="center">
                        <div
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "10px",
                          }}
                        >
                          <Button
                            onClick={(e) =>
                              dispatch(
                                getVentasFacturadas({
                                  ...filters,
                                  fechaSalida: filters.fechaSalida
                                    ? utcDateRange(filters.fechaSalida)
                                    : null,
                                  fechaVenta: filters.fechaVenta
                                    ? utcDateRange(filters.fechaVenta)
                                    : null,
                                })
                              )
                            }
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingTop: "12px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "300px",
                              paddingTop: "8px",
                            }}
                          >
                            <ReactSelectNonCreate
                              isClearable={true}
                              value={filters.tipo_comprobante}
                              name="tipo_comprobante"
                              placeholder="Tipo de Comprobante"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                  marginTop: "-8px",
                                }),
                              }}
                              menuPortalTarget={document.body}
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  tipo_comprobante: e,
                                });
                              }}
                              options={Object.keys(cTiposComprobantes)
                                .filter((c) => c !== "Facturas")
                                .map((tipo) => ({
                                  value: cTiposComprobantes[tipo],
                                  label: tipo,
                                }))
                                .concat({ value: "MiPYME", label: "Mi PYME" })}
                            />
                          </FormControl>

                          <Grid md={9} container justifyContent="space-around">
                            <Box>
                              Fecha Venta:{" "}
                              <DateRangePicker
                                value={filters.fechaVenta}
                                onChange={(ranges) =>
                                  setFilters({
                                    ...filters,
                                    fechaVenta: ranges,
                                  })
                                }
                                locale="es-AR"
                              />{" "}
                            </Box>
                            <Box>
                              Fecha Salida:{" "}
                              <DateRangePicker
                                value={filters.fechaSalida}
                                onChange={(ranges) =>
                                  setFilters({
                                    ...filters,
                                    fechaSalida: ranges,
                                  })
                                }
                                locale="es-AR"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              {filtroElegido ? (
                <Grid>
                  {printTable(
                    ventasFiltradas
                      .sort((a, b) => b.id - a.id)
                      .slice(0, cantidad),
                    cEstados.Facturada
                  )}
                </Grid>
              ) : (
                <Grid>
                  {ventasFacturadas.length > 0 &&
                    printTable(
                      ventasFacturadas
                        .sort(
                          (a, b) =>
                            moment(b.fecha_emision).unix() -
                            moment(a.fecha_emision).unix()
                        )
                        .slice(0, cantidad),
                      cEstados.Facturada
                    )}
                </Grid>
              )}
              <div>
                <br />
                <br />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCantidad(cantidad + 100);
                  }}
                >
                  Mostrar más ventas
                </a>
                &nbsp;
              </div>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </Grid>
      </Grid>
      {clicked && clicked.length > 0 ? (
        <ImprimirMultipleBtn
          ids={clicked.map((c) => c.id)}
          modo={
            clicked.every((c) => c.estado === "Pendiente")
              ? "Pendientes"
              : clicked.every((c) => c.estado === "Aprobada")
              ? "Aprobadas"
              : false
          }
        />
      ) : (
        <AddVentaBtn />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    ventasFacturadas: state.ventas.facturadas,
    ultimoRemito: state.ventas.ultimoRemito,
    filtered: state.ventas.filtered,
    loading: state.ventas.loading,
    error: state.ventas.error,
    saving: state.ventas.saving,
    bypassIo: state.ventas.bypassIo,
    clientes: state.clientes.clientes,
  };
};

export default connect(mapStateToProps)(PanelAdministrativo);
