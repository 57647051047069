import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { getProductos } from "../../actions/productos";
import moment from "moment";
import { addVentaEstimada, clearCurrent, setOpen, updateVentaEstimada } from "../../actions/ventas_estimadas";

import "moment/locale/es";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5 // First week of year must contain 4 January (7 + 1 - 4)
  }
});
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const emptyVentaEstimada = {
  semana: moment().isoWeek(),
  ano: moment().isoWeekYear(),
  items: []
};

const VentaEstimadaForm = ({ children, current, open, loading_productos, productos, dispatch }) => {
  const [venta_estimada, setVentaEstimada] = useState(emptyVentaEstimada);

  const { semana, ano, cantidad, items } = venta_estimada;

  useEffect(() => {
    dispatch(getProductos());
    if (current !== null) {
      setVentaEstimada(current);
    } else {
      let tmpVentaEstimada = {
        semana: "",
        items: []
      };

      setVentaEstimada(emptyVentaEstimada);
    }
  }, [current, open]);

  const onChange = (e) => {
    setVentaEstimada({ ...venta_estimada, [e.target.name]: e.target.value });
  };

  const onValueChange = (e, name) => {
    setVentaEstimada({ ...venta_estimada, [name]: e.floatValue });
  };

  const onChangeCheck = (name) => (event) => {
    setVentaEstimada({ ...venta_estimada, [name]: event.target.checked });
  };

  const agregarItem = (index) => {
    if (items.length)
      setVentaEstimada({
        ...venta_estimada,
        items: [...items, { producto_id: null, precio: "" }]
      });
    else {
      let itemsTmp = [];
      productos.forEach((producto) => {
        itemsTmp = [
          ...itemsTmp,
          {
            producto_id: producto.id,
            precio: producto.nombre.includes("Granel") ? 800 : 70
          }
        ];
      });
      setVentaEstimada({
        ...venta_estimada,
        items: itemsTmp
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!semana || !ano) {
    } else {
      try {
        if (!current) {
          await dispatch(addVentaEstimada(venta_estimada));
        } else {
          await dispatch(updateVentaEstimada(venta_estimada));
        }
      } catch (error) {
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setVentaEstimada(emptyVentaEstimada);
    dispatch(clearCurrent());
  };

  const onChangeItem = (e, index, campo, floatVal = false) => {
    let val;
    if (!floatVal) {
      val = e.target.value;
    } else {
      val = e.floatValue;
    }
    setVentaEstimada({
      ...venta_estimada,
      items: [...items.slice(0, index), { ...items[index], [campo]: val }, ...items.slice(index + 1)]
    });

  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current ? "Nueva Venta Estimada" : "Editar Venta Estimada"}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={4}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator=""
                max={53}
                min={1}
                mask=""
                prefix=""
                decimalScale={2}
                allowNegative={false}
                margin="dense"
                name="semana"
                label="Semana"
                value={semana}
                onValueChange={(e) => onValueChange(e, "semana")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator=""
                max={2100}
                min={2021}
                mask=""
                prefix=""
                decimalScale={2}
                allowNegative={false}
                margin="dense"
                name="ano"
                label="Año"
                value={ano}
                onValueChange={(e) => onValueChange(e, "ano")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator=""
                max={9999999}
                min={1}
                mask=""
                prefix=""
                decimalScale={2}
                allowNegative={false}
                margin="dense"
                name="cantidad"
                label="Cantidad"
                value={cantidad}
                onValueChange={(e) => onValueChange(e, "cantidad")}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {/*
          <h3 color="primary">Items: </h3>
          <Grid container className={classes.root} spacing={2}>
            {items &&
              !loading_productos &&
              items.map((item, index) => {
                return (
                  <Fragment key={`items[${index}]`}>
                    <Grid item xs={4} md={4}>
                      <Select
                        input={
                          <OutlinedInput
                            style={{
                              height: "40px",
                              marginTop: "8px",
                              width: "100%"
                            }}
                            id="select-producto"
                          />
                        }
                        fullWidth
                        value={item.producto_id ? item.producto_id : ""}
                        name={`items[${index}].producto`}
                        onChange={(e) => onChangeItem(e, index, "producto_id")}
                      >
                        {productos.map((producto) => (
                          <MenuItem value={producto.id} key={producto.id}>
                            {producto.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <NumberFormat
                        customInput={TextField}
                        decimalSeparator=","
                        thousandSeparator="."
                        mask=""
                        prefix="$"
                        decimalScale={2}
                        allowNegative={false}
                        margin="dense"
                        name={`items[${index}].precio`}
                        label="Valor"
                        value={item.precio}
                        onValueChange={(e) => onChangeItem(e, index, "precio", true)}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>
          
          {!loading_productos && (
            <h4>
              <Button color="primary" href="#!" onClick={agregarItem}>
                Agregar Item
              </Button>
            </h4>
          )}
          */}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} variant="contained" color="primary" type="submit">
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.ventasEstimadas.current,
    open: state.ventasEstimadas.open,
    productos: state.productos.productos,
    loading_productos: state.productos.loading
  };
};

export default connect(mapStateToProps)(VentaEstimadaForm);
