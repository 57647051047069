import axios from 'axios';

import {
  ADD_PROVEDOR,
  DELETE_PROVEDOR,
  SET_CURRENT_PROVEDOR,
  CLEAR_CURRENT_PROVEDOR,
  UPDATE_PROVEDOR,
  FILTER_PROVEDOR,
  CLEAR_FILTER_PROVEDOR,
  ERROR_PROVEDOR,
  GET_PROVEDOR,
  CLEAR_PROVEDOR,
  SET_OPEN_PROVEDOR
} from './types';

//Add Provedor
export const addProvedor = provedor => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/provedores', provedor, config);
      dispatch({ type: ADD_PROVEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PROVEDOR, payload: error.msg });
    }
  };
};

export const getProvedores = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/provedores');
      dispatch({ type: GET_PROVEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PROVEDOR, payload: error.msg });
    }
  };
};

//Delete Provedor

export const deleteProvedor = provedorId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/provedores/${provedorId}`);

      dispatch({ type: DELETE_PROVEDOR, payload: provedorId });
    } catch (error) {
      dispatch({ type: ERROR_PROVEDOR, payload: error.response.msg });
    }
  };
};

//Set Current Provedor

export function setCurrent(provedor) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_PROVEDOR, payload: provedor });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_PROVEDOR, payload: open });
  };
}

//Clear Current Provedor
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_PROVEDOR });
  };
};
//Update Provedor

export const updateProvedor = provedor => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/provedores/${provedor.id}`,
        provedor,
        config
      );
      dispatch({ type: UPDATE_PROVEDOR, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_PROVEDOR, payload: error.response.msg });
    }
  };
};

//Filter Provedor

export const filterProvedores = text => {
  return dispatch => {
    dispatch({ type: FILTER_PROVEDOR, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_PROVEDOR });
  };
};

export const clearProvedores = () => {
  return dispatch => {
    dispatch({ type: CLEAR_PROVEDOR });
  };
};
