import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { filterProvedores } from '../../actions/provedores';

const ProvedoresFilter = ({ provedores, filter, filtered, dispatch }) => {
  useEffect(() => {}, [filtered]);

  const onChange = e => {
    dispatch(filterProvedores(e.target.value));
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <TextField
        type='text'
        label='Filtrar provedores'
        margin='dense'
        variant='outlined'
        onChange={onChange}
        fullWidth
        value={filter}
        style={{ paddingBottom: '10px' }}
      />
    </form>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.provedores.filter,
    filtered: state.provedores.filtered,
    provedores: state.provedores
  };
};

export default connect(mapStateToProps)(ProvedoresFilter);
