import { TableCell, TableRow } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { clearCurrent, deleteCliente } from "../../actions/clientes";
import { cTiposTelefonos, cCanales } from "../../config/constantes";
import PanelVentasBotones from "./PanelVentasBotones";
import ReactDOMServer from "react-dom/server";
import WhatsAppIcon from "../utils/WhatsAppIcon";
const useStyles = makeStyles({
  button: {
    minWidth: "32px",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(204,204,204,0.1)",
    },
  },
  addressLink: {
    color: "black",
    textDecorationLine: "none",

    overflow: "hidden",

    "&:hover": {
      textDecorationLine: "underline",
    },
  },
  tableRowRed: {
    backgroundColor: "rgba(204,0,0,0.2)",
  },
  tableRowGreen: {
    backgroundColor: "rgba(0,204,0,0.2)",
  },
});

const PanelVentasItem = ({ cliente, clientes, current, dispatch }) => {
  const { id, nombre, tipo, pos } = cliente;
  const classes = useStyles();
  const confirm = useConfirm();
  const onDelete = () => {
    dispatch(deleteCliente(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, cliente) => {
    confirm({
      description: `Desea borrar la cliente "${cliente.nombre}"?`,
      title: "ATENCION",
      cancellationText: "Cancelar",
      confirmationText: "Confirmar",
    }).then(() => onDelete());
  };

  let contacto = cliente.contactoComprador
    ? cliente.contactoComprador
    : cliente.telefonos[0]
    ? cliente.telefonos[0]
    : null;
  let contactoStr;
  if (contacto) {
    contactoStr = [];

    if (contacto.tipo_telefono_id === 3)
      contactoStr.push(
        ReactDOMServer.renderToStaticMarkup(
          <a
            className={classes.addressLink}
            key={`whatsapp${contacto.numero}`}
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${contacto.email}`}
          >
            {contacto.email}
          </a>
        )
      );
    else
      contactoStr.push(
        contacto.tipo_telefono_id === 2
          ? contacto.numero
          : ReactDOMServer.renderToStaticMarkup(
              <a
                className={classes.addressLink}
                key={`whatsapp${contacto.numero}`}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/+54${contacto.numero
                  .replace(/\D/g, "")
                  .replace(/\+54/g, "")}`}
              >
                <WhatsAppIcon />
                +54{contacto.numero.replace(/\+54/g, "")}
              </a>
            )
      );
    if (contacto.nombre) contactoStr.push(contacto.nombre);
  }

  let balance_a = cliente.balance_a
    ? parseFloat(cliente.balance_a).toFixed(2)
    : 0;
  let balance_x = cliente.balance_x
    ? parseFloat(cliente.balance_x).toFixed(2)
    : 0;

  let balance = "$" + balance_a + " / $" + balance_x;

  let clasificacion = "I";
  let indicador_vol = 0;
  let promedio_8w = 0;
  if (cliente.total_items_8w) {
    promedio_8w = cliente.total_items_8w / 2;
    if (
      cCanales[cliente.canal_id] === "S" ||
      cCanales[cliente.canal_id] === "MAY"
    ) {
      if (promedio_8w > 0 && promedio_8w < 120) clasificacion = "C";
      if (promedio_8w >= 120 && promedio_8w < 240) clasificacion = "B";
      if (promedio_8w >= 240) clasificacion = "A";
    } else if (cCanales[cliente.canal_id] === "DIS") {
      if (promedio_8w > 0 && promedio_8w < 1000) clasificacion = "C";
      if (promedio_8w >= 1000 && promedio_8w < 2000) clasificacion = "B";
      if (promedio_8w >= 2000) clasificacion = "A";
    } else if (cCanales[cliente.canal_id] === "MIN") {
      if (promedio_8w > 0 && promedio_8w < 48) clasificacion = "C";
      if (promedio_8w >= 48 && promedio_8w < 96) clasificacion = "B";
      if (promedio_8w >= 96) clasificacion = "A";
    }else{
      if (promedio_8w > 0 && promedio_8w < 48) clasificacion = "C";
      if (promedio_8w >= 48 && promedio_8w < 96) clasificacion = "B";
      if (promedio_8w >= 96) clasificacion = "A";
    }
  }

  if (cliente.total_items_4w && promedio_8w) {
    indicador_vol = cliente.total_items_4w / promedio_8w;
  }

  return (
    <Fragment>
      <TableRow
        className={
          cliente.comunicacionesSemana &&
          cliente.comunicacionesSemana.find(
            (cont) => cont.cliente_tipo_comunicacion_id === 3
          )
            ? classes.tableRowGreen
            : cliente.comunicacionesSemana &&
              cliente.comunicacionesSemana.find(
                (cont) => cont.cliente_tipo_comunicacion_id === 1
              )
            ? classes.tableRowRed
            : classes.tableRow
        }
      >
        <TableCell style={{ ...(cliente.fijar && { fontWeight: "bold" }) }}>
          {cliente.grupo ? cliente.grupo.nombre : cliente.nombre} (
          {cliente.nombre})
        </TableCell>
        <TableCell>
          {contacto &&
            contactoStr.map((c, index) => (
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: (index > 0 ? " - " : "") + c,
                }}
              ></span>
            ))}
        </TableCell>
        <TableCell align="center">{balance}</TableCell>
        <TableCell align="center">{cliente.clasificacion_vol}</TableCell>
        <TableCell align="center">
          {indicador_vol >= 0 && (
            <Fragment>
              {Math.round(indicador_vol * 100) / 100} (
              {cliente.total_items_4w + " / " + promedio_8w})
            </Fragment>
          )}
        </TableCell>
        <TableCell align="center">{cliente.semanas_ultima_compra}</TableCell>
        <TableCell align="center">
          {(
            cliente.total_items_ultima_semana -
            cliente.semanas_ultima_compra * (cliente.consumo_semanal_estimado)
          ).toFixed(2)}
        </TableCell>
        <TableCell align="center">
          {(cliente.total_compras_ultimo_ano / 52).toFixed(2)}
        </TableCell>
        <PanelVentasBotones cliente={cliente} />
      </TableRow>
    </Fragment>
  );
};

PanelVentasItem.propTypes = {
  cliente: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    current: state.clientes.current,
  };
};

export default connect(mapStateToProps)(PanelVentasItem);
