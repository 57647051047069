import axios from 'axios';

import {
  ADD_GRUPO,
  DELETE_GRUPO,
  SET_CURRENT_GRUPO,
  CLEAR_CURRENT_GRUPO,
  UPDATE_GRUPO,
  FILTER_GRUPO,
  CLEAR_FILTER_GRUPO,
  ERROR_GRUPO,
  GET_GRUPO,
  CLEAR_GRUPO,
  SET_OPEN_GRUPO
} from './types';

//Add Grupo
export const addGrupo = grupo => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/grupos', grupo, config);
      dispatch({ type: ADD_GRUPO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_GRUPO, payload: error.msg });
    }
  };
};

export const getGrupos = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/grupos');
      dispatch({ type: GET_GRUPO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_GRUPO, payload: error.msg });
    }
  };
};

//Delete Grupo

export const deleteGrupo = grupoId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/grupos/${grupoId}`);

      dispatch({ type: DELETE_GRUPO, payload: grupoId });
    } catch (error) {
      dispatch({ type: ERROR_GRUPO, payload: error.response.msg });
    }
  };
};

//Set Current Grupo

export function setCurrent(grupo) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_GRUPO, payload: grupo });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_GRUPO, payload: open });
  };
}

//Clear Current Grupo
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_GRUPO });
  };
};
//Update Grupo

export const updateGrupo = grupo => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/grupos/${grupo.id}`,
        grupo,
        config
      );
      dispatch({ type: UPDATE_GRUPO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_GRUPO, payload: error.response.msg });
    }
  };
};

//Filter Grupo

export const filterGrupos = text => {
  return dispatch => {
    dispatch({ type: FILTER_GRUPO, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_GRUPO });
  };
};

export const clearGrupos = () => {
  return dispatch => {
    dispatch({ type: CLEAR_GRUPO });
  };
};
