import {
  Button, CardActions, Grid,
  Typography
} from '@material-ui/core/';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  clearCurrent, deleteVendedor,

  setCurrent
} from '../../actions/vendedores';

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: '#f7f7f7'
  },
  addressLink: {
    color: 'black',
    textDecorationLine: 'none',

    overflow: 'hidden',

    '&:hover': {
      textDecorationLine: 'underline'
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: '180px',
    overflowY: 'auto',
    fontSize: '0.9rem'
  },
  cardTitle: {
    fontSize: '1.15rem'
  }
});

const VendedorItem = ({ vendedor, vendedores, current, dispatch, listas_precios }) => {
  const { id, nombre, tipo, balance } = vendedor;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteVendedor(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, vendedor) => {
    confirm( {
              description: `Desea borrar la vendedor "${vendedor.nombre}"?`,
              title: 'ATENCION',
              cancellationText: 'Cancelar',
              confirmationText: 'Confirmar'
            })

    .then(() => onDelete());
  }

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            className={classes.cardTitle}
          >
            {nombre}
          </Typography>
          <p color="textSecondary">
            {vendedor.comision && (
              <Fragment>Comisión: {vendedor.comision}%</Fragment>
            )}
            
          </p>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            onClick={(e) => onClickBorrar(confirm, dispatch, vendedor)}
            size="small"
            color="secondary"
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => dispatch(setCurrent(vendedor))}
            size="small"
            color="primary"
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

VendedorItem.propTypes = {
  vendedor: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    vendedores: state.vendedores.vendedores,
    current: state.vendedores.current
  };
};

export default connect(mapStateToProps)((VendedorItem));
