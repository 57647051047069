import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getVentasEstimadas,
  getMixVentas,
} from "../../actions/ventas_estimadas";
import subscribeTo from "../../utils/subscribeTo";
import AddVentaEstimadaBtn from "./AddVentaEstimadaBtn";
import VentaEstimadaForm from "./VentaEstimadaForm";
import { getProductos } from "../../actions/productos";
import NumberFormat from "react-number-format";
import { Button, Icon } from "@material-ui/core";
import EditarStockForm from "./EditarStockForm";
import moment from "moment";
import "moment/locale/es";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  tableRowCollapsed: {
    display: "none",
  },
});

const EstimacionVentas = ({
  ventas_estimadas,
  mix_ventas,
  filtered,
  loading,
  dispatch,
  productos,
  loading_productos,
}) => {
  const classes = useStyles();

  const [productosOrdenados, setProductosOrdenados] = useState([]);
  const [openStock, setOpenStock] = useState(null);
  const [collapse, setCollapse] = useState(true);
  const [fechaEstimacionVentas, setFechaEstimacionVentasState] = useState([
    moment().day(1),
    moment().day(12),
  ]);

  const setFechaEstimacionVentas = (state) => {
    setFechaEstimacionVentasState(
      state ? state : [moment().day(1), moment().day(12)]
    );
  };

  useEffect(() => {
    if (productos && productos.length) {
      let tmp = [];
      for (let i = 0; i < productos.length; i++) {
        if (productos[i].id !== 6) tmp.push(productos[i]);
      }
      setProductosOrdenados(tmp);
    }
  }, [productos]);

  useEffect(() => {
    dispatch(getVentasEstimadas());
    dispatch(
      getMixVentas(
        moment(fechaEstimacionVentas[0]).format("YYYY-MM-DD"),
        moment(fechaEstimacionVentas[1]).format("YYYY-MM-DD")
      )
    );
    dispatch(getProductos());
    if (!loading) {
      subscribeTo("ventas_estimadasChanged", (err, data) => {
        dispatch(getVentasEstimadas());
      });
      return () => {
        subscribeTo("ventas_estimadasChanged", null);
      };
    }
  }, [dispatch, loading, fechaEstimacionVentas]);

  moment().locale("es");

  var startOfWeek = moment(fechaEstimacionVentas[0]).startOf("week");
  var endOfWeek = moment(fechaEstimacionVentas[0]).endOf("week");

  var days = [];
  var day = startOfWeek;

  day = day.clone().add(1, "d");
  let i = 0;
  while (day <= endOfWeek) {
    if (i++ === 5) break;
    days.push(day);
    day = day.clone().add(1, "d");
  }
  if (
    moment(fechaEstimacionVentas[1]).startOf("week").format("YYYY-MM-DD") !==
    startOfWeek.format("YYYY-MM-DD")
  ) {
    startOfWeek = moment(fechaEstimacionVentas[1]).startOf("week");
    endOfWeek = moment(fechaEstimacionVentas[1]).endOf("week");
  } else {
    startOfWeek = moment(fechaEstimacionVentas[1])
      .add(1, "week")
      .startOf("week");
    endOfWeek = moment(fechaEstimacionVentas[1]).add(1, "week").endOf("week");
  }

  day = startOfWeek;

  day = day.clone().add(1, "d");
  i = 0;
  while (day <= endOfWeek) {
    if (i++ === 5) break;
    days.push(day);
    day = day.clone().add(1, "d");
  }

  if (ventas_estimadas !== null && !ventas_estimadas.length && !loading) {
    return (
      <Fragment>
        <VentaEstimadaForm />
        <h4>No hay ninguna Venta todavía!</h4> <AddVentaEstimadaBtn />
      </Fragment>
    );
  }

  const getMixAprobadas = (mix_ventas, d, producto) => {
    return (
      (mix_ventas[producto.id][d.format("MM-DD-YYYY") + "_3"]
        ? mix_ventas[producto.id][d.format("MM-DD-YYYY") + "_3"]
        : 0) +
      (mix_ventas[producto.id][d.format("MM-DD-YYYY") + "_2"]
        ? mix_ventas[producto.id][d.format("MM-DD-YYYY") + "_2"]
        : 0)
    );
  };

  return (
    <Fragment>
      <div>
        <EditarStockForm openStock={openStock} setOpenStock={setOpenStock} />
      </div>
      <div>
        <div className={classes.root}>
          <h3>Estimacion de Ventas</h3>
          Filtrar por fechas:{" "}
          <DateRangePicker
            value={fechaEstimacionVentas}
            onChange={(ranges) => setFechaEstimacionVentas(ranges)}
            locale="es-AR"
          />
          {productosOrdenados.length > 0 && mix_ventas && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell align="center">
                      <a onClick={(e) => setCollapse(!collapse)}>
                        {collapse ? "+" : "-"} Variedad
                      </a>
                    </TableCell>

                    {days.map((d) => (
                      <TableCell align="center">{d.format("ddd DD")}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productosOrdenados.map((producto, index) => (
                    <>
                      <TableRow
                        className={collapse && classes.tableRowCollapsed}
                        key={index}
                      >
                        <TableCell align="center">{producto.nombre}</TableCell>
                        {days.map((d) => (
                          <TableCell align="center">
                            <span style={{ color: "green" }}>
                              {getMixAprobadas(mix_ventas, d, producto)
                                ? getMixAprobadas(mix_ventas, d, producto)
                                : ""}
                            </span>
                            <span style={{ color: "red" }}>
                              {mix_ventas[producto.id][
                                d.format("MM-DD-YYYY") + "_1"
                              ]
                                ? " " +
                                  mix_ventas[producto.id][
                                    d.format("MM-DD-YYYY") + "_1"
                                  ] +
                                  ""
                                : ""}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </>
                  ))}
                  <TableRow className={classes.tableRow}>
                    <TableCell align="center">
                      <strong>Total</strong>
                    </TableCell>
                    {days.map((d) => (
                      <TableCell align="center">
                        <span>
                          <strong>
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              decimalScale={0}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={
                                ventas_estimadas.find(
                                  (ve) =>
                                    ve.ano == moment().isoWeekYear() &&
                                    ve.semana == moment().isoWeek()
                                )
                                  ? mix_ventas[d.format("MM-DD-YYYY")]
                                  : 0
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </strong>
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell align="center">
                      <strong>Total Objetivo</strong>
                    </TableCell>
                    {days.map((d) => (
                      <TableCell align="center">
                        <span>
                          <strong>
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={
                                ventas_estimadas.find(
                                  (ve) =>
                                    ve.ano == moment().isoWeekYear() &&
                                    ve.semana == moment().isoWeek()
                                )
                                  ? (mix_ventas[d.format("MM-DD-YYYY")] /
                                      ventas_estimadas.find(
                                        (ve) =>
                                          ve.ano == moment().isoWeekYear() &&
                                          ve.semana == moment().isoWeek()
                                      ).cantidad) *
                                    100
                                  : 0
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </strong>
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    mix_ventas: state.ventasEstimadas.mix_ventas,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(EstimacionVentas);
