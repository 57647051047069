import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getVentasEstimadas } from "../../actions/ventas_estimadas";
import subscribeTo from "../../utils/subscribeTo";
import AddVentaEstimadaBtn from "./AddVentaEstimadaBtn";
import VentaEstimadaForm from "./VentaEstimadaForm";
import VentasEstimadas from "./VentasEstimadas";
import { getProductos } from "../../actions/productos";
import { Grid } from "@material-ui/core";
import Stocks from "./Stocks";
import EstimacionVentas from "./EstimacionVentas";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px"
  }
});

const PanelStock = ({ ventas_estimadas, filtered, loading, dispatch, productos, loading_productos }) => {
  const classes = useStyles();

  const [productosOrdenados, setProductosOrdenados] = useState([]);

  useEffect(() => {
    if (productos && productos.length) {
      let tmp = [];
      for (let i = 0; i < productos.length; i++) {
        if (productos[i].id !== 6) tmp.push(productos[i]);
      }
      setProductosOrdenados(tmp);
    }
  }, [productos]);

  useEffect(() => {
    dispatch(getVentasEstimadas());
    dispatch(getProductos());
    if (!loading) {
      subscribeTo("ventas_estimadasChanged", (err, data) => {
        dispatch(getVentasEstimadas());
      });
      return () => {
        subscribeTo("ventas_estimadasChanged", null);
      };
    }
  }, [dispatch, loading]);

  if (ventas_estimadas !== null && !ventas_estimadas.length && !loading) {
    return (
      <Fragment>
        <VentaEstimadaForm />
        <h4>No hay ventas estimadas!</h4> <AddVentaEstimadaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} xl={6} key={1}>
            <VentasEstimadas ultimaSemana={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} key={2}>
            <Stocks />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} key={2}>
            <EstimacionVentas />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading
  };
};

export default connect(mapStateToProps)(PanelStock);
