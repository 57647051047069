import escapeRegExp from 'escape-string-regexp';
import {
  GET_LANZAMIENTOS_DE_CAJA,
  ERROR_LANZAMIENTOS_DE_CAJA
} from '../actions/types';

const initialState = {
  lanzamientosDeCaja: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LANZAMIENTOS_DE_CAJA:
      return {
        ...state,
        lanzamientosDeCaja: action.payload,
        loading: false
      };
    case ERROR_LANZAMIENTOS_DE_CAJA:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
