import axios from "axios";
import subscribeTo from "../utils/subscribeTo";
import he from "he";

import {
  ADD_CLIENTE,
  DELETE_CLIENTE,
  SET_CURRENT_CLIENTE,
  CLEAR_CURRENT_CLIENTE,
  UPDATE_CLIENTE,
  FILTER_CLIENTE,
  FILTER_CLIENTE_DIR,
  FILTER_CLIENTE_ZONA,
  CLEAR_FILTER_CLIENTE,
  ERROR_CLIENTE,
  GET_CLIENTE,
  CLEAR_CLIENTE,
  SET_OPEN_CLIENTE,
  OBTENER_CLIENTE_AFIP,
  SET_LOADING_AFIP,
  SET_SAVING_CLIENTE,
  FILTER_CLIENTES_PANEL_VENTAS,
  ADD_CONTACTO,
  SET_BYPASS_IO_CLIENTES,
} from "./types";

//Add Cliente
export const addCliente = (cliente) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch(setBypassIoClientes(true));
    dispatch(setSavingCliente(true));
    try {
      let cuit = cliente.cuit;

      if (
        parseInt(cuit) !== 11111111111 ||
        parseInt(cliente.clasificacion) !== 3
      )
        try {
          await axios.get("/api/clientes/getClienteAfip/" + cuit);
        } catch (e) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: {
              msg: "Error: CUIT no encontrado en la AFIP, no se pudo guardar el cliente",
            },
          };
        }
      const res = await axios.post("/api/clientes", cliente, config);
      dispatch({ type: ADD_CLIENTE, payload: res.data });
      dispatch(setSavingCliente(false));
      if (res.data.warning) {
        dispatch({ type: ERROR_CLIENTE, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_CLIENTE, payload: "" }), 5000);
      }
    } catch (error) {
      console.error(error);
      dispatch(setBypassIoClientes(false));
      await dispatch({ type: ERROR_CLIENTE, payload: error.response.msg });
      setTimeout(() => dispatch({ type: ERROR_CLIENTE, payload: "" }), 5000);
      dispatch(setSavingCliente(false));
    }
  };
};

export const getClientes = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/clientes");
      dispatch({ type: GET_CLIENTE, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CLIENTE, payload: error.msg });
    }
  };
};

//Delete Cliente

export const deleteCliente = (clienteId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/clientes/${clienteId}`);

      dispatch({ type: DELETE_CLIENTE, payload: clienteId });
    } catch (error) {
      await dispatch({ type: ERROR_CLIENTE, payload: error.response.data });
      setTimeout(() => dispatch({ type: ERROR_CLIENTE, payload: "" }), 5000);
    }
  };
};
export const setBypassIoClientes = (should) => {
  return (dispatch) => {
    dispatch({ type: SET_BYPASS_IO_CLIENTES, payload: should });
  };
};

//Set Current Cliente

export function setCurrent(cliente) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_CLIENTE, payload: cliente });
  };
}

export function obtenerClienteAfip(cuit, grupo, currentCliente) {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_AFIP });
    try {
      const cliente = await axios.get(
        "/api/clientes/getClienteAfip/" +
          cuit.toString().replace(/-/g, "").replace(/_/g, "")
      );
      cliente.grupo = grupo;

      dispatch({
        type: OBTENER_CLIENTE_AFIP,
        payload: { currentCliente: currentCliente, ...cliente },
      });
    } catch (error) {
      await dispatch({ type: ERROR_CLIENTE, payload: error.response.data });
      setTimeout(() => dispatch({ type: ERROR_CLIENTE, payload: "" }), 5000);
    }
  };
}

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_CLIENTE, payload: open });
  };
}

//Clear Current Cliente
export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_CLIENTE });
  };
};

export const setSavingCliente = (saving) => {
  return (dispatch) => {
    dispatch({ type: SET_SAVING_CLIENTE, payload: saving });
  };
};

//Update Cliente

export const updateCliente = (cliente) => {
  return async (dispatch) => {
    dispatch(setSavingCliente(true));
    dispatch(setBypassIoClientes(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      let cuit = cliente.cuit.toString().replace(/-/g, "").replace(/_/g, "");
      if (
        parseInt(cuit) !== 11111111111 &&
        parseInt(cliente.clasificacion) !== 3 &&
        cliente.cambioCuit
      ) {
        try {
          await axios.get(
            "/api/clientes/getClienteAfip/" +
              cuit.toString().replace(/-/g, "").replace(/_/g, "")
          );
        } catch (e) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: {
              msg: "Error: CUIT no encontrado en la AFIP, no se pudo guardar el cliente",
            },
          };
        }
      }

      const res = await axios.put(
        `/api/clientes/update/${cliente.id}`,
        cliente,
        config
      );

      dispatch({ type: UPDATE_CLIENTE, payload: res.data });
    } catch (error) {
      dispatch(setBypassIoClientes(false));
      await dispatch({ type: ERROR_CLIENTE, payload: error.response.msg });
      setTimeout(() => dispatch({ type: ERROR_CLIENTE, payload: "" }), 5000);
    }
    dispatch(setSavingCliente(false));
  };
};

//Filter Cliente

export const filterClientes = (text, dir = false, zona = false) => {
  return (dispatch) => {
    if (!dir && !zona) dispatch({ type: FILTER_CLIENTE, payload: text });
    else if (zona) dispatch({ type: FILTER_CLIENTE_ZONA, payload: text });
    else if (dir) dispatch({ type: FILTER_CLIENTE_DIR, payload: text });
  };
};

export const filterClientesPanelVentas = (filters) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CLIENTES_PANEL_VENTAS, payload: filters });
  };
};

//Clear Filter
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_CLIENTE });
  };
};

export const clearClientes = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CLIENTE });
  };
};

export const addContacto = (cliente) => {
  return async (dispatch) => {
    dispatch(setSavingCliente(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/clientes/addContacto`, cliente, config);
      await dispatch({ type: UPDATE_CLIENTE, payload: res.data.cliente });
      dispatch({ type: FILTER_CLIENTES_PANEL_VENTAS, payload: false });
    } catch (error) {
      dispatch({ type: ERROR_CLIENTE, payload: error.response.msg });
    }
    dispatch(setSavingCliente(false));
  };
};

export const removeContactosSemana = (cliente) => {
  return async (dispatch) => {
    dispatch(setSavingCliente(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `/api/clientes/removeContactosSemana`,
        cliente,
        config
      );
      await dispatch({
        type: UPDATE_CLIENTE,
        payload: res.data.cliente,
      });
      dispatch({ type: FILTER_CLIENTES_PANEL_VENTAS, payload: false });
    } catch (error) {
      dispatch({ type: ERROR_CLIENTE, payload: error.response.msg });
    }
    dispatch(setSavingCliente(false));
  };
};
