import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  Icon,
  IconButton,
  List,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import Logo from "../../static/images/logo.png";
import { getRoleCanDo } from "../usuarios/Usuario";
import "./MainNavigation.css";
import NavLinks from "./NavLinks";

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fecha: {
    position: "absolute",

    left: "90px",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      display: "none",
    },
    [theme.breakpoints.up("md")]: {},
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: "48px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: { minHeight: "48px" },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  mainTitle: {
    color: "#fff",
    paddingLeft: "4px",
  },
  listItem: {
    fontSize: "12px",
    padding: "2px 2px 4px 2px",
  },
  listIcon: {
    paddingRight: "25px",
  },
}));

const menu = [
  {
    title: "Ventas",
    link: "/panel_ventas",
    icon: "monetization_on",
  },
  {
    title: "Logística",
    link: "/ventas",
    icon: "monetization_on",
  },
  {
    title: "Administrativo",
    link: "/panel_administrativo",
    icon: "monetization_on",
  },
  {
    title: "Cobranzas",
    icon: "monetization_on",
    menu: [
      {
        title: "Rendiciones",
        link: "/rendiciones",
        icon: "crop_16_9",
      },
      {
        title: "Cobranzas",
        link: "/cobranzas",
        icon: "attach_money",
      },
      {
        title: "Saldos Clientes",
        link: "/saldos_clientes",
        icon: "attach_money",
      },
    ],
  },
  {
    title: "Gastos",
    link: "/gastos",
    secure: "gastos",
    icon: "local_grocery_store",
  },
  {
    title: "Transporte",
    icon: "local_shipping",
    menu: [
      {
        title: "Transportistas",
        link: "/transportistas",
        icon: "local_shipping",
      },

      {
        title: "Optimo Camino",
        link: "/optimo_camino",
        icon: "crop_16_9",
      },
    ],
  },

  {
    title: "Clientes",
    link: "/clientes",
    icon: "group",
  },

  {
    title: "Caja",
    icon: "calendar_today",
    menu: [
      {
        title: "Cajas",
        link: "/cajas",
        secure: "cajas",
        icon: "attach_money",
      },

      {
        title: "Cheques",
        link: "/cheques",
        icon: "crop_16_9",
      },
    ],
  },
  {
    title: "Reportes",
    icon: "calendar_today",
    menu: [
      {
        title: "Dashboard",
        link: "/dashboard",
        icon: "attach_money",
      },
      {
        title: "Volumen Ventas Semanal",
        link: "/reportes/volumen_ventas",
        icon: "attach_money",
      },
      {
        title: "Volumen Ventas Semanal Bonif.",
        link: "/reportes/volumen_ventas_bonificadas",
        icon: "attach_money",
      },
      {
        title: "Total Ventas",
        link: "/reportes/total_ventas",
        icon: "attach_money",
      },
      {
        title: "RG IVA Mensual",
        link: "/api/reportes/rg_iva",
        icon: "attach_money",
        special: "rg_iva",
      },
    ],
  },
  {
    title: "Stock",
    icon: "calendar_today",
    menu: [
      {
        title: "Panel Stock",
        link: "/stock",
        icon: "attach_money",
      },

      {
        title: "Ventas Estimadas",
        link: "/ventas_estimadas",
        icon: "crop_16_9",
      },

      {
        title: "Historial Stock",
        link: "/ajustes_stock",
        icon: "crop_16_9",
      },
    ],
  },
  {
    title: "⚙",
    icon: "calendar_today",
    menu: [
      {
        title: "Grupos",
        link: "/grupos",
        icon: "supervised_user_circle",
      },
      {
        title: "Productos",
        link: "/productos",
        icon: "fastfood",
      },
      {
        title: "Precios",
        link: "/listasPrecios",
        icon: "list",
      },
      {
        title: "Vendedores",
        link: "/vendedores",
        icon: "list",
      },

      {
        title: "Grupos de Contacto",
        link: "/zonas",
        icon: "calendar_today",
      },
      {
        title: "Buscador de dieteticas",
        link: "/buscador_dieteticas",
        icon: "calendar_today",
      },
    ],
  },
];

const MainNavigation = (props) => {
  const { isAuthenticated, logout, usuario, loading } = useContext(AuthContext);
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const onLogout = () => {
    logout();
  };

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} role="presentation" />
      <Divider />
      <List>
        {menu
          .filter((item) => !item.secure || getRoleCanDo(item.secure))
          .map((item, index) => (
            <MenuItem
              button
              selected={props.history.location.pathname === item.link}
              key={item + index}
              onClick={(e) => {
                if (item.special === "logout") onLogout();
                else if (item.special === "rg_iva") {
                  alert("RG IVA");
                  window.open(item.link, "_blank");
                } else {
                  props.history.push(item.link);
                  setMobileOpen(false);
                }
              }}
              className={classes.listItem}
            >
              <Icon className={classes.listIcon}>{item.icon}</Icon> {item.title}
              <Link to={item.link ? item.link : "/"}></Link>
            </MenuItem>
          ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" className="brand-logo">
            <img src={Logo} className="round-img logo" alt="Logo" />{" "}
          </Link>
          <span className={classes.fecha}>
            {moment().format("ddd DD/MM")} S.{moment().isoWeek()}
          </span>
          <nav className="main-navigation__header-nav">
            <NavLinks menu={menu} />
          </nav>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <nav className={classes.drawer} aria-label="Mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
    </Fragment>
  );
};

export default withRouter(connect()(MainNavigation));
