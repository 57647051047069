import { Snackbar } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { getListasPrecios } from "../../actions/listas_precios";
import {
  addVendedor,

  clearCurrent,
  setOpen, updateVendedor
} from "../../actions/vendedores";
import AlertContext from "../../context/alert/alertContext";





const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  btnAfip: {
    marginTop: "5px"
  }
}));

const emptyVendedor = {
  nombre: "",
  comision: ""
};

const VendedorForm = ({
  children,
  current,
  loading,
  vendedores,
  transportistas,
  loading_transportistas,
  loading_listas_precios,
  open,
  dispatch,
  error,
  loading_afip
}) => {
  const [vendedor, setVendedor] = useState(emptyVendedor);

  const { nombre, comision } = vendedor;
  const alertContext = useContext(AlertContext);
  useEffect(() => {
    dispatch(getListasPrecios());

    if (current !== null) {
      setVendedor(current);
    } else {
      setVendedor(emptyVendedor);
    }
  }, [current, open]);

  const onChange = (e, special_field = null) => {
    setVendedor({ ...vendedor, [e.target.name]: e.target.value });
  };

  const onValueChange = (e) => {
    setVendedor({ ...vendedor, comision: e.floatValue });
  };

  const onChangeCheck = (event, name) => {
    setVendedor({ ...vendedor, [name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!nombre) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else {
      try {
        if (!current || !current.id) {
          await dispatch(addVendedor(vendedor));
        } else {
          await dispatch(updateVendedor(vendedor));
        }
      } catch (error) {
        alert(error);
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setVendedor(emptyVendedor);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } 

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="secondary">
        {!current ? "Nuevo vendedor" : "Editar vendedor"}
      </DialogTitle>

      <DialogContent dividers={true}>
        {error && (
          <Snackbar
            open={error ? true : false}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                margin="dense"
                name="nombre"
                label="Nombre *"
                value={nombre}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=","
                thousandSeparator="."
                mask=""
                suffix="%"
                allowNegative={false}
                margin="dense"
                name={`comision`}
                label="Comisión %"
                value={comision}
                onValueChange={onValueChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.vendedores.current,
    vendedores: state.vendedores.vendedores,
    loading: state.vendedores.loading,
    error: state.vendedores.error,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    transportistas: state.transportistas.transportistas,
    loading_transportistas: state.transportistas.loading,
    open: state.vendedores.open,
    loading_afip: state.vendedores.loading_afip
  };
};

export default connect(mapStateToProps)(VendedorForm);
