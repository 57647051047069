export const tiposComprobante = [
  { id: 1, nombre: "Factura A", ini: "FCA" },
  { id: 2, nombre: "Factura B", ini: "FCB" },
  { id: 7, nombre: "Factura E", ini: "FCE" },
  { id: 3, nombre: "Factura X", ini: "FCX" },
  { id: 4, nombre: "Nota de Credito A", ini: "NCA" },
  { id: 5, nombre: "Nota de Credito B", ini: "NCB" },
  { id: 8, nombre: "Nota de Credito E", ini: "NCe" },
  { id: 6, nombre: "Nota de Credito X", ini: "NCX" },

  { id: 11, nombre: "Nota de Débito A", ini: "NDA" },
  { id: 12, nombre: "Nota de Débito B", ini: "NDB" },

  { id: 13, nombre: "Nota de Débito X", ini: "NDX" },

  { id: 9, nombre: "Pago a Cuenta", ini: "PC" },
  { id: 10, nombre: "Pago a Cuenta X", ini: "PCX" },
];

export const tiposComprobanteGastos = [
  "Factura A",
  "Factura B",
  "Factura X",
  "Nota de Credito A",
  "Nota de Credito B",
  "Nota de Credito C",
  "Nota de Credito X",
];

export const tiposFormasDePago = [
  { id: 1, nombre: "Efectivo" },
  { id: 2, nombre: "Transferencia" },
  { id: 3, nombre: "Cheque" },
  { id: 4, nombre: "Cheque electrónico" },
  { id: 5, nombre: "No Paga" },
];

export const tiposTelefonos = [
  { id: 1, nombre: "Whatsapp" },
  { id: 2, nombre: "Llamada" },
  { id: 3, nombre: "E-mail" },
];

export const tiposCobranzas = [
  { id: 1, nombre: "Efectivo contraentrega" },
  { id: 2, nombre: "Transferencia" },
  { id: 3, nombre: "No cobrar" },
  { id: 4, nombre: "Cheque" },
  { id: 5, nombre: "Efectivo B" },
];

export const tiposCobranzasCobro = [
  { id: 1, nombre: "Efectivo" },
  { id: 2, nombre: "Transferencia" },
  { id: 4, nombre: "Cheque" },
];

export const tiposRetenciones = [
  { id: 1, nombre: "Ganancias" },
  { id: 2, nombre: "IIBB" },
  { id: 3, nombre: "IVA" },
  { id: 4, nombre: "SUSS" },
  { id: 5, nombre: "Seg. e Hig." },
];

export const tiposComprobanteCliente = [
  { id: 1, nombre: "Factura A" },
  { id: 2, nombre: "Factura B" },
  { id: 7, nombre: "Factura E" },
  { id: 3, nombre: "Factura X" },
];

export const provincias = [
  { id: 1, nombre: "Buenos Aires" },
  { id: 2, nombre: "Capital Federal" },
  { id: 3, nombre: "Catamarca" },
  { id: 4, nombre: "Chaco" },
  { id: 5, nombre: "Chubut" },
  { id: 6, nombre: "Córdoba" },
  { id: 7, nombre: "Corrientes" },
  { id: 8, nombre: "Entre Ríos" },
  { id: 9, nombre: "Formosa" },
  { id: 10, nombre: "Jujuy" },
  { id: 11, nombre: "La Pampa" },
  { id: 12, nombre: "La Rioja" },
  { id: 13, nombre: "Mendoza" },
  { id: 14, nombre: "Misiones" },
  { id: 15, nombre: "Neuquén" },
  { id: 16, nombre: "Río Negro" },
  { id: 17, nombre: "Salta" },
  { id: 18, nombre: "San Juan" },
  { id: 19, nombre: "San Luis" },
  { id: 20, nombre: "Santa Cruz" },
  { id: 21, nombre: "Santa Fe" },
  { id: 22, nombre: "Santiago del Estero" },
  { id: 23, nombre: "Tierra del Fuego" },
  { id: 24, nombre: "Tucumán" },
];

export const condicionIva = [
  { id: 1, nombre: "Responsable Inscripto" },
  { id: 2, nombre: "Monotributista" },
  { id: 3, nombre: "Consumidor Final" },
  { id: 4, nombre: "Exento de IVA" },
];

export const estados = ["Pendiente", "Aprobada", "Facturada", "Entregada"];

export const idProductoOtros = 6;

export const diasSemana = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
export const diasSemanaNum = [0, 1, 2, 3, 4, 5, 6];

export const canales = [
  { id: 1, nombre: "MIN" },
  { id: 2, nombre: "MAY" },
  { id: 3, nombre: "DIS" },
  { id: 4, nombre: "S" },
  { id: 5, nombre: "Petit May" },
  { id: 6, nombre: "SPMI" },
  { id: 7, nombre: "GASTRO" },
];

export const clienteTiposContacto = [
  { id: 1, nombre: "Comprador" },
  { id: 2, nombre: "Pagos" },
  { id: 6, nombre: "Facturación" },
  { id: 3, nombre: "Dueño" },
  { id: 4, nombre: "Encargado" },
  { id: 5, nombre: "Otros" },
];

export const personerias = [
  { id: 1, nombre: "Física" },
  { id: 2, nombre: "Jurídica" },
];

export const clienteTiposComunicacion = [
  { id: 1, nombre: "Rechazada" },
  { id: 2, nombre: "Llamado" },
  { id: 3, nombre: "Venta" },
];

export const clienteMotivosComunicacion = [
  { id: 1, nombre: "Stock" },
  { id: 2, nombre: "Precio" },
  { id: 3, nombre: "Espacio" },
  { id: 4, nombre: "Vacaciones" },
];

export const cEstados = {
  Pendiente: 1,
  Aprobada: 2,
  Facturada: 3,
  Entregada: 4,
};
export const cTiposComprobantes = {
  "Factura A": 1,
  "Factura B": 2,
  "Factura X": 3,
  "Nota de Credito A": 4,
  "Nota de Credito B": 5,
  "Nota de Credito X": 6,
  "Nota de Débito A": 11,
  "Nota de Débito B": 12,
  "Nota de Débito X": 13,
  "Pago a Cuenta": 9,
  "Pago a Cuenta X": 10,
  "Factura E": 7,
  Facturas: [1, 2, 3, 7],
};

export const cTiposCajas = {
  "Efectivo en Pesos": 1,
  "Efectivo en Dólares": 2,
  "Cuenta corriente en pesos": 3,
  "Cheque terceros": 4,
  Cheque: 5,
  Ajuste: 6,
};

export const cCanales = {
  1: "MIN",
  2: "MAY",
  3: "DIS",
  4: "S",
  5: "Petit May",
  6: "SPMI",
  7: "GASTRO",
};

export const cTiposTelefonos = { 1: "Whatsapp", 2: "Llamada", 3: "E-mail" };

export const cEstadosCheques = [
  { id: 1, nombre: "En cartera" },
  { id: 2, nombre: "Depositado" },
  { id: 3, nombre: "Acreditado" },
  { id: 4, nombre: "Rechazado" },
  { id: 5, nombre: "Utilizado" },
  { id: 6, nombre: "Vencido" },
];

export const cHabilitaciones = [
  { id: 1, nombre: "HABILITACION SAN ISIDRO" },
  { id: 2, nombre: "HABILITACION SAN FERNANDO" },
  { id: 3, nombre: "HABILITACION ESCOBAR" },
  { id: 4, nombre: "HABILITACION MALVINAS ARGENTINAS" },
  { id: 5, nombre: "HABILITACION AVELLANEDA" },
  { id: 6, nombre: "HABILITACION MORON" },
];

export const cValorMiPyme = 1357480; //299555

export const cValorRgIVA = 100000; //100000;
