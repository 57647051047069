import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { filterVendedores } from '../../actions/vendedores';

const VendedoresFilter = ({ vendedores, filter, filtered, dispatch }) => {
  useEffect(() => {}, [filtered]);

  const onChange = e => {
    dispatch(filterVendedores(e.target.value));
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <TextField
        type='text'
        label='Filtrar vendedores'
        margin='dense'
        variant='outlined'
        onChange={onChange}
        fullWidth
        value={filter}
        style={{ paddingBottom: '10px' }}
      />
    </form>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.vendedores.filter,
    filtered: state.vendedores.filtered,
    vendedores: state.vendedores
  };
};

export default connect(mapStateToProps)(VendedoresFilter);
