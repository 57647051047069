import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { getProductos } from "../../actions/productos";
import moment from "moment";
import {
  addVentaEstimada,
  clearCurrent,
  setOpen,
  updateVentaEstimada,
  ajustarStock,
} from "../../actions/ventas_estimadas";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const EditarStockForm = ({
  children,
  current,
  setOpenStock,
  openStock,
  dispatch,
}) => {
  useEffect(() => {}, [current]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!true) {
    } else {
      //SUBMIT
      dispatch(ajustarStock(openStock));
      handleClose();
    }
  };

  const onValueChange = (e, name) => {
    setOpenStock({ ...openStock, [name]: e.floatValue });
  };

  const onChange = (e, option) => {
    if (option === "motivo_ajuste_stock_id") {
      setOpenStock({ ...openStock, motivo_ajuste_stock_id: e.target.value });
    }
    if (option === "fecha") {
      setOpenStock({ ...openStock, fecha: e });
    } else {
      setOpenStock({ ...openStock, [option]: e.target.value });
    }
  };

  const classes = useStyles();

  function handleClose() {
    setOpenStock(null);
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || openStock ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      {openStock && (
        <>
          <DialogTitle id="max-width-dialog-title" color="primary">
            {openStock.nombre + ": Ajuste de Stock"}
          </DialogTitle>
          <DialogContent dividers={true}>
            <form onSubmit={onSubmit}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                  <div>
                    Fecha de Ajuste: *{" "}
                    <DatePicker
                      onChange={(date) => onChange(date, "fecha")}
                      value={moment(openStock.fecha).toDate()}
                      format="dd/MM/y"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl
                    variant="outlined"
                    style={{ height: "55px", paddingTop: "8px", width: "100%" }}
                  >
                    <InputLabel htmlFor="select-cobranza">Motivo *</InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          name={`motivo_ajuste_stock_id`}
                          id={`motivo_ajuste_stock_id`}
                        />
                      }
                      value={openStock.motivo_ajuste_stock_id || null}
                      onChange={(e) => onChange(e, "motivo_ajuste_stock_id")}
                      label="Motivo"
                      fullWidth
                      margin="dense"
                      autoWidth={false}
                    >
                      <MenuItem value={1}>Produccion</MenuItem>
                      <MenuItem value={2}>Control de stock</MenuItem>
                      <MenuItem value={3}>Descarte</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <NumberFormat
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator=""
                    disabled={!openStock.motivo_ajuste_stock_id}
                    mask=""
                    prefix=""
                    decimalScale={0}
                    allowNegative={
                      openStock.motivo_ajuste_stock_id &&
                      openStock.motivo_ajuste_stock_id === 1
                        ? false
                        : true
                    }
                    margin="dense"
                    name="cantidad"
                    value={openStock.cantidad}
                    label="Ajuste* "
                    onValueChange={(e) => onValueChange(e, "cantidad")}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {openStock.motivo_ajuste_stock_id &&
                  openStock.motivo_ajuste_stock_id === 1 && (
                    <Grid item xs={12} md={12}>
                      <NumberFormat
                        customInput={TextField}
                        decimalSeparator=","
                        thousandSeparator=""
                        max={53}
                        min={1}
                        mask=""
                        prefix=""
                        decimalScale={2}
                        allowNegative={false}
                        margin="dense"
                        name="batch"
                        value={openStock.batch}
                        label="Batch"
                        onValueChange={(e) => onValueChange(e, "batch")}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )}
                {openStock.motivo_ajuste_stock_id &&
                (openStock.motivo_ajuste_stock_id === 2 ||
                  openStock.motivo_ajuste_stock_id === 3) ? (
                  <>
                    <Grid item xs={12} md={12} style={{ minHeight: "200px" }}>
                      <TextField
                        margin="dense"
                        name="motivo_ajuste"
                        value={openStock.motivo_ajuste}
                        label="Notas"
                        onChange={(e) => onChange(e, "motivo_ajuste")}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} md={12} style={{ minHeight: "200px" }}>
                    <br />
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <h3>
              Nueva cantidad:{" "}
              {openStock.cantidad_anterior +
                (openStock.cantidad ? openStock.cantidad : 0)}
            </h3>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              type="submit"
            >
              Guardar
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    mix_ventas: state.ventasEstimadas.mix_ventas,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(EditarStockForm);
