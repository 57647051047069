import axios from "axios";

import {
  ADD_CHEQUE,
  DELETE_CHEQUE,
  SET_CURRENT_CHEQUE,
  CLEAR_CURRENT_CHEQUE,
  UPDATE_CHEQUE,
  FILTER_CHEQUE,
  CLEAR_FILTER_CHEQUE,
  ERROR_CHEQUE,
  GET_CHEQUE,
  CLEAR_CHEQUE,
  SET_OPEN_CHEQUE,
  DEPOSITAR_MULTIPLES_CHEQUES
} from "./types";

//Add Cheque
export const addCheque = (cheque) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const res = await axios.post("/api/cheques", cheque, config);
      dispatch({ type: ADD_CHEQUE, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE, payload: "Error agregando cheque" });
    }
  };
};

export const getCheques = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/cheques");
      dispatch({ type: GET_CHEQUE, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE, payload: error.msg });
    }
  };
};

//Delete Cheque

export const deleteCheque = (chequeId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/cheques/${chequeId}`);

      dispatch({ type: DELETE_CHEQUE, payload: chequeId });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE, payload: error.response.msg });
    }
  };
};

//Set Current Cheque

export function setCurrent(cheque) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_CHEQUE, payload: cheque });
  };
}

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_CHEQUE, payload: open });
  };
}

//Clear Current Cheque
export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_CHEQUE });
  };
};
//Update Cheque

export const updateCheque = (cheque) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const res = await axios.put(`/api/cheques/${cheque.id}`, cheque, config);
      dispatch({ type: UPDATE_CHEQUE, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE, payload: error.response.msg });
    }
  };
};

export const depositarMultiples = (cheques, fecha, banco, proveedor, accion) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const res = await axios.put(
        `/api/cheques/depositar_multiples/1`,
        { cheques, fecha, banco, proveedor, accion },
        config
      );

      dispatch({
        type: DEPOSITAR_MULTIPLES_CHEQUES,
        payload: { cheques: res.data.cheques, fecha, banco, proveedor, accion }
      });
      if (res.data.warning) {
        dispatch({ type: ERROR_CHEQUE, payload: res.data.warning.message });
        setTimeout(() => dispatch({ type: ERROR_CHEQUE, payload: "" }), 3000);
      }
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE, payload: error.response.data.error.msg });
      setTimeout(() => dispatch({ type: ERROR_CHEQUE, payload: "" }), 3000);
    }
  };
};

//Filter Cheque

export const filterCheques = (text) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CHEQUE, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_CHEQUE });
  };
};

export const clearCheques = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CHEQUE });
  };
};
