import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { filterClientesPanelVentas } from "../../actions/clientes";

const PanelVentasFilter = ({
  clientes,
  zonas,
  canales,
  filter_panel_ventas,
  dispatch,
}) => {
  const diasZonas = {
    0: ["CABA1"], //Lunes
    1: ["CABA2"], //Martes
    2: [],
    3: ["DIS", "DISINT"],
    4: ["INT", "SUP", "MAY", "GASTRO"],
    5: [],
    6: [],
  };

  const [filtersPanelVentas, setFiltersPanelVentas] = useState({
    zonas: filter_panel_ventas.zonas
      ? filter_panel_ventas.zonas
      : diasZonas[new Date().getDay()],
    canales: filter_panel_ventas.canales ? filter_panel_ventas.canales : [],
    nombre: "",
  });

  useEffect(() => {});
  const onChange = (e, tipo) => {
    if (tipo === "zona") {
      setFiltersPanelVentas({ ...filtersPanelVentas, zonas: e.target.value });
      dispatch(
        filterClientesPanelVentas({
          ...filtersPanelVentas,
          zonas: e.target.value,
        })
      );
    } else if (tipo === "canal") {
      setFiltersPanelVentas({ ...filtersPanelVentas, canales: e.target.value });
      dispatch(
        filterClientesPanelVentas({
          ...filtersPanelVentas,
          canales: e.target.value,
        })
      );
    } else if (tipo === "forma_contacto") {
      setFiltersPanelVentas({
        ...filtersPanelVentas,
        forma_contacto: e.target.value,
      });
      dispatch(
        filterClientesPanelVentas({
          ...filtersPanelVentas,
          forma_contacto: e.target.value,
        })
      );
    } else {
      setFiltersPanelVentas({
        ...filtersPanelVentas,
        nombre: e.target.value,
      });
      dispatch(
        filterClientesPanelVentas({
          ...filtersPanelVentas,
          nombre: e.target.value,
        })
      );
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid container>
        <Grid item md={2}>
          <FormControl
            variant="outlined"
            style={{
              height: "40px",
              width: "100%",
              paddingTop: "8px",
            }}
          >
            <InputLabel htmlFor="select-tipo-comprobante">Zona</InputLabel>
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "80%",
                  }}
                  id="select-tipo-comprobante"
                />
              }
              value={filtersPanelVentas.zonas}
              name="zona"
              multiple={true}
              onChange={(e) => onChange(e, "zona")}
            >
              {zonas.map((zona) => (
                <MenuItem value={zona.nombre} key={zona.id}>
                  {zona.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl
            variant="outlined"
            style={{
              height: "40px",
              width: "100%",
              paddingTop: "8px",
            }}
          >
            <InputLabel htmlFor="select-tipo-comprobante">Canal</InputLabel>
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "80%",
                  }}
                  id="select-tipo-comprobante"
                />
              }
              value={filtersPanelVentas.canales}
              name="canal"
              multiple={true}
              onChange={(e) => onChange(e, "canal")}
            >
              {canales.map((canal) => (
                <MenuItem value={canal.nombre} key={canal.id}>
                  {canal.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl
            variant="outlined"
            style={{
              height: "40px",
              width: "100%",
              paddingTop: "8px",
            }}
          >
            <InputLabel htmlFor="select-tipo-comprobante">
              Forma de Contacto
            </InputLabel>
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "80%",
                  }}
                  id="select-tipo-comprobante"
                />
              }
              value={filtersPanelVentas.forma_contacto || ""}
              name="zona"
              onChange={(e) => onChange(e, "forma_contacto")}
            >
              <MenuItem value={""} key={"todos"}>
                Todas
              </MenuItem>
              <MenuItem value={"mail"} key={"mail"}>
                Mail
              </MenuItem>
              <MenuItem value={"telefono"} key={"telefono"}>
                Telefono
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            type="text"
            label="Filtrar clientes"
            margin="dense"
            variant="outlined"
            onChange={(e) => onChange(e, "nombre")}
            fullWidth
            value={filtersPanelVentas.nombre}
            style={{ paddingBottom: "10px" }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.clientes.filter,
    filter_panel_ventas: state.clientes.filter_panel_ventas,
    filtered: state.clientes.filtered,
    clientes: state.clientes,
  };
};

export default connect(mapStateToProps)(PanelVentasFilter);
