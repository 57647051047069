import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { setOpen, setCurrent } from '../../actions/cheques_propios';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));
const AddChequePropioBtn = ({ dispatch }) => {
  const classes = useStyles();

  return (
    <div className='fixed-action-btn'>
      <Fab
        onClick={() => dispatch(setOpen(true))}
        color='primary'
        aria-label='Add'
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    cheques_propios: state.chequesPropios.cheques_propios,
    current: state.chequesPropios.current
  };
};

export default connect(mapStateToProps)(AddChequePropioBtn);
