import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTransportistas } from '../../actions/transportistas';
import subscribeTo from '../../utils/subscribeTo';
import AddTransportistaBtn from '../transportistas/AddTransportistaBtn';
import TransportistaFilter from '../transportistas/TransportistaFilter';
import TransportistaForm from '../transportistas/TransportistaForm';
import TransportistaItem from './TransportistaItem';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const Transportistas = ({ transportistas, filtered, loading, dispatch }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getTransportistas());
    if (!loading) {
      subscribeTo('transportistasChanged', (err, data) => {
        dispatch(getTransportistas());
      });
      return () => {
        subscribeTo('transportistasChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (transportistas !== null && !transportistas.length && !loading) {
    
    return (
      <Fragment>
        <TransportistaForm />
        <h4>No hay ningún transportista todavía!</h4> <AddTransportistaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <TransportistaForm />
      </div>
      <div>
        <TransportistaFilter />
        <div className={classes.root}>
          {transportistas !== null && !loading ? (
            <Grid
              container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              {(filtered ? filtered : transportistas).map(
                (transportista, key) => (
                  <TransportistaItem key={key} transportista={transportista} />
                )
              )}
            </Grid>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddTransportistaBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    transportistas: state.transportistas.transportistas,
    filtered: state.transportistas.filtered,
    loading: state.transportistas.loading
  };
};

export default connect(mapStateToProps)(Transportistas);
