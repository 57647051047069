import { Input, MenuItem, OutlinedInput, Radio, Select } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { Fragment, useState } from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { depositarMultiples } from "../../actions/cheques";
import { calcWorkingDays } from "../../utils/functions";
import { cEstadosCheques, cTiposCajas } from "../../config/constantes";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(12)
  },
  fab2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(20)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ApproveChequeBtn = ({ dispatch, ids, modo, cajas, estado, setSelected }) => {
  const [fecha, setFecha] = useState(new Date());
  const [fechaUtilizado, setFechaUtilizado] = useState(new Date());
  const [fechaAcreditado, setFechaAcreditado] = useState(new Date());
  const [fechaRechazado, setFechaRechazado] = useState(new Date());

  const [banco, setBanco] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("depositar");
  const [proveedor, setProveedor] = React.useState("");
  const classes = useStyles();
  const confirm = useConfirm();

  const onClickAprobar = (confirm, dispatch) => {
    if (modo === "En cartera") {
      confirm({
        description:
          selectedValue[0].toUpperCase() +
          selectedValue.slice(1) +
          (ids.length > 1 ? ` multiples cheques` : " cheque") +
          `?`,
        title: "ATENCION",
        cancellationText: "Cancelar",
        confirmationText: "Confirmar"
      }).then(() => {
        let fechaEnviar =
          selectedValue === "depositar"
            ? fecha
            : selectedValue === "utilizar"
            ? fechaUtilizado
            : selectedValue === "rechazar"
            ? fechaRechazado
            : selectedValue === "acreditar"
            ? fechaAcreditado
            : "";
        return dispatch(depositarMultiples(ids, fechaEnviar, banco, proveedor, selectedValue)).then(() => {
          setSelected([]);
        });
      });
    } else if (modo === "Aprobadas") {
    }
    /* confirm({
        description: `Asignar multiples ventas a camioneta ${camioneta}?`,
        title: "ATENCION",
        cancellationText: "Cancelar",
        confirmationText: "Confirmar"
      }).then(() => dispatch(asignarCamioneta(ids, camioneta)));
      */
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="fixed-action-btn">
      {estado === 1 && (
        <div
          style={{
            color: "black",
            backgroundColor: "rgb(237 247 237)",
            padding: "10px",
            border: "1px black solid",
            borderRadius: "10px"
          }}
          className={classes.fab2}
        >
          <h3>Acción en {ids.length} cheques en cartera:</h3>
          <Fragment>
            <Radio name="accion" value="depositar" checked={selectedValue === "depositar"} onChange={handleChange} />
            Depositar:{" "}
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "200px"
                  }}
                  id="select-forma-pago"
                  placeholder="Banco"
                />
              }
              onChange={(e) => setBanco(e.target.value)}
              value={banco}
              name="banco"
            >
              {cajas
                .filter((c) => c.caja_tipo_id === cTiposCajas["Cuenta corriente en pesos"])
                .map((banco, index) => (
                  <MenuItem value={banco.id} key={index}>
                    {banco.nombre}
                  </MenuItem>
                ))}
            </Select>
            <Fragment>
              {" "}
              Fecha de Depósito <DatePicker onChange={(date) => setFecha(date)} value={fecha} format="dd/MM/y" />{" "}
            </Fragment>
            <br />
            <br />
            <Radio name="accion" value="utilizar" checked={selectedValue === "utilizar"} onChange={handleChange} />
            Utilizar:{" "}
            <OutlinedInput
              placeholder="Provedor"
              margin="dense"
              value={proveedor}
              onChange={(e) => setProveedor(e.target.value)}
            ></OutlinedInput>{" "}
            Fecha de Utilizacion{" "}
            <DatePicker onChange={(date) => setFechaUtilizado(date)} value={fechaUtilizado} format="dd/MM/y" /> <br />
            <br />
            <Radio name="accion" value="rechazar" checked={selectedValue === "rechazar"} onChange={handleChange} />
            Rechazado{" "}
            <DatePicker onChange={(date) => setFechaRechazado(date)} value={fechaRechazado} format="dd/MM/y" />
          </Fragment>
        </div>
      )}
      {estado === 2 && (
        <div
          style={{
            color: "black",
            backgroundColor: "rgb(237 247 237)",
            padding: "10px",
            border: "1px black solid",
            borderRadius: "10px"
          }}
          className={classes.fab2}
        >
          <h3>Acción en {ids.length} cheques depositados:</h3>
          <Fragment>
            <Radio name="accion" value="acreditar" checked={selectedValue === "acreditar"} onChange={handleChange} />
            Acreditado{" "}
            <DatePicker onChange={(date) => setFechaAcreditado(date)} value={fechaAcreditado} format="dd/MM/y" />
            <br />
            <br />
            <Radio name="accion" value="rechazar" checked={selectedValue === "rechazar"} onChange={handleChange} />
            Rechazado{" "}
            <DatePicker onChange={(date) => setFechaRechazado(date)} value={fechaRechazado} format="dd/MM/y" />
          </Fragment>
        </div>
      )}
      {(estado === 3 || estado === 4 || estado === 5) && (
        <div
          style={{
            color: "black",
            backgroundColor: "rgb(237 247 237)",
            padding: "10px",
            border: "1px black solid",
            borderRadius: "10px"
          }}
          className={classes.fab2}
        >
          <h3>
            Acción en {ids.length} cheques {cEstadosCheques.find((e) => e.id === estado).nombre.toLowerCase() + "s"}:
          </h3>
          <Fragment>
            <Radio name="accion" value="cartera" checked={selectedValue === "cartera"} onChange={handleChange} />
            Volver a cartera
          </Fragment>
        </div>
      )}
      <Fab
        style={{ color: "white", backgroundColor: "blue" }}
        aria-label="Add"
        onClick={(e) => onClickAprobar(confirm, dispatch)}
        className={classes.fab}
      >
        <Icon>check_rounded</Icon>
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ventas: state.ventas.ventas,
    current: state.ventas.current
  };
};

export default connect(mapStateToProps)(ApproveChequeBtn);
