import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAjustesStock } from "../../actions/ventas_estimadas";
import subscribeTo from "../../utils/subscribeTo";
import AddVentaEstimadaBtn from "./AddVentaEstimadaBtn";
import VentaEstimadaForm from "./VentaEstimadaForm";
import { getProductos } from "../../actions/productos";
import NumberFormat from "react-number-format";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
});

const AjustesStock = ({
  ajustes_stock,
  filtered,
  loading,
  dispatch,
  productos,
  loading_productos,
  ultimaSemana,
}) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAjustesStock());
    dispatch(getProductos());
    if (!loading) {
      subscribeTo("productosChanged", (err, data) => {
        dispatch(getAjustesStock());
      });
      return () => {
        subscribeTo("productosChanged", null);
      };
    }
  }, [dispatch, loading]);

  if (ajustes_stock !== null && !ajustes_stock.length && !loading) {
    return (
      <Fragment>
        <VentaEstimadaForm />
        <h4>No hay ajustes de stock!</h4>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <VentaEstimadaForm />
      </div>
      <div>
        <div className={classes.root}>
          <h3>Histórico de Ajustes</h3>
          {ajustes_stock && ajustes_stock.length && !loading_productos && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell align="center">Fecha</TableCell>
                    <TableCell align="center">Usuario</TableCell>
                    <TableCell align="center">Producto</TableCell>
                    <TableCell align="center">Stock Anterior</TableCell>
                    <TableCell align="center">Ajuste</TableCell>
                    <TableCell align="center">Stock Final</TableCell>
                    <TableCell align="center">Motivo</TableCell>
                    <TableCell align="center">Batch/Notas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ajustes_stock &&
                    ajustes_stock.length &&
                    ajustes_stock.map((ajuste, index) => (
                      <>
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell align="center">
                            {ajuste.fecha &&
                              moment(ajuste.fecha).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {ajuste.usuario.nombre}
                          </TableCell>
                          <TableCell align="center">
                            {ajuste.producto.nombre}
                          </TableCell>

                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix=""
                              decimalScale={0}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={
                                ajuste.cantidad_anterior
                                  ? ajuste.cantidad_anterior
                                  : "-"
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>

                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix=""
                              decimalScale={0}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={ajuste.cantidad ? ajuste.cantidad : "-"}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix=""
                              decimalScale={0}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={
                                ajuste.absoluto
                                  ? ajuste.cantidad
                                  : ajuste.cantidad
                                  ? ajuste.cantidad + ajuste.cantidad_anterior
                                  : "-"
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            {ajuste.motivo.nombre}
                          </TableCell>
                          <TableCell align="center">
                          {ajuste.batch ? ajuste.batch : ajuste.motivo_ajuste}
                          {ajuste.absoluto ? "[absoluto]":""}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    ajustes_stock: state.ventasEstimadas.ajustes_stock,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(AjustesStock);
