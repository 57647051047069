import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { setOpen } from '../../actions/cobranzas';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));
const AddCobranzaBtn = ({ dispatch }) => {
  const classes = useStyles();

  return (
    <div className='fixed-action-btn'>
      <Fab
        onClick={() => dispatch(setOpen(true))}
        color='primary'
        aria-label='Add'
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    cobranzas: state.cobranzas.cobranzas,
    current: state.cobranzas.current
  };
};

export default connect(mapStateToProps)(AddCobranzaBtn);
