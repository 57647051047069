import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import transportistasReducer from "../reducers/transportistas";
import provedoresReducer from "../reducers/provedores";
import cajasReducer from "../reducers/cajas";
import bancosReducer from "../reducers/bancos";
import clientesReducer from "../reducers/clientes";
import chequesReducer from "../reducers/cheques";
import chequesPropiosReducer from "../reducers/chequesPropios";
import gastosReducer from "../reducers/gastos";
import cobranzasReducer from "../reducers/cobranzas";
import rendicionesReducer from "../reducers/rendiciones";
import usuariosReducer from "../reducers/usuarios";
import productosReducer from "../reducers/productos";
import zonasReducer from "../reducers/zonas";
import gruposReducer from "../reducers/grupos";
import vendedoresReducer from "../reducers/vendedores";
import ventasReducer from "../reducers/ventas";
import listasPreciosReducer from "../reducers/listasPrecios";
import lanzamientosDeCajaReducer from "../reducers/lanzamientosDeCaja";
import ventasEstimadasReducer from "../reducers/ventasEstimadas";
import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";

export default () => {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })) ||
    compose;

  const store = createStore(
    combineReducers({
      transportistas: transportistasReducer,
      provedores: provedoresReducer,
      gastos: gastosReducer,
      cajas: cajasReducer,
      clientes: clientesReducer,
      chequesPropios: chequesPropiosReducer,
      cheques: chequesReducer,
      rendiciones: rendicionesReducer,
      lanzamientosDeCaja: lanzamientosDeCajaReducer,
      productos: productosReducer,
      listasPrecios: listasPreciosReducer,
      ventas: ventasReducer,
      bancos: bancosReducer,
      zonas: zonasReducer,
      grupos: gruposReducer,
      vendedores: vendedoresReducer,
      cobranzas: cobranzasReducer,
      usuarios: usuariosReducer,
      ventasEstimadas: ventasEstimadasReducer,
      form: formReducer
    }),
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
  return store;
};
