import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addZona,

  clearCurrent,
  setOpen, updateZona
} from "../../actions/zonas";




const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const emptyZona = {
  nombre: "",
  dias_entrega: []
};

const ZonaForm = ({ children, current, open, dispatch }) => {
  const [zona, setZona] = useState(emptyZona);

  const { nombre, dias_entrega } = zona;

  useEffect(() => {
    if (current !== null) {
      setZona(current);
    } else {
      setZona(emptyZona);
    }
  }, [current, open]);

  const onChange = (e, option) => {
    if (option === "dias_entrega") {
      setZona({ ...zona, dias_entrega: e.target.value });
    } else {
      setZona({ ...zona, [e.target.name]: e.target.value });
    }
  };

  const onValueChange = (e) => {
    setZona({ ...zona, stock: e.floatValue });
  };

  const onChangeCheck = (name) => (event) => {
    setZona({ ...zona, [name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!nombre) {
    } else {
      try {
        if (!current) {
          await dispatch(addZona(zona));
        } else {
          await dispatch(updateZona(zona));
        }
      } catch (error) {
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setZona(emptyZona);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current ? "Nueva zona" : "Editar zona"}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                name="nombre"
                label="Nombre"
                value={nombre}
                onChange={onChange}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                style={{ height: "55px", width: "100%" }}
              >
                <Select
                  input={
                    <OutlinedInput name={`dias_entrega`} id={`dias_entrega`} />
                  }
                  value={dias_entrega}
                  onChange={(e) => onChange(e, "dias_entrega")}
                  label="Dias"
                  fullWidth
                  autoWidth={false}
                  multiple={true}
                >
                  <MenuItem value={1}>Lunes</MenuItem>
                  <MenuItem value={2}>Martes</MenuItem>
                  <MenuItem value={3}>Miércoles</MenuItem>
                  <MenuItem value={4}>Jueves</MenuItem>
                  <MenuItem value={5}>Viernes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.zonas.current,
    open: state.zonas.open
  };
};

export default connect(mapStateToProps)(ZonaForm);
