import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
  addCaja,
  clearCurrent,
  setOpen,
  updateCaja,
} from "../../actions/cajas";
import { cTiposCajas } from "../../config/constantes";
import { tiposCaja } from "./Cajas";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

const emptyCaja = {
  nombre: "",
  caja_tipo_id: null,
  balance: "",
  especial: false,
  valor_ajuste: "",
  motivo_ajuste: "",
  fecha_ajuste: new Date(),
};

const CajaForm = ({ children, current, open, dispatch }) => {
  const [caja, setCaja] = useState(emptyCaja);

  const {
    nombre,
    caja_tipo_id,
    balance,
    especial,
    valor_ajuste,
    billetes,
    motivo_ajuste,
    fecha_ajuste,
    es_balance,
  } = caja;

  useEffect(() => {
    if (current !== null) {
      if (!current.ajuste) setCaja(current);
      else
        setCaja({
          ...emptyCaja,
          ...current,
          ...(current.es_balance && {
            motivo_ajuste: "Balance",
          }),
        });
    } else {
      setCaja(emptyCaja);
    }
  }, [current, open]);

  useEffect(() => {
    if (caja.billetes) {
      setCaja({
        ...caja,
        valor_ajuste: Object.keys(caja.billetes).reduce(
          (acc, b) =>
            parseInt(acc) +
            (caja.billetes[b]
              ? parseInt(b * parseInt(caja.billetes[b] ? caja.billetes[b] : 0))
              : 0),
          0
        ),
      });
    } else {
      setCaja({
        ...caja,
        valor_ajuste: 0,
      });
    }
    console.log(caja.billetes);
  }, [billetes]);

  const onChange = (e, extra) => {
    if (!extra) {
      setCaja({ ...caja, [e.target.name]: e.target.value });
    } else if (extra === "fecha_ajuste") {
      setCaja({ ...caja, fecha_ajuste: moment(e).format("YYYY-MM-DD") });
    }
  };

  const onValueChange = (type, e, extra) => {
    if (!extra) setCaja({ ...caja, [type]: e.floatValue });
    else
      setCaja({
        ...caja,
        billetes: { ...caja.billetes, [extra]: e.floatValue },
      });
  };

  const onChangeCheck = (event, name) => {
    setCaja({ ...caja, [name]: event.target.checked });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!nombre) {
    } else {
      try {
        if (!current) {
          dispatch(addCaja(caja));
        } else {
          console.log(caja);
          dispatch(updateCaja(caja));
        }
      } catch (error) {}

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setCaja(emptyCaja);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="primary">
        {!current
          ? "Nueva caja"
          : !current.ajuste
          ? "Editar caja"
          : current.es_balance
          ? "Balance de caja: " + current.nombre
          : "Ajuste de caja: " + current.nombre}
      </DialogTitle>
      {!current || !current.ajuste ? (
        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="nombre"
                  label="Nombre"
                  value={nombre}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "8px",
                  }}
                >
                  <InputLabel htmlFor="select-tipo-comprobante">
                    Tipo de Caja
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        id="select-tipo-comprobante"
                      />
                    }
                    fullWidth
                    value={caja_tipo_id ? caja_tipo_id : ""}
                    name="caja_tipo_id"
                    onChange={(e) => onChange(e)}
                  >
                    {tiposCaja.map((tipo_caja) => (
                      <MenuItem value={tipo_caja.id} key={tipo_caja.id}>
                        {tipo_caja.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <NumberFormat
                  autoFocus
                  customInput={TextField}
                  decimalSeparator=","
                  thousandSeparator="."
                  mask=""
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  margin="dense"
                  name="balance"
                  label="Balance"
                  value={balance}
                  disabled
                  onValueChange={(e) => onValueChange("balance", e)}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  value="especial"
                  control={
                    <Checkbox
                      margin="dense"
                      name="especial"
                      label="Especial"
                      value="1"
                      checked={especial ? true : false}
                      onChange={(e) => onChangeCheck(e, "especial")}
                      className={classes.textField}
                      variant="outlined"
                    />
                  }
                  label="Especial?"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      ) : (
        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={!es_balance ? 6 : 8}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="motivo_ajuste"
                  label="Motivo *"
                  value={motivo_ajuste}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {!es_balance && (
                <Grid item xs={12} md={2}>
                  <NumberFormat
                    customInput={TextField}
                    decimalSeparator=","
                    thousandSeparator="."
                    mask=""
                    prefix="$"
                    decimalScale={2}
                    allowNegative={true}
                    margin="dense"
                    name="valor_ajuste"
                    label="Valor *"
                    value={valor_ajuste}
                    onValueChange={(e) => onValueChange("valor_ajuste", e)}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <div style={{ paddingTop: "12px" }}>
                  Fecha*:{" "}
                  <DatePicker
                    onChange={(date) => onChange(date, "fecha_ajuste")}
                    value={new Date()}
                    format="dd/MM/y"
                  />
                </div>
              </Grid>
              {es_balance && (
                <>
                  {caja_tipo_id === cTiposCajas["Efectivo en Pesos"] ||
                  caja_tipo_id === cTiposCajas["Efectivo en Dólares"] ? (
                    <>
                      <Grid item xs={12} md={12}>
                        <h3>Billetes</h3>
                      </Grid>
                      {[1000, 500, 200, 100, 50, 20, 10, 5, 2, 1].map((b) => (
                        <Grid item xs={12} md={2}>
                          <NumberFormat
                            customInput={TextField}
                            decimalSeparator=","
                            thousandSeparator="."
                            mask=""
                            prefix=""
                            decimalScale={0}
                            allowNegative={false}
                            margin="dense"
                            name="valor_ajuste"
                            label={"$" + b}
                            value={billetes ? billetes[b] : null}
                            onValueChange={(e) =>
                              onValueChange("billetes", e, b)
                            }
                            className={classes.textField}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={2}>
                        <NumberFormat
                          customInput={TextField}
                          decimalSeparator=","
                          thousandSeparator="."
                          mask=""
                          prefix="$"
                          decimalScale={2}
                          allowNegative={true}
                          margin="dense"
                          name="valor_ajuste"
                          label="Valor *"
                          value={valor_ajuste}
                          onValueChange={(e) =>
                            onValueChange("valor_ajuste", e)
                          }
                          className={classes.textField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </form>
        </DialogContent>
      )}
      <DialogActions>
        {es_balance && <h3>Nuevo balance de caja: ${valor_ajuste || 0}</h3>}
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.cajas.current,
    open: state.cajas.open,
  };
};

export default connect(mapStateToProps)(CajaForm);
