import escapeRegExp from 'escape-string-regexp';
import {
  ADD_PROVEDOR,
  DELETE_PROVEDOR,
  SET_CURRENT_PROVEDOR,
  CLEAR_CURRENT_PROVEDOR,
  UPDATE_PROVEDOR,
  FILTER_PROVEDOR,
  CLEAR_FILTER_PROVEDOR,
  ERROR_PROVEDOR,
  GET_PROVEDOR,
  CLEAR_PROVEDOR,
  SET_OPEN_PROVEDOR
} from '../actions/types';

const initialState = {
  provedores: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVEDOR:
      return {
        ...state,
        provedores: action.payload,
        loading: false
      };
    case ADD_PROVEDOR:
      return {
        ...state,
        provedores: [...state.provedores, action.payload],
        loading: false
      };
    case UPDATE_PROVEDOR:
      return {
        ...state,
        provedores: state.provedores.map(provedor =>
          provedor.id === action.payload.id ? action.payload : provedor
        )
      };
    case DELETE_PROVEDOR:
      return {
        ...state,
        provedores: state.provedores.filter(
          provedor => provedor.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_PROVEDOR:
      return {
        ...state,
        provedores: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_PROVEDOR:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_PROVEDOR:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_PROVEDOR:
      return {
        ...state,
        current: null
      };

    case FILTER_PROVEDOR:
      return {
        ...state,
        filter: action.payload,
        filtered: state.provedores.filter(provedor => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return provedor.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_PROVEDOR:
      return {
        ...state,
        filtered: null
      };
    case ERROR_PROVEDOR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
