import { Grid, LinearProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { getProductos } from '../../actions/productos';
import subscribeTo from '../../utils/subscribeTo';
import AddProductoBtn from '../productos/AddProductoBtn';
import ProductoForm from '../productos/ProductoForm';
import ProductoItem from './ProductoItem';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: '5px'
  }
});

const Productos = ({ productos, filtered, loading, dispatch }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(getProductos());
    if (!loading) {
      subscribeTo('productosChanged', (err, data) => {
        dispatch(getProductos());
      });
      return () => {
        subscribeTo('productosChanged', null);
      };
    }
  }, [dispatch, loading]);

  if (productos !== null && !productos.length && !loading) {
    return (
      <Fragment>
        <ProductoForm />
        <h4>No hay ningun Producto todavía!</h4> <AddProductoBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <ProductoForm />
      </div>
      <div>
        <div className={classes.root}>
          {productos !== null && !loading ? (
            <Fragment>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='flex-start'
                alignItems='flex-start'
              >
                {(filtered ? filtered : productos).map((producto, key) => (
                  <ProductoItem key={key} producto={producto} />
                ))}
              </Grid>
            </Fragment>
          ) : (
            <LinearProgress className={classes.root} />
          )}
        </div>
      </div>
      <AddProductoBtn />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    productos: state.productos.productos,
    filtered: state.productos.filtered,
    loading: state.productos.loading
  };
};

export default connect(mapStateToProps)(Productos);
