import axios from 'axios';

import {
  GET_USUARIOS,
  ADD_USUARIO,
  DELETE_USUARIO,
  UPDATE_USUARIO,
  CLEAR_USUARIOS,
  USUARIO_ERROR,
  SET_CURRENT,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_USUARIOS,
  CLEAR_FILTER,
  SET_SAVING_USUARIO
} from './types';

export const getUsuarios = (filters = null) => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/usuarios', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          filters: filters
        },
        data: null
      });
      dispatch({ type: GET_USUARIOS, payload: res.data });
    } catch (error) {
      dispatch({ type: USUARIO_ERROR, payload: error.msg });
    }
  };
};

export function setCurrent(usuario) {
  return dispatch => {
    dispatch({ type: SET_CURRENT, payload: usuario });
  };
}
export const setSavingUsuario = (saving) => {
  return dispatch => {
    dispatch({ type: SET_SAVING_USUARIO, payload: saving });
  };
};

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN, payload: open });
  };
}

export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT });
  };
};

export const filterUsuarios = (filter, filterType) => {
  return dispatch => {
    dispatch({ type: FILTER_USUARIOS, filter: filter, filterType: filterType });
  };
};
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER });
  };
};

//Add Usuario
export const addUsuario = usuario => {
  return async dispatch => {
    dispatch(setSavingUsuario(true));
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/usuarios', usuario, config);
      dispatch({ type: ADD_USUARIO, payload: res.data });
    } catch (error) {
      dispatch({ type: USUARIO_ERROR, payload: error.msg });
    }
    dispatch(setSavingUsuario(false));
  };
};

//Delete Usuario

export const deleteUsuario = usuarioId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/usuarios/${usuarioId}`);

      dispatch({ type: DELETE_USUARIO, payload: usuarioId });
    } catch (error) {
      dispatch({ type: USUARIO_ERROR, payload: error.response.msg });
    }
  };
};

//Set Current Usuario

//Clear Current Usuario

//Update Usuario

export const updateUsuario = usuario => {
  return async dispatch => {
    dispatch(setSavingUsuario(true));
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(`/api/usuarios/${usuario.id}`, usuario, config);

      dispatch({ type: UPDATE_USUARIO, payload: res.data });
    } catch (error) {
      dispatch({ type: USUARIO_ERROR, payload: error.msg });
    }
    dispatch(setSavingUsuario(false));
  };
};

//Filter Usuario

//Clear Filter

export const clearUsuarios = () => {
  return dispatch => {
    dispatch({ type: CLEAR_USUARIOS });
  };
};
