import React, { useState, useEffect, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import {
  addChequePropio,
  updateChequePropio,
  clearCurrent,
  setOpen,
  setCurrent,
  getChequesPropios
} from '../../actions/cheques_propios';
import { getCajas } from '../../actions/cajas';
import ObjectID from 'bson-objectid';
import PlacesInput from '../utils/PlacesInput';
import TelefonosInput from '../utils/TelefonosInput';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TimeInput from 'material-ui-time-picker';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-date-picker';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';



const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  dialogMain: {
    minHeight: '50vh'
  }
}));

const emptyChequePropio = {
  nota: '',
  valor: '',
  fecha: null,
  caja: '',
  fecha_deposito: null,
  numero: '',
  //AUX VARIABLE
  saving: false
};

const ChequePropioForm = ({
  children,
  current,
  open,
  dispatch,

  cajas,
  loading_cajas,

}) => {
  const [cheque_propio, setChequePropio] = useState(emptyChequePropio);

  const {
    nota,
    fecha,
    valor,
    caja,
    fecha_deposito,
    numero,
    saving
  } = cheque_propio;

  useEffect(() => {
   
    dispatch(getCajas());
   
    if (current !== null) {
      setChequePropio(current);
    } else {
      setChequePropio(emptyChequePropio);
    }
  }, [current, open]);

  const onChange = (e, tipo = null) => {
    setChequePropio({ ...cheque_propio, saving: true });

    if (tipo === 'fecha' || tipo === 'fecha_deposito') {
      setChequePropio({ ...cheque_propio, [tipo]: e });
    } else if (tipo === 'banco') {
      setChequePropio({ ...cheque_propio, banco: e.value });
    } else setChequePropio({ ...cheque_propio, [e.target.name]: e.target.value });
  };

  const onValueChange = (e, tipo) => {
    if (tipo === 'cuit') {
      setChequePropio({ ...cheque_propio, cuit: e.floatValue });
    } else setChequePropio({ ...cheque_propio, valor: e.floatValue });
  };

  const onChangeCheck = name => event => {
    setChequePropio({ ...cheque_propio, [name]: event.target.checked });
  };

  const createOptionBanco = label => ({
    nombre: label,
    _id: ObjectID(new Date().getTime()).toHexString()
  });



  const onSubmit = async e => {
    e.preventDefault();

    if (!valor || !caja) {
    } else {
      setChequePropio({ ...cheque_propio, saving: true });

      try {
        if (!current) {
          await dispatch(addChequePropio(cheque_propio));
        } else {
          await dispatch(updateChequePropio(cheque_propio));
        }
      } catch (error) { 
      }

      await dispatch(getChequesPropios(cheque_propio));
      setChequePropio({ ...cheque_propio, saving: false });
      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setChequePropio(emptyChequePropio);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      classes={{ paper: classes.dialogMain }}
      fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
      scroll='paper'
    >
      <DialogTitle id='max-width-dialog-title' color='primary'>
        {!current ? 'Nuevo cheque propio' : 'Editar cheque propio'}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={onSubmit}>
        
          <Grid container className={classes.root} spacing={2}>
          {!loading_cajas && (
            <Fragment>

              <Grid item xs={12} md={4}>
                <FormControl
                  variant='outlined'
                  style={{
                    height: '40px',
                    width: '100%',
                    paddingTop: '8px'
                  }}
                >
                  <InputLabel htmlFor='select-tipo-comprobante'>
                    Caja
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{
                          height: '40px',
                          width: '100%'
                        }}
                        id='select-tipo-comprobante'
                      />
                    }
                    fullWidth
                    value={caja ? caja : ''}
                    name='caja'
                    onChange={e => onChange(e)}
                  >
                    {cajas.map(caja => (
                      caja.tipo ==='Cuenta corriente en pesos' && (
                        <MenuItem value={caja._id} key={caja._id}>
                        {caja.nombre}
                      </MenuItem>
                      )
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </Fragment>
          )}
            <Grid item xs={12} md={6}>
              <TextField
                margin='dense'
                name='numero'
                label='Numero'
                value={numero || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>

 

            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                thousandSeparator='.'
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                margin='dense'
                name='valor'
                label='Valor $'
                value={valor}
                onValueChange={onValueChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                margin='dense'
                name='nota'
                label='Notas'
                value={nota || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
              Fecha de Cobro:{' '}
              <DatePicker
                onChange={date => onChange(date, 'fecha')}
                value={fecha ? moment(fecha).toDate() : null}
                format='dd/MM/y'
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: '10px' }}>
            Fecha Acreditación:{' '}
            <DatePicker
              onChange={date => onChange(date, 'fecha_deposito')}
              value={
                fecha_deposito ? moment(fecha_deposito).toDate() : null
              }
              format='dd/MM/y'
            />
          </Grid>
          

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant='contained'
          color='primary'
          type='submit'
          disabled={saving}
        >
          Guardar
        </Button>
        <Button
          onClick={handleClose}
          variant='contained'
          color='secondary'
          disabled={saving}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    current: state.chequesPropios.current,
    open: state.chequesPropios.open,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
    bancos: state.bancos.bancos,
    loading_bancos: state.bancos.loading
  };
};

export default connect(mapStateToProps)(ChequePropioForm);
