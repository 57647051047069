import axios from 'axios';

import {
  ADD_ZONA,
  DELETE_ZONA,
  SET_CURRENT_ZONA,
  CLEAR_CURRENT_ZONA,
  UPDATE_ZONA,
  FILTER_ZONA,
  CLEAR_FILTER_ZONA,
  ERROR_ZONA,
  GET_ZONA,
  CLEAR_ZONA,
  SET_OPEN_ZONA
} from './types';

//Add Zona
export const addZona = zona => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/zonas', zona, config);
      dispatch({ type: ADD_ZONA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_ZONA, payload: error.msg });
    }
  };
};

export const getZonas = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/zonas');
      dispatch({ type: GET_ZONA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_ZONA, payload: error.msg });
    }
  };
};

//Delete Zona

export const deleteZona = zonaId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/zonas/${zonaId}`);

      dispatch({ type: DELETE_ZONA, payload: zonaId });
    } catch (error) {
      dispatch({ type: ERROR_ZONA, payload: error.response.msg });
    }
  };
};

//Set Current Zona

export function setCurrent(zona) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_ZONA, payload: zona });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_ZONA, payload: open });
  };
}

//Clear Current Zona
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_ZONA });
  };
};
//Update Zona

export const updateZona = zona => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(
        `/api/zonas/${zona.id}`,
        zona,
        config
      );
      dispatch({ type: UPDATE_ZONA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_ZONA, payload: error.response.msg });
    }
  };
};

//Filter Zona

export const filterZonas = text => {
  return dispatch => {
    dispatch({ type: FILTER_ZONA, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_ZONA });
  };
};

export const clearZonas = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ZONA });
  };
};
