import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import { getRoleCanDo } from "../usuarios/Usuario";
import "./NavLinks.css";

const NavLinks = (props) => {
  const classes = useStyles();

  const { isAuthenticated, logout, usuario, loading } = useContext(AuthContext);
  return (
    <div className="navbar">
      {isAuthenticated && (
        <Fragment>
          {props.menu
            .filter(
              (item) =>
                (!item.secure || getRoleCanDo(item.secure)) &&
                getRoleCanDo("vend", item)
            )
            .map((item, index) => {
              if (item.link)
                return (
                  <NavLink
                    key={index}
                    to={item.link ? item.link : "/"}
                    className={
                      props.history.location.pathname === item.link
                        ? classes.selectedLink
                        : ""
                    }
                  >
                    <span>{item.title}</span>
                  </NavLink>
                );
              else {
                return (
                  <div className="dropdown" key={index}>
                    <button
                      style={{ display: "flex", alignItems: "center" }}
                      className="dropbtn"
                    >
                      {item.title}
                      <Icon>expand_more</Icon>
                    </button>
                    <div className="dropdown-content">
                      {item.menu
                        .filter(
                          (item) =>
                            (!item.secure || getRoleCanDo(item.secure)) &&
                            getRoleCanDo("vend", item)
                        )
                        .map((item, index) => (
                          <>
                            {item.special === "rg_iva" ? (
                              <a href={item.link} target="_blank">
                                {item.title}
                              </a>
                            ) : (
                              <NavLink
                                key={index}
                                to={item.link ? item.link : "/"}
                              >
                                {item.title}
                              </NavLink>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                );
              }
            })}

          <a
            type="button"
            href="#"
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "16px",
            }}
            onClick={(e) => logout()}
            title="Salir"
          >
            <Icon>exit_to_app</Icon>
          </a>
        </Fragment>
      )}
    </div>
  );
};
const useStyles = makeStyles({
  dropbtn: {},
  selectedLink: {
    borderBottom: "1px solid",
  },
});
export default withRouter(connect()(NavLinks));
