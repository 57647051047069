import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStore } from 'redux';
import { getTransportistas } from '../../actions/transportistas';
import subscribeTo from '../../utils/subscribeTo';
import WhatsAppIcon from '../utils/WhatsAppIcon';


const About = ({ transportistas, dispatch, loading }) => {
  useEffect(() => {
    dispatch(getTransportistas());
    if (!loading) {
      subscribeTo('transportistasChanged', (err, data) => {
        dispatch(getTransportistas());
      });
      return () => {
        subscribeTo('transportistasChanged', null);
      };
    }
  }, [dispatch, loading]);

  const store = createStore((state = { count: 0 }, action) => {
    switch (action.type) {
      case 'INCREMENT':
        return {
          count: state.count + action.incrementBy
        };
      default:
        return state;
    }
  });
  const unsubscribe = store.subscribe(() => {
    //
  });

  store.dispatch({
    type: 'INCREMENT',
    incrementBy: 5
  });

  return (
    <div>
      <h1>Acerca de esta app</h1>
      <p className='my-1'>Nutree!</p>
      <p className='bg-dark p'>
        <strong>Version:</strong>0.4
      </p>
      <p className='bg-danger p'>
        <strong>Problemas?</strong>
        <br />
        <a
          href='https://wa.me/5521996332325'
          target='_blank'
          rel='noopener noreferrer'
          className='text-dark'
        >
          <WhatsAppIcon /> +55 21 996332325
        </a>
      </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    transportistas: state.transportistas.transportistas,
    loading: state.transportistas.loading
  };
};

export default connect(mapStateToProps)(About);
