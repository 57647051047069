import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import subscribeTo from "../../utils/subscribeTo";
import { DownloadTableExcel } from "react-export-table-to-excel";

import axios from "axios";
import "moment/locale/es";
import {
  filterClientes,
  getClientes,
  setBypassIoClientes,
} from "../../actions/clientes";
import VolumenVentasFilter from "./VolumenVentasFilter";
import { Button } from "@material-ui/core";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
});

const VolumenVentasReporte = ({
  clientes,
  filter,
  loading,
  dispatch,
  bonificadas = false,
  zonas,
  error,
  bypassIo,
  filter_panel_ventas,
}) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const diasZonas = {
    0: ["CABA1"], //Lunes
    1: ["CABA2"], //Martes
    2: [],
    3: ["DIS", "DISINT"],
    4: ["INT", "SUP", "MAY","GASTRO"],
    5: [],
    6: [],
  };

  const [resultados, setResultados] = useState({});
  const [resultadosFiltered, setResultadosFiltered] = useState({});
  const [clientesSorted, setClientesSorted] = useState(null);

  const [totalSemanas, setTotalSemanas] = useState(null);
  const [totalClientes, setTotalClientes] = useState(null);

  const [weeks, setWeeks] = useState(null);

  const [filtersVolumenVentas, setFiltersVolumenVentas] = useState({
    zonas: [],
    nombre: "",
    fecha: [
      moment().subtract(12, "weeks").toDate().toISOString(),
      moment().add(1, "week").toDate().toISOString(),
    ],
  });

  const isoWeek = moment().isoWeek();

  useEffect(() => {
    dispatch(getClientes());
    if (!loading) {
      subscribeTo("clientesChanged", (err, data) => {
        if (bypassIo) {
          dispatch(getClientes());
        } else {
          dispatch(setBypassIoClientes(false));
        }

        if (filter) dispatch(filterClientes(filter));
      });
      return () => {
        subscribeTo("clientesChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  const resumarClientesSemanas = () => {
    let clientesTmp = {};
    let semanasTmp = {};
    for (let week of weeks) {
      semanasTmp[week] = 0;
    }

    for (let cliente of resultadosFiltered.clientes) {
      let sumClientesTmp = 0;
      for (let semana of Object.keys(cliente)) {
        if (
          semana === "total" ||
          semana === "nombre" ||
          semana === "razon_social" ||
          semana === "nombre_grupo" ||
          semana === "id"
        )
          continue;

        semanasTmp = {
          ...semanasTmp,
          [semana]:
            (semanasTmp[semana] ? semanasTmp[semana] : 0) + cliente[semana],
        };

        if (weeks.indexOf(semana) === -1) continue;
        sumClientesTmp += cliente[semana];
      }
      clientesTmp = { ...clientesTmp, [cliente.nombre]: sumClientesTmp };
    }
    setTotalSemanas(semanasTmp);
    setTotalClientes(clientesTmp);
  };

  useEffect(() => {
    if (resultadosFiltered && resultadosFiltered.clientes) {
      resumarClientesSemanas();
    } else {
      setTotalSemanas(null);
      setTotalClientes(null);
    }
  }, [resultadosFiltered.clientes]);

  useEffect(() => {
    let filteredTmp = resultados;
    let filtro = false;
    if (
      (filtersVolumenVentas.nombre || filtersVolumenVentas.zonas) &&
      resultados &&
      resultados.clientes
    ) {
      filtro = true;
      filteredTmp = {
        ...resultados,
        clientes: resultados.clientes.filter((cliente) => {
          const regex = new RegExp(
            `${escapeRegExp(filtersVolumenVentas.nombre)}`,
            "gi"
          );

          return (
            (cliente.nombre && cliente.nombre.match(regex)) ||
            (cliente.razon_social && cliente.razon_social.match(regex)) ||
            (cliente.nombre_grupo && cliente.nombre_grupo.match(regex))
          );
        }),
      };

      if (
        filtersVolumenVentas.zonas &&
        filtersVolumenVentas.zonas.length &&
        resultados
      ) {
        filtro = true;
        filteredTmp = {
          ...filteredTmp,
          clientes: filteredTmp.clientes.filter((cliente) => {
            return clientes.find(
              (cli) =>
                cli.nombre === cliente.nombre &&
                filtersVolumenVentas.zonas.find((z) => z === cli.zona.nombre)
            );
          }),
        };
      }

      if (filtro) setResultadosFiltered(filteredTmp);
    } else {
      setTotalSemanas(null);
      setTotalClientes(null);
      setResultadosFiltered(resultados);
    }
  }, [filtersVolumenVentas.nombre, filtersVolumenVentas.zonas]);

  useEffect(() => {
    const weeksTmp = [];

    if (!filtersVolumenVentas.fecha) {
      setFiltersVolumenVentas({
        ...filtersVolumenVentas,
        fecha: [
          moment().subtract(12, "weeks").toDate().toISOString(),
          moment().add(1, "week").toDate().toISOString(),
        ],
      });
    } else {
      const nWeeks = moment(filtersVolumenVentas.fecha[1]).diff(
        moment(filtersVolumenVentas.fecha[0]),
        "week"
      );

      for (let i = 0; i <= nWeeks; i++) {
        let tmpWeek =
          moment(filtersVolumenVentas.fecha[1]).subtract(i, "week").isoWeek() <
          10
            ? "0" +
              moment(filtersVolumenVentas.fecha[1])
                .subtract(i, "week")
                .isoWeek()
            : moment(filtersVolumenVentas.fecha[1])
                .subtract(i, "week")
                .isoWeek();
        weeksTmp.push(
          tmpWeek +
            "-" +
            moment(filtersVolumenVentas.fecha[1])
              .subtract(i, "week")
              .format("YY")
        );
      }

      setWeeks(weeksTmp);
      console.log(weeksTmp);

      const res = axios
        .post("/api/reportes/volumen_ventas", {
          fecha: filtersVolumenVentas.fecha,
          bonificadas,
        })
        .then((res) => {
          setResultados(res.data);
          setResultadosFiltered(res.data);
        });
    }
  }, [filtersVolumenVentas.fecha]);

  return (
    <Fragment>
      <div></div>
      <div>
        <div className={classes.root}>
          <h3>Volumen de ventas semanal {bonificadas && "bonificadas"}</h3>
          <VolumenVentasFilter
            filtersVolumenVentas={filtersVolumenVentas}
            setFiltersVolumenVentas={setFiltersVolumenVentas}
          />
          {resultadosFiltered &&
          resultadosFiltered.clientes &&
          clientes &&
          clientes.length > 0 ? (
            <TableContainer component={Paper}>
              <DownloadTableExcel
                filename={"volumen_ventas_" + moment().unix() + ".xlsx"}
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                >
                  Exportar Reporte
                </Button>
              </DownloadTableExcel>
              <Table
                className={classes.table}
                aria-label="simple table"
                id="table-to-xls"
                innerRef={tableRef}
              >
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell align="center">Cliente</TableCell>
                    {weeks.map((semana) => (
                      <TableCell align="center">{semana}</TableCell>
                    ))}
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultadosFiltered.clientes.map((cliente, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell align="center">{cliente.nombre}</TableCell>
                        {weeks.map((semana) => (
                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={0}
                              fixedDecimalScale={true}
                              value={cliente[semana] || 0}
                            />
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          <strong>
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={0}
                              fixedDecimalScale={true}
                              value={
                                totalClientes &&
                                Object.keys(totalClientes).length > 0
                                  ? totalClientes[cliente.nombre]
                                  : "-" || 0
                              }
                            />
                          </strong>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                  <TableRow>
                    <TableCell align="center">
                      <strong>TOTAL</strong>
                    </TableCell>
                    {weeks.map((semana) => (
                      <TableCell align="center" key={semana}>
                        <strong>
                          <NumberFormat
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={0}
                            fixedDecimalScale={true}
                            value={
                              totalSemanas &&
                              Object.keys(totalSemanas).length > 0
                                ? totalSemanas[semana]
                                : "-" || 0
                            }
                          />
                        </strong>
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <strong>
                        <NumberFormat
                          displayType="text"
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={0}
                          fixedDecimalScale={true}
                          value={
                            totalSemanas && Object.keys(totalSemanas).length > 0
                              ? Object.keys(totalSemanas).reduce(
                                  (acc, t) =>
                                    acc +
                                    (weeks.indexOf(t) !== -1
                                      ? totalSemanas[t]
                                      : 0),
                                  0
                                )
                              : "-" || 0
                          }
                        />
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>Cargando...</>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    filter: state.clientes.filter,
    loading: state.clientes.loading,
    filter_panel_ventas: state.clientes.filter_panel_ventas,
    zonas: state.zonas.zonas,
  };
};

export default connect(mapStateToProps)(VolumenVentasReporte);
