import escapeRegExp from 'escape-string-regexp';
import {
  ADD_PRODUCTO,
  DELETE_PRODUCTO,
  SET_CURRENT_PRODUCTO,
  CLEAR_CURRENT_PRODUCTO,
  UPDATE_PRODUCTO,
  FILTER_PRODUCTO,
  CLEAR_FILTER_PRODUCTO,
  ERROR_PRODUCTO,
  GET_PRODUCTO,
  CLEAR_PRODUCTO,
  SET_OPEN_PRODUCTO
} from '../actions/types';

const initialState = {
  productos: null,
  current: null,
  filtered: null,
  filter: '',
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTO:
      return {
        ...state,
        productos: action.payload,
        loading: false
      };
    case ADD_PRODUCTO:
      return {
        ...state,
        productos: [action.payload, ...state.productos],
        loading: false
      };
    case UPDATE_PRODUCTO:
      return {
        ...state,
        productos: state.productos.map(producto =>
          producto.id === action.payload.id ? action.payload : producto
        )
      };
    case DELETE_PRODUCTO:
      return {
        ...state,
        productos: state.productos.filter(
          producto => producto.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_PRODUCTO:
      return {
        ...state,
        productos: null,
        error: null,
        current: null,
        loading: null
      };
    case SET_CURRENT_PRODUCTO:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN_PRODUCTO:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT_PRODUCTO:
      return {
        ...state,
        current: null
      };

    case FILTER_PRODUCTO:
      return {
        ...state,
        filter: action.payload,
        filtered: state.productos.filter(producto => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return producto.razon_social.match(regex);
        })
      };
    case CLEAR_FILTER_PRODUCTO:
      return {
        ...state,
        filtered: null
      };
    case ERROR_PRODUCTO:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
