import axios from 'axios';

import {
  ADD_CHEQUE_PROPIO,
  DELETE_CHEQUE_PROPIO,
  SET_CURRENT_CHEQUE_PROPIO,
  CLEAR_CURRENT_CHEQUE_PROPIO,
  UPDATE_CHEQUE_PROPIO,
  FILTER_CHEQUE_PROPIO,
  CLEAR_FILTER_CHEQUE_PROPIO,
  ERROR_CHEQUE_PROPIO,
  GET_CHEQUE_PROPIO,
  CLEAR_CHEQUE_PROPIO,
  SET_OPEN_CHEQUE_PROPIO
} from './types';

//Add ChequePropio
export const addChequePropio = cheque_propio => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/cheques_propios', cheque_propio, config);
      dispatch({ type: ADD_CHEQUE_PROPIO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE_PROPIO, payload: error.msg });
    }
  };
};

export const getChequesPropios = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/cheques_propios');
      dispatch({ type: GET_CHEQUE_PROPIO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE_PROPIO, payload: error.msg });
    }
  };
};

//Delete ChequePropio

export const deleteChequePropio = cheque_propioId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/cheques_propios/${cheque_propioId}`);

      dispatch({ type: DELETE_CHEQUE_PROPIO, payload: cheque_propioId });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE_PROPIO, payload: error.response.msg });
    }
  };
};

//Set Current ChequePropio

export function setCurrent(cheque_propio) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_CHEQUE_PROPIO, payload: cheque_propio });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_CHEQUE_PROPIO, payload: open });
  };
}

//Clear Current ChequePropio
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_CHEQUE_PROPIO });
  };
};
//Update ChequePropio

export const updateChequePropio = cheque_propio => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(`/api/cheques_propios/${cheque_propio._id}`, cheque_propio, config);
      dispatch({ type: UPDATE_CHEQUE_PROPIO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CHEQUE_PROPIO, payload: error.response.msg });
    }
  };
};

//Filter ChequePropio

export const filterChequesPropios = text => {
  return dispatch => {
    dispatch({ type: FILTER_CHEQUE_PROPIO, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_CHEQUE_PROPIO });
  };
};

export const clearChequesPropios = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CHEQUE_PROPIO });
  };
};
