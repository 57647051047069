import {
  Button, CardActions, Grid,
  Typography
} from '@material-ui/core/';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
  clearCurrent, deleteProvedor,

  setCurrent
} from '../../actions/provedores';
import WhatsAppIcon from '../utils/WhatsAppIcon';

const useStyles = makeStyles({
  card: {
    //minWidth: 300
  },
  cardActions: {
    backgroundColor: '#f7f7f7'
  },
  addressLink: {
    color: 'black',
    textDecorationLine: 'none',

    overflow: 'hidden',

    '&:hover': {
      textDecorationLine: 'underline'
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    height: '180px',
    overflowY: 'auto',
    fontSize: '0.9rem'
  },
  cardTitle: {
    fontSize: '1.15rem'
  }
});

const ProvedorItem = ({ provedor, provedores, current, dispatch }) => {
  const { id, razon_social, email, telefonos, cuit } = provedor;
  const classes = useStyles();
  const confirm = useConfirm();

  const onDelete = () => {
    dispatch(deleteProvedor(id));
    dispatch(clearCurrent());
  };

  const onClickBorrar = (confirm, dispatch, provedor) => {
    confirm( {
              description: `Desea borrar al provedor "${provedor.razon_social}"?`,
              title: 'ATENCION',
              cancellationText: 'Cancelar',
              confirmationText: 'Confirmar'
            })

    .then(() => onDelete());
}

  return (
    <Grid item xs={12} sm={12} md={4} xl={3} key={id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant='h6'
            component='h6'
            className={classes.cardTitle}
          >
            {razon_social}
          </Typography>
          {cuit && (
            <p color='textSecondary'>
              CUIT:{' '}
              <NumberFormat
                displayType='text'
                value={cuit}
                format='##-########-#'
              />
            </p>
          )}
          {email && (
            <div>
              <Icon style={{ verticalAlign: 'text-bottom' }}>email</Icon>{' '}
              <a
                className={classes.addressLink}
                href={`mailto:${email}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {email}
              </a>
            </div>
          )}
          <Grid container>
            {telefonos &&
              telefonos
                .sort((a, b) => {
                  if (b.tipo === 'WhatsApp') return 1;
                  else return 0;
                })
                .map((telefono, index) => {
                  let icon;
                  switch (telefono.tipo) {
                    case 'WhatsApp':
                      icon = 'chat';
                      break;
                    default:
                      icon = 'call';
                      break;
                  }
                  const returnjsx = (
                    <Grid item xs={12} md={6} key={`telefono${index}`}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          display: 'inline-block',
                          paddingRight: '10px'
                        }}
                      >
                        {telefono.tipo !== 'WhatsApp' ? (
                          <Icon style={{ verticalAlign: 'text-bottom' }}>
                            {icon}
                          </Icon>
                        ) : (
                          <WhatsAppIcon />
                        )}{' '}
                        {telefono.numero}
                      </span>
                    </Grid>
                  );

                  if (telefono.tipo === 'WhatsApp')
                    return (
                      <a
                        className={classes.addressLink}
                        key={`whatsapp${index}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://wa.me/${telefono.numero.replace(
                          /\D/g,
                          ''
                        )}`}
                      >
                        {returnjsx}
                      </a>
                    );
                  else return returnjsx;
                })}
          </Grid>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            onClick={e=>onClickBorrar(confirm, dispatch, provedor)}
            size='small'
            color='secondary'
          >
            <DeleteOutlinedIcon />
          </Button>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={() => dispatch(setCurrent(provedor))}
            size='small'
            color='primary'
          >
            <EditOutlinedIcon /> Editar
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

ProvedorItem.propTypes = {
  provedor: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    provedores: state.provedores.provedores,
    current: state.provedores.current
  };
};

export default connect(mapStateToProps)((ProvedorItem));
