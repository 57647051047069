import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import escapeRegExp from "escape-string-regexp";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import subscribeTo from "../../utils/subscribeTo";
import { DownloadTableExcel } from "react-export-table-to-excel";

import axios from "axios";
import "moment/locale/es";
import {
  filterClientes,
  getClientes,
  setBypassIoClientes,
} from "../../actions/clientes";
import TotalVentasFilter from "./TotalVentasFilter";
import { Button } from "@material-ui/core";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  tableHeader: {
    borderBottom: "1px solid black",
    textAlign: "center",
    backgroundColor: "rgba(204,204,204,0.4)",
  },
  tableRow0: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
  tableRow1: {
    backgroundColor: "#eee",
    "&:hover": {
      backgroundColor: "lightgray",
    },
  },
});

const TotalVentasReporte = ({
  clientes,
  filter,
  loading,
  dispatch,
  bonificadas = false,
  zonas,
  error,
  bypassIo,
  filter_panel_ventas,
}) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const diasZonas = {
    0: ["CABA1"], //Lunes
    1: ["CABA2"], //Martes
    2: [],
    3: ["DIS", "DISINT"],
    4: ["INT", "SUP", "MAY","GASTRO"],
    5: [],
    6: [],
  };

  const [resultados, setResultados] = useState({});
  const [resultadosFiltered, setResultadosFiltered] = useState({});
  const [clientesSorted, setClientesSorted] = useState(null);

  const [totalSemanas, setTotalSemanas] = useState(null);
  const [totalClientes, setTotalClientes] = useState(null);

  const [weeks, setWeeks] = useState(null);

  const [filtersTotalVentas, setFiltersTotalVentas] = useState({
    zonas: [],
    nombre: "",
    mostrar_x: true,
    fecha: [
      moment().subtract(12, "weeks").toDate().toISOString(),
      moment().add(1, "week").toDate().toISOString(),
    ],
  });

  const isoWeek = moment().isoWeek();

  useEffect(() => {
    dispatch(getClientes());
    if (!loading) {
      subscribeTo("clientesChanged", (err, data) => {
        if (bypassIo) {
          dispatch(getClientes());
        } else {
          dispatch(setBypassIoClientes(false));
        }

        if (filter) dispatch(filterClientes(filter));
      });
      return () => {
        subscribeTo("clientesChanged", null);
      };
    }
  }, [dispatch, loading, filter]);

  const resumarClientesSemanas = () => {
    let clientesTmp = {};
    let semanasTmp = {};
    for (let week of weeks) {
      semanasTmp[week] = 0;
    }

    for (let cliente of resultadosFiltered.clientes) {
      let sumClientesTmp = 0;
      for (let semana of Object.keys(cliente)) {
        if (
          semana === "total" ||
          semana === "nombre" ||
          semana === "razon_social" ||
          semana === "nombre_grupo" ||
          semana === "id"
        )
          continue;

        semanasTmp = {
          ...semanasTmp,
          [semana]:
            (semanasTmp[semana] ? semanasTmp[semana] : 0) + cliente[semana],
        };

        if (weeks.indexOf(semana) === -1) continue;
        sumClientesTmp += cliente[semana];
      }
      clientesTmp = { ...clientesTmp, [cliente.nombre]: sumClientesTmp };
    }
    setTotalSemanas(semanasTmp);
    setTotalClientes(clientesTmp);
  };

  useEffect(() => {
    if (resultadosFiltered && resultadosFiltered.clientes) {
      resumarClientesSemanas();
    } else {
      setTotalSemanas(null);
      setTotalClientes(null);
    }
  }, [resultadosFiltered.clientes]);

  useEffect(() => {
    let filteredTmp = resultados;
    let filtro = false;
    if (
      (filtersTotalVentas.nombre || filtersTotalVentas.zonas) &&
      resultados &&
      resultados.clientes
    ) {
      filtro = true;
      filteredTmp = {
        ...resultados,
        clientes: resultados.clientes.filter((cliente) => {
          const regex = new RegExp(
            `${escapeRegExp(filtersTotalVentas.nombre)}`,
            "gi"
          );

          return (
            (cliente.nombre && cliente.nombre.match(regex)) ||
            (cliente.razon_social && cliente.razon_social.match(regex)) ||
            (cliente.nombre_grupo && cliente.nombre_grupo.match(regex))
          );
        }),
      };

      if (
        filtersTotalVentas.zonas &&
        filtersTotalVentas.zonas.length &&
        resultados
      ) {
        filtro = true;
        filteredTmp = {
          ...filteredTmp,
          clientes: filteredTmp.clientes.filter((cliente) => {
            return clientes.find(
              (cli) =>
                cli.nombre === cliente.nombre &&
                filtersTotalVentas.zonas.find((z) => z === cli.zona.nombre)
            );
          }),
        };
      }

      if (filtro) setResultadosFiltered(filteredTmp);
    } else {
      setTotalSemanas(null);
      setTotalClientes(null);
      setResultadosFiltered(resultados);
    }
  }, [filtersTotalVentas.nombre, filtersTotalVentas.zonas]);

  useEffect(() => {
    const weeksTmp = [];

    if (!filtersTotalVentas.fecha) {
      setFiltersTotalVentas({
        ...filtersTotalVentas,
        fecha: [
          moment().subtract(12, "weeks").toDate().toISOString(),
          moment().add(1, "week").toDate().toISOString(),
        ],
      });
    } else {
      const nWeeks = moment(filtersTotalVentas.fecha[1]).diff(
        moment(filtersTotalVentas.fecha[0]),
        "week"
      );

      for (let i = 0; i <= nWeeks; i++) {
        let tmpWeek =
          moment(filtersTotalVentas.fecha[1]).subtract(i, "week").isoWeek() < 10
            ? "0" +
              moment(filtersTotalVentas.fecha[1]).subtract(i, "week").isoWeek()
            : moment(filtersTotalVentas.fecha[1]).subtract(i, "week").isoWeek();
        weeksTmp.push(
          tmpWeek +
            "-" +
            moment(filtersTotalVentas.fecha[1]).subtract(i, "week").format("YY")
        );
      }

      setWeeks(weeksTmp);
      console.log(weeksTmp);

      const res = axios
        .post("/api/reportes/total_ventas", {
          fecha: filtersTotalVentas.fecha,
          bonificadas,
        })
        .then((res) => {
          setResultados(res.data);
          setFiltersTotalVentas({ ...filtersTotalVentas, nombre: "" });
          setResultadosFiltered(res.data);
        });
    }
  }, [filtersTotalVentas.fecha]);

  return (
    <Fragment>
      <div></div>
      <div>
        <div className={classes.root}>
          <h3>Reporte Total Ventas</h3>
          <TotalVentasFilter
            filtersTotalVentas={filtersTotalVentas}
            setFiltersTotalVentas={setFiltersTotalVentas}
          />
          {resultadosFiltered &&
          resultadosFiltered.clientes &&
          clientes &&
          clientes.length > 0 ? (
            <TableContainer component={Paper}>
              <DownloadTableExcel
                filename={"total_ventas_" + moment().unix() + ".xlsx"}
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                >
                  Exportar Reporte
                </Button>
              </DownloadTableExcel>
              <Table
                className={classes.table}
                aria-label="simple table"
                id="table-to-xls"
                innerRef={tableRef}
              >
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Imp. Neto Grav.</TableCell>
                    <TableCell align="center">IVA</TableCell>
                    <TableCell align="center">
                      Total{filtersTotalVentas.mostrar_x && " A"}
                    </TableCell>
                    {filtersTotalVentas.mostrar_x && (
                      <>
                        <TableCell align="center">X</TableCell>
                        <TableCell align="center">Total</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultadosFiltered.clientes.map((cliente, index) => (
                    <>
                      <TableRow
                        key={index}
                        className={classes["tableRow" + (index % 2)]}
                      >
                        <TableCell>{cliente.nombre}</TableCell>

                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            decimalSeparator=","
                            prefix="$"
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={cliente.total_neto}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            decimalSeparator=","
                            prefix="$"
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={cliente.total_iva}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            decimalSeparator=","
                            prefix="$"
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={cliente.total_neto + cliente.total_iva}
                          />
                        </TableCell>
                        {filtersTotalVentas.mostrar_x && (
                          <>
                            <TableCell align="right">
                              <NumberFormat
                                displayType="text"
                                decimalSeparator=","
                                prefix="$"
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={cliente.total_x}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <NumberFormat
                                displayType="text"
                                decimalSeparator=","
                                prefix="$"
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={
                                  cliente.total_neto +
                                  cliente.total_iva +
                                  cliente.total_x
                                }
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>Cargando...</>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    clientes: state.clientes.clientes,
    filter: state.clientes.filter,
    loading: state.clientes.loading,
    filter_panel_ventas: state.clientes.filter_panel_ventas,
    zonas: state.zonas.zonas,
  };
};

export default connect(mapStateToProps)(TotalVentasReporte);
