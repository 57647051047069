import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

import Icon from "@material-ui/core/Icon";
import { setOpen, setCurrent, transferCaja } from "../../actions/cajas";
import {
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab1: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(12),
  },
  fab2: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(20),
  },
}));
const AddCajaBtn = ({ dispatch, cajas }) => {
  const [openTransfer, setOpenTransfer] = useState(false);
  const [transferForm, setTransferForm] = useState({
    cajaFrom: null,
    cajaTo: null,
    value: "",
  });
  const classes = useStyles();

  return (
    <div className="fixed-action-btn">
      {openTransfer && (
        <div
          style={{
            color: "black",
            backgroundColor: "lightBlue",
            padding: "0px 10px 10px 10px",
            border: "1px black solid",
            borderRadius: "10px",
          }}
          className={classes.fab2}
        >
          <h3>Transferir</h3>

          <div>
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "200px",
                  }}
                  id="select-forma-pago"
                  placeholder="Desde"
                />
              }
              onChange={(e) =>
                setTransferForm({ ...transferForm, cajaFrom: e.target.value })
              }
              value={transferForm.cajaFrom}
              name="cajaFrom"
            >
              {cajas
                .filter((c) => c.balance > 0)
                .map((caja, index) => (
                  <MenuItem value={caja.id} key={index}>
                    {caja.nombre}
                  </MenuItem>
                ))}
            </Select>{" "}
            ➔{" "}
            <Select
              input={
                <OutlinedInput
                  style={{
                    height: "40px",
                    width: "200px",
                  }}
                  id="select-forma-pago"
                  placeholder="Para"
                />
              }
              onChange={(e) =>
                setTransferForm({ ...transferForm, cajaTo: e.target.value })
              }
              value={transferForm.cajaTo}
              name="cajaFrom"
            >
              {cajas.map((caja, index) => (
                <MenuItem value={caja.id} key={index}>
                  {caja.nombre}
                </MenuItem>
              ))}
            </Select>
          </div>

          <NumberFormat
            customInput={TextField}
            decimalSeparator=","
            thousandSeparator="."
            mask=""
            prefix="$"
            allowNegative={false}
            decimalScale={2}
            label="Valor $ *"
            margin="dense"
            fullWidth
            value={transferForm.value}
            onValueChange={(e) => {
              if (
                cajas.find((c) => c.id === transferForm.cajaFrom).balance >=
                e.floatValue
              )
                setTransferForm({ ...transferForm, value: e.floatValue });
              else
                setTransferForm({
                  ...transferForm,
                  value: transferForm.value,
                });
            }}
          />
          <Button
            color={"primary"}
            onClick={(e) =>
              dispatch(transferCaja(transferForm)).then(() =>
                setOpenTransfer(false)
              )
            }
            variant="contained"
            style={{ marginTop: "10px", float: "right" }}
          >
            Transferir
          </Button>
        </div>
      )}
      <Fab
        onClick={() => setOpenTransfer(!openTransfer)}
        style={{ backgroundColor: "lightBlue" }}
        aria-label="Add"
        className={classes.fab1}
      >
        <CompareArrowsIcon />
      </Fab>
      <Fab
        onClick={() => dispatch(setOpen(true))}
        color="primary"
        aria-label="Add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cajas: state.cajas.cajas,
    current: state.cajas.current,
  };
};

export default connect(mapStateToProps)(AddCajaBtn);
