import { CircularProgress, Snackbar } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from '@material-ui/lab';
import ObjectID from 'bson-objectid';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import ReactSelectNonCreate from 'react-select';
import ReactSelect from 'react-select/creatable';
import { getCajas } from '../../actions/cajas';
import { getCheques } from '../../actions/cheques';
import { getChequesPropios } from "../../actions/cheques_propios";
import {
  addGasto,

  clearCurrent,
  setOpen, updateGasto
} from '../../actions/gastos';
import { addProvedor, getProvedores } from '../../actions/provedores';
import { tiposComprobanteGastos } from '../../config/constantes.js';
import AlertContext from '../../context/alert/alertContext';
import { getTipoGastos } from '../usuarios/Usuario';








const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  formHeader: {
    backgroundColor: '#4caf50',
    color: 'white'
  },
  formFooter: {
    backgroundColor: 'gainsboro',
    
    padding: '10px'
  },
}));

const GastoForm = ({
  children,
  current,
  open,
  saving,
  dispatch,
  provedores,
  loading_provedores,
  cajas,
  loading_cajas,
  cheques,
  loading_cheques,
  cheques_propios,
  loading_cheques_propios,
  error
}) => {
  const emptyGasto = () => ({
    tipo: '',
    fecha_gasto: new Date(),
    fecha_pago: new Date(),
    fecha_recepcion: '',
    fecha_vencimiento: '',
    numero_remito: '',
    provedor: '',
    cuit: '',
    tipo_comprobante: '',
    numero_comprobante: '',
    alic_iva: '',
    alic_retenciones: '',
    neto_gravado: '',
    alic_iva2: '',
    alic_retenciones2: '',
    neto_gravado2: '',
    no_gravado: '',
    iibb: '',
    descripcion: '',
    pagos: []
  });

  const [gasto, setGasto] = useState(emptyGasto());
  const alertContext = useContext(AlertContext);

  const {
    _id,
    tipo,
    fecha_gasto,
    fecha_pago,
    fecha_recepcion,
    fecha_vencimiento,
    numero_remito,
    provedor,
    cuit,
    tipo_comprobante,
    numero_comprobante,
    alic_iva,
    alic_retenciones,
    neto_gravado,
    alic_iva2,
    alic_retenciones2,
    neto_gravado2,
    no_gravado,
    iibb,
    descripcion,
    pagos
  } = gasto;

  let alic_iva_pesos = (alic_iva * neto_gravado) / 100;
  let alic_iva_pesos2 = (alic_iva2 * neto_gravado2) / 100;

  let balance =
    (alic_iva && neto_gravado
      ? parseFloat((alic_iva * neto_gravado) / 100)
      : 0) +
    (alic_retenciones && neto_gravado
      ? parseFloat((alic_retenciones * neto_gravado) / 100)
      : 0) +
    (neto_gravado ? parseFloat(neto_gravado) : 0) +
    (alic_iva2 && neto_gravado2
      ? parseFloat((alic_iva2 * neto_gravado2) / 100)
      : 0) +
    (alic_retenciones2 && neto_gravado2
      ? parseFloat((alic_retenciones2 * neto_gravado2) / 100)
      : 0) +
    (neto_gravado2 ? parseFloat(neto_gravado2) : 0) +
    (iibb ? parseFloat(iibb) : 0) +
    (no_gravado ? parseFloat(no_gravado) : 0) -
    (pagos && pagos.length
      ? pagos.reduce(
          (a, b) => a + (isNaN(parseFloat(b.valor)) ? 0 : parseFloat(b.valor)),
          0
        )
      : 0);

  const dirRef = useRef('');
  useEffect(() => {
    dispatch(getProvedores());
    dispatch(getCajas());
    dispatch(getCheques());
    dispatch(getChequesPropios());
    if (current !== null) {
      setGasto(current);
    } else {
      setGasto(emptyGasto());
    }
  }, [current, open]);

  const onChange = (e, special_field = null) => {
   
    if (!special_field) {
      setGasto({ ...gasto, [e.target.name]: e.target.value });
    } else if (special_field === 'provedor') {
      const provedorTmp = provedores.find(p => p._id === e.value);

      setGasto({
        ...gasto,
        provedor: e.value,
        cuit: provedorTmp.cuit,
        alic_iva: provedorTmp.alic_iva ? provedorTmp.alic_iva : alic_iva,
        alic_iva2: provedorTmp.alic_iva2 ? provedorTmp.alic_iva2 : alic_iva2,
        alic_retenciones: provedorTmp.alic_retenciones
          ? provedorTmp.alic_retenciones
          : alic_retenciones,
        alic_retenciones2: provedorTmp.alic_retenciones2
          ? provedorTmp.alic_retenciones2
          : alic_retenciones2
      });
    } else if (special_field === 'tipo') {
      setGasto({ ...gasto, tipo: e.value });
    } else if (special_field === 'tipo_comprobante') {
      
      setGasto({
        ...gasto,

        alic_iva: 0,
        alic_iva2: 0,
        alic_retenciones: 0,
        alic_retenciones2: 0,
        tipo_comprobante: e.target.value
      });
      alic_iva_pesos = 0;
      alic_iva_pesos2 = 0;
    } else {
      setGasto({ ...gasto, [special_field]: e });
    }
  };

  const onChangePago = (e, index, campo) => {
    if (campo === 'valor') {
      setGasto({
        ...gasto,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], valor: e.floatValue },
          ...pagos.slice(index + 1)
        ]
      });
    } else if (campo === 'fecha_pago') {
      setGasto({
        ...gasto,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], fecha_pago: e },
          ...pagos.slice(index + 1)
        ]
      });
    } else if (campo === 'cheque') {
      let chequeTmp = cheques.find(cheque => cheque._id === e.value);
      setGasto({
        ...gasto,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], valor: chequeTmp.valor, cheque: chequeTmp._id },
          ...pagos.slice(index + 1)
        ]
      });
    }else if (campo === 'cheque_propio') {
      let chequeTmp = cheques_propios.find(cheque_propio => cheque_propio._id === e.value);
      setGasto({
        ...gasto,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], valor: chequeTmp.valor, cheque_propio: chequeTmp._id },
          ...pagos.slice(index + 1)
        ]
      });
    } else {
      setGasto({
        ...gasto,
        pagos: [
          ...pagos.slice(0, index),
          { ...pagos[index], [campo]: e.target.value },
          ...pagos.slice(index + 1)
        ]
      });
    }
  };

  const agregarPago = () => {
    setGasto({
      ...gasto,
      pagos: [...pagos, { valor: '', caja: '', fecha_pago: new Date() }]
    });
  };

  const agregarNotaDeCredito  = () => { }

  const agregarNotaDeDebito = () => { }

  const onValueChange = (values, field_name) => {
    if (field_name === 'alic_iva_pesos') {
      setGasto({
        ...gasto,
        neto_gravado: (100 * values.floatValue) / alic_iva
      });
    } else if (field_name === 'alic_iva_pesos2') {
      setGasto({
        ...gasto,
        neto_gravado2: (100 * values.floatValue) / alic_iva2
      });
    } else if (field_name === 'numero_comprobante') {
      setGasto({ ...gasto, [field_name]: values.formattedValue });
    } else setGasto({ ...gasto, [field_name]: values.floatValue });
  };

  const createOptionProvedor = label => ({
    razon_social: label,
    _id: ObjectID(new Date().getTime()).toHexString()
  });

  const handleCreateProvedor = async inputValue => {
    const newOption = createOptionProvedor(inputValue);

    provedores.push(newOption);
    dispatch(addProvedor(newOption));

    setGasto({ ...gasto, provedor: newOption._id });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!tipo || !neto_gravado) {
      alertContext.setAlert('Completar campos obligatorios', 'error');
    } else {
      try {
        if (!current) {
          dispatch(addGasto(gasto));
        } else {
          dispatch(updateGasto(gasto));
        }
      } catch (error) { 
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setGasto(emptyGasto());
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      fullScreen={true}
      style={!useMediaQuery(useTheme().breakpoints.down('sm'))?{ width: '90%', height: '95%', margin: 'auto' }:{}}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
      scroll='paper'
    >
      <DialogTitle id='max-width-dialog-title' color='primary' className={classes.formHeader} >
        {!current ? 'Nuevo Gasto' : 'Editar Gasto'}
      </DialogTitle>

      <DialogContent dividers={true}>
      {error && (<Snackbar open={error ? true : false} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  >
      <Alert severity="error">
        {error}
      </Alert>
      </Snackbar>)}
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            {!loading_provedores && (
              <Fragment>
              <Grid item xs={12} md={4}>
              <FormControl
                variant='outlined'
                style={{
                  height: '40px',
                  width: '100%',
                  paddingTop: '8px'
                }}
              >
                <ReactSelectNonCreate
                  fullWidth
                  value={
                    tipo && getTipoGastos().find(p => p === tipo)
                      ? {
                          label: tipo,
                          value: tipo
                        }
                      : ''
                  }
                  name='tipo'
                  placeholder='Tipo de Gasto'
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                      marginTop: '-8px'
                    })
                  }}
                  menuPortalTarget={document.body}
                  onChange={e => onChange(e, 'tipo')}
                  options={getTipoGastos().map(tipo => ({
                    value: tipo,
                    label: tipo
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}  md={8}>
              <TextField
                margin='dense'
                name='descripcion'
                label='Descripcion'
                variant='outlined'
                multiline
                id='descripcion'
                value={descripcion || ''}
                onChange={onChange}
                fullWidth
                rowsMax='3'
              />
            </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    variant='outlined'
                    style={{
                      height: '40px',
                      width: '100%',
                      paddingTop: '8px'
                    }}
                  >
                    <ReactSelect
                      fullWidth
                      value={
                        provedor && provedores.find(p => p._id === provedor)
                          ? {
                              label: provedores.find(p => p._id === provedor)
                                .razon_social,
                              value: provedor
                            }
                          : ''
                      }
                      name='provedor'
                      placeholder='Provedor: Seleccionar o nuevo...'
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                          marginTop: '-8px'
                        })
                      }}
                      menuPortalTarget={document.body}
                      formatCreateLabel={value => 'Nuevo provedor: ' + value}
                      onChange={e => onChange(e, 'provedor')}
                      onCreateOption={handleCreateProvedor}
                      options={provedores.map(provedor => ({
                        value: provedor._id,
                        label: provedor.razon_social
                      }))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <NumberFormat
                    customInput={TextField}
                    format='##-########-#'
                    mask='_'
                    decimalScale={0}
                    allowNegative={false}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    style={{ paddingBottom: '10px' }}
                    value={cuit || ''}
                    name='cuit'
                    label='CUIT'
                    onValueChange={values => onValueChange(values, 'cuit')}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} md={3}>
            <FormControl
              variant='outlined'
              style={{
                height: '40px',
                width: '100%',
                paddingTop: '8px'
              }}
            >
              <InputLabel htmlFor='select-tipo-comprobante'>
                Tipo Comprobante
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    style={{
                      height: '40px',
                      width: '100%'
                    }}
                    id='select-tipo-comprobante'
                  />
                }
                fullWidth
                value={tipo_comprobante ? tipo_comprobante : ''}
                name='tipo_comprobante'
                onChange={e => onChange(e, 'tipo_comprobante')}
              >
                {tiposComprobanteGastos.map(tipo => (
                  <MenuItem value={tipo} key={tipo}>
                    {tipo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: '10px' }}>
              Fecha Factura:{' '}
              <DatePicker
                onChange={date => onChange(date, 'fecha_gasto')}
                value={fecha_gasto ? moment(fecha_gasto).toDate() : null}
                format='dd/MM/y'
              />
            </Grid>


            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                format='####-########'
                decimalScale={0}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={numero_comprobante || ''}
                name='numero_comprobante'
                label='Número de Comprobante'
                onValueChange={values =>
                  onValueChange(values, 'numero_comprobante')
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
            <NumberFormat
              customInput={TextField}
              format='####-########'
              decimalScale={0}
              allowNegative={false}
              type='text'
              margin='dense'
              variant='outlined'
              fullWidth
              style={{ paddingBottom: '10px' }}
              value={numero_remito || ''}
              name='numero_remito'
              label='Número de remito'
              onValueChange={values =>
                onValueChange(values, 'numero_remito')
              }
            />
          </Grid>
            <Grid item xs={12} md={3} style={{ marginTop: '10px' }}>
            Fecha Recepcion:{' '}
            <DatePicker
              onChange={date => onChange(date, 'fecha_recepcion')}
              value={fecha_recepcion ? moment(fecha_recepcion).toDate() : null}
              format='dd/MM/y'
            />
          </Grid>
        <Grid item xs={12} md={3} style={{ marginTop: '10px' }}>
          Fecha Vencimiento:{' '}
          <DatePicker
            onChange={date => onChange(date, 'fecha_vencimiento')}
            value={fecha_vencimiento ? moment(fecha_vencimiento).toDate() : null}
            format='dd/MM/y'
          />
        </Grid>


            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_iva || ''}
                name='alic_iva'
                label='IVA %'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values => onValueChange(values, 'alic_iva')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_iva_pesos || ''}
                name='alic_iva_pesos'
                label='IVA $'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values =>
                  onValueChange(values, 'alic_iva_pesos')
                }
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                thousandSeparator='.'
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={neto_gravado || ''}
                name='neto_gravado'
                label='Neto Gravado $'
                onValueChange={(values, test) =>
                  onValueChange(values, 'neto_gravado', test)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_retenciones || ''}
                name='alic_retenciones'
                label='Retenciones %'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values =>
                  onValueChange(values, 'alic_retenciones')
                }
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_iva2 || ''}
                name='alic_iva2'
                label='IVA %'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values => onValueChange(values, 'alic_iva2')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_iva_pesos2 || ''}
                name='alic_iva_pesos2'
                label='IVA $'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values =>
                  onValueChange(values, 'alic_iva_pesos2')
                }
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                thousandSeparator='.'
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={neto_gravado2 || ''}
                name='neto_gravado2'
                label='Neto Gravado $'
                onValueChange={(values, test) =>
                  onValueChange(values, 'neto_gravado2', test)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={alic_retenciones2 || ''}
                name='alic_retenciones2'
                label='Retenciones %'
                disabled={
                  tipo_comprobante === 'Factura C' ||
                  tipo_comprobante === 'Factura X'
                }
                onValueChange={values =>
                  onValueChange(values, 'alic_retenciones2')
                }
              />
            </Grid>
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                thousandSeparator='.'
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={no_gravado || ''}
                name='no_gravado'
                label='No Gravado $'
                onValueChange={values => onValueChange(values, 'no_gravado')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                thousandSeparator='.'
                mask=''
                prefix='$'
                decimalScale={2}
                allowNegative={false}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth
                style={{ paddingBottom: '10px' }}
                value={iibb || ''}
                name='iibb'
                label='IIBB $'
                onValueChange={values => onValueChange(values, 'iibb')}
              />
            </Grid>
            <Grid item xs={12}>
              <h2 style={{ textAlign: 'right' }}>
                TOTAL: $
                <NumberFormat
                  displayType='text'
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  value={
                    (alic_iva && neto_gravado
                      ? parseFloat((alic_iva * neto_gravado) / 100)
                      : 0) +
                    (alic_iva2 && neto_gravado2
                      ? parseFloat((alic_iva2 * neto_gravado2) / 100)
                      : 0) +
                    (alic_retenciones && neto_gravado
                      ? parseFloat((alic_retenciones * neto_gravado) / 100)
                      : 0) +
                    (alic_retenciones2 && neto_gravado2
                      ? parseFloat((alic_retenciones2 * neto_gravado2) / 100)
                      : 0) +
                    (neto_gravado ? parseFloat(neto_gravado) : 0) +
                    (neto_gravado2 ? parseFloat(neto_gravado2) : 0) +
                    (iibb ? parseFloat(iibb) : 0) +
                    (no_gravado ? parseFloat(no_gravado) : 0)
                  }
                />
              </h2>
            </Grid>
          </Grid>
          <h2 color='primary'>PAGOS: </h2>
          {pagos &&
            pagos.map((pago, index) => {
              return (
                <Grid
                  key={`pagos[${index}]`}
                  container
                  className={classes.root}
                  spacing={2}
                >
                  {!loading_cajas && !loading_cheques && (
                    <Grid item xs={12} md={4}>
                      <FormControl
                        variant='outlined'
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingTop: '8px'
                        }}
                      >
                        <InputLabel htmlFor='select-tipo-gasto'>
                          Caja
                        </InputLabel>
                        <Select
                          input={
                            <OutlinedInput
                              style={{
                                height: '40px',
                                width: '100%'
                              }}
                              value={pago.caja}
                              id='select-tipo-gasto'
                            />
                          }
                          fullWidth
                          name={`pagos[${index}].caja`}
                          onChange={e => onChangePago(e, index, 'caja')}
                          value={pago.caja}
                        >
                          {cajas.map(caja =>
                            tipo_comprobante === 'Factura X' ? (
                              caja.nombre === 'Caja X' && (
                                <MenuItem value={caja._id} key={caja._id}>
                                  {caja.nombre} / {caja.tipo}
                                </MenuItem>
                              )
                            ) : (
                              caja.nombre !== 'Caja X' &&
                              <MenuItem value={caja._id} key={caja._id}>
                                {caja.nombre} / {caja.tipo}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {!loading_cheques &&
                  cajas.find(
                    caja => caja._id === pago.caja && caja.tipo === 'Cheque terceros'
                  ) ? (
                    <Grid item xs={12} md={4}>
                      <FormControl
                        variant='outlined'
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingTop: '8px'
                        }}
                      >
                        


                        <ReactSelectNonCreate

                          value={
                            pago.cheque && cheques.find(p => p._id === pago.cheque)
                              ? {
                                  label: cheques.find(p => p._id === pago.cheque).fecha_formatted+' / '+cheques.find(p => p._id === pago.cheque).razon_social+' / $'+cheques.find(p => p._id === pago.cheque).valor,
                                  value: pago.cheque
                                }
                              : ''
                          }
                          fullWidth
                          name={`pagos[${index}].cheque`}
                          styles={{
                            menuPortal: base => ({
                              ...base,
                              zIndex: 9999,
                              marginTop: '-8px'
                            })
                          }}
                          menuPortalTarget={document.body}
                          onChange={e => onChangePago(e, index, 'cheque')}
                          
                          options={cheques.filter(cheque=>(cheque.lanzamientoCajaObj === undefined ||
                            cheque.lanzamientoCajaObj.cheque ===
                              pago.cheque)).map(cheque => ( 
                              {
                            value: cheque._id,
                            label: cheque.fecha_formatted+' / '+cheque.razon_social+' / $'+cheque.valor
                          })
                          
                          )}
                        />

                        
                      </FormControl>
                      <input
                        type='hidden'
                        name={`pagos[${index}].valor`}
                        onChange={e => onChangePago(e, index, 'valor')}
                        value={
                          pago.valor
                            ? pago.valor.$numberDecimal
                              ? parseFloat(pago.valor.$numberDecimal)
                              : pago.valor
                            : ''
                        }
                      />
                    </Grid>
                  ) :( (!loading_cheques_propios &&
                    cajas.find(
                      caja => caja._id === pago.caja && caja.tipo === 'Cheque'
                    )) ? (
                      <Grid item xs={12} md={4}>
                        <FormControl
                          variant='outlined'
                          style={{
                            height: '40px',
                            width: '100%',
                            paddingTop: '8px'
                          }}
                        >
                          
  
  
                          <ReactSelectNonCreate
  
                            value={
                              pago.cheque_propio && cheques_propios.find(p => p._id === pago.cheque_propio)
                                ? {
                                    label: cheques_propios.find(p => p._id === pago.cheque_propio).fecha_formatted+' / $'+cheques_propios.find(p => p._id === pago.cheque_propio).valor,
                                    value: pago.cheque_propio
                                  }
                                : ''
                            }
                            fullWidth
                            name={`pagos[${index}].cheque_propio`}
                            styles={{
                              menuPortal: base => ({
                                ...base,
                                zIndex: 9999,
                                marginTop: '-8px'
                              })
                            }}
                            menuPortalTarget={document.body}
                            onChange={e => onChangePago(e, index, 'cheque_propio')}
                            
                            options={cheques_propios.filter(cheque_propio=>(cheque_propio.lanzamientoCajaObj === undefined ||
                              cheque_propio.lanzamientoCajaObj.cheque_propio ===
                                pago.cheque_propio)).map(cheque_propio => ( 
                                {
                              value: cheque_propio._id,
                              label: cheque_propio.fecha_formatted+' / $'+cheque_propio.valor
                            })
                            
                            )}
                          />
  
                          
                        </FormControl>
                        <input
                          type='hidden'
                          name={`pagos[${index}].valor`}
                          onChange={e => onChangePago(e, index, 'valor')}
                          value={
                            pago.valor
                              ? pago.valor.$numberDecimal
                                ? parseFloat(pago.valor.$numberDecimal)
                                : pago.valor
                              : ''
                          }
                        />
                      </Grid>
                    ):(
                    <Grid item xs={12} md={3}>
                      <NumberFormat
                        customInput={TextField}
                        decimalSeparator=','
                        thousandSeparator='.'
                        mask=''
                        prefix='$'
                        decimalScale={2}
                        value={
                          pago.valor
                            ? pago.valor.$numberDecimal
                              ? parseFloat(pago.valor.$numberDecimal)
                              : pago.valor
                            : ''
                        }
                        type='text'
                        margin='dense'
                        variant='outlined'
                        fullWidth
                        label='Valor'
                        style={{ paddingBottom: '10px' }}
                        name={`pagos[${index}].valor`}
                        onValueChange={e => onChangePago(e, index, 'valor')}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} md={4} style={{ marginTop: '10px' }}>
                    Fecha Pago:{' '}
                    <DatePicker
                      onChange={date => onChangePago(date, index, 'fecha_pago')}
                      value={
                        pago.fecha_pago
                          ? moment(pago.fecha_pago).toDate()
                          : null
                      }
                      format='dd/MM/y'
                    />
                  </Grid>
                </Grid>
              );
            })}
          <h4>
            <Button color='primary' href='#!' onClick={agregarPago}>
              Agregar Pago
            </Button>
          </h4>
          <h2 color='primary'>NOTAS DE CREDITO: </h2>
          <h4>
            <Button color='primary' href='#!' onClick={agregarNotaDeCredito}>
              Agregar nota de credito
            </Button>
          </h4>
          <h2 color='primary'>NOTAS DE DEBITO: </h2>
          <h4>
            <Button color='primary' href='#!' onClick={agregarNotaDeDebito}>
              Agregar nota de debito
            </Button>
          </h4>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <h2 style={{ textAlign: 'right' }}>
                BALANCE: $
                <NumberFormat
                  displayType='text'
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  value={
                    Math.round(balance * 100) / 100
                    //
                  }
                />
              </h2>
            </Grid>{' '}
          </Grid>
        </form>
      </DialogContent>
      {!saving ? (
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant='contained'
            color='primary'
            type='submit'
          >
            Guardar
        </Button>
          <Button onClick={handleClose} variant='contained' color='secondary'>
            Cancelar
        </Button>
        </DialogActions>
      ) : (
          <DialogActions className={classes.formFooter}>

            <CircularProgress color="secondary" />
          </DialogActions>
        )}
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    current: state.gastos.current,
    open: state.gastos.open,
    saving: state.gastos.saving,
    error: state.gastos.error,
    provedores: state.provedores.provedores,
    loading_provedores: state.provedores.loading,
    cajas: state.cajas.cajas,
    loading_cajas: state.cajas.loading,
    cheques: state.cheques.cheques,
    loading_cheques: state.cheques.loading,
    cheques_propios: state.chequesPropios.cheques_propios,
    loading_cheques_propios: state.chequesPropios.loading
  };
};

export default connect(mapStateToProps)(GastoForm);
