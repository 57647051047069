import { Snackbar } from "@material-ui/core/";
import { Alert } from "@material-ui/lab";
import React, { useContext } from "react";
import AlertContext from "../../context/alert/alertContext";

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map((alert) => (
      <Snackbar
        open={true}
        key={alert.id}
        className={`alert alert-${alert.type}`}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert color={alert.type}>
          <i className="fas fa-info-circle" /> {alert.msg}
        </Alert>
      </Snackbar>
    ))
  );
};

export default Alerts;
