import axios from "axios";

import {
  ADD_CAJA,
  DELETE_CAJA,
  SET_CURRENT_CAJA,
  CLEAR_CURRENT_CAJA,
  UPDATE_CAJA,
  FILTER_CAJA,
  CLEAR_FILTER_CAJA,
  ERROR_CAJA,
  GET_CAJA,
  CLEAR_CAJA,
  SET_OPEN_CAJA,
  TRANSFER_CAJA,
} from "./types";

//Add Caja
export const addCaja = (caja) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/cajas", caja, config);
      dispatch({ type: ADD_CAJA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CAJA, payload: error.msg });
    }
  };
};

export const getCajas = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/cajas");
      dispatch({ type: GET_CAJA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CAJA, payload: error.msg });
    }
  };
};

//Delete Caja

export const deleteCaja = (cajaId) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/cajas/${cajaId}`);

      dispatch({ type: DELETE_CAJA, payload: cajaId });
    } catch (error) {
      dispatch({ type: ERROR_CAJA, payload: error.response.msg });
    }
  };
};

//Set Current Caja

export function setCurrent(caja) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_CAJA, payload: caja });
  };
}

export function setOpen(open) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_CAJA, payload: open });
  };
}

//Clear Current Caja
export const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_CAJA });
  };
};
//Update Caja

export const updateCaja = (caja) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/cajas/${caja.id}`, caja, config);
      dispatch({ type: UPDATE_CAJA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CAJA, payload: error.response.msg });
    }
  };
};

export const transferCaja = (form) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/cajas/transfer`,
        {
          caja_desde: form.cajaFrom,
          caja_hacia: form.cajaTo,
          valor: form.value,
        },
        config
      );
      dispatch({ type: TRANSFER_CAJA, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_CAJA, payload: error.response.msg });
    }
  };
};

//Filter Caja

export const filterCajas = (text) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CAJA, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER_CAJA });
  };
};

export const clearCajas = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CAJA });
  };
};
