import React, { useEffect, useContext, Fragment } from 'react';

import AuthContext from '../../context/auth/authContext';
import Spinner from '../layout/Spinner';
import Dashboard from './Dashboard';

import M from 'materialize-css/dist/js/materialize.min.js';

const Home = props => {
  const authContext = useContext(AuthContext);
  const { loading, dispatch } = props;
  useEffect(() => {
    if (!authContext.loading && loading) M.AutoInit();
    if (localStorage.token) {
      authContext.loadUsuario();
      props.history.push("/panel_ventas");
    } else {
      props.history.push('/login');
    }
    // eslint-disable-next-line
  }, [authContext.loading]);

  return (
    <Fragment>
      {!authContext.loading ? (
        <div>
          <Dashboard />
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.transportistas.loading
  };
};

export default Home;
