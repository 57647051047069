import escapeRegExp from 'escape-string-regexp';
import moment from 'moment';
import {
  GET_USUARIOS,
  USUARIO_ERROR,
  DELETE_USUARIO,
  SET_CURRENT,
  SET_OPEN,
  CLEAR_CURRENT,
  FILTER_USUARIOS,
  CLEAR_FILTER,
  ADD_USUARIO,
  UPDATE_USUARIO,
  SET_SAVING_USUARIO
} from '../actions/types';

const initialState = {
  usuarios: null,
  current: null,
  filters: { descripcion: '', tipo: '' },
  filtered: null,
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  switch (action.type) {
    case GET_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
        loading: false
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    case SET_OPEN:
      return {
        ...state,
        open: action.payload
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };
      case SET_SAVING_USUARIO:
        return {
          ...state,
          saving: action.payload
        };

    case FILTER_USUARIOS:
      const shouldFilter = (usuario, property, value) => {
        switch (property) {
          case 'descripcion':
            return usuario.descripcion.match(
              new RegExp(`${escapeRegExp(value)}`, 'gi')
            );
            break;
          case 'tipo':
            return usuario.tipo.match(value);
            break;
          case 'fecha_usuario':
          case 'fecha_pago':
            return (
              formatDate(usuario[property]) >= formatDate(value[0]) &&
              formatDate(usuario[property]) <= formatDate(value[1])
            );
            break;
          default:
            break;
        }
      };
      return {
        ...state,
        filters: action.filter,
        filtered: state.usuarios.filter(usuario => {
          let ok = true;
          for (var property in action.filter) {
            //if (action.filterType === property) continue;
            if (
              action.filter.hasOwnProperty(property) &&
              action.filter[property]
            ) {
              ok = ok && shouldFilter(usuario, property, action.filter[property]);
            }
          }
          //ok = ok && shouldFilter(usuario, action.filterType, action.payload);
          return ok;
        })
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null
      };
    case ADD_USUARIO:
      return {
        ...state,
        usuarios: [action.payload, ...state.usuarios],
        loading: false
      };
    case UPDATE_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map(usuario =>
          usuario.id === action.payload.id ? action.payload : usuario
        )
      };
    case DELETE_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.filter(usuario => usuario.id !== action.payload),
        loading: false
      };
    /*

    case CLEAR_CONTACTS:
      return {
        ...state,
        transportistas: null,
        error: null,
        current: null,
        loading: null
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };

    case FILTER_CONTACTS:
      return {
        ...state,
        filtered: state.transportistas.filter(transportista => {
          const regex = new RegExp(`${escapeRegExp(action.payload)}`, 'gi');
          return (
            transportista.nombre.match(regex) ||
            transportista.email.match(regex)
          );
        })
      };

    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload
      };
      */
    default:
      return state;
  }
};
