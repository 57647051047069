import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { filterClientesPanelVentas } from "../../actions/clientes";
import { getZonas } from "../../actions/zonas";
import debounce from "lodash.debounce";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";

const VolumenVentasFilter = ({
  clientes,
  zonas,
  filter_panel_ventas,
  loading_zonas,
  dispatch,
  filtersVolumenVentas,
  setFiltersVolumenVentas,
}) => {
  useEffect(() => {
    if (!zonas || !zonas.length) dispatch(getZonas());
  });
  const onChange = (e, tipo) => {
    if (tipo === "zona") {
      setFiltersVolumenVentas({
        ...filtersVolumenVentas,
        zonas: e.target.value,
      });
    } else if (tipo === "forma_contacto") {
      setFiltersVolumenVentas({
        ...filtersVolumenVentas,
        forma_contacto: e.target.value,
      });
    } else {
      setFiltersVolumenVentas({
        ...filtersVolumenVentas,
        nombre: e.target.value,
      });
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {zonas && zonas.length && (
        <Grid container>
          <Grid item md={2}>
            <FormControl
              variant="outlined"
              style={{
                height: "40px",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <InputLabel htmlFor="select-tipo-comprobante">Zona</InputLabel>
              <Select
                input={
                  <OutlinedInput
                    style={{
                      height: "40px",
                      width: "80%",
                    }}
                    id="select-tipo-comprobante"
                  />
                }
                value={filtersVolumenVentas.zonas}
                name="zona"
                multiple={true}
                onChange={(e) => onChange(e, "zona")}
              >
                {zonas.map((zona) => (
                  <MenuItem value={zona.nombre} key={zona.id}>
                    {zona.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} style={{ margin: "auto", marginBottom: "22px" }}>
            Fecha:{" "}
            <DateRangePicker
              value={filtersVolumenVentas.fecha}
              onChange={(ranges) =>
                setFiltersVolumenVentas({
                  ...filtersVolumenVentas,
                  fecha: ranges,
                })
              }
              locale="es-AR"
            />{" "}
          </Grid>
          <Grid item md={7}>
            <TextField
              type="text"
              label="Filtrar clientes"
              margin="dense"
              variant="outlined"
              onChange={(e) => onChange(e, "nombre")}
              fullWidth
              value={filtersVolumenVentas.nombre}
              style={{ paddingBottom: "10px" }}
            />
          </Grid>
        </Grid>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.clientes.filter,
    filter_panel_ventas: state.clientes.filter_panel_ventas,
    filtered: state.clientes.filtered,
    clientes: state.clientes,
    zonas: state.zonas.zonas,
    loading_zonas: state.zonas.loading,
  };
};

export default connect(mapStateToProps)(VolumenVentasFilter);
