import axios from 'axios';

import {
  ADD_BANCO,
  DELETE_BANCO,
  SET_CURRENT_BANCO,
  CLEAR_CURRENT_BANCO,
  UPDATE_BANCO,
  FILTER_BANCO,
  CLEAR_FILTER_BANCO,
  ERROR_BANCO,
  GET_BANCO,
  CLEAR_BANCO,
  SET_OPEN_BANCO
} from './types';

//Add Banco
export const addBanco = banco => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.post('/api/bancos', banco, config);
      dispatch({ type: ADD_BANCO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_BANCO, payload: error.msg });
    }
  };
};

export const getBancos = () => {
  return async dispatch => {
    try {
      const res = await axios.get('/api/bancos');
      dispatch({ type: GET_BANCO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_BANCO, payload: error.msg });
    }
  };
};

//Delete Banco

export const deleteBanco = bancoId => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/bancos/${bancoId}`);

      dispatch({ type: DELETE_BANCO, payload: bancoId });
    } catch (error) {
      dispatch({ type: ERROR_BANCO, payload: error.response.msg });
    }
  };
};

//Set Current Banco

export function setCurrent(banco) {
  return dispatch => {
    dispatch({ type: SET_CURRENT_BANCO, payload: banco });
  };
}

export function setOpen(open) {
  return dispatch => {
    dispatch({ type: SET_OPEN_BANCO, payload: open });
  };
}

//Clear Current Banco
export const clearCurrent = () => {
  return dispatch => {
    dispatch({ type: CLEAR_CURRENT_BANCO });
  };
};
//Update Banco

export const updateBanco = banco => {
  return async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const res = await axios.put(`/api/bancos/${banco.id}`, banco, config);
      dispatch({ type: UPDATE_BANCO, payload: res.data });
    } catch (error) {
      dispatch({ type: ERROR_BANCO, payload: error.response.msg });
    }
  };
};

//Filter Banco

export const filterBancos = text => {
  return dispatch => {
    dispatch({ type: FILTER_BANCO, payload: text });
  };
};

//Clear Filter
export const clearFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FILTER_BANCO });
  };
};

export const clearBancos = () => {
  return dispatch => {
    dispatch({ type: CLEAR_BANCO });
  };
};
