import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect } from 'react-redux';
import {
  addProvedor,

  clearCurrent,
  setOpen, updateProvedor
} from '../../actions/provedores';
import { getFormasPago } from '../usuarios/Usuario';
import TelefonosInput from '../utils/TelefonosInput';



const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

const emptyProvedor = {
  nombre: '',
  razon_social: '',
  cuit: '',
  nombre_contacto: '',
  forma_pago: '',
  dias_pago: '',
  alic_iva: '',
  alic_retenciones: '',
  alic_iva2: '',
  alic_retenciones2: '',
  email: '',
  direccion: {
    direccion: '',
    placeId: null,
    detalles: ''
  },
  telefonos: []
};

const ProvedorForm = ({ children, current, open, dispatch }) => {
  const [provedor, setProvedor] = useState(emptyProvedor);

  const {
    nombre,
    razon_social,
    nombre_contacto,
    forma_pago,
    dias_pago,
    cuit,
    alic_iva,
    alic_retenciones,
    alic_iva2,
    alic_retenciones2,
    email,
    telefonos,
    direccion
  } = provedor;
  const dirRef = useRef('');
  useEffect(() => {
    if (current !== null) {
      setProvedor(current);
    } else {
      setProvedor(emptyProvedor);
    }
  }, [current, open]);

  const onChange = e => {
    if (e.target.name === 'direccion_detalles') {
      setProvedor({
        ...provedor,
        direccion: { ...direccion, detalles: e.target.value }
      });
    } else setProvedor({ ...provedor, [e.target.name]: e.target.value });
  };

  const onValueChange = (values, field_name) => {
    setProvedor({ ...provedor, [field_name]: values.floatValue });
  };

  const onChangeTel = (e, index, campo) => {
    setProvedor({
      ...provedor,
      telefonos: [
        ...telefonos.slice(0, index),
        { ...telefonos[index], [campo]: e.target.value },
        ...telefonos.slice(index + 1)
      ]
    });
  };

  const onChangeDir = (e, fromSelect = false, object = {}) => {
    let direccion, placeId;

    //Typed by the user!
    direccion = e;
    placeId = null;
    setProvedor({
      ...provedor,
      direccion: { direccion, placeId }
    });
  };

  const onSelectAddress = (direccion, placeId) => {
    setProvedor({
      ...provedor,
      direccion: { direccion, placeId }
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!razon_social) {
    } else {
      try {
        if (!current) {
          dispatch(addProvedor(provedor));
        } else {
          dispatch(updateProvedor(provedor));
        }
      } catch (error) { 
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setProvedor(emptyProvedor);
    dispatch(clearCurrent());
  };

  const onDeleteTelefono = (e, index) => {
    setProvedor({
      ...provedor,
      telefonos: [...telefonos.slice(0, index), ...telefonos.slice(index + 1)]
    });
  };

  const agregarTelefono = index => {
    setProvedor({
      ...provedor,
      telefonos: [...telefonos, { nombre:'', email: '', numero: '', descripcion: '', tipo: '' }]
    });
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      fullScreen={useMediaQuery(useTheme().breakpoints.down('sm'))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
      scroll='paper'
    >
      <DialogTitle id='max-width-dialog-title' color='primary'>
        {!current ? 'Nuevo provedor' : 'Editar provedor'}
      </DialogTitle>

      <DialogContent dividers={true}>
        <form onSubmit={onSubmit}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                autoFocus
                margin='dense'
                name='nombre'
                label='Nombre'
                value={nombre || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                autoFocus
                margin='dense'
                name='razon_social'
                label='Razon Social'
                value={razon_social || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                format='##-########-#'
                mask='_'
                decimalScale={0}
                allowNegative={false}
                margin='dense'
                name='cuit'
                label='CUIT'
                value={cuit || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                autoFocus
                margin='dense'
                name='nombre_contacto'
                label='Nombre de Contacto'
                value={nombre_contacto || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormControl
                variant='outlined'
                style={{
                  height: '40px',
                  width: '100%',
                  paddingTop: '8px'
                }}
              >
                <InputLabel htmlFor='select-forma-pago'>
                  Forma de Pago
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      style={{
                        height: '40px',
                        width: '100%'
                      }}
                      value={forma_pago}
                      id='select-forma-pago'
                    />
                  }
                  fullWidth
                  value={forma_pago}
                  name='forma_pago'
                  onChange={e => onChange(e)}
                >
                  {getFormasPago().map(tipo => (
                    <MenuItem value={tipo} key={tipo}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <NumberFormat
                customInput={TextField}
                format='###'
                decimalScale={0}
                allowNegative={false}
                margin='dense'
                name='dias_pago'
                label='Plazo de pago'
                value={dias_pago || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                margin='dense'
                name='alic_iva'
                label='IVA % (1)'
                value={alic_iva || ''}
                onValueChange={values => onValueChange(values, 'alic_iva')}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                margin='dense'
                name='alic_retenciones'
                label='Retenciones % (1)'
                value={alic_retenciones || ''}
                onValueChange={values =>
                  onValueChange(values, 'alic_retenciones')
                }
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                margin='dense'
                name='alic_iva2'
                label='IVA % (2)'
                value={alic_iva2 || ''}
                onValueChange={values => onValueChange(values, 'alic_iva2')}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberFormat
                customInput={TextField}
                decimalSeparator=','
                mask=''
                suffix='%'
                decimalScale={2}
                allowNegative={false}
                margin='dense'
                name='alic_retenciones2'
                label='Retenciones % (2)'
                value={alic_retenciones2 || ''}
                onValueChange={values =>
                  onValueChange(values, 'alic_retenciones2')
                }
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <PlacesAutocomplete
                value={(direccion && direccion.direccion) || ''}
                onChange={onChangeDir}
                onSelect={onSelectAddress}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div>
                    <TextField
                      variant='outlined'
                      fullWidth
                      label='Direccion'
                      autoComplete='nope'
                      {...getInputProps({
                        placeholder: 'Buscar direccion ...',
                        className: 'location-search-input'
                      })}
                    />
                    <div className={'autocomplete-dropdown-container'}>
                      {loading && <div>Cargando...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin='dense'
                name='direccion_detalles'
                label='Detalles (depto/referencia/etc)'
                value={(direccion && direccion.detalles) || ''}
                onChange={onChange}
                className={classes.textField}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                margin='dense'
                type='email'
                name='email'
                label='E-mail'
                variant='outlined'
                id='email'
                value={email}
                onChange={onChange}
                className={classes.textField}
                fullWidth
              />
            </Grid>
          </Grid>
          <h3 color='primary'>Contactos: </h3>
          {telefonos &&
            telefonos.map((telefono, index) => {
              return (
                <Fragment key={`telefonos[${index}]`}>
                  <TelefonosInput
                    index={index}
                    classes={classes}
                    telefono={telefono}
                    onChange={onChangeTel}
                    onDeleteTelefono={onDeleteTelefono}
                  />
                </Fragment>
              );
            })}
          <h4>
            <Button color='primary' href='#!' onClick={agregarTelefono}>
              Agregar Contacto
            </Button>
          </h4>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant='contained'
          color='primary'
          type='submit'
        >
          Guardar
        </Button>
        <Button onClick={handleClose} variant='contained' color='secondary'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    current: state.provedores.current,
    open: state.provedores.open
  };
};

export default connect(mapStateToProps)(ProvedorForm);
