import { Snackbar } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import DatePicker from "react-date-picker";
import ReactSelect from "react-select";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { addRendicion, clearCurrent, setOpen, updateRendicion } from "../../actions/rendiciones";
import { getListasPrecios } from "../../actions/listas_precios";
import { tiposFormasDePago } from "../../config/constantes";
import AlertContext from "../../context/alert/alertContext";
import { getCurrentUser } from "../usuarios/Usuario";
import { getUsuarios } from "../../actions/usuarios";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectFormControl: {
    marginTop: theme.spacing(1),
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  btnAfip: {
    marginTop: "5px"
  }
}));

const emptyRendicion = {
  fecha_cobranza: new Date(),
  rendidor_id: getCurrentUser().id,
  n_ruta: "",
  observaciones: "",
  rendidor: null,
  importe_deposito: "",
  importe_efectivo: "",
  importe_cheque: ""
};

const RendicionForm = ({
  children,
  current,
  loading,
  listas_precios,
  rendiciones,
  transportistas,
  loading_transportistas,
  loading_listas_precios,
  open,
  loading_usuarios,
  usuarios,
  dispatch,
  error,
  loading_afip
}) => {
  const [rendicion, setRendicion] = useState(emptyRendicion);

  const { rendidor_id, fecha_cobranza, n_ruta, observaciones, importe_deposito, importe_efectivo, importe_cheque } =
    rendicion;
  const alertContext = useContext(AlertContext);
  useEffect(() => {
    dispatch(getListasPrecios());
    dispatch(getUsuarios());
    if (current !== null) {
      setRendicion(current);
    } else {
      setRendicion(emptyRendicion);
    }
  }, [current, open]);

  const onChange = (e, special_field = null) => {
    if (!special_field) {
      setRendicion({ ...rendicion, [e.target.name]: e.target.value });
    } else if (
      special_field === "importe_efectivo" ||
      special_field === "importe_deposito" ||
      special_field === "importe_cheque"
    ) {
      setRendicion({
        ...rendicion,
        [special_field]: e.floatValue
      });
    } else if (special_field === "rendidor_id") {
      if (e) {
        const usuarioTmp = usuarios.find((p) => p.id === e.value);
        setRendicion({
          ...rendicion,
          rendidor_id: e.value,
          rendidor: usuarioTmp
        });
      } else {
        setRendicion({
          ...rendicion,
          rendidor_id: null,
          rendidor: null
        });
      }
    } else {
      setRendicion({ ...rendicion, [special_field]: e });
    }
  };

  const onValueChange = (values, field_name) => {
    setRendicion({ ...rendicion, [field_name]: values.floatValue });
  };

  const onChangeCheck = (event, name) => {
    setRendicion({ ...rendicion, [name]: event.target.checked });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!fecha_cobranza || !rendidor_id || !n_ruta) {
      alertContext.setAlert("Completar campos obligatorios", "error");
    } else {
      try {
        if (!current || !current.id) {
          await dispatch(addRendicion(rendicion));
        } else {
          await dispatch(updateRendicion(rendicion));
        }
      } catch (error) {
        alert(error);
      }

      clearAll();
      handleClose();
    }
  };

  const clearAll = () => {
    setRendicion(emptyRendicion);
    dispatch(clearCurrent());
  };

  const classes = useStyles();

  function handleClose() {
    dispatch(clearCurrent());
    dispatch(setOpen(false));
  } // To disable any eslint 'google not defined' errors

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={useMediaQuery(useTheme().breakpoints.down("sm"))}
      open={current || open ? true : false}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="max-width-dialog-title" color="secondary">
        {!current ? "Nueva rendición" : "Editar rendición"}
      </DialogTitle>

      <DialogContent dividers={true}>
        {error && (
          <Snackbar open={error ? true : false} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
        {!loading_usuarios && usuarios && usuarios.length && (
          <form onSubmit={onSubmit}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>
                <div>
                  Fecha de Cobranza: *{" "}
                  <DatePicker
                    onChange={(date) => onChange(date, "fecha cobranza")}
                    value={fecha_cobranza ? moment(fecha_cobranza).toDate() : null}
                    format="dd/MM/y"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="outlined"
                  style={{
                    height: "40px",
                    width: "100%",
                    paddingTop: "8px"
                  }}
                >
                  <ReactSelect
                    fullWidth
                    value={
                      rendidor_id && usuarios.find((p) => p.id === rendidor_id)
                        ? {
                            label: usuarios.find((p) => p.id === rendidor_id).nombre,
                            value: rendidor_id
                          }
                        : ""
                    }
                    name="rendidor_id"
                    isClearable
                    placeholder="Rendidor *"
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        marginTop: "-8px"
                      }),

                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                      })
                    }}
                    menuPortalTarget={document.body}
                    onChange={(e) => onChange(e, "rendidor_id")}
                    options={usuarios.map((usuario) => ({
                      value: usuario.id,
                      label: usuario.nombre
                    }))}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} style={{ marginTop: "10px" }}>
                {" "}
                Ruta:{" "}
                <Select
                  input={
                    <OutlinedInput
                      style={{
                        height: "35px",
                        width: "100px"
                      }}
                      margin="dense"
                      id="select-forma-pago"
                    />
                  }
                  placeholder="Ruta"
                  onChange={(e) => onChange(e)}
                  value={n_ruta}
                  name="n_ruta"
                >
                  {[1, 2, 3, 4].map((tipo, index) => (
                    <MenuItem value={tipo} key={index}>
                      {tipo}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator=","
                  thousandSeparator="."
                  mask=""
                  prefix="$"
                  decimalScale={2}
                  value={importe_efectivo ? importe_efectivo : ""}
                  type="text"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Efectivo"
                  style={{ paddingBottom: "10px" }}
                  name="importe_efectivo"
                  onValueChange={(e) => onChange(e, "importe_efectivo")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator=","
                  thousandSeparator="."
                  mask=""
                  prefix="$"
                  decimalScale={2}
                  value={importe_cheque ? importe_cheque : ""}
                  type="text"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Cheque"
                  style={{ paddingBottom: "10px" }}
                  name="importe_cheque"
                  onValueChange={(e) => onChange(e, "importe_cheque")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator=","
                  thousandSeparator="."
                  mask=""
                  prefix="$"
                  decimalScale={2}
                  value={importe_deposito ? importe_deposito : ""}
                  type="text"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Depósito"
                  style={{ paddingBottom: "10px" }}
                  name="importe_deposito"
                  onValueChange={(e) => onChange(e, "importe_deposito")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  name="observaciones"
                  label="Observaciones"
                  variant="outlined"
                  multiline
                  id="observaciones"
                  value={observaciones || ""}
                  onChange={onChange}
                  fullWidth
                  rowsMax="3"
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} variant="contained" color="primary" type="submit">
          Guardar
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    current: state.rendiciones.current,
    rendiciones: state.rendiciones.rendiciones,
    loading: state.rendiciones.loading,
    error: state.rendiciones.error,
    listas_precios: state.listasPrecios.listas_precios,
    loading_listas_precios: state.listasPrecios.loading,
    transportistas: state.transportistas.transportistas,
    loading_transportistas: state.transportistas.loading,
    open: state.rendiciones.open,
    loading_afip: state.rendiciones.loading_afip,
    usuarios: state.usuarios.usuarios,
    loading_usuarios: state.usuarios.loading_usuarios
  };
};

export default connect(mapStateToProps)(RendicionForm);
