import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getMixVentas,
  getVentasEstimadas,
} from "../../actions/ventas_estimadas";
import subscribeTo from "../../utils/subscribeTo";
import AddVentaEstimadaBtn from "./AddVentaEstimadaBtn";
import VentaEstimadaForm from "./VentaEstimadaForm";
import { getProductos } from "../../actions/productos";
import NumberFormat from "react-number-format";
import moment from "moment";

import "moment/locale/es";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
});

const VentasEstimadas = ({
  ventas_estimadas,
  filtered,
  loading,
  dispatch,
  mix_ventas,
  productos,
  loading_productos,
  ultimaSemana,
}) => {
  const classes = useStyles();

  const [productosOrdenados, setProductosOrdenados] = useState([]);

  useEffect(() => {
    if (productos && productos.length) {
      let tmp = [];
      for (let i = 0; i < productos.length; i++) {
        if (productos[i].id !== 6) tmp.push(productos[i]);
      }
      setProductosOrdenados(tmp);
    }
  }, [productos]);

  useEffect(() => {
    dispatch(getVentasEstimadas());
    dispatch(getProductos());
    dispatch(getMixVentas());
    if (!loading) {
      subscribeTo("ventas_estimadasChanged", (err, data) => {
        dispatch(getVentasEstimadas());
      });
      return () => {
        subscribeTo("ventas_estimadasChanged", null);
      };
    }
  }, [dispatch, loading]);

  useEffect(() => {
    console.log(mix_ventas);
  }, [mix_ventas]);

  const sumarMixVentasSemanal = (mix) => {
    return Object.keys(mix)
      .filter((k) => {
        return (
          k.indexOf("-") !== -1 &&
          moment(k).isoWeekYear() === moment().isoWeekYear() &&
          moment(k).isoWeek() === moment().isoWeek()
        );
      })
      .reduce((acc, v) => {
        return parseInt(mix[v]) + parseInt(acc);
      }, 0);
  };

  if (ventas_estimadas !== null && !ventas_estimadas.length && !loading) {
    return (
      <Fragment>
        <VentaEstimadaForm />
        <h4>No hay ventas estimadas!</h4> <AddVentaEstimadaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <VentaEstimadaForm />
      </div>
      <div>
        <div className={classes.root}>
          <h3>Ventas estimadas</h3>
          {ventas_estimadas &&
            ventas_estimadas.length &&
            !loading_productos &&
            productosOrdenados.length && (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow width="3%" className={classes.tableHeader}>
                      <TableCell align="center">Año</TableCell>
                      <TableCell align="center">Semana</TableCell>

                      <TableCell align="center">Packs Objetivo</TableCell>
                      <TableCell align="center">Ocupación</TableCell>
                      <TableCell align="center">Cantidad de packs</TableCell>
                      <TableCell align="center">
                        Porcentaje semanal de ventas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ventas_estimadas &&
                      ventas_estimadas.length &&
                      ventas_estimadas
                        .filter(
                          (ve) =>
                            !ultimaSemana ||
                            (ve.ano == moment().isoWeekYear() &&
                              ve.semana == moment().isoWeek())
                        )
                        .map((venta_estimada, index) => (
                          <>
                            <TableRow
                              className={
                                venta_estimada.chequeada
                                  ? classes.tableRowChequeada
                                  : classes.tableRow
                              }
                              key={index}
                            >
                              <TableCell align="center">
                                {venta_estimada.ano}
                              </TableCell>
                              <TableCell align="center">
                                {venta_estimada.semana}
                              </TableCell>
                              <TableCell align="center">
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  mask=""
                                  prefix=""
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  margin="dense"
                                  name="horaAtencion2Fin"
                                  label="Fin Tarde"
                                  value={
                                    venta_estimada.cantidad
                                      ? venta_estimada.cantidad
                                      : "-"
                                  }
                                  variant="outlined"
                                  fullWidth
                                />{" "}
                                <AddVentaEstimadaBtn />
                              </TableCell>
                              <TableCell align="center">
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  suffix="%"
                                  decimalScale={2}
                                  value={
                                    (productos.reduce(
                                      (acc, prod) => prod.stock + acc,
                                      0
                                    ) /
                                      35000) *
                                    100
                                  }
                                />
                              </TableCell>{" "}
                              <TableCell align="center">
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  suffix=""
                                  decimalScale={2}
                                  value={
                                    ventas_estimadas &&
                                    mix_ventas &&
                                    ventas_estimadas.find(
                                      (ve) =>
                                        ve.ano == moment().isoWeekYear() &&
                                        ve.semana == moment().isoWeek()
                                    )
                                      ? sumarMixVentasSemanal(mix_ventas)
                                      : 0
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                <NumberFormat
                                  displayType="text"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  suffix="%"
                                  decimalScale={2}
                                  value={
                                    ventas_estimadas &&
                                    mix_ventas &&
                                    ventas_estimadas.find(
                                      (ve) =>
                                        ve.ano == moment().isoWeekYear() &&
                                        ve.semana == moment().isoWeek()
                                    )
                                      ? (sumarMixVentasSemanal(mix_ventas) *
                                          100) /
                                        venta_estimada.cantidad
                                      : 0
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    filtered: state.ventasEstimadas.filtered,
    mix_ventas: state.ventasEstimadas.mix_ventas,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(VentasEstimadas);
