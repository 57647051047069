import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStore } from 'redux';
import { getTransportistas } from '../../actions/transportistas';
import subscribeTo from '../../utils/subscribeTo';


const About = ({ transportistas, dispatch, loading }) => {
  useEffect(() => {
    dispatch(getTransportistas());
    if (!loading) {
      subscribeTo('transportistasChanged', (err, data) => {
        dispatch(getTransportistas());
      });
      return () => {
        subscribeTo('transportistasChanged', null);
      };
    }
  }, [dispatch, loading]);

  const store = createStore((state = { count: 0 }, action) => {
    switch (action.type) {
      case 'INCREMENT':
        return {
          count: state.count + action.incrementBy
        };
      default:
        return state;
    }
  });
  const unsubscribe = store.subscribe(() => {
    //
  });

  store.dispatch({
    type: 'INCREMENT',
    incrementBy: 5
  });

  return (
    <div>
      <h1>Conexion REST API con AFIP</h1>

      <p className='bg-danger p'>
        <strong>Obtener ultimo comprobante de factura</strong>
        <br />
        <a
          href='/api/afip/tesst'
          target='_blank'
          rel='noopener noreferrer'
          className='text-dark'
        >
          CLCIK ACA
        </a>
      </p>

      <p className='bg-danger p'>
        <strong>Emitir factura de test</strong>
        <br />
        <a
          href='/api/afip/getVoucher'
          target='_blank'
          rel='noopener noreferrer'
          className='text-dark'
        >
          CLICK ACA
        </a>
      </p>
      
      <p className='bg-danger p'>
      <strong>Mostrar factura de test</strong>
      <br />
      <a
        href='/api/afip/getVoucher'
        target='_blank'
        rel='noopener noreferrer'
        className='text-dark'
      >
        CLICK ACA
      </a>
    </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    transportistas: state.transportistas.transportistas,
    loading: state.transportistas.loading
  };
};

export default connect(mapStateToProps)(About);
