import {
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import {
  getTransportistas,
  getTiempoPedidos,
} from "../../actions/transportistas";
import subscribeTo from "../../utils/subscribeTo";
import * as XLSX from "xlsx";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DatePicker from "react-date-picker";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  expansionPanel: {
    padding: "0 0 0 0",
  },
  summary: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
  },
  summary2: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
    width: "30%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "15%",
  },
  heading3: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    width: "100%",
  },
  expansionPanelFilter2: {
    backgroundColor: "white",
    /*   padding: "0 10px 20px 10px", */
  },
  heading2: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const OptimoCamino = ({
  transportistas,
  filtered,
  loading,
  dispatch,
  tiempos,
}) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (dates) => {
    setSelectedDate(moment(dates).format("YYYY-MM-DD"));

    window.open(
      "/api/transportistas/exportar_optimo_camino/" +
        moment(dates).format("YYYY-MM-DD")
    );
  };

  const classes = useStyles();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const exportar = (date) => {
    console.log("aca date", date);
    dispatch(getTiempoPedidos(date));
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    window.open("/api/transportistas/exportar_optimo_camino/" + date);
  };

  useEffect(() => {
    dispatch(getTiempoPedidos(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    dispatch(getTransportistas());
    if (!loading) {
      subscribeTo("transportistasChanged", (err, data) => {
        dispatch(getTransportistas());
      });
      return () => {
        subscribeTo("transportistasChanged", null);
      };
    }
  }, [dispatch, loading]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const downloadExcel = async (type) => {
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    if (type === "remitos") {
      try {
        const response = await axios({
          method: "post",
          url: "/api/transportistas/remitos",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("YYYYMMDD") + "-remitos.xlsx"
        );
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("todo deal with exception", error);
      }
    } else if (type === "hojaRuta") {
      const reader = new FileReader();

      reader.onload = async (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const camionetas = [];
        data.forEach((v) => {
          if (camionetas.indexOf(v.Nombre) === -1) camionetas.push(v.Nombre);
        });

        for (let camioneta of camionetas) {
          try {
            const response = await axios({
              method: "post",
              url: "/api/transportistas/rutas/" + camioneta,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
              responseType: "arraybuffer",
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              moment().format("YYYYMMDD") + "-ruta-" + camioneta + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          } catch (error) {}
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  return (
    <Fragment>
      <Accordion defaultExpanded>
        <AccordionSummary
          /*  expandIcon={<Icon>filter_list</Icon>} */
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading}>
            Exportar Optimo Camino
          </Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelFilter}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={8}>
              <div>
                {[
                  {
                    show: moment().format("ddd DD"),
                    date: moment().format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(1, "day").format("ddd DD"),
                    date: moment().add(1, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(2, "day").format("ddd DD"),
                    date: moment().add(2, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(3, "day").format("ddd DD"),
                    date: moment().add(3, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(4, "day").format("ddd DD"),
                    date: moment().add(4, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(5, "day").format("ddd DD"),
                    date: moment().add(5, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(6, "day").format("ddd DD"),
                    date: moment().add(6, "day").format("YYYY-MM-DD"),
                  },
                  {
                    show: moment().add(7, "day").format("ddd DD"),
                    date: moment().add(7, "day").format("YYYY-MM-DD"),
                  },
                ].map((day) => (
                  <Button
                    color="primary"
                    variant={"outlined"}
                    style={{ marginRight: "6px" }}
                    onClick={(e) => {
                      exportar(day.date);
                    }}
                  >
                    {day.show}
                  </Button>
                ))}
              </div>
              {tiempos && (
                <h3>
                  {" "}
                  Tiempo de {moment(selectedDate).format("DD MMMM")} -{" "}
                  {tiempos?.Tiempos}
                </h3>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              
                Otra fecha: {" "}
                <DatePicker
                  format="dd/MM/y"
                  value={selectedDate ? moment(selectedDate).toDate() : null}
                  onChange={(e) => handleDateChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          /*  expandIcon={<Icon>filter_list</Icon>} */
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.summary}
        >
          <Typography className={classes.heading3}>
            Exportar Remitos TD + Hoja de Ruta
          </Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelFilter}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={9}>
              Resumen Camino export:{" "}
              <input type="file" onChange={handleFileSelect} />
              <Grid item xs={12} md={9}>
                <Button
                  color="primary"
                  variant={"outlined"}
                  style={{ marginRight: "6px", marginTop: "15px" }}
                  onClick={(e) => {
                    downloadExcel("remitos");
                  }}
                >
                  Generar Remitos TD
                </Button>
                <Button
                  color="primary"
                  variant={"outlined"}
                  style={{ marginRight: "6px", marginTop: "15px" }}
                  onClick={(e) => {
                    downloadExcel("hojaRuta");
                  }}
                >
                  Generar Hoja de Ruta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    transportistas: state.transportistas.transportistas,
    filtered: state.transportistas.filtered,
    loading: state.transportistas.loading,
    tiempos: state.transportistas.tiempos,
  };
};

export default connect(mapStateToProps)(OptimoCamino);
