import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getVentasEstimadas,
  getMixVentas,
} from "../../actions/ventas_estimadas";
import subscribeTo from "../../utils/subscribeTo";
import AddVentaEstimadaBtn from "./AddVentaEstimadaBtn";
import VentaEstimadaForm from "./VentaEstimadaForm";
import { getProductos } from "../../actions/productos";
import NumberFormat from "react-number-format";
import { Button, Icon, IconButton, Tooltip } from "@material-ui/core";
import EditarStockForm from "./EditarStockForm";
import moment from "moment";
import "moment/locale/es";
import EditarStockMultipleForm from "./EditarStockMultipleForm";

moment.updateLocale("es", {
  week: {
    dow: 0, // First day of week is Monday
    doy: 5, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingBottom: "5px",
  },
  buttonHelp: {
    fontSize: "12px",
    borderRadius: 20,
    minWidth: "10px",
    width: "10px",
    minHeight: "20px",
    height: "20px",
  },
});

const Stocks = ({
  ventas_estimadas,
  mix_ventas,
  filtered,
  loading,
  dispatch,
  productos,
  loading_productos,
}) => {
  const classes = useStyles();

  const [productosOrdenados, setProductosOrdenados] = useState([]);
  const [openStock, setOpenStock] = useState(null);
  const [openStockMultiple, setOpenStockMultiple] = useState(null);

  useEffect(() => {
    if (productos && productos.length) {
      let tmp = [];
      for (let i = 0; i < productos.length; i++) {
        if (productos[i].id !== 6) tmp.push(productos[i]);
      }
      setProductosOrdenados(tmp);
    }
  }, [productos]);

  useEffect(() => {
    dispatch(getVentasEstimadas());
    dispatch(getMixVentas());
    dispatch(getProductos());
    if (!loading) {
      subscribeTo("ventas_estimadasChanged", (err, data) => {
        dispatch(getVentasEstimadas());
      });
      subscribeTo("productosChanged", (err, data) => {
        dispatch(getProductos());
      });
      return () => {
        subscribeTo("ventas_estimadasChanged", null);
      };
    }
  }, [dispatch, loading]);

  if (ventas_estimadas !== null && !ventas_estimadas.length && !loading) {
    return (
      <Fragment>
        <VentaEstimadaForm />
        <h4>No hay ventas estimadas!</h4> <AddVentaEstimadaBtn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <EditarStockForm openStock={openStock} setOpenStock={setOpenStock} />
        <EditarStockMultipleForm
          openStock={openStockMultiple}
          setOpenStock={setOpenStockMultiple}
          
        />
      </div>
      <div>
        <div className={classes.root}>
          <h3>Stock</h3>
          {productosOrdenados.length > 0 && mix_ventas && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow width="3%" className={classes.tableHeader}>
                    <TableCell align="center">Variedad</TableCell>
                    <TableCell align="center">Stock</TableCell>

                    <TableCell align="center">
                      MV{" "}
                      <Tooltip title="Mix Ventas" placement="top">
                        <Button
                          className={classes.buttonHelp}
                          size="small"
                          variant="outlined"
                        >
                          ?
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      MVS{" "}
                      <Tooltip title="Mix Ventas Semanal" placement="top">
                        <Button
                          className={classes.buttonHelp}
                          size="small"
                          variant="outlined"
                        >
                          ?
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      DS{" "}
                      <Tooltip title="Días de Stock" placement="top" arrow>
                        <Button
                          className={classes.buttonHelp}
                          size="small"
                          variant="outlined"
                        >
                          ?
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setOpenStockMultiple({
                            motivo_ajuste_stock_id: 2,
                          });
                        }}
                      >
                        <Icon>edit</Icon> Ajuste múltiple
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productosOrdenados.map((producto, index) => {
                    let diasStock =
                      ventas_estimadas.length &&
                      (ventas_estimadas.find(
                        (ve) =>
                          ve.ano == moment().isoWeekYear() &&
                          ve.semana == moment().isoWeek()
                      )
                        ? producto.stock /
                          ((ventas_estimadas.find(
                            (ve) =>
                              ve.ano == moment().isoWeekYear() &&
                              ve.semana == moment().isoWeek()
                          ).cantidad *
                            mix_ventas[producto.id].mix_ventas) /
                            5)
                        : "");
                    return (
                      <>
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell align="center">
                            {producto.nombre}
                          </TableCell>
                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              prefix=""
                              decimalScale={0}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={producto.stock}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={mix_ventas[producto.id].mix_ventas * 100}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              mask=""
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={
                                mix_ventas[producto.id].mix_ventas_ultima * 100
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              ...(diasStock <= 1.2
                                ? { color: "darkred" }
                                : diasStock <= 2
                                ? { color: "#dfdf00" }
                                : { color: "darkgreen" }),
                            }}
                          >
                            <NumberFormat
                              displayType="text"
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={2}
                              mask=""
                              fixedDecimalScale={true}
                              margin="dense"
                              name="horaAtencion2Fin"
                              label="Fin Tarde"
                              value={diasStock}
                              variant="outlined"
                              fullWidth
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Button
                              title={"Editar stock real"}
                              onClick={() => {
                                setOpenStock({
                                  id: producto.id,
                                  producto_id: producto.id,
                                  stock: producto.stock,
                                  nombre: producto.nombre,
                                  cantidad_anterior: producto.stock,
                                });
                              }}
                              style={{ color: "grey", minWidth: "32px" }}
                            >
                              <Icon>edit</Icon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ventas_estimadas: state.ventasEstimadas.ventas_estimadas,
    mix_ventas: state.ventasEstimadas.mix_ventas,
    filtered: state.ventasEstimadas.filtered,
    loading: state.ventasEstimadas.loading,
    productos: state.productos.productos,
    loading_productos: state.productos.loading,
  };
};

export default connect(mapStateToProps)(Stocks);
